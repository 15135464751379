import { useState } from "react";
import { Input, type InputProps } from "components/input";
import { Container, Label } from "./styles";

export interface NameProps extends InputProps {
  name: string;
}

export function Field({
  id: idProp,
  label,
  name,
  onValidation,
  ...props
}: NameProps) {
  const id = idProp ?? `faye-stripe-field-${name}`;

  const [error, setError] = useState(false);

  return (
    <Container>
      <Label disableAnimation error={error} htmlFor={id}>
        {label}
      </Label>

      <Input
        {...props}
        id={id}
        onValidation={(validation) => {
          setError(validation.status !== "valid");
          onValidation?.(validation);
        }}
        variant="simple"
      />
    </Container>
  );
}
