import { useOttUser } from "hooks/use-ott-user";
import { useEffect } from "react";

export function useGlindaDashboardRefresh() {
  const ottUser = useOttUser("glinda");

  useEffect(() => {
    if (!ottUser) {
      return;
    }

    const iframe = document.createElement("iframe");

    iframe.src = `${import.meta.env.VITE_GLINDA_URL}/api/refresh?t=dashboard`;
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    iframe.width = "0";
    iframe.height = "0";

    iframe.addEventListener("load", () => {
      iframe.remove();
    });

    document.body.append(iframe);
  }, [ottUser]);
}
