import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/input";
import { useDispatch } from "hooks/use-dispatch";
import { useSelector } from "hooks/use-selector";
import { setMainTravelerEmail } from "store/quote";
import { selectEmail } from "store/quote/selectors";
import { getAdvisorId } from "utils/tracking/utm";
import { type ValidationResult } from "utils/validation";
import { emailValidation } from "utils/validation/email";
import { notEmptyValidation } from "utils/validation/not-empty";
import { useEmailTracking, useTrackEmailSubmit } from "../tracking";
import {
  CollapsableSection,
  type CollapsableSectionProps,
} from "../collapsable-section";
import { EmailForm } from "./styles";

export interface EmailSectionProps
  extends Pick<
    CollapsableSectionProps,
    "active" | "completed" | "disabled" | "onActivate" | "showTitleBadge"
  > {
  onChange: (complete: boolean) => void;
}

export function EmailSection({
  active,
  onChange,
  ...props
}: EmailSectionProps) {
  useEmailTracking(active);
  const dispatch = useDispatch();
  const trackEmailSubmit = useTrackEmailSubmit();
  const { t } = useTranslation("screens", {
    keyPrefix: "checkout.sections.email",
  });
  const advisorId = getAdvisorId();

  const { t: tValidation } = useTranslation("validation");

  const [email, setEmail] = useState({
    validation: null as ValidationResult | null,
    value: useSelector(selectEmail) ?? "",
  });

  return (
    <CollapsableSection
      {...props}
      title={t(advisorId ? "advisors_title" : "title")}
      active={active}
    >
      <EmailForm>
        <Input
          autoComplete="email"
          label={t("input.label")}
          name="email"
          onChange={(event) => {
            setEmail({
              ...email,
              value: event.target.value.toLocaleLowerCase(),
            });
          }}
          onValidation={(validation) => {
            setEmail({ ...email, validation });

            if (email.validation !== null) {
              onChange(validation.status === "valid");

              if (validation?.status === "valid") {
                dispatch(setMainTravelerEmail(email.value));
                trackEmailSubmit(email.value);
              } else {
                dispatch(setMainTravelerEmail(null));
              }
            }
          }}
          required
          type="email"
          validate={[
            emailValidation,
            (value) =>
              notEmptyValidation(value) || tValidation("email.invalid"),
          ]}
          value={email.value}
          variant="simple"
        />
      </EmailForm>
    </CollapsableSection>
  );
}
