import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectTravelers } from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import { canTravelerDrive } from "utils/traveler";
import { type PopupSectionProps } from "../../popup-section";
import { CarCareContext } from "..";
import {
  Container,
  Dropdown,
  DropdownIcon,
  DropdownItem,
  Label,
} from "./styles";

export interface SelectedCarsProps extends PopupSectionProps {
  label: string;
}

export function SelectedCars({ label, ...props }: SelectedCarsProps) {
  const { t } = useTranslation("screens", {
    keyPrefix: "offer.addOns.carCare.popup",
  });
  const { cars, updateCars } = useContext(CarCareContext);
  const travelers = useSelector(selectTravelers);

  const maxCars = useMemo(() => {
    const drivers = travelers.filter((traveler) => canTravelerDrive(traveler));
    return Math.min(9, drivers.length);
  }, [travelers]);

  return (
    <Container {...props}>
      <Label>{label}</Label>

      <Dropdown
        size="small"
        value={cars}
        onChange={(e) => {
          updateCars(Number(e.target.value));
        }}
        inputProps={{
          "aria-label": t("cars.label"),
        }}
        IconComponent={DropdownIcon}
        MenuProps={{ disablePortal: true }}
      >
        {Array.from({ length: maxCars }).map((_, index) => (
          <DropdownItem
            key={index}
            value={index + 1}
            selected={index + 1 === cars}
          >
            {index + 1}
          </DropdownItem>
        ))}
      </Dropdown>
    </Container>
  );
}
