import { useEffect, useRef, useState, useContext } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  type StripeCardCvcElement,
  type StripeCardCvcElementChangeEvent,
  type StripeCardExpiryElement,
  type StripeCardExpiryElementChangeEvent,
  type StripeCardNumberElement,
  type StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "hooks/use-selector";
import { selectFullName, selectResidency } from "store/quote/selectors";
import { sendQuotePayment } from "utils/api/quote";
import { sentryCaptureError } from "utils/tracking/sentry";
import { type ValidationResult } from "utils/validation";
import { type CreditCardProps } from "../../credit-card";
import { useCardForm } from "./utils";
import { ProviderContext } from "../..";
import { Field } from "./field";
import {
  Container,
  ExtraFields,
  MainField,
  Padlock,
  PoweredByStripe,
  SecondaryField,
} from "./styles";

export function StripeCard({
  active,
  currentPaymentMethod,
  extraFields = false,
  formId,
  onLoad,
  onChange,
  onPaymentChange,
  quoteDto,
  userPolicy,
  ...props
}: CreditCardProps) {
  const fullName = useSelector(selectFullName);
  const residency = useSelector(selectResidency);

  const stripe = useStripe();
  const { isActivated } = useContext(ProviderContext) ?? {};

  const { t } = useTranslation("components", {
    keyPrefix: "stripe.paymentForm",
  });

  const cvcFieldRef = useRef<StripeCardCvcElement | null>(null);
  const expiryFieldRef = useRef<StripeCardExpiryElement | null>(null);
  const numberFieldRef = useRef<StripeCardNumberElement | null>(null);
  const [isAmex, setIsAmex] = useState(false);

  const onPaymentChangeRef = useRef(onPaymentChange);
  onPaymentChangeRef.current = onPaymentChange;

  const [name, setName] = useState({
    validation: null as ValidationResult | null,
    value: fullName,
  });

  const [postalCode, setPostalCode] = useState({
    validation: null as ValidationResult | null,
    value: residency?.zipCode ?? "",
  });

  const { ready, complete, error, setReady, handleFieldChange } = useCardForm({
    fullName,
    extraFields,
    onChange,
    zipCode: residency?.zipCode,
  });

  useEffect(() => {
    if (isActivated !== undefined) {
      onLoad();
    }
  }, [isActivated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (active && isActivated === false) {
      onPaymentChangeRef.current("creditCard", {
        status: "error",
        message: t("error.default"),
      });
    }
  }, [isActivated, active]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (active && extraFields && !postalCode.value && residency?.zipCode) {
      setPostalCode({ ...postalCode, value: residency.zipCode });
    }
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (active && !isMobile && ready.number) {
      numberFieldRef.current?.focus();
    }
  }, [active, ready.number]);

  return (
    <Container
      id={formId}
      onSubmit={async (event) => {
        event.preventDefault();

        if (
          !stripe ||
          !complete.cvc ||
          !complete.expiry ||
          !complete.name ||
          !complete.number ||
          !complete.postalCode ||
          !numberFieldRef.current ||
          currentPaymentMethod
        ) {
          return;
        }

        onPaymentChangeRef.current("creditCard", { status: "request" });

        try {
          const { error, token } = await stripe.createToken(
            numberFieldRef.current,
            { address_zip: postalCode.value, name: name.value },
          );

          if (error ?? !token) {
            throw new Error(error.message ?? t("creditCard.error.token"));
          }

          onPaymentChangeRef.current("creditCard", {
            status: "success",
            response: await sendQuotePayment(
              {
                paymentService: "stripe",
                paymentToken: token.id,
                purchasePaymentType: "PAYMENT_TYPE_CREDIT_CARD",
                ...(userPolicy?.id && { quoteId: userPolicy.id }),
              },
              quoteDto,
            ),
          });
        } catch (error) {
          sentryCaptureError(error, {
            contexts: { payment: { paymentType: "creditCard" } },
            name: "Payment_failed",
          });

          onPaymentChangeRef.current("creditCard", {
            status: "error",
            lead: t("errorPopup.lead"),
            message:
              (error as Error).message ??
              (typeof error === "string" ? error : t("error.default")),
          });
        }
      }}
    >
      <MainField
        {...props}
        disabled={!!currentPaymentMethod}
        endAdornment={<Padlock />}
        elementRef={numberFieldRef}
        error={!!error.number}
        {...(typeof error.number === "string" && { helperText: error.number })}
        label={
          <>
            {t("creditCard.number.label")}
            <PoweredByStripe />
          </>
        }
        onChange={(event: StripeCardNumberElementChangeEvent) => {
          if (event.brand === "amex") {
            if (!isAmex) {
              setIsAmex(true);
            }
          } else if (isAmex) {
            setIsAmex(false);
          }

          handleFieldChange("number", {
            complete: event.complete,
            error: event.error?.message,
          });

          if (event.complete) {
            expiryFieldRef.current?.focus();
          }
        }}
        onReady={() => {
          setReady((prev) => ({ ...prev, number: true }));
        }}
        options={{ showIcon: true }}
        placeholder="0000 0000 0000 0000"
        type="number"
      />

      <SecondaryField
        {...props}
        disabled={!!currentPaymentMethod}
        elementRef={expiryFieldRef}
        error={!!error.expiry}
        {...(typeof error.expiry === "string" && { helperText: error.expiry })}
        label={t("creditCard.expiry.label")}
        onChange={(event: StripeCardExpiryElementChangeEvent) => {
          handleFieldChange("expiry", {
            complete: event.complete,
            error: event.error?.message,
          });

          if (event.complete) {
            cvcFieldRef.current?.focus();
          }
        }}
        onReady={() => {
          setReady((prev) => ({ ...prev, expiry: true }));
        }}
        type="expiry"
      />

      <SecondaryField
        {...props}
        disabled={!!currentPaymentMethod}
        elementRef={cvcFieldRef}
        error={!!error.cvc}
        {...(typeof error.cvc === "string" && { helperText: error.cvc })}
        label={t("creditCard.cvc.label")}
        onChange={(event: StripeCardCvcElementChangeEvent) => {
          handleFieldChange("cvc", {
            complete: event.complete,
            error: event.error?.message,
          });
        }}
        onReady={() => {
          setReady((prev) => ({ ...prev, cvc: true }));
        }}
        placeholder={isAmex ? "0000" : "000"}
        type="cvc"
      />

      {extraFields && (
        <ExtraFields>
          <Field
            label="Name on card"
            name="name"
            onChange={(event) => {
              setName({
                ...name,
                value: event.target.value,
              });
            }}
            onValidation={(validation) => {
              const error = validation.status !== "valid";

              setName({
                ...name,
                validation,
              });

              handleFieldChange("name", {
                complete: !error,
                error,
              });
            }}
            validate={(value) => !!value}
            value={name.value}
          />

          <Field
            label="Zip code"
            name="postal-code"
            onChange={(event) => {
              setPostalCode({
                ...postalCode,
                value: event.target.value,
              });
            }}
            onValidation={(validation) => {
              const error = validation.status !== "valid";

              setPostalCode({
                ...postalCode,
                validation,
              });

              handleFieldChange("postalCode", {
                complete: !error,
                error,
              });
            }}
            required
            validate={(value) => /^\d{5}$/.test(value)}
            value={postalCode.value}
          />
        </ExtraFields>
      )}
    </Container>
  );
}
