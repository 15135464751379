import { styled } from "@mui/material/styles";

export const AddressForm = styled("div", {
  label: "address-form",
})`
  margin: ${({ theme }) => theme.spacing(3, 0, 2)};

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    max-width: ${({ theme }) => theme.spacing(52)};
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  ${({ theme }) => theme.breakpoints.up("tablet")} {
    padding-left: ${({ theme }) => theme.spacing(5)};
  }
`;

export const Lead = styled("div", {
  label: "lead",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 14px;
  font-weight: 500;
  margin: ${({ theme }) => theme.spacing(0.5, 0, 0, 4.5)};

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    font-size: 18px;
    margin-left: 0;
  }
`;
