import { styled } from "@mui/material/styles";

export const Component = styled("div", {
  label: "disclaimer",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  margin-top: ${({ theme }) => theme.spacing(4)};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-right: ${({ theme }) => theme.spacing(-2)};
    margin-left: ${({ theme }) => theme.spacing(-2)};
  }
`;
