import { PopupBody } from "components/popup/body";
import {
  Popup as PopupComponent,
  type PopupProps as PopupComponentProps,
} from "components/popup";
import { Item, ItemTitle, PopupHeader } from "./styles";

export interface PopupDataProps {
  content: Array<{
    description: string;
    title: string;
  }>;
  title: string;
}

export interface PopupProps
  extends Omit<PopupComponentProps, keyof PopupDataProps>,
    PopupDataProps {
  onClose: () => void;
}

export function Popup({ content, onClose, title, ...props }: PopupProps) {
  return (
    <PopupComponent {...props} onClose={onClose}>
      <PopupHeader onClose={onClose} title={title} />

      <PopupBody>
        {content.map(({ description, title }) => (
          <Item key={title}>
            <ItemTitle>{title}</ItemTitle>
            <p>{description}</p>
          </Item>
        ))}
      </PopupBody>
    </PopupComponent>
  );
}
