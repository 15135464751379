import classNames from "classnames";
import { type ReactNode, useContext, useEffect } from "react";
import { isMobileSafari } from "react-device-detect";
import { OfferLayoutContext } from "..";
import { Container } from "./styles";

export interface OfferLayoutPrintProps {
  children: ReactNode;
}

export function OfferLayoutPrint({ children }: OfferLayoutPrintProps) {
  const { setHasPrintLayout } = useContext(OfferLayoutContext);

  useEffect(() => {
    setHasPrintLayout(true);

    return () => {
      setHasPrintLayout(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={classNames(isMobileSafari && "mobileSafari")}>
      {children}
    </Container>
  );
}
