import { type ButtonBaseProps } from "@mui/material/ButtonBase";
import { forwardRef } from "react";
import { ArrowIcon, DefaultComponent, ProgressComponent } from "./styles";

export interface PopupButtonProps extends ButtonBaseProps {
  loading?: boolean;
  showArrow?: boolean;
}

export const PopupButton = forwardRef<HTMLButtonElement, PopupButtonProps>(
  function PopupButton({ loading, children, showArrow, ...props }, ref) {
    if (loading !== undefined) {
      return (
        <ProgressComponent ref={ref} loading={loading} {...props}>
          {children}
        </ProgressComponent>
      );
    }

    return (
      <DefaultComponent ref={ref} {...props}>
        {children}
        {showArrow && <ArrowIcon />}
      </DefaultComponent>
    );
  },
);
