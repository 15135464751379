import Dialog, { type DialogProps } from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useBreakpoint } from "hooks/use-breakpoint";
import { useEventListener } from "hooks/use-event-listener";
import { Paper } from "./styles";

const WINDOW_INNER_HEIGHT_CUSTOM_PROPERTY_NAME = "--faye-window-inner-height";

function updateWindowInnerHeightCustomProperty() {
  document.documentElement.style.setProperty(
    WINDOW_INNER_HEIGHT_CUSTOM_PROPERTY_NAME,
    `${window.innerHeight}px`,
  );
}

export interface PopupProps
  extends Omit<DialogProps, "PaperComponent" | "fullScreen"> {}

export function Popup(props: PopupProps) {
  const smBreakpoint = useBreakpoint("sm", { noSsr: true });
  const theme = useTheme();

  useEventListener(
    "resize",
    window,
    () => {
      if (!smBreakpoint && props.open) {
        updateWindowInnerHeightCustomProperty();
      }
    },
    [props.open, smBreakpoint],
  );

  useEffect(() => {
    if (!smBreakpoint && props.open) {
      const { pathname } = window.location;
      const top = window.scrollY;

      window.setTimeout(() => {
        updateWindowInnerHeightCustomProperty();
        document.documentElement.style.overflow = "hidden";
      }, theme.transitions.duration.enteringScreen);

      return () => {
        // useEventListener uses window.requestAnimationFrame to throttle
        // the event listener. Therefore, window.setTimeout below is necessary
        // to properly clean up <html> styles while resizing screen and
        // disabling showAsWindow (i.e. mobile orientation change).
        window.setTimeout(() => {
          document.documentElement.style.overflow = "";
          document.documentElement.style.removeProperty(
            WINDOW_INNER_HEIGHT_CUSTOM_PROPERTY_NAME,
          );

          if (pathname === window.location.pathname) {
            window.scroll({ top });
          }
        }, 0);
      };
    }
  }, [props.open, smBreakpoint, theme.transitions.duration.enteringScreen]);

  return (
    <Dialog {...props} fullScreen={!smBreakpoint} PaperComponent={Paper} />
  );
}
