import { styled } from "@mui/material/styles";
import { CircularLogo } from "components/logo/circular";

export const Container = styled("div", {
  label: "confirmation-layout",
})`
  ${({ theme }) => theme.breakpoints.up("md")} {
    background-color: ${({ theme }) => theme.palette.color.primary[110]};
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  background-color: ${({ theme }) => theme.palette.color.gray[100]};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  position: relative;

  ${({ theme }) => theme.breakpoints.up("md")} {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
    display: flex;
    gap: ${({ theme }) => theme.spacing(4)};
    padding: ${({ theme }) => theme.spacing(5, 15, 10)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: ${({ theme }) => theme.spacing(16)};
  }
`;

export const Logo = styled(CircularLogo, { label: "logo" })`
  display: none;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: initial;
    margin: ${({ theme }) => theme.spacing(4, 0, -6)};
    z-index: 1;
  }
`;

export const Main = styled("main", {
  label: "main",
})`
  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: ${({ theme }) => theme.spacing(0, 4)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-right: ${({ theme }) => theme.spacing(10)};
    padding-left: ${({ theme }) => theme.spacing(10)};
  }
`;
