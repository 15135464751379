import { loadScript } from "utils/load-script";

export interface GooglePlacesAddress {
  city?: string;
  postalCode?: string;
  stateCode?: string;
  street?: string;
  streetNumber?: string;
  apartment?: string;
}

const CACHE: {
  autocompleteService?: google.maps.places.AutocompleteService;
  geocoder?: google.maps.Geocoder;
  loaded: boolean;
  placesService?: google.maps.places.PlacesService;
  sessionToken: {
    timeout: number;
    value: google.maps.places.AutocompleteSessionToken;
  } | null;
} = {
  loaded: false,
  sessionToken: null,
};

function getSessionToken(
  canGenerate: true,
): google.maps.places.AutocompleteSessionToken;
function getSessionToken(
  canGenerate?: false,
): google.maps.places.AutocompleteSessionToken | null;
function getSessionToken(
  canGenerate = false,
): google.maps.places.AutocompleteSessionToken | null {
  if (CACHE.sessionToken?.value) {
    return CACHE.sessionToken.value;
  } else if (!canGenerate) {
    return null;
  }

  CACHE.sessionToken = {
    // According to the official docs, a session token expires a few minutes
    // after the beginning of a session:
    // https://developers.google.com/maps/documentation/places/web-service/session-tokens#example.
    // Therefore, expire it at the shortest "a few minutes" possible (2m), to
    // avoid being billed for each request if Google expires it.
    timeout: window.setTimeout(expireGooglePlacesSessionToken, 120000),
    value: new google.maps.places.AutocompleteSessionToken(),
  };

  return CACHE.sessionToken.value;
}

export async function loadGooglePlacesApi() {
  if (CACHE.loaded) {
    return;
  }

  if (!(await loadScript("googlePlacesApi"))) {
    throw new Error("Error while loading Google Places API");
  }

  CACHE.loaded = true;
}

export function expireGooglePlacesSessionToken(
  sessionToken?: google.maps.places.AutocompleteSessionToken,
) {
  if (
    CACHE.sessionToken &&
    (!sessionToken || CACHE.sessionToken.value === sessionToken)
  ) {
    window.clearTimeout(CACHE.sessionToken?.timeout);
    CACHE.sessionToken = null;
  }
}

export async function getGooglePlacesAddress(
  placeId: string,
  options?: { useSessionToken?: boolean },
) {
  const sessionToken = getSessionToken();

  // Geocoder API is cheaper than Places API for single requests,
  // so use it if an active session is not available
  const addressComponents = (
    options?.useSessionToken && sessionToken
      ? await googlePlacesGetDetails(placeId, {
          fields: ["address_components"],
          sessionToken,
        })
      : await googlePlacesGeocode(placeId)
  ).address_components as google.maps.GeocoderAddressComponent[];

  return addressComponents.reduce<GooglePlacesAddress>((address, component) => {
    if (component.types.includes("locality")) {
      address.city = component.long_name;
    } else if (component.types.includes("postal_code")) {
      address.postalCode = component.long_name;
    } else if (component.types.includes("administrative_area_level_1")) {
      address.stateCode = component.short_name;
    } else if (component.types.includes("route")) {
      address.street = component.long_name;
    } else if (component.types.includes("street_number")) {
      address.streetNumber = component.long_name;
    } else if (component.types.includes("sublocality") && !address.city) {
      address.city = component.long_name;
    } else if (component.types.includes("subpremise")) {
      address.apartment = component.short_name;
    }

    return address;
  }, {});
}

export async function googlePlacesAutocomplete(
  input: string,
  options?: {
    countries?: string[];
    types: ["address" | "(regions)"];
    useSessionToken?: boolean;
  },
): Promise<google.maps.places.AutocompletePrediction[]> {
  if (!CACHE.autocompleteService) {
    await loadGooglePlacesApi();
    CACHE.autocompleteService = new google.maps.places.AutocompleteService();
  } else if (!input) {
    return [];
  }

  const request: google.maps.places.AutocompletionRequest = {
    componentRestrictions: { country: options?.countries ?? null },
    input,
    language: "en",
    region: "us",
    types: options?.types,
  };

  if (options?.useSessionToken) {
    request.sessionToken = getSessionToken(true);
  }

  return (await CACHE.autocompleteService.getPlacePredictions(request))
    .predictions;
}

export async function googlePlacesGeocode(placeId: string) {
  if (!CACHE.geocoder) {
    await loadGooglePlacesApi();
    CACHE.geocoder = new google.maps.Geocoder();
  }

  const response = await CACHE.geocoder.geocode({
    language: "en",
    placeId,
    region: "us",
  });

  if (!response.results.length) {
    throw new Error("No results returned by Google Geocoder geocode");
  }

  return response.results[0];
}

export async function googlePlacesGetDetails(
  placeId: string,
  options: {
    fields: Array<"address_components" | "photos">;
    sessionToken?: google.maps.places.AutocompleteSessionToken;
    useSessionToken?: boolean;
  },
): Promise<google.maps.places.PlaceResult> {
  if (!CACHE.placesService) {
    await loadGooglePlacesApi();
    CACHE.placesService = new google.maps.places.PlacesService(
      document.createElement("div"),
    );
  }

  return await new Promise((resolve, reject) => {
    const request: google.maps.places.PlaceDetailsRequest = {
      fields: options.fields,
      language: "en",
      placeId,
      region: "us",
    };

    if (options.sessionToken) {
      request.sessionToken = options.sessionToken;
      expireGooglePlacesSessionToken(options.sessionToken);
    } else if (options.useSessionToken) {
      const sessionToken = getSessionToken();
      if (sessionToken) {
        request.sessionToken = sessionToken;
        expireGooglePlacesSessionToken();
      }
    }

    CACHE.placesService?.getDetails(request, (result, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        reject(new Error(`Google Places getDetails error: "${status}"`));
      } else if (!result) {
        reject(new Error("No results returned by Google Places getDetails"));
      } else {
        resolve(result);
      }
    });
  });
}

export function isGooglePlacesAddressComplete(
  googlePlacesAddress: GooglePlacesAddress,
) {
  return (
    !!googlePlacesAddress &&
    !!googlePlacesAddress.city &&
    !!googlePlacesAddress.postalCode &&
    !!googlePlacesAddress.stateCode &&
    !!googlePlacesAddress.street &&
    !!googlePlacesAddress.streetNumber
  );
}
