import { styled } from "@mui/material/styles";

export const ErrorMessage = styled("p", {
  label: "error-message",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  text-align: center;
`;

export const ErrorStack = styled("pre", {
  label: "error-stack",
})`
  background: ${({ theme }) => theme.palette.color.primary.main};
  color: ${({ theme }) => theme.palette.color.text.inverse};
  overflow-x: scroll;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const Title = styled("h1", {
  label: "title",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 42px;
  font-weight: 400;
  margin: ${({ theme }) => theme.spacing(4, 0)};
  text-align: center;
  white-space: pre-line;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 36px;
  }
`;
