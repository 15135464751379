import classNames from "classnames";
import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useState,
} from "react";
import { Outlet } from "react-router-dom";
import { Container, Main } from "./styles";

export const OfferLayoutContext = createContext({
  setHasPrintLayout: (() => {}) as Dispatch<SetStateAction<boolean>>,
});

export function OfferLayout() {
  const [hasPrintLayout, setHasPrintLayout] = useState(false);

  return (
    <OfferLayoutContext.Provider value={{ setHasPrintLayout }}>
      <Container className={classNames({ hasPrintLayout })}>
        <Main>
          <Outlet />
        </Main>
      </Container>
    </OfferLayoutContext.Provider>
  );
}
