import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "details",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 16px;
  line-height: 1.3;
  margin: ${({ theme }) => theme.spacing(2.5, -0.5, 0)};
  &::after {
    content: "";
    border-bottom: 1px dotted ${({ theme }) => theme.palette.color.primary[90]};
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    margin: ${({ theme }) => theme.spacing(2, 0)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 18px;
    justify-content: flex-start;
    margin-top: ${({ theme }) => theme.spacing(1.5)};
    &::after {
      content: unset;
    }
  }
`;

export const Item = styled("span", {
  label: "item",
})`
  font-weight: 400;
  margin: ${({ theme }) => theme.spacing(0, 0.5, 0)};
`;

export const HighlightedItem = styled(Item, {
  label: "destination-item",
})`
  font-weight: 500;
  display: block;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: initial;
  }
`;
