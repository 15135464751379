import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDestinations } from "store/quote/selectors";
import { type PlaceDetails } from "types/quote-types";

function hasResidencyStateInDestinations(
  stateCode?: string,
  destinations?: PlaceDetails[],
) {
  return (
    !!destinations &&
    !!stateCode &&
    destinations.some(
      ({ areaLevel1, country }) => country === "US" && areaLevel1 === stateCode,
    )
  );
}

export function useResidencyStateInDestinations(stateCode?: string) {
  const destinations = useSelector(selectDestinations);

  const [value, setValue] = useState(
    hasResidencyStateInDestinations(stateCode, destinations),
  );

  useEffect(() => {
    const stateInDestinations = hasResidencyStateInDestinations(
      stateCode,
      destinations,
    );

    if (stateInDestinations !== value) {
      setValue(stateInDestinations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinations, stateCode, value]);

  return value;
}
