import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  type FocusEvent,
  type KeyboardEvent,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { type InputProps } from "components/input";
import { InputGroup } from "components/input-group";
import { MAX_TRAVELER_AGE } from "settings";
import { type ValidationResult } from "utils/validation";
import {
  type Birthdate,
  calculateAge,
  stringifyBirthdate,
} from "utils/birthdate";
import { Input } from "./styles";

export interface BirthdateFieldProps {
  autoFocus?: boolean;
  className?: string;
  label?: boolean;
  name?: string;
  onChange: (value: Birthdate) => void;
  onValidation?: (validation: ValidationResult) => void;
  required?: boolean;
  value: Birthdate;
  variant?: InputProps["variant"];
}

export function BirthdateField({
  autoFocus,
  className,
  label = true,
  name,
  onChange,
  onValidation,
  required,
  value,
  variant,
}: BirthdateFieldProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "birthdateField",
  });

  const canFocusPrevious = useRef(false);
  const dayRef = useRef<HTMLInputElement | null>(null);
  const monthRef = useRef<HTMLInputElement | null>(null);
  const yearRef = useRef<HTMLInputElement | null>(null);
  const focused = useRef(false);
  const focusNext = useRef<"day" | "month" | null>(null);

  const onValidationRef = useRef(onValidation);
  onValidationRef.current = onValidation;

  const [blurred, setBlurred] = useState(false);
  const [blurredWithError, setBlurredWithError] = useState(false);

  const [errors, setErrors] = useState({
    min: false,
    max: false,
    month: false,
    day: false,
    year: false,
    required: false,
  });

  const [errorKey, setErrorKey] = useState<keyof typeof errors | null>(null);
  const hasError = !!errorKey;

  function blur() {
    focused.current = false;
    // setBlurred on next tick so, if another input from this group is focused,
    // focused.current will be set to true meanwhile. If it remains false, this
    // component lose the focus.
    window.setTimeout(() => {
      setBlurred(true);
    }, 0);
  }

  function focus(event: FocusEvent<HTMLInputElement>) {
    canFocusPrevious.current = !event.target.value;
    focused.current = true;
  }

  function keyUp(
    event: KeyboardEvent,
    value: string,
    targetRef: RefObject<HTMLInputElement | null>,
  ) {
    if (targetRef.current && !value && event.code === "Backspace") {
      if (canFocusPrevious.current) {
        targetRef.current.focus();
      } else {
        canFocusPrevious.current = true;
      }
    }
  }

  function padValue(value: string) {
    return value !== "0" && value.length === 1 ? `0${value}` : null;
  }

  function inputError(fieldValidation: boolean) {
    return (
      blurredWithError &&
      (fieldValidation || !!errors.min || !!errors.max || !!errors.required)
    );
  }

  useEffect(() => {
    const day = value.day !== "" ? parseInt(value.day) : null;
    const month = value.month !== "" ? parseInt(value.month) : null;
    const year = value.year !== "" ? parseInt(value.year) : null;

    const partial =
      (+!!day + +!!month + +!!year > 0 && +!!day + +!!month + +!!year < 3) ||
      (year ?? 0) < 1000;

    const age = partial ? null : calculateAge(stringifyBirthdate(value));
    const daysInMonth = dayjs(`${year ?? 2000}-${month ?? 1}`).daysInMonth();

    const inputErrors: typeof errors = {
      required: age === null && !!required,
      month: month === null ? partial : isNaN(month) || month < 1 || month > 12,
      day: day === null ? partial : isNaN(day) || day < 1 || day > daysInMonth,
      year: year === null ? partial : isNaN(year) || year < 1000,
      min: age !== null && age < 0,
      max: age !== null && age >= MAX_TRAVELER_AGE,
    };

    setErrors(inputErrors);

    setErrorKey(
      (Object.entries(inputErrors).find(([_, value]) => value)?.[0] ??
        null) as typeof errorKey,
    );
  }, [value, required]);

  useEffect(() => {
    if (focusNext.current === "day" && !errors.day) {
      yearRef.current?.focus();
    } else if (focusNext.current === "month" && !errors.month) {
      dayRef.current?.focus();
    }

    if (focusNext.current) {
      focusNext.current = null;
    }
  }, [errors]);

  useEffect(() => {
    if (blurred) {
      setBlurred(false);

      if (!focused.current) {
        setBlurredWithError(hasError);
      }
    }
  }, [blurred, hasError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!onValidationRef.current) return;

    if (hasError) {
      onValidationRef.current({
        status: "error",
        helperText: t(`validation.${errorKey}`),
      });
    } else {
      onValidationRef.current({
        status: "valid",
        helperText: null,
      });
    }
  }, [hasError, errorKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InputGroup
      className={className}
      error={blurredWithError}
      {...(label && {
        label: {
          required,
          text: t("label"),
          htmlFor: "faye-birthdate-month-field",
        },
      })}
      helperText={blurredWithError && errorKey && t(`validation.${errorKey}`)}
    >
      <Input
        autoFocus={autoFocus}
        autoComplete="off"
        error={inputError(!!errors.month)}
        id="faye-birthdate-month-field"
        inputProps={{
          "aria-label": t("month.label"),
        }}
        inputRef={monthRef}
        label={t("month.placeholder")}
        name={name && `${name}_month`}
        onBlur={(event) => {
          const month = padValue(event.target.value);

          if (month !== null) {
            onChange({ ...value, month });
          }

          blur();
        }}
        onChange={(event) => {
          const month = event.target.value.replace(/[^\d]/g, "").slice(0, 2);
          onChange({ ...value, month });
          canFocusPrevious.current = false;

          if (month.length === 2) {
            focusNext.current = "month";
          }
        }}
        onFocus={focus}
        type="tel"
        value={value.month}
        variant={variant}
      />

      <Input
        autoComplete="off"
        error={inputError(!!errors.day)}
        inputProps={{
          "aria-label": t("day.label"),
        }}
        inputRef={dayRef}
        label={t("day.placeholder")}
        name={name && `${name}_day`}
        onBlur={(event) => {
          const day = padValue(event.target.value);

          if (day !== null) {
            onChange({ ...value, day });
          }

          blur();
        }}
        onChange={(event) => {
          const day = event.target.value.replace(/[^\d]/g, "").slice(0, 2);
          onChange({ ...value, day });
          canFocusPrevious.current = false;

          if (day.length === 2) {
            focusNext.current = "day";
          }
        }}
        onFocus={focus}
        onKeyUp={(event) => {
          keyUp(event, value.day, monthRef);
        }}
        type="tel"
        value={value.day}
        variant={variant}
      />

      <Input
        autoComplete="off"
        error={inputError(!!errors.year)}
        inputProps={{
          "aria-label": t("year.label"),
        }}
        inputRef={yearRef}
        label={t("year.placeholder")}
        name={name && `${name}_year`}
        onBlur={blur}
        onChange={(event) => {
          const year = event.target.value.replace(/[^\d]/g, "").slice(0, 4);
          onChange({ ...value, year });
          canFocusPrevious.current = false;
        }}
        onFocus={focus}
        onKeyUp={(event) => {
          keyUp(event, value.year, dayRef);
        }}
        type="tel"
        value={value.year}
        variant={variant}
      />
    </InputGroup>
  );
}
