import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { IconButton as IconButtonComponent } from "components/button/icon";
import MinusIconBoldAsset from "assets/images/icons/minus-bold.svg";
import PlusIconAsset from "assets/images/icons/plus.svg";

export const IconButton = styled(IconButtonComponent, {
  label: "icon-button",
})`
  && {
    border: 1px solid ${({ theme }) => theme.palette.color.primary[90]};
    border-radius: ${({ theme }) => theme.shape.borderRadius * 3}px;
    flex: 0 0 ${({ theme }) => theme.spacing(3)};

    height: ${({ theme }) => theme.spacing(3)};
    padding: 0;
  }
`;

export const Container = styled("div", {
  label: "input",
})`
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(0, 1)};
  position: relative;
`;

export const Field = styled(InputBase, {
  label: "field",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.color.primary.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 1.5}px;
  padding: ${({ theme }) => theme.spacing(1)};

  &.${inputBaseClasses.error} {
    border-color: ${({ theme }) => theme.palette.color.alert.main};
    color: ${({ theme }) => theme.palette.color.alert.main};
  }

  .${inputBaseClasses.input} {
    font-size: 24px;
    font-weight: 600;
    padding: 0;
    text-align: center;
  }
`;

export const ErrorMessage = styled("div", {
  label: "error-message",
})`
  color: ${({ theme }) => theme.palette.color.alert.main};
  font-size: 14px;
  left: 0;
  line-height: 1.29;
  margin-top: ${({ theme }) => theme.spacing(1)};
  position: absolute;
  width: 100%;
`;

export const MinusIcon = styled(MinusIconBoldAsset, {
  label: "minus-icon",
})`
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
  fill: currentColor;
`;

export const PlusIcon = styled(PlusIconAsset, {
  label: "plus-icon",
})`
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
  fill: currentColor;
`;
