import { useTranslation } from "react-i18next";
import { useWhatsCoveredStrings } from "../../utils";
import { Title } from "../title";
import { TopicList } from "../topic-list";

export function WhatsCovered() {
  const { t } = useTranslation("screens", {
    keyPrefix: "offer.printLayout.whatsCovered",
  });

  const whatsCoveredStrings = useWhatsCoveredStrings();

  return (
    whatsCoveredStrings && (
      <>
        <Title>{t("title")}</Title>

        <Title>{whatsCoveredStrings.yourHealth.title}</Title>
        <TopicList
          items={whatsCoveredStrings.yourHealth.topics}
          template="plain"
        />
        <TopicList
          items={whatsCoveredStrings.yourHealth.popup.content}
          template="details"
        />

        <Title>{whatsCoveredStrings.yourTrip.title}</Title>
        <TopicList
          items={whatsCoveredStrings.yourTrip.topics}
          template="plain"
        />
        <TopicList
          items={whatsCoveredStrings.yourTrip.popup.content}
          template="details"
        />

        <Title>{whatsCoveredStrings.yourStuff.title}</Title>
        <TopicList
          items={whatsCoveredStrings.yourStuff.topics}
          template="plain"
        />
        <TopicList
          items={whatsCoveredStrings.yourStuff.popup.content}
          template="details"
        />
      </>
    )
  );
}
