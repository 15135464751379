import { styled } from "@mui/material/styles";

export const Component = styled("p", {
  label: "popup-subtitle",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 14px;
  line-height: 1;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;
