import { styled } from "@mui/material/styles";
import { BaseButton } from "components/button/base";
import AppStoreImageAsset from "assets/images/app-store.svg";
import GooglePlayImageAsset from "assets/images/google-play.svg";

export const AppStoreImage = styled(AppStoreImageAsset, {
  label: "app-store-image",
})`
  display: block;
  max-width: 100%;
  width: auto;
  height: ${({ theme }) => theme.spacing(4.5)};
`;

export const GooglePlayImage = styled(GooglePlayImageAsset, {
  label: "google-play-image",
})`
  display: block;
  max-width: 100%;
  width: auto;
  height: ${({ theme }) => theme.spacing(4.5)};
`;

export const Container = styled("div", {
  label: "download",
})`
  margin-top: ${({ theme }) => theme.spacing(3)};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: inherit;
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  margin-top: ${({ theme }) => theme.spacing(0.5)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: flex-end;
    display: flex;
  }
`;

export const Link = styled(BaseButton, {
  label: "link",
})`
  & + & {
    margin-left: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const Links = styled("div", {
  label: "links",
})`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)};
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: flex-start;
  }
`;

export const Lead = styled("div", {
  label: "lead",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  ${({ theme }) => theme.breakpoints.up("md")} {
    line-height: 1.4;
  }
`;

export const Title = styled("h2", {
  label: "title",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};

  .caption {
    color: ${({ theme }) => theme.palette.color.text.primary};
    font-size: 14px;
    font-weight: 400;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(1)};
    .caption {
      color: currentColor;
      font-size: inherit;
      font-weight: inherit;
    }
  }
`;
