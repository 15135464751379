import { apiFetch } from ".";

const CACHE: {
  token?: Promise<string>;
} = {};

export async function getPayPalToken(): Promise<string> {
  if (CACHE.token === undefined) {
    CACHE.token = apiFetch("paypal/token").then(
      async (response) => await response.text(),
    );
  }

  return await CACHE.token;
}
