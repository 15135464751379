import { buttonBaseClasses } from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import { ProgressButton } from "components/button/progress";

export const Component = styled(ProgressButton, {
  label: "button",
})`
  background: ${({ theme }) => theme.palette.color.gray[10]};
  color: ${({ theme }) => theme.palette.color.gray[100]};
  flex: 0 1 ${({ theme }) => theme.spacing(20)};
  font-size: 18px;
  margin: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(1.5, 4)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  width: 0;

  &.${buttonBaseClasses.disabled}, &.success {
    pointer-events: none;
  }

  &.outlined {
    border-color: ${({ theme }) => theme.palette.color.gray[10]};
    color: ${({ theme }) => theme.palette.color.gray[10]};
  }

  &.success {
    padding: ${({ theme }) => theme.spacing(2, 4)};
  }
`;

export const Svg = styled("svg", {
  label: "svg",
})`
  fill: currentColor;
  margin: 1px 0;
  transition: ${({ theme }) => theme.transitions.create("filter")};
  width: auto;
  height: ${({ theme }) => theme.spacing(2.25)};

  .monochrome &,
  .${buttonBaseClasses.disabled} & {
    filter: brightness(0) invert(1);
  }

  .outlined.${buttonBaseClasses.disabled} & {
    filter: brightness(0) invert(1) brightness(80%);
  }
`;
