import classNames from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ScreenHeader } from "components/screen-header";
import { OnboardingLayoutContext } from "layouts/onboarding";
import { useDispatch } from "hooks/use-dispatch";
import { useSelector } from "hooks/use-selector";
import { ENTRY_ROUTE } from "settings";
import {
  resetQuoteStatus,
  setDirectOfferDetailsFromUrlParams,
  setRegularQuote,
  setReviewAndEdit,
} from "store/quote";
import {
  selectCalculate,
  selectCanGetQuote,
  selectEmail,
  selectIsDirectOffer,
  selectIsRemarketing,
  selectQuote,
  selectQuoteFromHash,
  selectPolicyCodeHash,
  selectReviewAndEdit,
} from "store/quote/selectors";
import {
  calculateQuotePrice,
  getQuote,
  getQuoteFromHash,
} from "store/quote/thunks/quote";
import { useTracking } from "./tracking";
import { useProgressFromText } from "./use-progress-from-text";
import lottieUrl from "./lottie.json?url";
import { emailValidation } from "utils/validation/email";
import { setExperimentVariation } from "utils/experiments";
import { Container, Lottie, LottieContainer, LottieTrack } from "./styles";

const TOTAL_STEPS = 3;

export interface LoaderScreenProps {
  onComplete: () => void;
}

export function LoaderScreen({ onComplete }: LoaderScreenProps) {
  const dispatch = useDispatch();
  const calculate = useSelector(selectCalculate);
  const canGetQuote = useSelector(selectCanGetQuote);
  const email = useSelector(selectEmail);
  const isDirectOffer = useSelector(selectIsDirectOffer);
  const isRemarketing = useSelector(selectIsRemarketing);
  const quote = useSelector(selectQuote);
  const quoteFromHash = useSelector(selectQuoteFromHash);
  const policyCodeHash = useSelector(selectPolicyCodeHash);
  const reviewAndEdit = useSelector(selectReviewAndEdit);

  const { search } = useLocation();
  const { updateAvatar } = useContext(OnboardingLayoutContext);
  const navigate = useNavigate();

  const { t } = useTranslation("screens", {
    keyPrefix: "loader",
  });

  const [lottieIsReady, setLottieIsReady] = useState(false);
  const [navigateToEntryRoute, setNavigateToEntryRoute] = useState(false);

  const [previousPolicyCodeHash, setPreviousPolicyCodeHash] = useState<
    string | null
  >(null);

  const lead = t("lead");
  const title = t(isDirectOffer ? "title.offer" : "title.default");

  const { completeStep, step } = useProgressFromText(
    title + lead,
    TOTAL_STEPS,
    completeLoad,
  );

  const dispatchAndCompleteStep = useCallback(
    (
      action:
        | ReturnType<typeof calculateQuotePrice>
        | ReturnType<typeof getQuote>
        | ReturnType<typeof getQuoteFromHash>,
    ) => {
      const typedAction = action as ReturnType<typeof calculateQuotePrice> &
        ReturnType<typeof getQuote> &
        ReturnType<typeof getQuoteFromHash>;

      void dispatch(typedAction)
        .unwrap()
        .then(() => {
          if (navigateToEntryRoute) {
            completeStep(TOTAL_STEPS);
          } else {
            completeStep();
          }
        })
        .catch((error) => {
          // The order here is important, completeStep should be called first
          completeStep(TOTAL_STEPS);
          setNavigateToEntryRoute(true);
          throw error;
        });
    },
    [completeStep, dispatch, navigateToEntryRoute],
  );

  useEffect(() => {
    updateAvatar(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reviewAndEdit === "editing" && !previousPolicyCodeHash) {
      setPreviousPolicyCodeHash(policyCodeHash ?? null);
    }
  }, [policyCodeHash, previousPolicyCodeHash, reviewAndEdit]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const emailFromUrl = searchParams.get("email")?.replaceAll(" ", "+");
    const isEmailValid = emailFromUrl && emailValidation(emailFromUrl) === true;

    // TODO Remove quote_hash support once it's not being used anymore
    const policyCodeHash =
      searchParams.get("hash") || searchParams.get("quote_hash");

    // Review and edit
    if (searchParams.has("r")) {
      dispatch(setReviewAndEdit("reviewing"));
      setNavigateToEntryRoute(true);
    }

    if (policyCodeHash) {
      dispatch(
        setDirectOfferDetailsFromUrlParams({
          ...(isEmailValid ? { email: emailFromUrl } : {}),
          isRemarketing: searchParams.has("is_remarketing"),
          policyCodeHash,
        }),
      );

      if (!isEmailValid) {
        setExperimentVariation("shortOb", "active");
      }
    } else {
      dispatch(setRegularQuote());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step === 0 && canGetQuote) {
      dispatch(resetQuoteStatus());
      dispatchAndCompleteStep(isDirectOffer ? getQuoteFromHash() : getQuote());
    }
  }, [canGetQuote, dispatch, dispatchAndCompleteStep, isDirectOffer, step]);

  useEffect(() => {
    if (
      step === 1 &&
      (quote.status === "fulfilled" || quoteFromHash.status === "fulfilled")
    ) {
      dispatchAndCompleteStep(
        previousPolicyCodeHash
          ? calculateQuotePrice(previousPolicyCodeHash)
          : calculateQuotePrice(),
      );
    }
  }, [
    dispatchAndCompleteStep,
    previousPolicyCodeHash,
    quote.status,
    quoteFromHash.status,
    step,
  ]);

  useEffect(() => {
    if (step === 2 && calculate.status === "fulfilled") {
      completeStep();
    }
  }, [calculate.status, completeStep, step]);

  useTracking();

  function completeLoad() {
    const searchParams = new URLSearchParams();

    if (email) {
      searchParams.set("email", email);
    }

    if (isRemarketing) {
      searchParams.set("is_remarketing", "1");
    }

    if (policyCodeHash) {
      searchParams.set("hash", policyCodeHash);
    }

    if (navigateToEntryRoute) {
      navigate(ENTRY_ROUTE);
    } else {
      navigate(
        { pathname: "/offer", search: searchParams.toString() },
        { replace: true },
      );
    }

    onComplete();
  }

  return (
    <Container>
      <ScreenHeader title={title} lead={lead} />

      <LottieContainer>
        <LottieTrack>
          <Lottie
            className={classNames({ lottieIsReady })}
            onReady={() => {
              setLottieIsReady(true);
            }}
            play
            url={lottieUrl}
          />
        </LottieTrack>
      </LottieContainer>
    </Container>
  );
}
