import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "actions",
})`
  margin: ${({ theme }) => theme.spacing(3, 0, 0)};
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-wrap: wrap;
    margin: ${({ theme }) => theme.spacing(2, 0, 0)};
  }
`;
