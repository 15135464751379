import {
  type AnimationEventCallback,
  type AnimationEvents,
  type BMCompleteEvent,
} from "lottie-web";
import {
  type ComponentPropsWithoutRef,
  type MutableRefObject,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useLatest } from "react-use";
import {
  type LottieAnimation,
  type LottieOptions,
  useLottieAnimation,
} from "./utils";

export interface LottieProps extends ComponentPropsWithoutRef<"div"> {
  onComplete?: AnimationEventCallback<AnimationEvents["complete"]>;
  onReady?: () => void;
  options?: LottieOptions;
  play?: boolean | "restart";
  preload?: boolean;
  url: string;
}

export const Lottie = forwardRef<LottieAnimation, LottieProps>(function Lottie(
  { onComplete, onReady, options, play = false, preload, url, ...props },
  ref,
) {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const latestOnComplete = useLatest(onComplete);
  const latestOnReady = useLatest(onReady);

  const animation = useLottieAnimation(
    containerRef as MutableRefObject<HTMLDivElement>,
    url,
    options,
  );

  useImperativeHandle(ref, () => animation, [animation]);

  useEffect(() => {
    if (animation.ready) {
      latestOnReady.current?.();
    }
  }, [animation.ready]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (animation.ready) {
      if (latestOnComplete.current) {
        function handleComplete(event: BMCompleteEvent) {
          latestOnComplete.current?.(event);
        }

        animation.addEventListener("complete", handleComplete);

        return () => {
          animation.removeEventListener("complete", handleComplete);
        };
      }
    }
  }, [animation.ready]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!animation.ready) {
      if ((play || preload) && !animation.item) {
        animation.load();
      }
    } else if (play) {
      animation.play(play === "restart");
    } else {
      animation.pause();
    }
  }, [animation, play, preload]);

  return <div ref={containerRef} {...props} />;
});
