import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "policy-price",
})`
  font-size: 52px;
  font-weight: 700;
  line-height: 1;

  .currency,
  .fraction {
    font-size: 26px;
  }

  .currency {
    font-weight: 600;
  }
`;
