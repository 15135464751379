import Autocomplete, {
  type AutocompleteProps,
} from "@mui/material/Autocomplete";
import { Paper } from "./paper";
import { Popper } from "./popper";
import { ClearIcon, DropdownIcon } from "./styles";

export interface BaseAutocompleteProps<
  Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo> {
  compact?: boolean;
}

export function BaseAutocomplete<
  Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  compact,
  ...props
}: BaseAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>) {
  return (
    <Autocomplete
      autoHighlight
      clearIcon={<ClearIcon />}
      PaperComponent={(props) => <Paper compact={compact} {...props} />}
      PopperComponent={Popper}
      popupIcon={<DropdownIcon />}
      {...props}
    />
  );
}
