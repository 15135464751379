import { useState } from "react";
import { type CSSProperties } from "styled-components";
import { type InputProps } from "components/input";
import { BaseAutocomplete, type BaseAutocompleteProps } from "./base";
import { Input, Wrapper } from "./styles";

export interface AutocompleteProps<
  Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<
    BaseAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>,
    "renderInput"
  > {
  InputProps?: InputProps;
}

export function Autocomplete<
  Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  className,
  InputProps,
  ...props
}: AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>) {
  const [inputHeight, setInputHeight] = useState(0);

  return (
    <Wrapper
      className={className}
      style={
        {
          "--input-height": `${inputHeight}px`,
        } as CSSProperties
      }
    >
      <BaseAutocomplete
        {...props}
        disablePortal
        renderInput={(props) => (
          <Input
            ref={(element: HTMLDivElement) => {
              const height = element?.offsetHeight;

              if (height && height !== inputHeight) {
                setInputHeight(height);
              }
            }}
            {...props}
            {...InputProps}
            InputProps={{
              ...props.InputProps,
              ...InputProps?.InputProps,
            }}
            inputProps={{
              ...props.inputProps,
              ...InputProps?.inputProps,
            }}
          />
        )}
      />
    </Wrapper>
  );
}
