import { styled } from "@mui/material/styles";
import { Button } from "components/button";
import { PlainButton } from "components/button/plain";
import Accordion from "@mui/material/Accordion";
import AccordionSummary, {
  type AccordionSummaryProps,
  accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CheckBadgeIconAsset from "assets/images/icons/check-badge.svg";
import PlusIconAsset from "assets/images/icons/plus.svg";
import DropdownIconAsset from "assets/images/icons/dropdown.svg";

export const CheckIcon = styled(CheckBadgeIconAsset, {
  label: "check-icon",
})`
  fill: currentColor;
  width: auto;
  height: ${({ theme }) => theme.spacing(3)};
  margin: ${({ theme }) => theme.spacing(0.5)};
`;

export const PlusIcon = styled(PlusIconAsset, {
  label: "plus-icon",
})`
  fill: currentColor;
  width: auto;
  height: ${({ theme }) => theme.spacing(1.5)};
  margin: ${({ theme }) => theme.spacing(1.25)};
`;

export const ExpandIcon = styled(DropdownIconAsset, {
  label: "expand-icon",
})`
  fill: currentColor;
  width: auto;
  height: ${({ theme }) => theme.spacing(1)};
  transform: rotateZ(0);
  transition: ${({ theme }) => theme.transitions.create("transform")};
`;

export const Container = styled("div", {
  label: "item",
})`
  border-bottom: 1px solid ${({ theme }) => theme.palette.color.gray[90]};
  color: ${({ theme }) => theme.palette.color.text.primary};
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  padding: ${({ theme }) => theme.spacing(0, 0, 2)};

  & + & {
    padding-top: ${({ theme }) => theme.spacing(2)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-right: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const ContentWrapper = styled(Accordion, {
  label: "content",
})`
  &.withCta {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
`;

export const CtaDisabledButton = styled(Button, {
  label: "cta-button",
})`
  padding: ${({ theme }) => theme.spacing(1.2, 0.4)};
  background: ${({ theme }) => theme.palette.color.text.inverse};
`;

export const CtaContainer = styled("div", {
  label: "cta-container",
})`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: ${({ theme }) => theme.spacing(0, 0, 0, 2)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: ${({ theme }) => theme.spacing(0, 0, 0, 4)};
  }
`;

export const CtaPrice = styled("div", {
  label: "cta-price",
})`
  font-size: 16px;
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacing(1)};
  line-height: 1;
  color: ${({ theme }) => theme.palette.color.primary.main};
`;

export const CtaWrapper = styled("div", {
  label: "cta-wrapper",
})`
  align-items: flex-end;
  display: flex;
  flex: 0;
  flex-direction: column;
  margin-left: auto;
  text-align: right;

  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const Description = styled(AccordionDetails, {
  label: "description",
})`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.12;
  padding: ${({ theme }) => theme.spacing(1, 0)};
`;

export const Title = styled(
  (props: AccordionSummaryProps) => (
    <AccordionSummary disableRipple {...props} />
  ),
  {
    label: "title",
  },
)`
  color: ${({ theme }) => theme.palette.color.gray[10]};
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  display: flex;
  padding: 0;
  align-items: baseline;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(1.25)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
  }

  .${accordionSummaryClasses.content} {
    display: flex;
    flex-direction: column;
    margin: 0;
    flex-grow: 0;
  }
`;

export const Trigger = styled(PlainButton, {
  label: "trigger",
})`
  display: block;
  color: ${({ theme }) => theme.palette.color.primary.main};
  font-size: inherit;
  font-weight: 700;
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
