import { selectName } from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import { getUtm } from "utils/tracking/utm";
import { mixpanelTrack } from "utils/tracking/mixpanel";

export function useAdvisorEmailTracking() {
  const name = useSelector(selectName);

  return (email: string) => {
    const utm = getUtm();

    mixpanelTrack("saw_advisor_email_warning", {
      ...utm,
      advisor_email: email,
      first_name: name?.firstName,
      last_name: name?.lastName,
    });
  };
}
