import type { SvgImport } from "types/svg-import";

export function getSvgrAspectRatio(source: SvgImport) {
  const { width, height } = getSvgrDimensions(source);
  return `${width / height}`;
}

export function getSvgrDimensions(source: SvgImport) {
  const dimensions: { width?: number; height?: number } = {};
  const matches = source.toString().matchAll(/(height|width): *([\d.]+)/g);

  for (const [_, a, b] of matches) {
    dimensions[a as keyof typeof dimensions] = parseFloat(b);
  }

  if (dimensions.width === undefined || dimensions.height === undefined) {
    throw new Error("Missing dimensions data");
  }

  return dimensions as Required<typeof dimensions>;
}
