import classNames from "classnames";
import { type CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMeasure } from "react-use";
import { useBreakpoint } from "hooks/use-breakpoint";
import { useSelector } from "hooks/use-selector";
import {
  selectHasTripCancellationCoverage,
  selectResidencyState,
} from "store/quote/selectors";
import { PolicyPriceSection } from "./section/policy-price";
import { TripCostSection } from "./section/trip-cost";
import { TripInfoSection } from "./section/trip-info";
import {
  BackButton,
  BackButtonIcon,
  Content,
  InfoWrapper,
  Sticky,
  Title,
  TitleContainer,
} from "./styles";

export function Header() {
  const hasTripCancellation = useSelector(selectHasTripCancellationCoverage);
  const residencyState = useSelector(selectResidencyState);

  const navigate = useNavigate();
  const { t } = useTranslation("screens", { keyPrefix: "offer" });

  const mdBreakpoint = useBreakpoint("md", { noSsr: true });

  const [stickyRef, { height: stickyHeight }] = useMeasure<HTMLDivElement>();

  const showTripCost =
    residencyState === "NY" || residencyState === "WA" || hasTripCancellation;

  return (
    <Content style={{ "--top-padding": `${stickyHeight}px` } as CSSProperties}>
      <Sticky ref={stickyRef}>
        {!mdBreakpoint && (
          <TitleContainer>
            <BackButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <BackButtonIcon />
            </BackButton>
            <Title>{t("title")}</Title>
          </TitleContainer>
        )}

        <InfoWrapper className={classNames({ showTripCost })}>
          <TripInfoSection />
          {mdBreakpoint && showTripCost && <TripCostSection />}
          <PolicyPriceSection />
        </InfoWrapper>
      </Sticky>

      {!mdBreakpoint && showTripCost && <TripCostSection />}
    </Content>
  );
}
