import dayjs from "dayjs";
import { type ComponentPropsWithRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOttUser } from "hooks/use-ott-user";
import { useSelector } from "hooks/use-selector";
import {
  selectDestinations,
  selectTripEndDate,
  selectTripStartDate,
} from "store/quote/selectors";
import { LocalTimezoneDate } from "utils/date";
import { dateRangeFormatter } from "utils/formatters/date-range";
import {
  Container,
  Days,
  Destination,
  DestinationContainer,
  DestinationMore,
  Title,
  TripDates,
} from "./styles";

export function TripInfoSection(props: ComponentPropsWithRef<"div">) {
  const destinations = useSelector(selectDestinations);
  const tripStartDate = useSelector(selectTripStartDate);
  const tripEndDate = useSelector(selectTripEndDate);
  const days = useMemo(
    () => dayjs(tripEndDate).diff(tripStartDate, "days"),
    [tripEndDate, tripStartDate],
  );

  const { t } = useTranslation("screens", { keyPrefix: "offer.tripDetails" });

  const ottUser = useOttUser("glinda");

  return (
    <Container {...props}>
      <Title>{t("title", { context: ottUser && "advisor" })}</Title>

      <Days>{t("day", { count: days || 1 })}</Days>

      {destinations?.[0] && (
        <DestinationContainer>
          <Destination>{destinations[0].label}</Destination>
          {destinations.length > 1 && (
            <DestinationMore>
              {t("moreThanOneDestination", {
                count: destinations.length - 1,
              })}
            </DestinationMore>
          )}
        </DestinationContainer>
      )}

      {!!tripStartDate && !!tripEndDate && (
        <TripDates>
          {dateRangeFormatter(
            new LocalTimezoneDate(tripStartDate),
            new LocalTimezoneDate(tripEndDate),
          )}
        </TripDates>
      )}
    </Container>
  );
}
