import { type MouseEventHandler, type ReactNode } from "react";
import { Component } from "./styles";

export interface LinkProps {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  target?: string;
  to?: string;
}

export function Link({ children, onClick, target, to }: LinkProps) {
  if (typeof children === "undefined" || typeof to === "undefined") {
    return null;
  }

  return (
    <Component href={to} onClick={onClick} target={target}>
      {children}
    </Component>
  );
}
