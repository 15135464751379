import classNames from "classnames";
import {
  type ReactNode,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useEvent } from "react-use";
import { useBreakpoint } from "hooks/use-breakpoint";
import {
  CheckLightIcon,
  Container,
  Content,
  Progress,
  Title,
  TitleBadge,
  TitleProgress,
} from "./styles";

export interface CollapsableSectionProps {
  active: boolean;
  children: ReactNode;
  completed: boolean;
  disabled: boolean;
  loading?: boolean;
  onActivate: () => void;
  showTitleBadge?: boolean;
  title: string;
}

export interface CollapsableSectionRef {
  updateContentHeight: () => void;
}

export const CollapsableSection = forwardRef<
  CollapsableSectionRef,
  CollapsableSectionProps
>(function CollapsableSection(
  {
    active,
    children,
    completed,
    disabled,
    loading: loadingProp = false,
    onActivate,
    showTitleBadge = true,
    title,
  },
  ref,
) {
  const mdBreakpoint = useBreakpoint("md");

  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  const updateContentHeight = useCallback(() => {
    const contentClone = contentRef.current?.cloneNode(true) as HTMLDivElement;
    contentRef.current?.parentElement?.appendChild(contentClone);
    contentClone.style.height = "auto";
    setContentHeight(contentClone.getBoundingClientRect().height);
    contentClone.remove();
  }, []);

  useEvent("unload", () => {
    // Avoid scroll flickering on page reload
    if (active) {
      containerRef.current?.scrollIntoView({
        behavior: "instant",
        block: !mdBreakpoint ? "start" : "end",
      });
    }
  });

  useEffect(() => {
    if (!active) {
      if (contentHeight) {
        setContentHeight(0);
      }
      return;
    } else if (loading) {
      if (!loadingProp) {
        setLoading(false);
      }
      return;
    } else if (loadingProp) {
      if (active) {
        setLoading(true);
      }
      return;
    }

    window.addEventListener("resize", updateContentHeight);
    updateContentHeight();

    return () => {
      window.removeEventListener("resize", updateContentHeight);
    };
  }, [active, contentHeight, loading, loadingProp, updateContentHeight]);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      setMounted(true);
    });
  }, []);

  useImperativeHandle(ref, () => ({
    updateContentHeight: () => {
      window.requestAnimationFrame(updateContentHeight);
    },
  }));

  return (
    <Container
      ref={containerRef}
      className={classNames({
        active,
        completed,
        disabled,
        loading,
        mounted,
      })}
      onClick={() => {
        if (!active && !disabled) {
          onActivate();
        }
      }}
    >
      <Title>
        {showTitleBadge && (
          <TitleBadge>
            <Progress />
            <CheckLightIcon />
          </TitleBadge>
        )}

        {title}

        {!showTitleBadge && <TitleProgress />}
      </Title>

      <Content
        ref={contentRef}
        onTransitionEnd={(event) => {
          if (
            active &&
            event.propertyName === "height" &&
            event.target === contentRef.current
          ) {
            containerRef.current?.scrollIntoView({
              behavior: "smooth",
              block: !mdBreakpoint ? "start" : "end",
            });
          }
        }}
        style={{ height: contentHeight }}
      >
        {children}
      </Content>
    </Container>
  );
});
