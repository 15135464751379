import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "cta-bar",
})`
  background: ${({ theme }) => theme.palette.color.gray[100]};
  border-radius: ${({ theme }) => theme.spacing(4, 4, 0, 0)};
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2.5, 2)};
  position: fixed;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  box-shadow: ${({ theme }) => theme.customShadows.top};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(2.5)};
  }
`;
