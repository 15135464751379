import { isClient } from "utils/is-client";

export type UtmQuery = Record<string, string>;

const CACHE: { utm?: UtmQuery | null } = {};

export const UTM_STORAGE_KEY = "faye:utm";

export function getUtm() {
  return CACHE.utm ?? null;
}

export function getUtmArray() {
  const utm = getUtm();
  return Object.entries(utm ?? []).map(([key, value]) => ({ key, value }));
}

export function setUtm(utm: UtmQuery) {
  window.localStorage.setItem(UTM_STORAGE_KEY, JSON.stringify(utm));
  CACHE.utm = utm;
}

export function initUtm() {
  if (!isClient() || typeof CACHE.utm !== "undefined") {
    return;
  }

  const searchParams = new URLSearchParams(window.location.search);
  const utm: Record<string, string> = {};

  let hasUtm = false;

  for (const [key, value] of searchParams) {
    if (key === "utm_term" && value === "mailto") {
      continue;
    } else if (key.slice(0, 4) === "utm_" && !utm[key]) {
      hasUtm = true;
      utm[key] = value;
    } else if (key === "ref") {
      hasUtm = true;
      utm.ref = value;
      utm.utm_medium = "product_features";
      utm.utm_source = "share_earn";
    }
  }

  if (hasUtm) {
    window.localStorage.setItem(UTM_STORAGE_KEY, JSON.stringify(utm));
    CACHE.utm = utm;
  } else {
    const storedUtm = window.localStorage.getItem(UTM_STORAGE_KEY);
    CACHE.utm = storedUtm && JSON.parse(storedUtm);
  }
}

// The methods below are exclusive to onboarding (not a part of a common module)

export function getAdvisorId() {
  const utm = getUtm();

  return (
    (utm && utm.utm_medium === "bd-traveladvisors" && utm.utm_campaign) || null
  ); // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
}
