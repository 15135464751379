import { styled } from "@mui/material/styles";

export const Component = styled("label", {
  label: "label",
})`
  align-items: center;
  color: currentColor;
  cursor: default;
  display: flex;
  overflow: hidden;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 14px;
  }

  &.faye__label--error {
    color: ${({ theme }) => theme.palette.color.alert.main};
  }
`;

export const Content = styled("span", {
  label: "content",
})`
  overflow: hidden;
  text-overflow: ellipsis;
`;
