import phoneAImage from "./phone.png";
import { Container, Phone } from "./styles";

export function Art() {
  return (
    <Container>
      <Phone alt="" src={phoneAImage} />
    </Container>
  );
}
