import { createBrowserRouter } from "react-router-dom";
import { Redirect } from "components/redirect";
import { CheckoutLayout } from "layouts/checkout";
import { OnboardingLayout } from "layouts/onboarding";
import { ConfirmationLayout } from "layouts/confirmation";
import { CheckoutScreen } from "screens/checkout";
import { ComingSoonScreen } from "screens/coming-soon";
import { ConfirmationScreen } from "screens/confirmation";
import { DatesScreen } from "screens/dates";
import { DestinationScreen } from "screens/destination";
import { ErrorScreen } from "screens/error";
import { StateScreen } from "screens/state";
import { TravelersScreen } from "screens/travelers";
import { OfferRouter } from "./offer";

export const router = createBrowserRouter(
  [
    {
      element: <Redirect />,
      errorElement: <ErrorScreen />,
      children: [
        {
          element: <OnboardingLayout />,
          children: [
            {
              index: true,
              element: null, // Set index so Redirect component can redirect it
            },
            {
              path: "coming-soon",
              element: <ComingSoonScreen />,
            },
            {
              path: "dates",
              element: <DatesScreen />,
            },
            {
              path: "destination",
              element: <DestinationScreen />,
            },
            {
              path: "state",
              element: <StateScreen />,
            },
            {
              path: "travelers",
              element: <TravelersScreen />,
            },
          ],
        },
        {
          element: <CheckoutLayout />,
          children: [
            {
              path: "checkout",
              element: <CheckoutScreen />,
            },
          ],
        },
        {
          element: <ConfirmationLayout />,
          children: [
            {
              path: "confirmation",
              element: <ConfirmationScreen />,
            },
          ],
        },
        {
          // React Router will show a warning about this route, stating that it should
          // be changed to "offer/*". This can be safely ignored, so OfferRouter will
          // use the already configured errorElement value for offer/* routes. Also,
          // OfferRouter does not have any subroute.
          path: "offer",
          element: <OfferRouter />,
        },
      ],
    },
  ],
  {
    basename: import.meta.env.BASE_URL,
  },
);
