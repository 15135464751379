import { type ComponentPropsWithoutRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { type ButtonProps } from "components/button";
import { type BaseButtonProps } from "components/button/base";
import { numberFormatter } from "utils/formatters/number";
import { useCoverage } from "../../../utils";

import {
  CheckIcon,
  PlusIcon,
  ExpandIcon,
  Container,
  ContentWrapper,
  CtaDisabledButton,
  CtaContainer,
  CtaPrice,
  CtaWrapper,
  Description,
  Title,
  Trigger,
} from "./styles";
import { IconButton } from "components/button/icon";

export interface ItemProps extends ComponentPropsWithoutRef<"div"> {
  CtaButtonProps?: ButtonProps;
  coverageType: string;
  description: string;
  price?: number | false | null;
  priceSuffix?: string | null;
  title: string;
  triggerLabel?: string | null;
  TriggerProps?: BaseButtonProps;
}

export function Item({
  CtaButtonProps = {},
  coverageType,
  description,
  price = null,
  priceSuffix,
  title,
  triggerLabel = null,
  TriggerProps = {},
  ...props
}: ItemProps) {
  const {
    coverage,
    enable: enableCoverage,
    disable: disableCoverage,
  } = useCoverage(coverageType);

  const { t } = useTranslation("screens", { keyPrefix: "offer.addOns" });

  return (
    <Container {...props}>
      <ContentWrapper elevation={0} disableGutters>
        <Title
          expandIcon={<ExpandIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          component="h4"
        >
          {title}

          {price !== null && price !== false && (
            <CtaPrice>
              {numberFormatter(price, { style: "currency" })}
              {priceSuffix && <span className="suffix">{priceSuffix}</span>}
            </CtaPrice>
          )}
        </Title>
        <Description>
          <Trans>{description}</Trans>

          {triggerLabel !== null && (
            <Trigger {...TriggerProps}>{triggerLabel}</Trigger>
          )}
        </Description>
      </ContentWrapper>

      {price !== null && (
        <CtaWrapper>
          {price === false && (
            <CtaContainer>
              <CtaDisabledButton disabled variant="outlined">
                {t("addCta")}
              </CtaDisabledButton>
            </CtaContainer>
          )}

          {price !== false && (
            <>
              {coverage?.isSelected && (
                <CtaContainer>
                  <IconButton
                    {...CtaButtonProps}
                    title={t(coverage.isSelected ? "removeCta" : "addCta")}
                    onClick={disableCoverage}
                    variant="standard"
                    icon={<CheckIcon />}
                  ></IconButton>
                </CtaContainer>
              )}

              {!coverage?.isSelected && (
                <CtaContainer>
                  <IconButton
                    onClick={enableCoverage}
                    variant="outlined"
                    {...CtaButtonProps}
                    title={t("addCta")}
                    icon={<PlusIcon />}
                  ></IconButton>
                </CtaContainer>
              )}
            </>
          )}
        </CtaWrapper>
      )}
    </Container>
  );
}
