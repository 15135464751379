import { type User } from "firebase/auth";
import { useLayoutEffect, useState } from "react";
import { type OttTarget, getOttUser } from "utils/ott";

export function useOttUser(target: OttTarget) {
  const [ottUser, setOttUser] = useState<User | null>(null);

  useLayoutEffect(() => {
    void getOttUser(target)
      .then((user) => {
        setOttUser(user);
      })
      .catch((error) => {
        setOttUser(null);
        throw error;
      });
  }, [target]);

  return ottUser;
}
