import { styled } from "@mui/material/styles";
import { Input as InputComponent } from "components/input";

export const ApartmentInput = styled(InputComponent, {
  label: "apartment-input",
})`
  flex: 0 0 ${({ theme }) => theme.spacing(12)};
  margin: ${({ theme }) => theme.spacing(0, 0.5)};
`;

export const Input = styled(InputComponent, {
  label: "input",
})`
  margin: ${({ theme }) => theme.spacing(0, 0.5)};
`;

export const StateWrapper = styled("div", {
  label: "state-wrapper",
})`
  margin: ${({ theme }) => theme.spacing(0, 0.5)};
  width: 100%;
`;
