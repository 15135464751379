import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch } from "hooks/use-dispatch";
import {
  datesAndDestinationGivenThunk,
  updateAdvisorEmail,
} from "store/quote/thunks/general";

export function Init() {
  const dispatch = useDispatch();

  useEffect(() => {
    void dispatch(updateAdvisorEmail());
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const args: {
      dates?: {
        tripStartDate: string;
        tripEndDate: string;
      };
      destinationGoogleId?: string;
    } = {};

    const destination = searchParams.get("destination");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    if (destination) {
      args.destinationGoogleId = destination;
    }

    if (startDate && endDate) {
      const parsedStartDate = dayjs(startDate);
      const parsedEndDate = dayjs(endDate);

      if (parsedStartDate.isValid() && parsedEndDate.isValid()) {
        args.dates = {
          tripStartDate: parsedStartDate.format("YYYY-MM-DD"),
          tripEndDate: parsedEndDate.format("YYYY-MM-DD"),
        };
      }
    }

    if (Object.keys(args).length) {
      void dispatch(datesAndDestinationGivenThunk(args));
    }
  }, [dispatch]);

  return null;
}
