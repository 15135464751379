import { styled } from "@mui/material/styles";
import ArrowIconAsset from "assets/images/icons/arrow.svg";
import { BaseButton } from "components/button/base";

export const Content = styled("div", {
  label: "header",
})`
  padding-top: var(--top-padding, 0px);

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 0;
  }
`;

export const BackButton = styled(BaseButton, {
  label: "back-button",
})`
  position: absolute;
`;

export const BackButtonIcon = styled(ArrowIconAsset, {
  label: "back-button-icon",
})`
  fill: ${({ theme }) => theme.palette.color.text.primary};
  transform: rotate(-180deg);
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
`;

export const Title = styled("h1", { label: "title" })`
  color: ${({ theme }) => theme.palette.color.primary.main};
  margin: 0 auto;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
`;

export const Sticky = styled("div", {
  label: "sticky",
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  background-color: ${({ theme }) => theme.palette.color.primary[100]};
  border-radius: ${({ theme }) => theme.spacing(0, 0, 4, 4)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: relative;
    background-color: initial;
  }
`;

export const InfoWrapper = styled("div", { label: "info-wrapper" })`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(0, 4, 4)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: 0;
    padding: ${({ theme }) => theme.spacing(0, 2)};

    &.showTripCost {
      display: grid;
      gap: ${({ theme }) => theme.spacing(4)};
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

export const TitleContainer = styled("div", {
  label: "title-container",
})`
  align-items: center;
  display: flex;
  padding: ${({ theme }) => theme.spacing(2, 4, 0, 4)};
`;
