import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { type DateRange, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs, { type Dayjs } from "dayjs";
import dayjsIsBetweenPlugin from "dayjs/plugin/isBetween";
import { useContext, useState } from "react";
import { Hidden as HiddenDay } from "components/date-picker/day/hidden";
import { useSelector } from "hooks/use-selector";
import { selectTripEndDate, selectTripStartDate } from "store/quote/selectors";
import { PopupSection, type PopupSectionProps } from "../../popup-section";
import { Label } from "../label";
import { CarCareContext } from "..";
import { DatePicker as DatePickerComponent, Day } from "./styles";

dayjs.extend(dayjsIsBetweenPlugin);

export interface DatePickerProps
  extends Omit<PopupSectionProps, "onChange" | "renderInput" | "value"> {
  label: string;
}

export function DatePicker({ label, ...props }: DatePickerProps) {
  const tripStartDate = useSelector(selectTripStartDate);
  const tripEndDate = useSelector(selectTripEndDate);

  if (!tripStartDate) {
    throw new Error("tripStartDate is empty.");
  } else if (!tripEndDate) {
    throw new Error("tripEndDate is empty.");
  }

  const { addDateRange, dateRanges } = useContext(CarCareContext);

  const tripDates = {
    start: dayjs(tripStartDate).startOf("day"),
    end: dayjs(tripEndDate),
  };

  const [maxDate, setMaxDate] = useState<Dayjs>(tripDates.end);
  const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);

  return (
    <PopupSection {...props}>
      <Label>{label}</Label>

      <LocalizationProvider adapterLocale="en" dateAdapter={AdapterDayjs}>
        <DatePickerComponent
          calendars={1}
          dayOfWeekFormatter={(day) => day}
          defaultCalendarMonth={tripDates.start}
          disableHighlightToday={true}
          maxDate={maxDate}
          minDate={tripDates.start}
          onChange={(value) => {
            if (value[0] !== null && value[1] !== null) {
              addDateRange(value[0], value[1]);

              setMaxDate(tripDates.end);
              setValue([null, null]);
            } else {
              if (value[0] !== null) {
                // Disable dates after the earliest range
                let earliestRangeStartDate: Dayjs | null = null;

                for (const dateRange of dateRanges) {
                  if (dateRange.start > value[0]) {
                    if (
                      !earliestRangeStartDate ||
                      dateRange.start < earliestRangeStartDate
                    ) {
                      earliestRangeStartDate = dateRange.start;
                    }
                  }
                }

                if (earliestRangeStartDate) {
                  setMaxDate(earliestRangeStartDate);
                }
              }

              setValue(value);
            }
          }}
          reduceAnimations
          slots={{
            day: ({ day, ...props }) => {
              if (day < tripDates.start || day > tripDates.end) {
                return (
                  <HiddenDay
                    key={props.key}
                    ref={props.ref}
                    role={props.role}
                  />
                );
              }

              for (const { end, start } of dateRanges) {
                if (day.isSame(start, "day")) {
                  props.disabled = true;
                  props.isPreviewing = true;
                  props.isEndOfPreviewing = day.isSame(end, "day");
                  props.isStartOfPreviewing = true;
                  break;
                } else if (day.isSame(end, "day")) {
                  props.disabled = true;
                  props.isPreviewing = true;
                  props.isEndOfPreviewing = true;
                  break;
                } else if (day.isBetween(end, start, "day", "[]")) {
                  props.disabled = true;
                  props.isPreviewing = true;
                  break;
                }
              }

              return <Day day={day} {...props} />;
            },
          }}
          value={value}
        />
      </LocalizationProvider>
    </PopupSection>
  );
}
