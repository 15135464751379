import { Outlet } from "react-router-dom";
import { Container, Content, Logo, Main } from "./styles";

export function ConfirmationLayout() {
  return (
    <Container>
      <Main>
        <Logo />
        <Content>
          <Outlet />
        </Content>
      </Main>
    </Container>
  );
}
