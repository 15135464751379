import { type Theme } from "@mui/material/styles";
import useMediaQuery, {
  type UseMediaQueryOptions,
} from "@mui/material/useMediaQuery";

export function useBreakpoint(
  breakpoint: keyof Theme["breakpoints"]["values"],
  options?: UseMediaQueryOptions,
) {
  return useMediaQuery<Theme>(
    (theme) => theme.breakpoints.up(breakpoint),
    options,
  );
}
