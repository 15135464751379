import { createContext } from "react";
import { type ContextState, useContextState } from "hooks/use-context-state";
import { useGlindaDashboardRefresh } from "hooks/use-glinda-dashboard-refresh";
import { OfferLayoutContent } from "layouts/offer/content";
import { Body } from "./body";
import { Cta } from "./cta";
import { Footnotes } from "./footnotes";
import { Header } from "./header";
import { PrintLayout } from "./print-layout";
import { useTracking } from "./tracking";
import { useTranslation } from "react-i18next";
import { useOttUser } from "hooks/use-ott-user";
import { Container } from "./styles";

export interface OfferScreenProps {
  isFayeApiRequest: boolean;
}

export const OfferContext = createContext<{
  invalidTripCost?: ContextState<boolean>;
}>({});

export function OfferScreen({ isFayeApiRequest }: OfferScreenProps) {
  const invalidTripCost = useContextState<boolean>(false);

  const { t } = useTranslation("screens", { keyPrefix: "offer" });
  const ottUser = useOttUser("glinda");

  useGlindaDashboardRefresh();
  useTracking();

  return (
    <>
      <PrintLayout isFayeApiRequest={isFayeApiRequest} />

      <OfferContext.Provider
        value={{
          invalidTripCost,
        }}
      >
        <Container>
          <OfferLayoutContent
            title={t("title", { context: ottUser && "advisor" })}
            {...(ottUser && { lead: t("lead", { context: "advisor" }) })}
          >
            <Header />
            <Body />
            <Footnotes />
          </OfferLayoutContent>
          <Cta />
        </Container>
      </OfferContext.Provider>
    </>
  );
}
