import { styled } from "@mui/material/styles";
import ExternalIconAsset from "assets/images/icons/external.svg";
import { Button as ButtonComponent } from "components/button";

export const Button = styled(ButtonComponent, {
  label: "button",
})`
  align-items: baseline;
  font-size: 18px;
  width: 100%;

  & + & {
    margin-left: ${({ theme }) => theme.spacing(2.5)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-width: ${({ theme }) => theme.spacing(43)};
    width: auto;
  }
`;

export const ExternalIcon = styled(ExternalIconAsset, {
  label: "external-icon",
})`
  fill: currentColor;
  margin-left: ${({ theme }) => theme.spacing(1)};
  width: auto;
  height: ${({ theme }) => theme.spacing(1.25)};
`;
