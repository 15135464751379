import { t } from "i18next";

export function advisorEmailValidation(advisorEmail: string) {
  return (value: string) =>
    value !== advisorEmail || {
      helperText: t("email.advisor", { ns: "validation" }),
      state: { name: "advisorEmailValidation" },
      status: "warning" as const,
    };
}
