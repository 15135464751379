import { useEffect, useState } from "react";
import { Frames } from "frames-react";
import { getCheckout } from "utils/payment";
import { ProviderContext } from "..";
import { styles } from "./card/styles";

export interface CheckoutProviderProps {
  children: React.ReactNode;
}

export function CheckoutProvider({ children }: CheckoutProviderProps) {
  const [loaded, setLoaded] = useState(false);
  const [isActivated, setIsActivated] = useState<boolean>();

  useEffect(() => {
    void getCheckout()
      .then(() => {
        setLoaded(true);
      })
      .catch(() => {
        setIsActivated(false);
      });
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <Frames
      config={{
        publicKey: import.meta.env.VITE_CHECKOUT_PUBLIC_KEY,
        style: styles,
      }}
      frameActivated={() => {
        setIsActivated(true);
      }}
    >
      <ProviderContext.Provider value={{ isActivated }}>
        {children}
      </ProviderContext.Provider>
    </Frames>
  );
}
