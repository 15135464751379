import { styled } from "@mui/material/styles";
import { BaseButton } from "components/button/base";
import DropdownIconAsset from "assets/images/icons/dropdown.svg";

export const DropdownIcon = styled(DropdownIconAsset, {
  label: "dropdown-icon",
})`
  fill: ${({ theme }) => theme.palette.color.primary.main};

  .active & {
    transform: rotate(-180deg);
  }
`;

export const DropdownTrigger = styled(BaseButton, {
  label: "dropdown-trigger",
})`
  margin-right: ${({ theme }) => theme.spacing(-1)};
  padding: ${({ theme }) => theme.spacing(1)};
`;
