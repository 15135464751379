import { type ComponentPropsWithoutRef } from "react";
import { Trans } from "react-i18next";
import { Link } from "./link";
import { Component } from "./styles";

export interface DisclaimerProps
  extends Omit<ComponentPropsWithoutRef<"div">, "children"> {}

export function Disclaimer(props: DisclaimerProps) {
  return (
    <Component {...props} role="note">
      <Trans
        components={{ Link: <Link target="_blank" /> }}
        i18nKey="form.disclaimer.text"
        ns="components"
      />
    </Component>
  );
}
