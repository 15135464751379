export type GtmProperties = Record<string, unknown>;

export function getGtmDataLayer() {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  return window.dataLayer;
}

export function gtmTrack(event: string, properties: GtmProperties = {}) {
  getGtmDataLayer().push({ event, ...properties });
}
