import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { selectTravelers } from "store/quote/selectors";
import { dateFormatter } from "utils/formatters/date";
import { useSelector } from "hooks/use-selector";
import { Title } from "../title";
import { TopicList } from "../topic-list";

export function Travelers() {
  const travelers = useSelector(selectTravelers);

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.printLayout.travelers",
  });

  return (
    <>
      <Title additionalTitle={t("additionalTitle")}>{t("title")}</Title>

      <TopicList
        items={travelers.map(({ dateOfBirth, firstName, lastName }) => ({
          title: `${firstName} ${lastName}`.trim(),
          description: dateFormatter(dayjs(dateOfBirth).toDate()),
        }))}
      />
    </>
  );
}
