import dayjs, { type Dayjs } from "dayjs";
import classNames from "classnames";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { type CSSProperties, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DesktopDateRangePicker,
  type DesktopDateRangePickerProps,
} from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import { filledInputClasses } from "@mui/material/FilledInput";
import { useBreakpoint } from "hooks/use-breakpoint";
import { MAX_TRIP_DURATION_DAYS, MAX_TRIP_START_DAYS } from "settings";
import { Input } from "./input";
import { getMaxEnd, getMaxStart, getMinEnd, getMinStart } from "./utils";
import { type EndDateError, type StartDateError, validate } from "./validation";
import { Day, InputGroup, Wrapper } from "./styles";

export interface DatePickerProps
  extends Omit<
    DesktopDateRangePickerProps<Dayjs>,
    "onChange" | "renderInput" | "value"
  > {
  initialValue: [Dayjs | null, Dayjs | null];
  onChange: (
    valid: boolean | null,
    value: [Dayjs | null, Dayjs | null],
  ) => void;
}

export const DATE_FORMAT = "MM/DD/YYYY";

export function DatePicker({
  initialValue,
  onChange: onChangeProp,
  ...props
}: DatePickerProps) {
  const mdBreakpoint = useBreakpoint("md");

  const { t } = useTranslation("screens", {
    keyPrefix: "dates.datePicker",
  });

  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const [inputHeight, setInputHeight] = useState(0);
  const [picking, setPicking] = useState<"start" | "end" | false>(false);
  const [value, setValue] = useState(initialValue);

  const [error, setError] = useState([
    null as StartDateError | null,
    null as EndDateError | null,
  ]);

  const [open, setOpen] = useState(
    !mdBreakpoint && !initialValue[0] && !initialValue[1],
  );

  function updateErrorAndHandleOnChange(value: [Dayjs | null, Dayjs | null]) {
    const validation = validate(value);

    if (error[0] !== validation[0] || error[1] !== validation[1]) {
      setError(validation);
    }

    if (validation[0] === null && validation[1] === null) {
      onChangeProp(value[0] && value[1] ? true : null, value);
    } else {
      onChangeProp(false, value);
    }
  }

  useEffect(() => {
    updateErrorAndHandleOnChange(value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LocalizationProvider
      adapterLocale="en"
      dateAdapter={AdapterDayjs}
      localeText={{
        start: t("startLabel"),
        end: t("endLabel"),
      }}
    >
      <Wrapper
        ref={wrapperRef}
        style={{ "--input-height": `${inputHeight}px` } as CSSProperties}
      >
        <DesktopDateRangePicker
          {...props}
          autoFocus
          calendars={mdBreakpoint ? 2 : 1}
          dayOfWeekFormatter={(day) => day} // MUI picks first char by default
          format={DATE_FORMAT}
          maxDate={picking === "start" ? getMaxStart() : getMaxEnd(value[0])}
          minDate={picking === "start" ? getMinStart() : getMinEnd(value[0])}
          onChange={(rawValue) => {
            const value: [Dayjs | null, Dayjs | null] = [
              rawValue[0] && dayjs().isSame(rawValue[0], "day")
                ? dayjs()
                : rawValue[0],
              rawValue[1],
            ];

            setValue(value);
            updateErrorAndHandleOnChange(value);
          }}
          onClose={() => {
            window.scroll({
              behavior: "smooth",
              top: 0,
            });

            setPicking(false);
            setOpen(false);
          }}
          onOpen={() => {
            window.setTimeout(() => {
              wrapperRef.current?.scrollIntoView({
                behavior: "smooth",
              });
            }, 0);

            setOpen(true);
          }}
          open={open}
          onRangePositionChange={(rangePosition) => {
            setPicking(rangePosition);
          }}
          selectedSections={null}
          slotProps={{
            popper: {
              disablePortal: true,
              anchorEl: wrapperRef.current,
            },
          }}
          slots={{
            day: ({ onClick, ...props }) => (
              <Day
                {...props}
                onClick={(...args) => {
                  if (picking === "start") {
                    setPicking("end");
                  } else if (picking === "end") {
                    setPicking(false);
                  }

                  if (onClick) {
                    onClick(...args);
                  }
                }}
              />
            ),
            fieldRoot: InputGroup,
            fieldSeparator: () => null,
            textField: ({ value, variant: _, ...props }) => {
              const isStart = props.label === t("startLabel");
              const isEnd = props.label === t("endLabel");
              const errorMessage = error[isStart ? 0 : 1];
              const isSelected =
                (isStart && picking === "start") ||
                (isEnd && picking === "end");

              return (
                <Input
                  {...props}
                  className={classNames(props.className, {
                    [filledInputClasses.focused]: isSelected,
                  })}
                  {...(isStart && {
                    inputRef: (input: HTMLInputElement) => {
                      const height = input?.parentElement?.offsetHeight;

                      if (height && height !== inputHeight) {
                        setInputHeight(height);
                      }
                    },
                  })}
                  error={!!errorMessage}
                  helperText={
                    errorMessage &&
                    t(`errorMessages.${errorMessage}`, {
                      maxDuration: MAX_TRIP_DURATION_DAYS,
                      maxEnd: MAX_TRIP_START_DAYS + MAX_TRIP_DURATION_DAYS,
                      maxStart: MAX_TRIP_START_DAYS,
                    })
                  }
                  name={isStart ? "start_date" : "end_date"}
                  value={value as string}
                />
              );
            },
            toolbar: () => null,
          }}
          value={value}
        />
      </Wrapper>
    </LocalizationProvider>
  );
}
