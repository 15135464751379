import { createAsyncThunk } from "@reduxjs/toolkit";
import { type ThunkAction, type RootState } from "store";
import {
  type DestinationGoogleData,
  type Name,
  type PlaceDetails,
  type Residency,
} from "types/quote-types";
import {
  selectDateOfBirth,
  selectDestinations,
  selectEmail,
  selectName,
  selectResidency,
  selectTravelers,
  selectTripEndDate,
  selectTripStartDate,
} from "store/quote/selectors";
import {
  addTraveler,
  setDates,
  setDestinations,
  setDestinationsGoogleData,
  setMainTraveler,
  setMainTravelerPersonalInformation,
  setResidency,
  setReviewAndEdit,
  setTravelers,
  updateTraveler,
} from "store/quote";
import { deepEqual } from "fast-equals";

export const setDestinationThunk = createAsyncThunk(
  "quote/setPlannedTripThunk",
  (
    {
      destinations,
      destinationsGoogleData,
    }: {
      destinations: PlaceDetails[];
      destinationsGoogleData: DestinationGoogleData[];
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const currentDestinations = selectDestinations(state);

    if (!deepEqual(destinations, currentDestinations)) {
      dispatch(setReviewAndEdit("editing"));
    }

    dispatch(setDestinationsGoogleData(destinationsGoogleData));
    dispatch(setDestinations({ destinations }));
  },
);

export const setDatesThunk = createAsyncThunk(
  "quote/setPlannedTripThunk",
  (
    {
      dates,
    }: {
      dates: {
        tripStartDate: string;
        tripEndDate: string;
      };
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const currentTripStartDate = selectTripStartDate(state);
    const currentTripEndDate = selectTripEndDate(state);

    if (
      dates.tripStartDate !== currentTripStartDate ||
      dates.tripEndDate !== currentTripEndDate
    ) {
      dispatch(setReviewAndEdit("editing"));
    }

    dispatch(setDates({ dates }));
  },
);

export const setMainTravelerPersonalInfoThunk = createAsyncThunk(
  "quote/setMainTravelerPersonalInfoThunk",
  async (
    {
      dateOfBirth,
      email,
      firstName,
      lastName,
    }: {
      dateOfBirth: string;
      email: string;
      firstName: string;
      lastName: string;
    },
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const currentDateOfBirth = selectDateOfBirth(state);
    const currentEmail = selectEmail(state);
    const currentName = selectName(state);

    if (
      dateOfBirth !== currentDateOfBirth ||
      email !== currentEmail ||
      firstName !== currentName?.firstName ||
      lastName !== currentName?.lastName
    ) {
      dispatch(setReviewAndEdit("editing"));

      dispatch(
        setMainTravelerPersonalInformation({
          dateOfBirth,
          email,
          firstName,
          lastName,
        }),
      );
    }
  },
);

export const setMainTravelerResidencyThunk = (
  residency: Residency,
): ThunkAction => {
  return (dispatch, getState) => {
    const state = getState();
    const currentResidency = selectResidency(state);

    if (
      !currentResidency ||
      !deepEqual({ ...residency, googleId: "" }, currentResidency)
    ) {
      dispatch(setReviewAndEdit("editing"));
    }

    dispatch(
      setResidency({
        ...residency,
        googleId: residency.googleId ?? "",
      }),
    );
  };
};

export const setMainTravelerStateThunk = (areaLevel1: string): ThunkAction => {
  return (dispatch, getState) => {
    const state = getState();
    const currentResidency = selectResidency(state);

    if (areaLevel1 !== currentResidency?.areaLevel1) {
      dispatch(
        setResidency({
          apartment: "",
          areaLevel1,
          country: "US",
          googleId: "",
          label: "",
          locality: "",
          streetAndNumber: "",
          zipCode: "",
        }),
      );
    }
  };
};

export const addTravelerThunk = createAsyncThunk(
  "quote/addTravelerInfoThunk",
  (
    {
      birthdate,
      email,
      name,
    }: {
      birthdate: string;
      email: string;
      name: Name;
    },
    { dispatch },
  ) => {
    dispatch(setReviewAndEdit("editing"));
    dispatch(addTraveler({ birthdate, email, name }));
  },
);

export const updateTravelerThunk = createAsyncThunk(
  "quote/updateTravelerInfoThunk",
  (
    {
      birthdate,
      email,
      index,
      name,
    }: {
      birthdate: string;
      email: string;
      index: number;
      name: Name;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const travelers = selectTravelers(state);
    const traveler = travelers[index];

    if (
      birthdate !== traveler.dateOfBirth ||
      email !== traveler.email ||
      name.firstName !== traveler.firstName ||
      name.lastName !== traveler.lastName
    ) {
      dispatch(setReviewAndEdit("editing"));
    }

    dispatch(updateTraveler({ birthdate, email, index, name }));
  },
);

export const setTravelersThunk = createAsyncThunk(
  "quote/setTravelersThunk",
  (
    travelers: Array<{
      dateOfBirth: string;
      firstName: string;
      lastName: string;
      email?: string;
    }>,
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const currentTravelers = selectTravelers(state);
    const filteredCurrentTravelers = currentTravelers.map(
      ({ mainTraveller: _mainTraveller, ...traveler }) => traveler,
    );

    if (deepEqual(travelers, filteredCurrentTravelers)) {
      return;
    }

    dispatch(setReviewAndEdit("editing"));

    dispatch(
      setMainTraveler({
        dateOfBirth: travelers[0].dateOfBirth,
        firstName: travelers[0].firstName,
        lastName: travelers[0].lastName,
        email: travelers[0].email,
      }),
    );

    dispatch(
      setTravelers([
        { ...travelers[0], mainTraveller: true },
        ...travelers
          .slice(1)
          .map((traveler) => ({ ...traveler, mainTraveller: false })),
      ]),
    );
  },
);
