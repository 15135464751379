import PaperComponent from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const Paper = styled(PaperComponent, {
  label: "paper",
})`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;

  && {
    max-width: ${({ theme }) => theme.spacing(100)};
  }
`;
