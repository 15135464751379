import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { OfferLayout } from "layouts/offer";
import { OnboardingLayout } from "layouts/onboarding";
import { LoaderScreen } from "screens/loader";
import { OfferScreen } from "screens/offer";

export function OfferRouter() {
  const { search } = useLocation();

  const [loaded, setLoaded] = useState(false);
  const [isFayeApiRequest, setIsFayeApiRequest] = useState(false);
  const [isPaymentRequest, setIsPaymentRequest] = useState(false);

  useEffect(() => {
    if (new URLSearchParams(search).has("fapi")) {
      setIsFayeApiRequest(true);
    }

    if (new URLSearchParams(search).has("p")) {
      setIsPaymentRequest(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      <Route element={loaded ? <OfferLayout /> : <OnboardingLayout />}>
        <Route
          index
          element={
            !loaded ? (
              <LoaderScreen
                onComplete={() => {
                  setLoaded(true);
                }}
              />
            ) : isPaymentRequest ? (
              <Navigate to="/checkout" />
            ) : (
              <OfferScreen isFayeApiRequest={isFayeApiRequest} />
            )
          }
        />
      </Route>
    </Routes>
  );
}
