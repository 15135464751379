import dayjs from "dayjs";
import dayjsUtcPlugin from "dayjs/plugin/utc";

dayjs.extend(dayjsUtcPlugin);

export interface Birthdate {
  day: string;
  month: string;
  year: string;
}

export function calculateAge(birthdate: string) {
  const birthday = dayjs.utc(birthdate);
  const today = dayjs.utc().startOf("day");

  const birthdayMonth = birthday.month();
  const todayMonth = today.month();
  const yearDifference = today.year() - birthday.year();

  if (
    todayMonth < birthdayMonth ||
    (todayMonth === birthdayMonth && today.date() < birthday.date())
  ) {
    return yearDifference - 1;
  }

  return yearDifference;
}

export function parseBirthdate(value: Date | string | null): Birthdate {
  if (!value) {
    return {
      day: "",
      month: "",
      year: "",
    };
  }

  const date = dayjs(value);

  return {
    day: `${date.date()}`.padStart(2, "0"),
    month: `${date.month() + 1}`.padStart(2, "0"),
    year: `${date.year()}`,
  };
}

export function stringifyBirthdate({ day, month, year }: Birthdate) {
  return (
    year.padStart(4, "0") +
    "-" +
    month.padStart(2, "0") +
    "-" +
    day.padStart(2, "0")
  );
}
