import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "talking-head",
})`
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.create("height")};

  &.show {
    // Use padding-top instead of margin-top, so the container fills up the space
    // before the previous component, making the speech bubble transition starting
    // from "behind" it
    padding-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const SpeechBubble = styled("div", {
  label: "speech-bubble",
})`
  background: ${({ theme }) => theme.palette.color.secondary[95]};
  border-radius: ${({ theme }) => theme.spacing(1.75)};
  color: ${({ theme }) => theme.palette.color.primary.main};
  flex: 0 1 ${({ theme }) => theme.spacing(35)};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  opacity: 0;
  position: relative;
  padding: ${({ theme }) => theme.spacing(1.5)};
  transform: translate3d(0, ${({ theme }) => theme.spacing(-2)}, 0);
  transition: ${({ theme }) =>
    theme.transitions.create(["opacity", "transform"])};

  &:before {
    content: "";
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${({ theme }) => theme.palette.color.secondary[95]};
  }

  .show & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: ${({ theme }) =>
      theme.transitions.create(["opacity", "transform"], {
        delay: theme.transitions.duration.standard * 1.5,
      })};
  }
`;
