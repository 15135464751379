import { styled } from "@mui/material/styles";
import {
  DateRangeCalendar,
  dateRangeCalendarClasses,
  dayPickerClasses,
  pickersCalendarHeaderClasses,
} from "@mui/x-date-pickers-pro";
import { type Dayjs } from "dayjs";
import { Day as DayComponent } from "components/date-picker/day";

export const DatePicker = styled(DateRangeCalendar<Dayjs>, {
  label: "date-picker",
})`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.color.text.primary};
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1)};

  .${dateRangeCalendarClasses.monthContainer} {
    flex: 1;
  }

  .${pickersCalendarHeaderClasses.root} {
    margin: ${({ theme }) => theme.spacing(0.25, 0)};
    padding: ${({ theme }) => theme.spacing(0, 0, 0, 1.75)};

    .${pickersCalendarHeaderClasses.labelContainer} {
      cursor: text;
      font-size: 13px;
      font-weight: 600;
    }

    .MuiPickersArrowSwitcher-root {
      padding: 0;

      .MuiPickersArrowSwitcher-button {
        color: inherit;
        padding: ${({ theme }) => theme.spacing(0.5)};

        &.Mui-disabled {
          color: ${({ theme }) => theme.palette.color.gray[60]};
        }
      }
    }
  }

  .${dayPickerClasses.weekDayLabel} {
    color: inherit;
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    width: auto;
    height: ${({ theme }) => theme.spacing(4)};
  }

  .${dayPickerClasses.slideTransition} {
    min-height: auto;
  }

  .${dayPickerClasses.weekContainer} {
    margin: 0;
  }
`;

export const Day = styled(DayComponent, {
  label: "day",
})`
  font-size: 14px;
  height: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(0.5)};

  &.highlight,
  &.preview {
    color: inherit;
  }

  &.highlightEnd,
  &.previewEnd {
    &::before {
      border-top-right-radius: 100vh;
      border-bottom-right-radius: 100vh;
    }
  }

  &.highlightStart,
  &.previewStart {
    &::before {
      border-top-left-radius: 100vh;
      border-bottom-left-radius: 100vh;
    }
  }

  &::after {
    background: ${({ theme }) => theme.palette.color.primary[95]};
    border-radius: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
