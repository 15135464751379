import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { type Quote } from "store/quote/initial-state";
import { type QuoteDto } from "types/quote-types";
import { sendQuotePayment } from "utils/api/quote";
import { sentryCaptureError } from "utils/tracking/sentry";
import { type PaymentChangeListener } from "..";
import { CircularProgress, Container } from "./styles";

export type TestPaymentMethod = "test";

export interface TestUserProps {
  active: boolean;
  onPaymentChange: PaymentChangeListener<TestPaymentMethod>;
  quoteDto: QuoteDto;
  userPolicy?: Quote["userPolicy"];
}

export function TestUser({
  active,
  onPaymentChange,
  quoteDto,
  userPolicy,
}: TestUserProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "stripe.paymentForm",
  });

  const onPaymentChangeRef = useRef(onPaymentChange);
  onPaymentChangeRef.current = onPaymentChange;

  useEffect(() => {
    if (!active) {
      return;
    }

    onPaymentChangeRef.current("test", { status: "request" });

    void sendQuotePayment(
      {
        paymentService: "stripe",
        paymentToken: "tok_dummy",
        purchasePaymentType: "PAYMENT_TYPE_CREDIT_CARD",
        ...(userPolicy?.id && { quoteId: userPolicy.id }),
      },
      quoteDto,
    )
      .then((paymentResponse) => {
        onPaymentChangeRef.current("test", {
          status: "success",
          response: paymentResponse,
        });
      })
      .catch((error) => {
        sentryCaptureError(error, {
          contexts: { payment: { paymentType: "test" } },
          name: "Payment_failed",
        });

        onPaymentChangeRef.current("test", {
          status: "error",
          message:
            (error as Error).message ??
            (typeof error === "string" ? error : t("error.default")),
        });
      });
  }, [active, quoteDto, t, userPolicy?.id]);

  return (
    <Container>
      <CircularProgress />
      {t("testUser.lead")}
    </Container>
  );
}
