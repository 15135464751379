import { useTranslation } from "react-i18next";
import { Popup } from "components/popup";
import { PopupBody } from "components/popup/body";
import { PopupButton } from "components/popup/button";
import { getStateByCode } from "utils/place/residency";
import { Content, Header } from "./styles";

export interface LimitedTripLengthPopupProps {
  stateCode: string | null;
}

export function LimitedTripLengthPopup({
  stateCode,
}: LimitedTripLengthPopupProps) {
  const state = stateCode ? getStateByCode(stateCode) : null;

  const { t } = useTranslation("components", {
    keyPrefix: "limitedTripLengthPopup",
  });

  return (
    <Popup disablePortal open={!!state?.label}>
      <Header hideCloseButton title={t("title")} />

      <PopupBody>
        <Content>{t("text", { state: state?.label })}</Content>

        <PopupButton type="submit">{t("button.label")}</PopupButton>
      </PopupBody>
    </Popup>
  );
}
