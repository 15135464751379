import { Trans } from "react-i18next";
import { Link } from "components/i18n/link";
import { selectDisclaimerPdfUrl } from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import { useDisclaimerParagraphs } from "../../utils";
import { Content } from "./styles";

export interface FootnotesProps {
  showPrintParagraph: boolean;
}

export function Footnotes({ showPrintParagraph }: FootnotesProps) {
  const disclaimerPdfUrl = useSelector(selectDisclaimerPdfUrl);

  const paragraphs = useDisclaimerParagraphs(showPrintParagraph);

  return (
    <>
      {paragraphs.map((paragraph) => (
        <Content key={paragraph}>
          <Trans
            components={{
              Link: <Link />,
              PdfLink: <Link to={disclaimerPdfUrl ?? "#"} />,
            }}
          >
            {paragraph}
          </Trans>
        </Content>
      ))}
    </>
  );
}
