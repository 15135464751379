import { autocompleteClasses } from "@mui/material/Autocomplete";
import { filledInputClasses } from "@mui/material/FilledInput";
import { inputBaseClasses } from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { Input as InputComponent } from "components/input";

export const Input = styled(InputComponent, {
  label: "input",
})`
  && .${autocompleteClasses.inputRoot} {
    // &&: increase specifity
    &.${inputBaseClasses.root} {
      padding: ${({ theme }) => theme.spacing(1.25, 0)};
    }

    &.${filledInputClasses.root} {
      padding: ${({ theme }) => theme.spacing(0.25, 0)};
    }

    &.${inputBaseClasses.disabled} {
      background: none;
      color: ${({ theme }) => theme.palette.color.gray[70]};
    }

    .${autocompleteClasses.input} {
      padding: 21px 0 4px;
    }
  }

  .${autocompleteClasses.endAdornment} {
    display: flex;
    position: relative;
  }

  .${autocompleteClasses.clearIndicator},
    .${autocompleteClasses.popupIndicator} {
    margin: ${({ theme }) => theme.spacing(0, -1, 0, 0)};
    padding: ${({ theme }) => theme.spacing(1)};

    &:hover {
      background: none;
    }
  }
`;

export const Wrapper = styled("div", {
  label: "container",
})`
  position: relative;

  .${autocompleteClasses.popper} {
    padding-top: ${({ theme }) => theme.spacing(0.25)};
    top: var(--input-height, 0px) !important;
    transform: none !important;
    width: 100% !important;
  }
`;
