import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "utils/tracking";
import { updateMixpanelUser } from "utils/tracking/mixpanel";

export function trackSubmit(state: string) {
  updateMixpanelUser({ state });
}

export function useTracking() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView("State", pathname);
  }, [pathname]);
}
