import { getCacheKey } from ".";

const CACHE: Record<string, Intl.DateTimeFormat> = {};

export interface Options extends Intl.DateTimeFormatOptions {
  locale?: string;
  style?: "medium" | "short";
}

export function dateFormatter(
  date: Date,
  { locale = "en-US", style = "medium", ...publicOptions }: Options = {},
) {
  const privateOptions: Intl.DateTimeFormatOptions = {};

  if (style === "medium") {
    privateOptions.day = "2-digit";
    privateOptions.month = "short";
    privateOptions.year = "numeric";
  }

  const options = { ...privateOptions, ...publicOptions };
  const cacheKey = getCacheKey(locale, options);

  if (!CACHE[cacheKey]) {
    CACHE[cacheKey] = new Intl.DateTimeFormat(locale, options);
  }

  return CACHE[cacheKey].format(date);
}
