import { styled } from "@mui/material/styles";
import { Lottie as LottieComponent } from "components/lottie";

export const Lottie = styled(LottieComponent, {
  label: "lottie",
})`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-20%);
  z-index: 1;
`;

export const Summary = styled("div", {
  label: "summary",
})`
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 1;
    margin: ${({ theme }) => theme.spacing(4, 0, 0, 0)};
    max-width: ${({ theme }) => theme.spacing(58)};
    text-align: left;
  }
`;

export const Top = styled("div", { label: "top" })`
  background-color: ${({ theme }) => theme.palette.color.primary[110]};
  border-radius: ${({ theme }) => theme.spacing(0, 0, 4, 4)};
  padding: ${({ theme }) => theme.spacing(2, 3)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    background-color: initial;
    border-radius: 0;
    padding: 0;
  }
`;

export const Title = styled("h1", {
  label: "title",
})`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme }) => theme.palette.color.text.primary};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 54px;
    font-size: clamp(24px, 4vw, 56px);
    color: ${({ theme }) => theme.palette.color.primary.main};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    line-height: 1.12;
    margin: 0;
    white-space: nowrap;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: clamp(50px, 4vw, 56px);
  }
`;
