import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { type PlaceDetails } from "types/quote-types";
import { trackPageView } from "utils/tracking";
import { mixpanelTrack, updateMixpanelUser } from "utils/tracking/mixpanel";

export function trackSubmit(destinations: PlaceDetails[]) {
  const names = destinations.map((destination) => destination.label);

  updateMixpanelUser({
    searched_destinations: names,
  });

  mixpanelTrack("setting_destination", {
    destinations: names,
    is_multiple_destinations: destinations.length > 1,
  });
}

export function useTracking() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView("Destinations", pathname);
  }, [pathname]);
}
