import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { type PaymentMethod } from "components/payment-form";
import { useSelector } from "hooks/use-selector";
import {
  selectCoverages,
  selectDefaultTripCost,
  selectDestinations,
  selectEmail,
  selectIsDomesticTraveling,
  selectIsRemarketing,
  selectName,
  selectTotalPrice,
  selectTravelers,
  selectTripCost,
} from "store/quote/selectors";
import { type Residency } from "types/quote-types";
import { trackPageView } from "utils/tracking";
import { getGtmDataLayer, gtmTrack } from "utils/tracking/gtm";
import { track as iterableTrack } from "utils/tracking/iterable";
import {
  mixpanelTrack,
  updateMixpanelUser,
  identifyMixpanelUser,
} from "utils/tracking/mixpanel";

export function useAddressTracking(active: boolean, isFullAddress: boolean) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (active) {
      trackPageView(
        isFullAddress ? "cant_find_address" : "complete_home_address",
        pathname,
      );
    }
  }, [active, isFullAddress, pathname]);
}

export function useEmailTracking(active: boolean) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (active) {
      trackPageView("complete_email", pathname);
    }
  }, [active, pathname]);
}

export function usePaymentTracking(active: boolean) {
  const destinations = useSelector(selectDestinations);

  const { pathname } = useLocation();

  useEffect(() => {
    if (active) {
      trackPageView("Payment", pathname, {
        num_of_destinations: destinations?.length,
      });
    }
  }, [active, pathname]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useTrackAddressSubmit() {
  const name = useSelector(selectName);

  return (residency: Residency) => {
    // Google Tag Manager

    getGtmDataLayer().push({
      address: {
        city: residency.locality,
        country: residency.country,
        first_name: name?.firstName,
        last_name: name?.lastName,
        postal_code: residency.zipCode,
        region: residency.areaLevel1,
        street: `${residency.streetAndNumber} ${residency.apartment}`.trim(),
      },
    });

    // Mixpanel

    updateMixpanelUser({ address: residency.label });
  };
}

export function useTrackEmailSubmit() {
  return (email: string) => {
    // Google Tag Manager

    getGtmDataLayer().push({ email });

    // Mixpanel
    void identifyMixpanelUser(email);
    updateMixpanelUser({ $email: email });
  };
}

export function useTrackCtaPressed() {
  const coverages = useSelector(selectCoverages);
  const totalPrice = useSelector(selectTotalPrice);
  const tripCost = useSelector(selectTripCost);

  return () => {
    const selectedAddons = coverages
      .filter((coverage) => !coverage.isBase && coverage.isSelected)
      .map((coverage) => coverage.title);

    updateMixpanelUser({
      selected_add_ons: selectedAddons,
    });

    mixpanelTrack("checkout_cta_pressed", {
      pressed_payment_CTA: "Bottom CTA",
      quote_price: totalPrice,
      selected_add_ons: selectedAddons,
      trip_cost: tripCost && Math.floor(tripCost),
    });
  };
}

export function useTrackPaymentMade() {
  const coverages = useSelector(selectCoverages);
  const defaultTripCost = useSelector(selectDefaultTripCost);
  const email = useSelector(selectEmail);
  const isDomesticTraveling = useSelector(selectIsDomesticTraveling);
  const isRemarketing = useSelector(selectIsRemarketing);
  const totalPrice = useSelector(selectTotalPrice);
  const travelers = useSelector(selectTravelers);
  const tripCost = useSelector(selectTripCost);

  return useCallback(
    (paymentMethod: PaymentMethod, uniqueUuid?: string, error?: string) => {
      const data = {
        default_trip_cost: defaultTripCost,
        payment_type: paymentMethod,
        status: typeof error !== "undefined" ? error : "Success",
        total_price: totalPrice,
        total_travelers_count: travelers.length,
        trip_cost: tripCost && Math.floor(tripCost),
      };

      const lastPurchaseDate = dayjs().format("YYYY-MM-DD");

      // Google Tag Manager

      const gtmItems = [
        {
          item_name: isDomesticTraveling ? "domesticBase" : "internationalBase",
          quantity: travelers.length,
        },
        ...coverages
          .filter(({ isDefault }) => !isDefault)
          .filter(({ isSelected }) => isSelected)
          .map(({ coverageType, isBase }) => ({
            item_name: coverageType,
            quantity: isBase ? travelers.length : 1,
          })),
      ];

      gtmTrack("purchase", {
        ecommerce: {
          currency: "USD",
          items: gtmItems,
          transaction_id: uniqueUuid,
          value: totalPrice,
        },
        email,
      });

      // Mixpanel

      updateMixpanelUser({
        last_purchase_date: lastPurchaseDate,
      });

      mixpanelTrack("made_payment", {
        is_remarketing: !!isRemarketing,
        last_purchase_date: lastPurchaseDate,
        ...data,
      });

      // Iterable

      if (email) {
        iterableTrack({
          dataFields: data,
          email,
          eventName: "iterable_purchase_policy",
        });
      }
    },

    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
}
