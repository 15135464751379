import { styled } from "@mui/material/styles";
import { Button as ButtonComponent } from "components/button";
import ArrowIconAsset from "assets/images/icons/arrow.svg";

export const Button = styled(ButtonComponent, {
  label: "button",
})`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.25)};
  padding: ${({ theme }) => theme.spacing(2, 3)};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: inherit;
  }

  & + & {
    margin-left: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => theme.breakpoints.up("sm")} {
      margin-left: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

export const ButtonGroup = styled("div", {
  label: "button-group",
})`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2.5, 0)};
  justify-content: center;
  width: 100%;
  left: 0;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: ${({ theme }) => theme.spacing(0, "auto")};
  }

  .single-button & {
    ${({ theme }) => theme.breakpoints.up("sm")} {
      max-width: ${({ theme }) => theme.spacing(42)};
    }
  }
`;

export const Container = styled("form", {
  label: "form",
})`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: 0 auto;
    max-width: ${({ theme }) => theme.spacing(60)};
  }
`;

export const ArrowIcon = styled(ArrowIconAsset, {
  label: "arrow-icon",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
`;
