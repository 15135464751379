import { styled } from "@mui/material/styles";

export const EmailForm = styled("div", {
  label: "email-form",
})`
  margin: ${({ theme }) => theme.spacing(3, 0, 2.5, 4.5)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: ${({ theme }) => theme.spacing(56)};
  }

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    margin-left: ${({ theme }) => theme.spacing(5)};
  }
`;
