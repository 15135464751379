import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "utils/tracking";
import { useSelector } from "hooks/use-selector";
import { selectEmail } from "store/quote/selectors";
import {
  identifyMixpanelUser,
  mixpanelTrack,
  updateMixpanelUser,
} from "utils/tracking/mixpanel";

export function trackAddTraveler() {
  mixpanelTrack("clicked_add_traveler");
}

export function trackRemoveTraveler() {
  mixpanelTrack("clicked_remove_traveler");
}

export function useTrackSubmit() {
  const currentEmail = useSelector(selectEmail);

  return (
    firstName: string,
    lastName: string,
    birthdate: string,
    travelersCount: number,
    email?: string,
  ) => {
    if (email) {
      void identifyMixpanelUser(email, currentEmail);
    }

    updateMixpanelUser({
      $email: email,
      $first_name: firstName,
      $last_name: lastName,
      date_of_birth: birthdate,
      total_travelers_count: travelersCount,
    });
  };
}

export function useTracking() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView("Traveler DOB & Name", pathname);
  }, [pathname]);
}
