import { forwardRef } from "react";
import { type ButtonProps } from "..";
import { Component, Progress } from "./styles";

export interface ProgressButtonProps extends ButtonProps {
  loading?: boolean;
}

export const ProgressButton = forwardRef<
  HTMLButtonElement,
  ProgressButtonProps
>(function ProgressButton({ children, loading, ...props }, ref) {
  return (
    <Component ref={ref} {...props}>
      {loading && <Progress />}
      {children}
    </Component>
  );
});
