import { useTranslation } from "react-i18next";
import { AppStoresQrCode, Container, Title } from "./styles";

export function QrCode() {
  const { t } = useTranslation("screens", {
    keyPrefix: "confirmation.download",
  });

  return (
    <Container>
      <Title>{t("qrCode")}</Title>
      <AppStoresQrCode />
    </Container>
  );
}
