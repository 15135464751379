import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { type QuotePaymentResponse, sendQuotePayment } from "utils/api/quote";
import { sentryCaptureError } from "utils/tracking/sentry";
import {
  type ButtonPaymentMethod,
  type ProviderButtonProps,
} from "../../buttons/button";
import { getCheckoutSession } from "utils/api/checkout";
import type {
  Component as PaymentComponent,
  ComponentName as Method,
  PayPaymentSessionSuccessfulResponse,
} from "@checkout.com/checkout-web-components";

import { ButtonWrapper, SuccessButton } from "./styles";

const GOOGLEPAY = "googlepay" as Method.Googlepay;
const APPLEPAY = "applepay" as Method.Applepay;

export function CheckoutButton({
  currentPaymentMethod,
  onLoad,
  onPaymentChange,
  quoteDto,
  userPolicy,
  variant = "dark",
}: ProviderButtonProps) {
  const [buttonWidth, setButtonWidth] = useState(0);
  const [paymentResponse, setPaymentResponse] =
    useState<QuotePaymentResponse>();
  const [availablePaymentMethod, setAvailablePaymentMethod] =
    useState<ButtonPaymentMethod>();
  const paymentElement = useRef<HTMLDivElement | null>(null);

  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;

  const onPaymentChangeRef = useRef(onPaymentChange);
  onPaymentChangeRef.current = onPaymentChange;

  const quoteDtoRef = useRef(quoteDto);
  quoteDtoRef.current = quoteDto;

  const { t } = useTranslation("components", {
    keyPrefix: "paymentForm",
  });

  useEffect(() => {
    let applePayComponent: PaymentComponent | null = null;
    let googlepayComponent: PaymentComponent | null = null;
    void getCheckoutSession(quoteDto.policyCodeHash)
      .then(async (response) => {
        onLoadRef.current(true);
        const cwc = await window.CheckoutWebComponents({
          publicKey: import.meta.env.VITE_CHECKOUT_PUBLIC_KEY,
          paymentSession: response,
          environment:
            import.meta.env.VITE_IS_DEVELOPMENT === "true"
              ? "sandbox"
              : "production",
          onPaymentCompleted: (component, payment) => {
            if (paymentElement.current) {
              setButtonWidth(paymentElement.current?.clientWidth);
            }

            void handlePaymentCompleted(component, payment);
          },
          onError: (_, error) => {
            handlePaymentFailed(
              error.details.type === GOOGLEPAY ? "googlePay" : "applePay",
            );
          },
        });

        applePayComponent = cwc.create(APPLEPAY);
        if (await applePayComponent.isAvailable()) {
          setAvailablePaymentMethod("applePay");
          applePayComponent.mount(paymentElement.current as HTMLElement);
        } else {
          googlepayComponent = cwc.create(GOOGLEPAY);
          if (await googlepayComponent.isAvailable()) {
            setAvailablePaymentMethod("googlePay");
            googlepayComponent.mount(paymentElement.current as HTMLElement);
          }
        }
      })
      .catch(() => {
        onLoadRef.current(false);
      });

    return () => {
      applePayComponent?.unmount();
      googlepayComponent?.unmount();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handlePaymentCompleted(
    { type: paymentType }: PaymentComponent,
    { id }: PayPaymentSessionSuccessfulResponse,
  ) {
    const paymentMethod = paymentType === GOOGLEPAY ? "googlePay" : "applePay";
    onPaymentChangeRef.current(paymentMethod, { status: "request" });

    try {
      setPaymentResponse(
        await sendQuotePayment(
          {
            paymentMethod,
            paymentToken: id,
            paymentService: "checkOut",
            purchasePaymentType:
              paymentMethod === "applePay"
                ? "PAYMENT_TYPE_APPLE_PAY"
                : "PAYMENT_TYPE_GOOGLE_PAY",
            ...(userPolicy?.id && { quoteId: userPolicy.id }),
          },
          quoteDtoRef.current,
        ),
      );
    } catch (error) {
      const message =
        (error as Error).message ??
        (typeof error === "string" ? error : t("error.default"));
      handlePaymentFailed(paymentMethod, message);
    }
  }

  function handlePaymentFailed(
    method: ButtonPaymentMethod,
    message?: string | null,
  ) {
    sentryCaptureError(message, {
      contexts: { payment: { paymentType: method } },
      name: "Payment_failed",
    });

    onPaymentChangeRef.current(method, {
      status: "error",
      message: message ?? t("error.default"),
    });
  }

  function handleDoneLottieComplete() {
    if (paymentResponse) {
      onPaymentChangeRef.current(
        currentPaymentMethod as "applePay" | "googlePay",
        { response: paymentResponse, status: "success" },
      );
    }
  }

  if (availablePaymentMethod && paymentResponse) {
    return (
      <SuccessButton
        success
        currentPaymentMethod={currentPaymentMethod}
        doneLabel={t("buttons.label.done")}
        onDoneLottieComplete={handleDoneLottieComplete}
        paymentMethod={availablePaymentMethod}
        variant={variant}
        style={{ minWidth: `${buttonWidth}px` }}
      />
    );
  }

  return (
    <ButtonWrapper
      ref={paymentElement}
      id="payment-provider-form"
      className={classNames({
        disabled: currentPaymentMethod === availablePaymentMethod,
      })}
    />
  );
}
