const EXTRACT_CALENDAR_DATE_REGEXP = /^(\d{4})-(\d{2})-(\d{2})/;

export class LocalTimezoneDate extends Date {
  constructor(value: string) {
    const parts = value.match(EXTRACT_CALENDAR_DATE_REGEXP)?.slice(1, 4);

    if (parts?.length === 3) {
      super(+parts[0], +parts[1] - 1, +parts[2]);
    } else {
      super(NaN);
    }
  }
}
