import { useTranslation } from "react-i18next";
import { OfferLayoutPrintHeader } from "layouts/offer/print/header";
import { dateFormatter } from "utils/formatters/date";
import { Content, Lead, QrCode, QrCodeContainer, Title } from "./styles";

export interface HeaderProps {
  showQrCode: boolean;
}

export function Header({ showQrCode }: HeaderProps) {
  const { t } = useTranslation("screens", {
    keyPrefix: "offer.printLayout.header",
  });

  return (
    <OfferLayoutPrintHeader>
      <Content>
        <div>
          <Title>{t("title")}</Title>
          <Lead>{t("lead", { date: dateFormatter(new Date()) })}</Lead>
        </div>

        {showQrCode && (
          <QrCodeContainer>
            {t("qrCode.title")}
            <QrCode value={window.location.href} />
          </QrCodeContainer>
        )}
      </Content>
    </OfferLayoutPrintHeader>
  );
}
