import { inputClasses } from "@mui/material";
import { filledInputClasses } from "@mui/material/FilledInput";
import { inputBaseClasses } from "@mui/material/InputBase";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const Component = styled(TextField, {
  label: "base-input",
})`
  width: 100%;

  .${inputLabelClasses.root} {
    cursor: text;
    font-size: inherit;
    // "-0.5px" aligns label with simple variant placeholder
    margin: ${({ theme }) => theme.spacing("-0.5px", 0, 0, -1.75)};
    max-width: none;
    padding: ${({ theme }) => theme.spacing(0.25)};
    color: ${({ theme }) => theme.palette.color.gray[70]};
    transition: ${({ theme }) =>
      theme.transitions.create(["color", "padding-top", "transform"])};

    &.${inputLabelClasses.focused} {
      color: ${({ theme }) => theme.palette.color.primary.main};
    }

    &.${inputLabelClasses.error} {
      color: ${({ theme }) => theme.palette.color.alert.main};
    }

    &.${inputLabelClasses.shrink} {
      padding-top: ${({ theme }) => theme.spacing(0.5)};
    }

    .${inputLabelClasses.asterisk} {
      color: inherit;
    }
  }

  .${inputBaseClasses.root} {
    color: inherit;
    font-size: inherit;

    ${({ theme }) => theme.breakpoints.up("md")} {
      line-height: 1.5;
    }

    &.${inputBaseClasses.focused} {
      border-color: ${({ theme }) => theme.palette.color.primary.main};
    }

    .${inputBaseClasses.input} {
      // border-radius below gives a better look on native browser highlight
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
      color: inherit;
      height: auto;
      line-height: inherit;

      &::placeholder {
        color: ${({ theme }) => theme.palette.color.gray[70]};
        opacity: 1;
      }

      &:autofill {
        background-clip: text;
      }
    }
  }

  .${filledInputClasses.root} {
    padding-top: ${({ theme }) => theme.spacing(0.25)};
    padding-bottom: ${({ theme }) => theme.spacing(0.25)};

    &,
    &:hover,
    &.${filledInputClasses.focused} {
      background: none;
    }

    .${filledInputClasses.input} {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .${inputClasses.root} {
    margin: 0;
    padding: ${({ theme }) => theme.spacing(1.25, 0)};
  }

  .${inputClasses.input} {
    padding: 4px 0 5px !important; // Reset to default Input padding
  }
`;

export const Container = styled("div", {
  label: "container",
})`
  align-items: center;
  background: ${({ theme }) => theme.palette.color.gray[100]};
  border: 1px solid ${({ theme }) => theme.palette.color.gray[90]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.color.text.primary};
  display: flex;
  font-size: 16px;
  padding: ${({ theme }) => theme.spacing(0, 1.5)};
  transition: ${({ theme }) =>
    theme.transitions.create(["background", "border", "color"])};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 14px;
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.palette.color.primary.main};
  }

  .faye__input--error & {
    color: ${({ theme }) => theme.palette.color.alert.main};
    border-color: ${({ theme }) => theme.palette.color.alert.main};
    background-color: ${({ theme }) => theme.palette.color.alert.light};
  }

  .faye__input--warning
    &
    .${inputLabelClasses.root},
    .faye__input--warning
    &
    .${inputLabelClasses.root}.${inputLabelClasses.focused} {
    color: ${({ theme }) => theme.palette.color.alert.main};
  }
`;

export const StartAdornment = styled("div", {
  label: "start-adornment",
})`
  align-items: center;
  display: inline-flex;
  margin-right: ${({ theme }) => theme.spacing(1)};
  position: relative;
`;

export const EndAdornment = styled("div", {
  label: "end-adornment",
})`
  align-items: center;
  display: inline-flex;
  margin-left: ${({ theme }) => theme.spacing(1)};
  position: relative;
`;

export const Wrapper = styled("div", {
  label: "wrapper",
})`
  width: 100%;
`;
