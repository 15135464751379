import { styled } from "@mui/material/styles";

export const Component = styled("h2", {
  label: "title",
})`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.color.text.primary};
  line-height: 1.4;
  position: relative;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 28px;
    font-weight: 500;
  }
`;
