import { styled } from "@mui/material/styles";
import { Logo as BaseLogo } from "components/logo";

export const Container = styled("header", {
  label: "header",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  margin-top: var(--top-offset, 0);
  transition: ${({ theme }) =>
    theme.transitions.create(["margin-top"], {
      duration: theme.transitions.duration.short,
    })};
`;

export const Content = styled("div", {
  label: "Content",
})`
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(3, 2, 2)};
  display: flex;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(4, 8, 2)};
  }
`;

export const Logo = styled(BaseLogo, { label: "logo" })`
  position: absolute;
`;

export const Avatar = styled("img", {
  label: "avatar",
})`
  border-radius: 100%;
  display: block;
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing(0, "auto")};
  width: ${({ theme }) => theme.spacing(8)};
  height: ${({ theme }) => theme.spacing(8)};

  &.large {
    width: ${({ theme }) => theme.spacing(10)};
    height: ${({ theme }) => theme.spacing(10)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ theme }) => theme.spacing(10)};
  }
`;
