import { styled } from "@mui/material/styles";
import { TalkingHead as BaseTalkingHead } from "components/talking-head";
import ClearIconAsset from "assets/images/icons/close-light.svg";
import { ScreenHeader as ScreenHeaderComponent } from "components/screen-header";

export const Taglist = styled("ul", { label: "taglist" })`
  list-style: none;
  margin-top: ${({ theme }) => theme.spacing(1.25)};
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1.25)};
`;

export const Tag = styled("li", {
  label: "tag",
})`
  background: ${({ theme }) => theme.palette.color.primary.background};
  border-radius: 100vh;
  color: ${({ theme }) => theme.palette.color.primary.main};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3;
  padding: ${({ theme }) => theme.spacing(0.75, 2)};
  transform-origin: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.75)};
  transform: scale(100%);

  &.removed {
    transform: scale(0%);
    transition: transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(1.5, 2)};
  }
`;

export const TalkingHead = styled(BaseTalkingHead, { label: "talking-head" })`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const ClearIcon = styled(ClearIconAsset, {
  label: "clear-icon",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(1.25)};
  height: auto;
`;

export const ScreenHeader = styled(ScreenHeaderComponent, {
  label: "screen-header",
})`
  padding: 0;
`;
