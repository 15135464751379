const CACHE = new Map<string, Promise<boolean>>();

export interface LoadScriptOptions {
  defer?: number;
  resolver?: (resolve: (value: boolean | PromiseLike<boolean>) => void) => void;
  timeout?: number;
}

const preset: { [Key in string]?: { src: string } & LoadScriptOptions } = {
  googlePlacesApi: {
    resolver: (resolve) => {
      window.initGoogleMaps = () => {
        resolve(true);
      };
    },
    src: `https://maps.googleapis.com/maps/api/js?key=${
      import.meta.env.VITE_GOOGLE_PLACES_API_KEY
    }&callback=initGoogleMaps&language=en&libraries=places&v=3.52`,
  },
};

export async function loadScript(
  // eslint-disable-next-line @typescript-eslint/ban-types
  src: "googlePlacesApi" | (string & {}),
  { defer, resolver, timeout }: LoadScriptOptions = {},
) {
  let script: HTMLScriptElement;
  let cachedScript = CACHE.get(src);

  if (cachedScript === undefined) {
    const presetData = preset[src];

    if (presetData) {
      defer ??= presetData.defer;
      resolver ??= presetData.resolver;
    }

    if (defer || timeout) {
      await new Promise((resolve) => {
        // Defer loading, so the script will not affect initial page load
        setTimeout(resolve, defer ?? 1000);
      });
    }

    cachedScript = new Promise<boolean>((resolve) => {
      script = document.createElement("script");

      script.src = presetData?.src ?? src;

      if (resolver) {
        resolver(resolve);
      } else {
        script.addEventListener("load", () => {
          resolve(true);
        });
      }

      script.addEventListener("error", () => {
        CACHE.delete(src);
        resolve(false);
      });

      document.body.appendChild(script);
    });

    CACHE.set(src, cachedScript);
  }

  return await Promise.race([
    cachedScript,
    ...(timeout
      ? [
          new Promise((resolve) => {
            setTimeout(() => {
              script?.remove();
              resolve(false);
            }, timeout);
          }),
        ]
      : []),
  ]);
}
