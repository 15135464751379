import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

export const Component = styled(ButtonBase, {
  label: "day",
})`
  flex: 1;
  font-family: inherit;
  line-height: 1;
  position: relative;
  z-index: 0;

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.color.gray[60]};
  }

  &.highlight,
  &.preview {
    &::before {
      content: "";
    }
  }

  &.highlightEnd,
  &.highlightStart,
  &.hover,
  &.previewEnd,
  &.previewStart,
  &.selected {
    &::after {
      content: "";
    }
  }

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
  }

  &::before {
    background: ${({ theme }) => theme.palette.color.primary[95]};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
