import { useTranslation } from "react-i18next";
import { useWhatsCoveredStrings } from "../../utils";
import { Section, type SectionProps } from "../section";
import { SectionBody } from "../section/body";
import { Item } from "./item";
import { ItemsWrapper } from "./styles";

export interface WhatsCoveredProps extends Omit<SectionProps, "title"> {}

export function WhatsCovered(props: WhatsCoveredProps) {
  const { t } = useTranslation("screens", { keyPrefix: "offer.whatsCovered" });

  const whatsCoveredStrings = useWhatsCoveredStrings();

  return (
    whatsCoveredStrings && (
      <Section {...props}>
        <SectionBody title={t("title")}>
          <ItemsWrapper>
            <Item
              title={whatsCoveredStrings.yourHealth.title}
              topics={whatsCoveredStrings.yourHealth.topics}
              popup={whatsCoveredStrings.yourHealth.popup}
            />

            <Item
              title={whatsCoveredStrings.yourTrip.title}
              topics={whatsCoveredStrings.yourTrip.topics}
              popup={whatsCoveredStrings.yourTrip.popup}
            />

            <Item
              title={whatsCoveredStrings.yourStuff.title}
              topics={whatsCoveredStrings.yourStuff.topics}
              popup={whatsCoveredStrings.yourStuff.popup}
            />
          </ItemsWrapper>
        </SectionBody>
      </Section>
    )
  );
}
