import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { type Quote } from "store/quote/initial-state";
import { type QuoteDto } from "types/quote-types";
import { getExperimentVariation } from "utils/experiments";
import {
  type PaymentMethod,
  type PaymentChangeListener,
  type PaymentFormProps,
} from "..";
import { PayPal } from "./paypal";
import { StripeButton } from "../stripe/button";
import { CheckoutButton } from "../checkout/button";
import { Container, Content, Divider } from "./styles";

export interface ButtonsProps {
  currentPaymentMethod: PaymentMethod;
  onLoad?: () => void;
  onPaymentChange: PaymentChangeListener<
    Extract<PaymentMethod, "applePay" | "googlePay" | "payPal" | null>
  >;
  paymentLinkHash?: string;
  quoteDto: QuoteDto;
  show: NonNullable<PaymentFormProps["show"]>;
  totalPrice: number;
  userPolicy?: Quote["userPolicy"];
  variant?: "dark" | "light";
}

export function Buttons({
  currentPaymentMethod,
  onLoad,
  onPaymentChange,
  paymentLinkHash,
  quoteDto,
  show,
  totalPrice,
  userPolicy,
  variant = "dark",
}: ButtonsProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "paymentForm",
  });

  const ProviderButton =
    getExperimentVariation("paymentProvider") === "stripe"
      ? StripeButton
      : CheckoutButton;
  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;

  const [loaded, setLoaded] = useState(() => ({
    paypal: show.includes("paypal") ? null : false,
    provider: show.includes("paymentProvider") ? null : false,
  }));

  useEffect(() => {
    if (loaded.paypal !== null && loaded.provider !== null) {
      onLoadRef.current?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded.paypal, loaded.provider]);

  if (loaded.paypal === false && loaded.provider === false) {
    return null;
  }

  return (
    <Container
      className={classNames({
        loaded: loaded.paypal !== null && loaded.provider !== null,
      })}
    >
      <Content>
        {show.includes("paymentProvider") && (
          <ProviderButton
            currentPaymentMethod={currentPaymentMethod}
            onLoad={(success) => {
              setLoaded((loaded) => ({ ...loaded, provider: success }));
            }}
            onPaymentChange={onPaymentChange}
            quoteDto={quoteDto}
            totalPrice={totalPrice}
            userPolicy={userPolicy}
            variant={variant}
          />
        )}
        {show.includes("paypal") && (
          <PayPal
            currentPaymentMethod={currentPaymentMethod}
            onLoad={(success) => {
              setLoaded((loaded) => ({ ...loaded, paypal: success }));
            }}
            onPaymentChange={onPaymentChange}
            paymentLinkHash={paymentLinkHash}
            quoteDto={quoteDto}
            totalPrice={totalPrice}
            userPolicy={userPolicy}
            variant={variant}
          />
        )}
      </Content>

      {show.includes("creditCard") && (
        <Divider>{t("buttons.divider.label")}</Divider>
      )}
    </Container>
  );
}
