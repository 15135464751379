import { type QuoteDto } from "types/quote-types";
import { type PaymentChangeListener, type PaymentMethod } from "..";
import { type Quote } from "store/quote/initial-state";
import { StripeCard } from "../stripe/card";
import { CheckoutCard } from "../checkout/card";
import { getExperimentVariation } from "utils/experiments";

export type CreditCardField =
  | "cvc"
  | "expiry"
  | "name"
  | "number"
  | "postalCode";

export type CreditCardPaymentMethod = "creditCard";

export interface CreditCardProps {
  active: boolean;
  currentPaymentMethod: PaymentMethod | null;
  extraFields?: boolean;
  formId: string;
  onLoad: () => void;
  onChange?: (complete: boolean, error: boolean) => void;
  onEscape?: () => void;
  onPaymentChange: PaymentChangeListener<CreditCardPaymentMethod>;
  quoteDto: QuoteDto;
  userPolicy?: Quote["userPolicy"];
}

export function CreditCard(props: CreditCardProps) {
  if (getExperimentVariation("paymentProvider") === "stripe")
    return <StripeCard {...props} />;

  return <CheckoutCard {...props} />;
}
