import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/input";
import { NameField } from "./name-field";
import { getExperimentVariation } from "utils/experiments";
import {
  type Birthdate,
  parseBirthdate,
  stringifyBirthdate,
} from "utils/birthdate";
import { type ValidationResult } from "utils/validation";
import { emailValidation } from "utils/validation/email";
import { notEmptyValidation } from "utils/validation/not-empty";
import { advisorEmailValidation } from "utils/validation/advisor-email";
import { useAdvisorEmailTracking } from "hooks/tracking/use-advisor-email";
import { useAdvisorEmail } from "hooks/use-advisor-email";
import { type Traveler } from "..";
import {
  BirthdateField,
  Container,
  Content,
  RemoveButton,
  RemoveIcon,
} from "./styles";

type FormTraveler = Omit<Traveler, "birthdate"> & { birthdate: Birthdate };
export type TravelerField = {
  [key in keyof Partial<FormTraveler>]: {
    value?: FormTraveler[key];
    validation?: ValidationResult;
  };
};

export interface FieldsProps {
  index: number;
  initialValue: Traveler | null;
  onChange: (value: Traveler | null, reason?: boolean) => void;
}

export function Fields({ index, initialValue, onChange }: FieldsProps) {
  const withEmail = getExperimentVariation("shortOb") === "inactive";
  const advisorEmail = useAdvisorEmail();
  const trackAdvisorEmail = useAdvisorEmailTracking();

  const { t } = useTranslation("screens", { keyPrefix: "travelers" });
  const { t: tValidation } = useTranslation("validation");
  const { t: tForm } = useTranslation("components", {
    keyPrefix: "personalDataForm",
  });

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const [birthdate, setBirthdate] = useState({
    validation: null as ValidationResult | null,
    value: parseBirthdate(initialValue?.birthdate ?? ""),
  });

  const [firstName, setFirstName] = useState({
    validation: null as ValidationResult | null,
    value: initialValue?.firstName ?? "",
  });

  const [lastName, setLastName] = useState({
    validation: null as ValidationResult | null,
    value: initialValue?.lastName ?? "",
  });

  const [email, setEmail] = useState({
    validation: null as ValidationResult | null,
    value: initialValue?.email ?? "",
  });

  useEffect(() => {
    onChangeRef.current(
      {
        email:
          withEmail && email.validation?.status !== "error" ? email.value : "",
        birthdate:
          birthdate.validation?.status === "valid"
            ? stringifyBirthdate(birthdate.value)
            : "",
        firstName:
          firstName.validation?.status === "valid" ? firstName.value : "",
        lastName: lastName.validation?.status === "valid" ? lastName.value : "",
      },
      birthdate.validation?.status === "valid" &&
        (!withEmail || email.validation?.status !== "error") &&
        firstName.validation?.status === "valid" &&
        lastName.validation?.status === "valid",
    );
  }, [birthdate, firstName, lastName, email, withEmail]);

  function changeField(field: TravelerField) {
    field.firstName && setFirstName({ ...firstName, ...field.firstName });

    field.lastName && setLastName({ ...lastName, ...field.lastName });

    field.birthdate && setBirthdate({ ...birthdate, ...field.birthdate });

    field.email && setEmail({ ...email, ...field.email });
  }

  return (
    <Container>
      <Content>
        <NameField
          index={index}
          firstName={firstName.value}
          lastName={lastName.value}
          onChange={changeField}
          {...(!!index && {
            label: {
              text: t("fieldsTitle", { count: index + 1, ordinal: true }),
              className: "name-field-label",
              htmlFor: `first_name_${index}`,
              startAdornment: (
                <RemoveButton
                  title={t("removeTravelerButton.label")}
                  onClick={() => {
                    onChangeRef.current(null, true);
                  }}
                >
                  <RemoveIcon />
                </RemoveButton>
              ),
            },
          })}
        />

        <BirthdateField
          name={`birthdate_${index}`}
          onChange={(value) => {
            changeField({ birthdate: { value } });
          }}
          onValidation={(validation) => {
            changeField({ birthdate: { validation } });
          }}
          required
          value={birthdate.value}
          variant="simple"
        />
      </Content>
      {withEmail && (
        <Content>
          <Input
            inputProps={{
              autoCapitalize: "none", // Safari only
              autoCorrect: "off", // Safari only
              spellCheck: "false",
            }}
            label={tForm("fields.email")}
            name={`email_${index}`}
            type="email"
            onChange={(event) => {
              changeField({ email: { value: event.target.value } });
            }}
            onValidation={(validation) => {
              if (
                advisorEmail &&
                (validation.state as { name: string })?.name ===
                  "advisorEmailValidation"
              ) {
                trackAdvisorEmail(advisorEmail);
              }

              changeField({ email: { validation } });
            }}
            required={index === 0}
            validate={[
              emailValidation,
              (value) =>
                index !== 0 ||
                notEmptyValidation(value) ||
                tValidation("email.invalid"),
              ...(advisorEmail ? [advisorEmailValidation(advisorEmail)] : []),
            ]}
            value={email.value}
          />
        </Content>
      )}
    </Container>
  );
}
