import { styled } from "@mui/material/styles";

export const Column = styled("div", {
  label: "column",
})`
  flex: 1;
  margin: ${({ theme }) => theme.spacing(2.5)};
`;

export const HeaderSpace = styled("div", {
  label: "header-space",
})`
  // 12 (header height) + 2.5 (row spacing)
  height: ${({ theme }) => theme.spacing(14.5)};

  .mobileSafari & {
    height: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Row = styled("div", {
  label: "row",
})`
  display: flex;
  margin: ${({ theme }) => theme.spacing(-2.5)};
`;

export const Table = styled("table", {
  label: "table",
})`
  border-collapse: collapse;
  width: 100%;
`;
