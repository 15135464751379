import React from "react";
import { createRoot } from "react-dom/client";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "styles/index.css";
import { Init } from "legacy/components/init";
import { router } from "router";
import { store } from "store";
import { init } from "utils/init";
import { theme } from "utils/theme";

void init().then(() => {
  const container = document.getElementById("root");
  const root = container && createRoot(container);
  root?.render(
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <Init />
            <CssBaseline />
            <RouterProvider router={router} />
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    </React.StrictMode>,
  );
});
