import { styled } from "@mui/material/styles";

export const Main = styled("main", {
  label: "main",
})`
  margin: 0 auto;

  max-width: ${({ theme }) => theme.spacing(180)};
  padding: ${({ theme }) => theme.spacing(2, 2, 0)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(1, 4, 0)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-right: ${({ theme }) => theme.spacing(10)};
    padding-left: ${({ theme }) => theme.spacing(10)};
  }
`;
