import { useEffect, useRef, useState } from "react";
import { type CreditCardField } from "../../credit-card";

export interface UseCardFormParams {
  fullName?: string;
  zipCode?: string;
  extraFields: boolean;
  onChange?: (complete: boolean, error: boolean) => void;
}

export function useCardForm({
  fullName,
  zipCode,
  extraFields,
  onChange,
}: UseCardFormParams) {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const [ready, setReady] = useState<Record<CreditCardField, boolean>>({
    cvc: false,
    expiry: false,
    number: false,
    name: !extraFields || !!fullName,
    postalCode: !extraFields || !!zipCode,
  });

  const [complete, setComplete] = useState<Record<CreditCardField, boolean>>({
    cvc: false,
    expiry: false,
    name: !extraFields || !!fullName,
    number: false,
    postalCode: !extraFields || !!zipCode,
  });

  const [error, setError] = useState<
    Record<CreditCardField, string | boolean | null>
  >({
    cvc: null,
    expiry: null,
    name: extraFields && !fullName,
    number: null,
    postalCode: extraFields && !zipCode,
  });

  useEffect(() => {
    if (!ready.number || !ready.expiry || !ready.cvc) {
      return;
    }

    // Handle card autofill event
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const field = mutation.target as HTMLDivElement;
        if (
          field.classList.contains("StripeElement--webkit-autofill") &&
          field.classList.contains("StripeElement--complete")
        ) {
          const name = field.id.split("-").at(-1);
          handleFieldChange(name as CreditCardField, {
            complete: true,
            error: false,
          });
        }
      });
    });

    const numberElement = document.getElementById("faye-stripe-field-number");
    numberElement &&
      observer.observe(numberElement, { attributeFilter: ["class"] });

    const expiryElement = document.getElementById("faye-stripe-field-expiry");
    expiryElement &&
      observer.observe(expiryElement, { attributeFilter: ["class"] });

    const cvcElement = document.getElementById("faye-stripe-field-cvc");
    cvcElement && observer.observe(cvcElement, { attributeFilter: ["class"] });

    return () => {
      observer.disconnect();
    };
  }, [ready.number, ready.expiry, ready.cvc]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChangeRef.current?.(
      complete.name &&
        complete.cvc &&
        complete.expiry &&
        complete.number &&
        complete.postalCode,
      !!error.name ||
        error.cvc !== null ||
        error.expiry !== null ||
        error.number !== null ||
        !!error.postalCode,
    );
  }, [complete, error]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleFieldChange(
    field: CreditCardField,
    value: { complete: boolean; error?: boolean | string },
  ) {
    if (value.complete) {
      if (!complete[field]) {
        // Using setComplete callback to sync between multiple calls to handleFieldChange
        setComplete((prev) => ({ ...prev, [field]: true }));
      }
    } else if (complete[field]) {
      setComplete((prev) => ({ ...prev, [field]: false }));
    }

    if (value.error) {
      setError((prev) => ({ ...prev, [field]: value.error }));
    } else if (error[field]) {
      setError((prev) => ({ ...prev, [field]: null }));
    }
  }

  return { ready, complete, error, setReady, handleFieldChange };
}
