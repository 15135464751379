import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "lead",
})`
  color: ${({ theme }) => theme.palette.color.gray[10]};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
  }

  strong {
    color: ${({ theme }) => theme.palette.color.primary.main};
    font-weight: 500;
  }
`;
