import { styled } from "@mui/material/styles";

export const Component = styled("div", {
  label: "popup-body",
})`
  margin-top: ${({ theme }) => theme.spacing(3)};
  overflow-y: scroll;
  padding: ${({ theme }) => theme.spacing(0, 2, 4)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: ${({ theme }) => theme.spacing(0, 10, 6, 8)};
  }
`;
