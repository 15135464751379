import {
  type AnyAction,
  type ThunkAction as BaseThunkAction,
  configureStore,
} from "@reduxjs/toolkit";
import quoteReducer from "./quote";
import { saveState } from "./session-persisted-state";

export type RootState = ReturnType<typeof store.getState>;

export type ThunkAction<ReturnType = void> = BaseThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export const store = configureStore({
  reducer: {
    quote: quoteReducer,
  },
});

store.subscribe(() => {
  saveState({
    quote: store.getState().quote,
  });
});
