import { styled } from "@mui/material/styles";

export const Component = styled("div", {
  label: "popup-section",
})`
  margin-top: ${({ theme }) => theme.spacing(3)};

  &:first-of-type {
    margin-top: 0;
  }

  & + & {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;
