import { styled } from "@mui/material/styles";
import LogoAsset from "assets/images/logo/main.svg";

export const Container = styled("div", {
  label: "offer-layout-print-header",
})`
  position: fixed;
  width: 100%;

  .mobileSafari & {
    position: static;
  }
`;

export const Logo = styled(LogoAsset, {
  label: "logo",
})`
  fill: ${({ theme }) => theme.palette.color.text.primary};
  width: ${({ theme }) => theme.spacing(8)};
  height: auto;
`;
