import { useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { ROOBERT_BASE_URL } from "settings";
import { getStripe } from "utils/payment";
import { ProviderContext } from "..";

export interface StripeProviderProps {
  children: React.ReactNode;
}

export function StripeProvider({ children }: StripeProviderProps) {
  const [isActivated, setIsActivated] = useState<boolean>();

  const stripe = useMemo(async () => {
    try {
      const stripe = await getStripe();
      setIsActivated(true);
      return stripe;
    } catch {
      setIsActivated(false);
      return null;
    }
  }, []);

  return (
    <ProviderContext.Provider value={{ isActivated }}>
      <Elements
        options={{
          fonts: [
            {
              display: "swap",
              family: "Roobert",
              src: [
                `url(${ROOBERT_BASE_URL}.woff2) format("woff2")`,
                `url(${ROOBERT_BASE_URL}.woff) format("woff")`,
              ].join(", "),
              weight: "400",
            },
          ],
        }}
        stripe={stripe}
      >
        {children}
      </Elements>
    </ProviderContext.Provider>
  );
}
