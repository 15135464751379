import { type Stripe } from "@stripe/stripe-js";
import { CDN as CHECKOUT_CDN } from "frames-react";
import {
  BRAINTREE_BASE_URL,
  CHECKOUT_PAYMENT_COMPONENT_URL,
  SCRIPT_TIMEOUT,
} from "settings";
import { getPayPalToken } from "utils/api/paypal";
import { loadScript } from "./load-script";
import { getExperimentVariation } from "./experiments";
import { ScriptLoadError } from "./api";

// prevent loading stripe internal scripts if not needed
const loadStripe =
  getExperimentVariation("paymentProvider") === "stripe"
    ? import("@stripe/stripe-js").then(({ loadStripe }) => loadStripe)
    : null;

const CACHE: {
  paypalCheckout?: ReturnType<
    NonNullable<typeof window.braintree>["paypalCheckout"]["create"]
  >;
  stripe?: Promise<Stripe | null>;
  checkout?: Promise<void>;
} = {};

export async function getPaypalCheckout() {
  if (CACHE.paypalCheckout === undefined) {
    CACHE.paypalCheckout = Promise.all([
      getPayPalToken(),
      loadScript(`${BRAINTREE_BASE_URL}/client.min.js`, {
        timeout: SCRIPT_TIMEOUT,
      }),
      loadScript(`${BRAINTREE_BASE_URL}/hosted-fields.min.js`, {
        timeout: SCRIPT_TIMEOUT,
      }),
      loadScript(`${BRAINTREE_BASE_URL}/paypal-checkout.min.js`, {
        timeout: SCRIPT_TIMEOUT,
      }),
    ]).then(async ([authorization, ...loaded]) => {
      if (loaded.some((value) => !value)) {
        throw new Error("Braintree client not available");
      }

      const client = await window.braintree?.client.create({
        authorization,
        paypal: { flow: "checkout" },
      });

      const paypalCheckout = await window.braintree?.paypalCheckout.create({
        client,
      });

      if (!paypalCheckout) {
        throw new ScriptLoadError("PayPal");
      }

      const sdk = await Promise.race([
        paypalCheckout.loadPayPalSDK({
          currency: "USD",
          intent: "capture",
        }),
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(false);
          }, SCRIPT_TIMEOUT);
        }),
      ]);

      if (sdk === false) {
        throw new ScriptLoadError("PayPal");
      }

      return paypalCheckout;
    });
  }

  return await CACHE.paypalCheckout;
}

export async function getStripe() {
  if (CACHE.stripe === undefined && import.meta.env.VITE_STRIPE_PUBLIC_KEY) {
    const loader = await loadStripe;

    if (loader) {
      CACHE.stripe = loader(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
    } else {
      throw new ScriptLoadError("Stripe");
    }
  }
  return (await CACHE.stripe) as Stripe | null;
}

export async function getCheckout() {
  if (CACHE.checkout === undefined) {
    CACHE.checkout = Promise.all([
      loadScript(CHECKOUT_CDN),
      loadScript(CHECKOUT_PAYMENT_COMPONENT_URL),
    ]).then(async (loaded) => {
      if (!loaded) {
        throw new ScriptLoadError("Checkout");
      }
    });
  }

  await CACHE.checkout;
}
