import { useTranslation } from "react-i18next";
import { selectPolicyUrl, selectReceiptUrl } from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import DownloadIconAsset from "assets/images/icons/download.svg";
import ViewIconAsset from "assets/images/icons/receipt.svg";
import { Link } from "./link";
import { Container } from "./styles";

export function Actions() {
  const policyUrl = useSelector(selectPolicyUrl);
  const receiptUrl = useSelector(selectReceiptUrl);

  const { t } = useTranslation("screens", {
    keyPrefix: "confirmation.actions",
  });

  return (
    <Container>
      <Link
        href={policyUrl}
        Icon={DownloadIconAsset}
        label={t("downloadPolicy")}
        shouldFetch
        track="policy"
      />

      <Link
        href={receiptUrl}
        Icon={ViewIconAsset}
        label={t("viewReceipt")}
        track="receipt"
      />
    </Container>
  );
}
