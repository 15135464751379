import { forwardRef } from "react";
import { type InputProps } from "components/input";
import { DATE_FORMAT } from "..";
import { Component } from "./styles";

export const Input = forwardRef<HTMLDivElement, InputProps>(function Input(
  { inputProps = {}, value: valueProp, ...props },
  ref,
) {
  const value = (valueProp as string).replace(/ /g, "");

  return (
    <Component
      inputProps={{
        ...inputProps,
        placeholder: DATE_FORMAT,
        readOnly: true,
      }}
      ref={ref}
      {...props}
      autoComplete="off"
      required
      value={value !== DATE_FORMAT ? value : ""}
    />
  );
});
