import { type CSSProperties, useContext } from "react";
import classNames from "classnames";
import { OnboardingLayoutContext } from "..";
import danielImage from "assets/images/daniel.jpg";
import tessImage from "assets/images/tess.jpg";
import profileLogoImage from "assets/images/logo/profile.svg?url";
import { getExperimentVariation } from "utils/experiments";
import { Container, Content, Logo, Avatar } from "./styles";

export interface HeaderProps {
  avatar: "person" | "faye" | null;
  topOffset: string;
}
export function Header({ avatar, topOffset }: HeaderProps) {
  const { headerRef } = useContext(OnboardingLayoutContext);
  const isFemaleAvatar = getExperimentVariation("femaleAvatar") === "active";

  const avatarImage =
    avatar === "person"
      ? isFemaleAvatar
        ? tessImage
        : danielImage
      : avatar === "faye"
        ? profileLogoImage
        : null;

  return (
    <Container
      ref={headerRef}
      style={{ "--top-offset": topOffset } as CSSProperties}
    >
      <Content>
        <Logo />
        {avatarImage && (
          <Avatar
            alt=""
            src={avatarImage}
            className={classNames({ large: isFemaleAvatar })}
          />
        )}
      </Content>
    </Container>
  );
}
