import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "../styles";
import { type AddressFields } from "../utils";
import { Autocomplete, type AutocompleteProps } from "./autocomplete";
import { ApartmentInput } from "./styles";

export interface SimpleAddressFieldsProps {
  initialValue: AddressFields | null;
  onChange: (address: AddressFields | null) => void;
  onFullAddressTriggerClick: () => void;
  showApartmentField?: boolean;
  stateRestriction?: AutocompleteProps["stateRestriction"];
}

export function SimpleAddressFields({
  initialValue,
  onChange,
  onFullAddressTriggerClick,
  showApartmentField = true,
  stateRestriction,
}: SimpleAddressFieldsProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "addressFields",
  });

  const [addressFields, setAddressFields] = useState(initialValue);
  const [apartment, setApartment] = useState(initialValue?.apartment ?? "");

  return (
    <Row>
      <Autocomplete
        name="address"
        onChange={(address) => {
          const addressFields: AddressFields | null = address && {
            googlePlacesId: address.googlePlacesId,
            label: address.label,
          };

          setAddressFields(addressFields);
          onChange(addressFields && { ...addressFields, apartment });
        }}
        onFullAddressTriggerClick={onFullAddressTriggerClick}
        placeholder={t("autocomplete")}
        stateRestriction={stateRestriction}
        value={addressFields}
      />

      {showApartmentField && (
        <ApartmentInput
          inputProps={{ "aria-label": t("apartment.ariaLabel") }}
          label={t("apartment.label")}
          name="apartment"
          onChange={(event) => {
            const apartment = event.target.value.slice(0, 7);
            setApartment(apartment);
            onChange(addressFields && { ...addressFields, apartment });
          }}
          value={apartment}
          variant="simple"
        />
      )}
    </Row>
  );
}
