import { styled } from "@mui/material/styles";
import { PopupSection } from "../../popup-section";
import { Label as LabelComponent } from "../label";

export const Container = styled(PopupSection, {
  label: "total",
})`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

export const Label = styled(LabelComponent, {
  label: "popup-label",
})`
  margin-right: ${({ theme }) => theme.spacing(1.5)};
  white-space: nowrap;
`;
