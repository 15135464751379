import { useEffect } from "react";
import { OfferLayoutPrint } from "layouts/offer/print";
import { useAddOnsStrings } from "../utils";
import { AddOns } from "./add-ons";
import { Header } from "./header";
import { PersonalDetails } from "./personal-details";
import { PolicyPrice } from "./policy-price";
import { Column, HeaderSpace, Row, Table } from "./styles";
import { TripDetails } from "./trip-details";
import { Travelers } from "./travelers";
import { WhatsCovered } from "./whats-covered";
import { Footnotes } from "./footnotes";

export interface PrintLayoutProps {
  isFayeApiRequest: boolean;
}

export function PrintLayout({ isFayeApiRequest }: PrintLayoutProps) {
  const { addOns, extraAddOns } = useAddOnsStrings({ filterDisabled: true });
  const addOnStrings = [...addOns, ...extraAddOns];

  useEffect(() => {
    // Indicate to the BE that the print version is ready, PDF can be requested
    window.F_PRINT_READY = true;
  }, []);

  // <table> element is used to properly align the fixed
  // header with the content on every printed page
  return (
    <OfferLayoutPrint>
      <Header showQrCode={!isFayeApiRequest} />

      <Table>
        <thead>
          <tr>
            <td>
              <HeaderSpace />
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <Row>
                <Column>
                  <TripDetails />
                </Column>

                <Column>{!isFayeApiRequest && <PolicyPrice />}</Column>
              </Row>

              {!isFayeApiRequest && (
                <Row>
                  <Column>
                    <Travelers />
                  </Column>

                  <Column>
                    <PersonalDetails />
                  </Column>
                </Row>
              )}

              <Row>
                <Column>
                  <WhatsCovered />
                </Column>
              </Row>

              {!!addOnStrings.length && (
                <Row>
                  <Column>
                    <AddOns addOnStrings={addOnStrings} />
                  </Column>
                </Row>
              )}

              <Row>
                <Column>
                  <Footnotes showPrintParagraph={!isFayeApiRequest} />
                </Column>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>
    </OfferLayoutPrint>
  );
}
