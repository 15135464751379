import { type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "hooks/use-breakpoint";
import {
  Header,
  TitleWrapper,
  Title,
  Subtitle,
  Component,
  StorksImage,
  ArrowIcon,
  BackButton,
  Logo,
} from "./styles";

export interface OfferLayoutContentProps {
  title?: string;
  lead?: string;
  children: ReactNode;
}

export function OfferLayoutContent({
  title,
  lead,
  children,
}: OfferLayoutContentProps) {
  const mdBreakpoint = useBreakpoint("md");
  const navigate = useNavigate();
  const { t } = useTranslation("screens", { keyPrefix: "offer" });

  return (
    <>
      {mdBreakpoint && (
        <>
          <Header>
            <Logo />
            <TitleWrapper>
              <Title>{title}</Title>
              <Subtitle>{lead}</Subtitle>
            </TitleWrapper>
          </Header>
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowIcon />
            {t("back")}
          </BackButton>
        </>
      )}
      <Component>
        <StorksImage />
        {children}
      </Component>
    </>
  );
}
