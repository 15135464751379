export const ENTRY_ROUTE = "/destination";

export const ADULT_AGE = 18;
export const MAX_TRAVELER_AGE = 105;
export const DRIVING_AGE = 16;

export const CHECKOUT_PAYMENT_COMPONENT_URL =
  "https://checkout-web-components.checkout.com/index.js";
export const BRAINTREE_BASE_URL =
  "https://js.braintreegateway.com/web/3.98.0/js";
export const DOWNLOAD_APP_ANDROID_URL = "https://assets.withfaye.com/android";
export const DOWNLOAD_APP_IOS_URL = "https://assets.withfaye.com/ios";
export const ROOBERT_BASE_URL =
  "https://assets.withfaye.com/fonts/roobert/RoobertVF";

export const SCRIPT_TIMEOUT = 5000;
export const LIMITED_TRIP_LENGTH_STATES = ["HI", "WA"];

export const MAX_LIMITED_TRIP_LENGTH_DURATION_DAYS = 90;
export const MAX_TRIP_DURATION_DAYS = 180;
export const MAX_TRIP_START_DAYS = 730;
export const MIN_ONGOING_TRIP_HOURS = 36;

export const MAX_TRIP_COST_VALUE = 150000;
export const MAX_TRIP_COST_VALUE_PER_TRAVELER = 40000;
export const MIN_TRIP_COST_VALUE = 1000;
export const TRIP_COST_VALUE_STEP = 500;

export const CANCEL_FOR_ANY_REASON_DAYS_LIMIT = 14;
export const CANCEL_FOR_ANY_REASON_DAYS_LIMIT_MISSOURI = 21;
export const CANCEL_FOR_ANY_REASON_DAYS_LIMIT_WASHINGTON = 21;

export const EXPERIMENTS = {
  creditCardExtraFields: {
    defaultVariation: "0",
    key: "f_exp_cc_extra_fields",
    variations: {
      0: "inactive",
      1: "active",
    },
  },

  shortOb: {
    defaultVariation: "0",
    key: "f_exp_short_ob",
    variations: {
      0: "inactive",
      1: "active",
    },
  },

  // This experiment is being used by the API and passed inside
  // Features header
  defaultTripCost: {
    defaultVariation: "0",
    key: "f_exp_default_trip_cost",
    variations: {
      0: "inactive",
      1: "active",
    },
  },

  paymentProvider: {
    defaultVariation: "stripe",
    key: "f_exp_payment_provider",
    variations: {
      stripe: "stripe",
      cko: "cko",
    },
  },

  mediaProof: {
    defaultVariation: "0",
    key: "f_exp_media_proof",
    variations: {
      0: "inactive",
      1: "active",
    },
  },

  femaleAvatar: {
    defaultVariation: "0",
    key: "f_exp_female_avatar",
    variations: {
      0: "inactive",
      1: "active",
    },
  },

  fullAddress: {
    defaultVariation: "0",
    key: "f_exp_full_address_form",
    variations: {
      0: "inactive",
      1: "active",
    },
  },
} as const;

export const UNSUPPORTED_COUNTRIES = [
  "AFGHANISTAN",
  "BELARUS",
  "CUBA",
  "HAITI",
  "IRAN",
  "IRAQ",
  "LIBYA",
  "MYANMAR",
  "NORTH KOREA",
  "RUSSIA",
  "SOUTH SUDAN",
  "SUDAN",
  "SYRIA",
  "UKRAINE",
  "YEMEN",
  "LEBANON",
];

// Unsupported countries are also added to this list because they can be
// presented by their name in other languages
export const UNSUPPORTED_PLACE_IDS = [
  "ChIJbQL_-LZu0TgReNqWvg1GtfM", // Afghanistan
  "ChIJgUit4oQl2kYREIzsgdGhAAA", // Belarus
  "ChIJtUx6DwdJzYgRGqQQkVL3jHk", // Cuba
  "ChIJEbvLf_PGto4R0_ZUTLI4FLU", // Haiti
  "ChIJ8R1rwS7s9z4RzvpRntOVsEA", // Iran
  "ChIJEUr1VD2CVxURAhYGorthpW0", // Iraq
  "ChIJDQHOjtmSqBMRKsL5x0FgB_o", // Libya
  "ChIJBylOcadSVjAREStiHOQOe7o", // Myanmar
  "ChIJN0NP5toCfjURHIYdPhyHCzo", // North Korea
  "ChIJ-yRniZpWPEURE_YRZvj9CRQ", // Russia
  "ChIJGTE-PbUwGRcRl7y2fmND8Ns", // South Sudan
  "ChIJlbFyEMQc2RURNythKkZwv9I", // Sudan
  "ChIJp8Y8QdzmGBURIPYqiPRGdYc", // Syria
  "ChIJjw5wVMHZ0UAREED2iIQGAQA", // Ukraine
  "ChIJX7w0fKzbAxYRrneufjcp8ZI", // Yemen
  "ChIJqViyBEGE_RQR5-hLGUux3P0", // Gaza Strip
  "ChIJZydUTgV__RQRkmMEE8mN-X8", // Gaza
  "ChIJg8c7n5PVHBURHhNjWfZsm0Q", // West Bank
  "ChIJ2b1miNLyHBURhvZm0QGgF-4", // Palestinian Territories
  "ChIJraoihAIXHxURcPkAbAk0fcw", // Lebanon
];

export const HIDDEN_PLACE_IDS = [
  "ChIJCzYy5IS16lQRQrfeQ5K5Oxw", // United States
  "ChIJI6GEtHvO24cRDSFYedmRxsI", // Cuba, MO, USA
  "ChIJnWmW2dovI4cRIZmkbZTZosc", // Cuba, NM, USA
  "ChIJI8Ftve2gGjkRGz4axYk7idU", // Iraq, Punjab, India
  "ChIJgy1vUd7fHBURU2QIX6wBGsc", // Iraq Burin
  "ChIJ6aPd4QyjP4gRqqaA2m2sFas", // Russia, OH, USA
  "ChIJ6aJnlDJHtIkRK-rzjlYiqCI", // Syria, VA, USA
  "ChIJSeNibReobogRcl2lZ0Rl32M", // Syria, IN, USA
  "ChIJ0dHzwN5tAocR_Ln13-ouRrM", // Sudan, TX, USA
  "ChIJH40GRtHKFI4R7qZeWNUlL-w", // Haiti, Maní, Casanare, Colombia
  "ChIJe5m05icQ1ocRuPymgFHQFUc", // Europa, MO, USA
  "ChIJu1SkbQS_PUcRwJ5XHBNMN8s", // Ukraina, Poland
  "ChIJKfiRSNkU0okRCfWyH5yrgtc", // Cuba, NY, USA
  "ChIJ5wg-n8At_YcROgz-NaH8SqM", // Cuba City, WI, USA
  "ChIJdZkqt-cx2YkRUK7qPnfrDG8", // Russia, NY, USA,
  "ChIJU5WUOH8EZIgRGhGQoJrhoUM", // Lebanon, TN, USA
  "ChIJJ66b4ecCxokRxe1IIMLjbnI", // Lebanon, PA, USA
  "ChIJh9cvqudOE4gR9k83nRY5ATI", // Lebanon, IN, USA
  "ChIJmTL9rwI4xYcRXblviMxnct0", // Lebanon, MO, USA
];
