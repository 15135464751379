export const COMPONENT_TYPE_AREA_LEVEL1 = "administrative_area_level_1";
export const COMPONENT_TYPE_COUNTRY = "country";
export const COMPONENT_TYPE_LOCALITY = "locality";

export const WEBSITE_URL = (
  import.meta.env.VITE_WEBSITE_URL +
  (window.sessionStorage.getItem("faye:home_path") ?? "")
).replace(/\/+/g, (match, index) => {
  // Preserve // from https?://
  return index <= 6 ? match : "/";
});
