import type {
  Attributes,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  Ref,
} from "react";

import { styled } from "@mui/material/styles";
import {
  dateRangeCalendarClasses,
  dayPickerClasses,
  pickersCalendarHeaderClasses,
} from "@mui/x-date-pickers-pro";
import { Day as DayComponent } from "components/date-picker/day";
import {
  InputGroup as InputGroupComponent,
  type InputGroupProps,
} from "components/input-group";

export const Day = styled(DayComponent, {
  label: "day",
})`
  font-size: 16px;
  height: ${({ theme }) => theme.spacing(5)};

  &.highlight,
  &.preview {
    color: ${({ theme }) => theme.palette.color.primary.main};
  }

  &.highlightStart:not(.previewEnd),
  &.previewStart:not(.highlightEnd) {
    &::before {
      left: 50%;
    }
  }

  &.highlightEnd:not(.previewStart),
  &.previewEnd:not(.highlightStart) {
    &::before {
      right: 50%;
    }
  }

  &.today:not(.Mui-disabled) {
    color: ${({ theme }) => theme.palette.color.text.primary};

    &::after {
      border: 1px solid ${({ theme }) => theme.palette.color.primary.main};
      content: "";
    }
  }

  // &&: increase specifity due to the previous :not(.Mui-disabled) usage
  &&.highlightEnd,
  &&.highlightStart,
  &&.hover,
  &&.previewEnd,
  &&.previewStart,
  &&.selected {
    color: ${({ theme }) => theme.palette.color.gray[100]};

    &::after {
      background: ${({ theme }) => theme.palette.color.primary.main};
      border: none;
    }
  }

  &::after {
    border-radius: 100%;
    width: ${({ theme }) => theme.spacing(5)};
    height: ${({ theme }) => theme.spacing(5)};
  }
`;

export const InputGroup = styled(
  InputGroupComponent as ForwardRefExoticComponent<
    PropsWithoutRef<InputGroupProps> &
      Attributes & { ref?: Ref<HTMLDivElement> | undefined }
  >,
  {
    label: "input-group",
  },
)`
  width: 100%;
`;

export const Wrapper = styled("div", {
  label: "wrapper",
})`
  display: flex;
  justify-content: center;

  .MuiPickersPopper-root {
    inset: initial !important;
    max-width: ${({ theme }) => theme.spacing(45)};
    padding: var(--input-height) 0 0;
    pointer-events: none;
    transform: none !important;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.appBar - 1};

    ${({ theme }) => theme.breakpoints.up("md")} {
      max-width: none;
      width: auto;
    }
  }

  .MuiPickersPopper-paper {
    border-radius: ${({ theme }) => +theme.shape.borderRadius * 2.5}px;
    box-shadow: ${({ theme }) => theme.customShadows.soft};
    color: ${({ theme }) => theme.palette.color.text.primary};
    margin: ${({ theme }) => theme.spacing(2, 0, 2, 0.75)};
    overflow: hidden;
    pointer-events: auto;

    .${dateRangeCalendarClasses.monthContainer} {
      border-color: ${({ theme }) => theme.palette.color.gray[70]};
      border-width: 1px;
      flex: 1;
    }

    .MuiPickersArrowSwitcher-root,
    .${dayPickerClasses.header}, .${dayPickerClasses.monthContainer} {
      ${({ theme }) => theme.breakpoints.up("md")} {
        padding-right: ${({ theme }) => theme.spacing(2.5)};
        padding-left: ${({ theme }) => theme.spacing(2.5)};
      }
    }

    .MuiPickersArrowSwitcher-root {
      .MuiPickersArrowSwitcher-button {
        color: ${({ theme }) => theme.palette.color.primary.main};
        padding: ${({ theme }) => theme.spacing(0.5)};

        &.Mui-disabled {
          color: ${({ theme }) => theme.palette.color.gray[60]};
        }
      }
    }

    .${pickersCalendarHeaderClasses.root} {
      margin: ${({ theme }) => theme.spacing(2, 0, 1.5)};
      padding: ${({ theme }) => theme.spacing(0, 0.5, 0, 1.75)};
    }

    .${pickersCalendarHeaderClasses.labelContainer},
      .MuiPickersArrowSwitcher-root
      .MuiTypography-root {
      color: ${({ theme }) => theme.palette.color.text.primary};
      cursor: default;
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
    }

    .MuiPickersArrowSwitcher-root .MuiTypography-root {
      word-spacing: ${({ theme }) => theme.spacing(4)};
    }

    .${dayPickerClasses.header} {
      margin: 0;

      .${dayPickerClasses.weekDayLabel} {
        color: ${({ theme }) => theme.palette.color.text.primary};
        flex: 1;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        width: auto;
        height: ${({ theme }) => theme.spacing(5)};
      }
    }

    .${dayPickerClasses.slideTransition} {
      ${({ theme }) => theme.breakpoints.up("md")} {
        width: 400px;
        // 5.5 === day height + top margin; 6 === max weeks; 2.5 bottom padding;
        height: ${({ theme }) => theme.spacing(5.5 * 6 + 2.5)};
      }
    }

    .${dayPickerClasses.weekContainer} {
      margin: ${({ theme }) => theme.spacing(0.5, 0, 0)};
    }
  }
`;
