import { menuClasses } from "@mui/material/Menu";
import MenuItem, { menuItemClasses } from "@mui/material/MenuItem";
import Select, { selectClasses } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import CloseIconAsset from "assets/images/icons/close-light.svg";
import DropDownIconAsset from "assets/images/icons/dropdown.svg";
import { PopupSection } from "../../popup-section";
import { Label as LabelComponent } from "../label";

export const Container = styled(PopupSection, {
  label: "selected-cars-container",
})`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

export const Label = styled(LabelComponent, {
  label: "label",
})`
  &.disabled {
    color: ${({ theme }) => theme.palette.color.gray[80]};
  }
`;

export const Dropdown = styled(Select, {
  label: "dropdown",
})`
  background-color: ${({ theme }) => theme.palette.color.gray[95]};
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: inherit;

  fieldset {
    border: none;
  }

  && .${selectClasses.outlined} {
    padding: ${({ theme }) => theme.spacing(0.75, 4, 0.75, 1.5)};
  }

  .${selectClasses.icon} {
    top: initial;
    right: ${({ theme }) => theme.spacing(1.25)};
  }

  .${menuClasses.paper} {
    box-shadow: none;
    filter: drop-shadow(${({ theme }) => theme.customShadows.soft});
  }

  .${menuClasses.list} {
    padding: 0;
  }
`;

export const DropdownItem = styled(MenuItem, {
  label: "dropdown-item",
})`
  font-size: inherit;

  &.${menuItemClasses.root} {
    color: ${({ theme }) => theme.palette.color.text.primary};
    min-height: unset;
    margin: auto;

    &:hover {
      background-color: ${({ theme }) => theme.palette.color.gray[80]};
    }
  }

  &.${menuItemClasses.selected} {
    background-color: unset;
    font-weight: 600;
  }
`;

export const CloseIcon = styled(CloseIconAsset, {
  label: "close-icon",
})`
  margin-right: ${({ theme }) => theme.spacing(2)};
  fill: ${({ theme }) => theme.palette.color.text.primary};
  width: ${({ theme }) => theme.spacing(1.25)};
  height: auto;
`;

export const DropdownIcon = styled(DropDownIconAsset, {
  label: "dropdown-icon",
})`
  fill: ${({ theme }) => theme.palette.color.primary.main};
  width: ${({ theme }) => theme.spacing(1)};
  height: auto;
`;
