import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { type StripeElementStyle } from "@stripe/stripe-js";
import { theme } from "utils/theme";

const STRIPE_ELEMENT_STYLES: StripeElementStyle = {
  base: {
    color: theme.palette.color.text.primary,
    fontFamily: theme.typography.fontFamily,

    "::placeholder": {
      color: theme.palette.color.gray[70],
    },
  },
  invalid: {
    color: theme.palette.color.text.primary,
  },
};

export const styles: {
  stripeElement: StripeElementStyle;
  stripeElementMd: StripeElementStyle;
} = {
  stripeElement: {
    ...STRIPE_ELEMENT_STYLES,
    base: {
      ...STRIPE_ELEMENT_STYLES.base,
      fontSize: "16px",
      lineHeight: "1.25",
    },
  },
  stripeElementMd: {
    ...STRIPE_ELEMENT_STYLES,
    base: {
      ...STRIPE_ELEMENT_STYLES.base,
      fontSize: "14px",
      lineHeight: "1.28625",
    },
  },
};

export const Input = styled("div", {
  label: "input",
})`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.color.gray[90]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  cursor: text;
  display: flex;
  padding: ${({ theme }) => theme.spacing(2, 1.5)};

  &.disabled {
    pointer-events: none;
  }

  &.error {
    border-color: ${({ theme }) => theme.palette.color.alert.main};
  }

  & > .StripeElement {
    flex: 1;
  }
`;

export const Label = styled(InputLabel, {
  label: "label",
})`
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  max-width: 100%;
  padding-left: ${({ theme }) => theme.spacing(1.5)};
  pointer-events: auto;
  position: static;
  transform: none;
`;
