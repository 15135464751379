import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "utils/formatters/number";
import { type PopupSectionProps } from "../../popup-section";
import { CarCareContext } from "..";
import { Container, Label } from "./styles";

export interface TotalProps extends PopupSectionProps {
  coveragePrice?: number | null;
  label: string;
  totalPrice: number;
}

export function Total({
  coveragePrice,
  label,
  totalPrice,
  ...props
}: TotalProps) {
  const { t } = useTranslation("screens", {
    keyPrefix: "offer.addOns.carCare.popup",
  });

  const { countDateRanges, cars } = useContext(CarCareContext);

  const daysCount = countDateRanges();

  if (!coveragePrice) {
    return null;
  }

  return (
    <Container {...props}>
      <div>
        <Label>{label}</Label>

        {t("total.calculation", {
          carCareDayPrice: numberFormatter(coveragePrice, {
            style: "currency",
          }),
          cars: t("total.cars", { count: cars }),
          dates: t("total.days", { count: daysCount }),
        })}
      </div>
      <span>
        {numberFormatter(totalPrice, {
          style: "currency",
        })}
      </span>
    </Container>
  );
}
