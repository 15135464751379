import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "utils/tracking";
import { mixpanelTrack } from "utils/tracking/mixpanel";

export function trackDownloadClick(os: "apple" | "other") {
  mixpanelTrack("pressed_app_download", {
    app_store: os === "apple" ? "Apple" : "Google",
  });
}

export function trackPolicyInfoAction(type: "policy" | "receipt") {
  if (type === "policy") {
    mixpanelTrack("summary_screen_pressed_policy_PDF");
  } else if (type === "receipt") {
    mixpanelTrack("summary_screen_pressed_receipt");
  }
}

export function useTracking() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView("Summary", pathname);
  }, [pathname]);
}
