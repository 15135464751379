import { LicenseInfo } from "@mui/x-license-pro";
import {
  browserTracingIntegration,
  init as sentryInit,
  replayIntegration,
} from "@sentry/react";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import {
  getAvailableExperiments,
  getExperimentVariation,
  setExperimentVariation,
} from "utils/experiments";
import { initOtt } from "utils/ott";
import {
  initMixpanel,
  refreshMixpanelAdvisor,
  refreshMixpanelBaseProperties,
  refreshMixpanelUserProperties,
  refreshMixpanelUtm,
} from "utils/tracking/mixpanel";
import { loadOfflineConversions } from "utils/tracking/offline-conversions";
import { getUtm, initUtm } from "utils/tracking/utm";

export async function init() {
  const promisesToWait: Array<Promise<unknown>> = [];

  initOtt("glinda");
  initUtm();

  loadOfflineConversions();

  const utms = getUtm();

  // Turn the experiment on for all travel advisor customers
  if (utms?.utm_medium === "bd-traveladvisors") {
    setExperimentVariation("shortOb", "active");
  }

  // Turn the experiment off for all comparisons sites
  if (utms?.utm_medium === "gd-comparisons") {
    setExperimentVariation("defaultTripCost", "inactive");
  }

  // MUI
  LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_PRO_KEY);

  // Sentry
  let buildEnv = "local";
  if (import.meta.env.VITE_BITBUCKET_BRANCH === "master") {
    buildEnv = "production";
  } else if ("VITE_BITBUCKET_BRANCH" in import.meta.env) {
    buildEnv = String(import.meta.env.VITE_BITBUCKET_BRANCH);
  }

  if (import.meta.env.MODE !== "development") {
    sentryInit({
      dsn:
        "https://cd5b4bc47b8d4eb7a96bcae543dffb92@o954259.ingest.sentry.io/" +
        "6125155",
      environment: buildEnv,
      integrations: [
        browserTracingIntegration(),
        replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      release: import.meta.env.VITE_APP_VERSION,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tunnel: "/stunnel",
      tracePropagationTargets: [
        "localhost",
        "empanda-dev-core.zennerplatform.com",
        "empanda-stg-core.zennerplatform.com",
        "api.faye-dev.com",
        "api.faye-stg.com",
        "api.withfaye.com",
      ],
    });
  }

  // Mixpanel
  initMixpanel(
    import.meta.env.VITE_MIXPANEL_KEY,
    import.meta.env.VITE_IS_DEVELOPMENT === "true",
    {
      ...getAvailableExperiments(),
      "Customer TZ":
        Intl.DateTimeFormat().resolvedOptions().timeZone ||
        new Date().toTimeString().split(" ")[1],
      test_variation:
        getExperimentVariation("defaultTripCost") === "active"
          ? "default_trip_cost"
          : getExperimentVariation("mediaProof") === "active"
            ? "media_proof"
            : getExperimentVariation("femaleAvatar") === "active"
              ? "OB_avatar_female"
              : null,
      version: import.meta.env.VITE_APP_VERSION,
    },
  );

  void refreshMixpanelAdvisor();
  refreshMixpanelBaseProperties();
  refreshMixpanelUserProperties();
  refreshMixpanelUtm();

  // i18next

  promisesToWait.push(
    import("strings/i18n/en.json").then(async ({ default: en }) => {
      return await i18next.use(initReactI18next).init({
        defaultNS: false,
        interpolation: {
          escapeValue: false,
        },
        lng: "en",
        resources: {
          en,
        },
      });
    }),
  );

  await Promise.all(promisesToWait);
}
