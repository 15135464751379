import { useState, useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ScreenHeader } from "components/screen-header";
import { selectEmail, selectResidency } from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import { WEBSITE_URL } from "legacy/utils/constants";
import { getStateByCode } from "utils/place/residency";
import { emailValidation } from "utils/validation/email";
import { type ValidationResult } from "utils/validation";
import { trackEmail } from "./tracking";
import { OnboardingLayoutContext } from "layouts/onboarding";
import {
  Form,
  FormInput,
  FormSubmitButton,
  HomepageLinkButton,
  MailIcon,
} from "./styles";

export function ComingSoonScreen() {
  const email = useSelector(selectEmail);
  const residency = useSelector(selectResidency);
  const { updateAvatar } = useContext(OnboardingLayoutContext);

  const { t } = useTranslation("screens", {
    keyPrefix: "comingSoon",
  });

  const [emailField, setEmailField] = useState({
    validation: null as ValidationResult | null,
    value: email,
  });

  useEffect(() => {
    updateAvatar("faye");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const stateCode = residency?.areaLevel1;
  const state = stateCode ? getStateByCode(stateCode) : null;

  return (
    <>
      <ScreenHeader
        lead={state?.value === "MO" ? t("missouri.lead") : t("lead")}
        title={
          state?.value === "MO"
            ? t("missouri.title")
            : t("title", { state: state?.label })
        }
      />

      <Form
        onSubmit={(event) => {
          event.preventDefault();

          if (emailField.validation?.status !== "error" && emailField.value) {
            trackEmail(emailField.value, state?.value);
            setEmailField((emailField) => ({
              ...emailField,
              value: "",
            }));
          }
        }}
      >
        <FormInput
          autoComplete="email"
          autoFocus={!isMobile}
          InputProps={{ startAdornment: <MailIcon /> }}
          label={t("form.inputLabel")}
          name="email"
          onChange={(event) => {
            setEmailField({
              ...emailField,
              value: event.target.value,
            });
          }}
          onValidation={(validation) => {
            setEmailField({
              ...emailField,
              validation,
            });
          }}
          validate={(value) => (value ? emailValidation(value) : true)}
          value={emailField.value}
        />

        <FormSubmitButton
          disabled={
            emailField.validation?.status === "error" || !emailField.value
          }
          type="submit"
        >
          {t("form.submitButton.label")}
        </FormSubmitButton>
      </Form>

      <HomepageLinkButton href={WEBSITE_URL} variant="outlined">
        {t("homepageLinkLabel")}
      </HomepageLinkButton>
    </>
  );
}
