import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import CheckLightIconAsset from "assets/images/icons/check-light.svg";

export const Container = styled("div", {
  label: "collapsable-section",
})`
  background: ${({ theme }) => theme.palette.color.gray[100]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.color.gray[80]};
  padding: ${({ theme }) => theme.spacing(3.5)};
  scroll-margin-top: var(--top-mobile-spacing);
  transition: ${({ theme }) => theme.transitions.create("border-color")};

  ${({ theme }) => theme.breakpoints.up("md")} {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
    border-width: 0;
    scroll-margin: ${({ theme }) =>
      theme.spacing(0, 0, "var(--bottom-desktop-spacing)")};
  }

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    padding: ${({ theme }) => theme.spacing(4)};
  }

  &:not(.mounted),
  &:not(.mounted) * {
    transition: none;
  }

  &:not(.active):not(.disabled) {
    cursor: pointer;
  }

  &.active:last-child {
    border-color: transparent;
  }

  & + & {
    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-top: ${({ theme }) => theme.spacing(2.25)};
    }
  }
`;

export const CheckLightIcon = styled(CheckLightIconAsset, {
  label: "check-light-icon",
})`
  fill: ${({ theme }) => theme.palette.color.gray[100]};
  opacity: 0;
  position: absolute;
  transition: ${({ theme }) =>
    theme.transitions.create("opacity", {
      duration: theme.transitions.duration.complex,
    })};
  width: auto;
  height: 0.75em;

  .completed & {
    opacity: 1;
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  opacity: 0;
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.create(["height", "opacity"])};

  .active & {
    opacity: 1;
  }
`;

export const Progress = styled(CircularProgress, {
  label: "progress",
})`
  color: inherit;
  opacity: 0;
  position: absolute;
  transition: ${({ theme }) =>
    theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.complex,
    })};
  width: 1.25em !important;
  height: 1.25em !important;

  .loading & {
    opacity: 1;
  }
`;

export const Title = styled("h1", {
  label: "title",
})`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  user-select: none;
  color: ${({ theme }) => theme.palette.color.primary.main};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 26px;
  }
`;

export const TitleBadge = styled("span", {
  label: "title-badge",
})`
  align-items: center;
  background: ${({ theme }) => theme.palette.color.gray[100]};
  border: 1px solid ${({ theme }) => theme.palette.color.primary.main};
  border-radius: 100%;
  color: ${({ theme }) => theme.palette.color.primary.main};
  counter-increment: collapsable-section;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
  transition: ${({ theme }) =>
    theme.transitions.create(["background", "color"], {
      duration: theme.transitions.duration.complex,
    })};
  width: ${({ theme }) => theme.spacing(2.75)};
  height: ${({ theme }) => theme.spacing(2.75)};

  .active &,
  .completed & {
    background: ${({ theme }) => theme.palette.color.primary.main};
    color: ${({ theme }) => theme.palette.color.gray[100]};
  }

  &::before {
    // counter-reset is set in ../../styles.tsx
    content: counter(collapsable-section);
    opacity: 1;
    transition: ${({ theme }) =>
      theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.complex,
      })};

    .completed &,
    .loading & {
      opacity: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    font-size: 15px;
    width: ${({ theme }) => theme.spacing(3.5)};
    height: ${({ theme }) => theme.spacing(3.5)};
  }
`;

export const TitleProgress = styled(CircularProgress, {
  label: "title-progress",
})`
  color: inherit;
  margin-left: ${({ theme }) => theme.spacing(1)};
  opacity: 0;
  transition: ${({ theme }) =>
    theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.complex,
    })};
  width: 1em !important;
  height: 1em !important;

  ${({ theme }) => theme.breakpoints.up("tablet")} {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  .loading & {
    opacity: 1;
  }
`;
