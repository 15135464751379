import { useEffect, useRef, useState, Fragment, createContext } from "react";
import { type Quote } from "store/quote/initial-state";
import { type QuoteDto } from "types/quote-types";
import { type QuotePaymentResponse } from "utils/api/quote";
import { Buttons, type ButtonsProps } from "./buttons";
import { StripeProvider } from "./stripe/provider";
import { CheckoutProvider } from "./checkout/provider";
import { CreditCard } from "./credit-card";
import { ErrorPopup } from "./error-popup";
import { TestUser } from "./test-user";
import { getExperimentVariation } from "utils/experiments";
import { Container } from "./styles";

export type PaymentChangeListener<PaymentMethod> = (
  method: PaymentMethod,
  data?:
    | { lead?: string; message: string; status: "error" }
    | { status: "request" }
    | { response: QuotePaymentResponse; status: "success" },
) => void;

export type PaymentMethod =
  | "applePay"
  | "creditCard"
  | "googlePay"
  | "payPal"
  | "test"
  | null;

export const ProviderContext = createContext<{
  isActivated?: boolean;
} | null>(null);

export interface PaymentFormProps {
  active: boolean;
  ButtonsProps: Pick<ButtonsProps, "onLoad" | "variant">;
  className?: string;
  creditCardExtraFields?: boolean;
  creditCardFormId: string;
  currentPaymentMethod: PaymentMethod;
  onLoad: () => void;
  onCreditCardChange: (complete: boolean, error: boolean) => void;
  onPaymentChange: PaymentChangeListener<PaymentMethod>;
  paymentLinkHash?: string;
  quoteDto: QuoteDto;
  show?: Array<"creditCard" | "paypal" | "paymentProvider">;
  testUser: boolean;
  totalPrice: number;
  userPolicy?: Quote["userPolicy"];
}

export function PaymentForm({
  active,
  ButtonsProps,
  className,
  creditCardExtraFields,
  creditCardFormId,
  currentPaymentMethod,
  onLoad,
  onCreditCardChange,
  onPaymentChange,
  paymentLinkHash,
  quoteDto,
  show = ["creditCard", "paypal", "paymentProvider"],
  testUser,
  totalPrice,
  userPolicy,
}: PaymentFormProps) {
  const wrapWithProvider =
    show.includes("creditCard") || show.includes("paymentProvider");
  const isStripe = getExperimentVariation("paymentProvider") === "stripe";
  const [error, setError] = useState<{
    lead?: string;
    message?: string;
  } | null>(null);
  const [loaded, setLoaded] = useState({
    buttons: false,
    card: !show.includes("creditCard"),
    testUser: true,
  });
  const Provider = wrapWithProvider
    ? isStripe
      ? StripeProvider
      : CheckoutProvider
    : Fragment;
  const onPaymentChangeRef = useRef(onPaymentChange);
  onPaymentChangeRef.current = onPaymentChange;

  useEffect(() => {
    if (Object.values(loaded).every(Boolean)) {
      onLoad();
    }
  }, Object.values(loaded)); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePaymentChange(
    method: Parameters<PaymentChangeListener<PaymentMethod>>[0],
    data: Parameters<PaymentChangeListener<PaymentMethod>>[1],
  ) {
    if (data?.status === "error") {
      const { lead, message } = data;
      setError({ lead, message });
    }

    onPaymentChangeRef.current(method, data);
  }

  return (
    <Provider>
      <Container className={className}>
        {testUser && (
          <TestUser
            active={active}
            onPaymentChange={handlePaymentChange}
            quoteDto={quoteDto}
            userPolicy={userPolicy}
          />
        )}

        <Buttons
          currentPaymentMethod={currentPaymentMethod}
          onPaymentChange={handlePaymentChange}
          paymentLinkHash={paymentLinkHash}
          quoteDto={quoteDto}
          show={show}
          totalPrice={totalPrice}
          onLoad={() => {
            setLoaded((prev) => ({ ...prev, buttons: true }));
          }}
          {...ButtonsProps}
        />

        {show.includes("creditCard") && (
          <CreditCard
            active={active}
            currentPaymentMethod={currentPaymentMethod}
            extraFields={creditCardExtraFields}
            formId={creditCardFormId}
            onLoad={() => {
              setLoaded((prev) => ({ ...prev, card: true }));
            }}
            onChange={onCreditCardChange}
            onPaymentChange={handlePaymentChange}
            quoteDto={quoteDto}
            userPolicy={userPolicy}
          />
        )}
      </Container>
      <ErrorPopup
        lead={error?.lead ?? null}
        error={error?.message ?? null}
        onClose={() => {
          setError(null);
        }}
      />
    </Provider>
  );
}
