import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "art",
})`
  align-items: center;
  display: flex;
  margin: ${({ theme }) => theme.spacing(2.5, "auto", 0)};
  max-width: min(60vw, ${({ theme }) => theme.spacing(33)});

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 1;
    margin: 0 0 0 auto;
    max-height: 60vh;
  }
`;

export const Phone = styled("img", {
  label: "phone",
})`
  max-width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 1;
`;
