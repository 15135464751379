import { createTheme } from "@mui/material/styles";

export const customShadows = {
  soft: "0px 16px 40px rgba(0, 0, 0, 0.12)",
  top: "0px -8px 20px rgba(0, 0, 0, 0.05)",
  medium: "0px 5px 8px rgba(0, 0, 0, 0.05)",
  hard: "0px 2px 2px rgba(0, 0, 0, 0.08)",
};

export const palette = {
  primary: { main: "#561DCF" },
  color: {
    alert: {
      light: "#FFE9E6",
      main: "#FF2609",
      text: "#991705",
    },
    warning: {
      light: "#FFF8D4",
      main: "#FFDC26",
      text: "#D89C00",
    },
    gray: {
      10: "#1A1919",
      50: "#83807C",
      60: "#9C9996",
      70: "#B5B2B0",
      80: "#CECCCA",
      90: "#E6E5E5",
      95: "#F3F2F2",
      100: "#FFFFFF",
    },
    primary: {
      40: "#3E2C87",
      main: "#561DCF",
      70: "#6C2FEE",
      90: "#C0B0FF",
      95: "#E2DFFF",
      100: "#F0ECFF",
      110: "#F7F5FF",
      background: "#F0ECFF",
    },
    secondary: {
      100: "#FBFAF9",
      95: "#F5F1EF",
      background: "#FBFAF9",
    },
    text: {
      primary: "#1A1919",
      inverse: "#FFFFFF",
    },
  },
};

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      tablet: 1024,
      lg: 1200,
      xl: 1536,
    },
  },

  customShadows,

  palette,

  typography: {
    fontFamily: ["Roobert", "sans-serif"].join(","),
  },

  shape: {
    borderRadius: 8,
  },
});
