import dayjs, { type Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form } from "components/form";
import { ScreenHeader } from "components/screen-header";
import { MIN_ONGOING_TRIP_HOURS } from "settings";
import { setDatesThunk } from "store/quote/thunks/steps";
import { checkIpIsInUs } from "utils/api/ip";
import { destinationNameForDisplay } from "utils/place/destinations";
import { useDispatch } from "hooks/use-dispatch";
import { useSelector } from "hooks/use-selector";
import { mixpanelTrack } from "utils/tracking/mixpanel";
import {
  selectDestinationsGoogleData,
  selectTripEndDate,
  selectTripStartDate,
} from "store/quote/selectors";
import { DatePicker } from "./date-picker";
import { trackSubmit, useTracking } from "./tracking";
import { Infobox } from "./styles";

export function DatesScreen() {
  const dispatch = useDispatch();
  const startDate = useSelector(selectTripStartDate);
  const endDate = useSelector(selectTripEndDate);
  const destinationsGoogleData = useSelector(selectDestinationsGoogleData);

  const navigate = useNavigate();

  const { t } = useTranslation("screens", {
    keyPrefix: "dates",
  });

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const [datePickerStatus, setDatePickerStatus] = useState({
    valid: null as boolean | null,
    value: [null, null] as [Dayjs | null, Dayjs | null],
  });

  const [ongoingTripMessage, setOngoingTripMessage] = useState<string | null>(
    null,
  );

  useEffect(() => {
    void (async () => {
      setOngoingTripMessage(null);

      if (!datePickerStatus.value[0]) {
        // Do nothing...
      } else if (await checkIpIsInUs()) {
        if (dayjs().isSame(datePickerStatus.value[0], "day")) {
          const message = t("talkingHead.ongoingTripInsideUs");
          mixpanelTrack("saw_insideUS_warning", { message });
          setOngoingTripMessage(message);
        }
      } else {
        const hoursFromTripStart = Math.floor(
          dayjs(datePickerStatus.value[0]).diff(dayjs(), "hours", true),
        );

        if (hoursFromTripStart < MIN_ONGOING_TRIP_HOURS) {
          const message = t("talkingHead.ongoingTripOutsideUs");
          mixpanelTrack("saw_outsideUS_warning", { message });
          setOngoingTripMessage(message);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerStatus.value[0]]);

  useTracking();

  return (
    <>
      <ScreenHeader
        title={t("title.main")}
        caption={
          destinationsGoogleData?.length !== 1
            ? t("caption.multipleDestinations")
            : t("caption.singleDestination", {
                destination: destinationNameForDisplay(
                  destinationsGoogleData[0],
                ),
              })
        }
      />

      <Form
        SubmitButtonProps={{
          ref: submitButtonRef,
          showArrow: true,
        }}
        name="dates"
        onSubmit={async () => {
          if (datePickerStatus.value[0] && datePickerStatus.value[1]) {
            await dispatch(
              setDatesThunk({
                dates: {
                  tripStartDate: dayjs(datePickerStatus.value[0]).format(),
                  tripEndDate: dayjs(datePickerStatus.value[1]).format(),
                },
              }),
            );

            trackSubmit(datePickerStatus.value[0], datePickerStatus.value[1]);

            navigate("/state");
          }
        }}
        submittable={!!datePickerStatus.valid}
      >
        <DatePicker
          initialValue={[
            startDate ? dayjs(startDate) : null,
            endDate ? dayjs(endDate) : null,
          ]}
          onAccept={() => {
            // Use setTimeout to focus submit button, as DatePicker focus the
            // date input field right after the calendar is closed
            window.setTimeout(() => {
              submitButtonRef.current?.focus();
            }, 0);
          }}
          onChange={(valid, value) => {
            setDatePickerStatus({ valid, value });
          }}
        />

        <Infobox show={!!ongoingTripMessage} type="warning">
          {ongoingTripMessage}
        </Infobox>
      </Form>
    </>
  );
}
