import { filledInputClasses } from "@mui/material/FilledInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { Input } from "components/input";

export const Component = styled(Input, {
  label: "input",
})`
  flex: 0 0 calc(50% - ${({ theme }) => theme.spacing(0.5)});

  .${inputLabelClasses.root},
    .${filledInputClasses.root},
    .${filledInputClasses.input} {
    cursor: pointer;
  }

  &.${filledInputClasses.focused} .faye__input__container {
    border-color: ${({ theme }) => theme.palette.color.primary.main};
  }
`;
