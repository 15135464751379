import { useEffect, useRef, useState } from "react";
import { type Residency } from "types/quote-types";
import { type GooglePlacesAddress } from "utils/google-places";
import { getStateByCode } from "utils/place/residency";
import { type ValidationResult } from "utils/validation";
import { type FullAddressFieldsProps } from "./full";

export interface AddressFields {
  apartment?: string;
  city?: string;
  googlePlacesId: string | null;
  label: string;
  postalCode?: string;
  stateCode?: string;
  streetAndNumber?: string;
}

export function getAddressFieldsFromResidency(
  residency?: Residency,
): AddressFields | null {
  if (
    !residency?.areaLevel1 ||
    !residency.label ||
    !residency.locality ||
    !residency.streetAndNumber ||
    !residency.zipCode
  ) {
    return null;
  }

  return {
    ...(residency.apartment && { apartment: residency.apartment }),
    city: residency.locality,
    label: residency.label,
    googlePlacesId: residency.googleId || null,
    postalCode: residency.zipCode,
    stateCode: residency.areaLevel1,
    streetAndNumber: residency.streetAndNumber,
  };
}

export function getAddressFieldsFromGooglePlacesAddress(
  googlePlacesAddress: GooglePlacesAddress,
  extraFields: Pick<AddressFields, "apartment" | "googlePlacesId" | "label">,
): AddressFields {
  return {
    ...extraFields,
    city: googlePlacesAddress.city,
    postalCode: googlePlacesAddress.postalCode,
    stateCode: googlePlacesAddress.stateCode,
    streetAndNumber: `${googlePlacesAddress.streetNumber ?? ""} ${
      googlePlacesAddress.street
    }`.trim(),
    apartment: googlePlacesAddress.apartment,
  };
}

export function getResidencyFromAddressFields(
  addressFields: AddressFields | null,
): Residency | null {
  if (
    !addressFields?.city ||
    !addressFields.label ||
    !addressFields.postalCode ||
    !addressFields.stateCode ||
    !addressFields.streetAndNumber
  ) {
    return null;
  }

  return {
    ...(addressFields.apartment && {
      apartment: addressFields.apartment,
    }),
    areaLevel1: addressFields.stateCode,
    country: "US",
    ...(addressFields.googlePlacesId && {
      googleId: addressFields.googlePlacesId,
    }),
    label: addressFields.label,
    locality: addressFields.city,
    streetAndNumber: addressFields.streetAndNumber,
    zipCode: addressFields.postalCode,
  };
}

export function useFullAddressFields({
  initialValue,
  onChange,
  stateRestriction,
}: FullAddressFieldsProps) {
  const previousFieldsRef = useRef<typeof fields>();

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const [fields, setFields] = useState(() => {
    const stateCode = stateRestriction ?? initialValue?.stateCode;
    const stateValue = stateCode ? getStateByCode(stateCode) : null;

    return {
      apartment: {
        validation: null as ValidationResult | null,
        value: initialValue?.apartment ?? "",
      },
      city: {
        validation: null as ValidationResult | null,
        value: initialValue?.city ?? "",
      },
      postalCode: {
        validation: null as ValidationResult | null,
        value: initialValue?.postalCode ?? "",
      },
      state: {
        valid: (!!stateValue || null) as boolean | null,
        value: stateValue,
      },
      streetAndNumber: {
        validation: null as ValidationResult | null,
        value: initialValue?.streetAndNumber ?? "",
      },
    };
  });

  useEffect(() => {
    if (
      fields.apartment.validation === null ||
      fields.city.validation === null ||
      fields.postalCode.validation === null ||
      fields.state.valid === null ||
      fields.streetAndNumber.validation === null
    ) {
      return;
    }

    if (
      fields.apartment.validation?.status !== "valid" ||
      fields.city.validation?.status !== "valid" ||
      fields.postalCode.validation?.status !== "valid" ||
      !fields.state.valid ||
      fields.streetAndNumber.validation?.status !== "valid"
    ) {
      onChangeRef.current(null);
    } else if (
      previousFieldsRef.current !== undefined &&
      (fields.apartment !== previousFieldsRef.current.apartment ||
        fields.city !== previousFieldsRef.current.city ||
        fields.postalCode !== previousFieldsRef.current.postalCode ||
        fields.state !== previousFieldsRef.current.state ||
        fields.streetAndNumber !== previousFieldsRef.current.streetAndNumber)
    ) {
      onChangeRef.current({
        ...(fields.apartment.value && {
          apartment: fields.apartment.value,
        }),
        city: fields.city.value,
        googlePlacesId: null,
        label: [
          fields.streetAndNumber.value,
          fields.city.value,
          fields.state.value?.value as string,
        ].join(", "),
        stateCode: fields.state.value?.value as string,
        streetAndNumber: fields.streetAndNumber.value,
        postalCode: fields.postalCode.value,
      });
    }

    previousFieldsRef.current = fields;
  }, [fields]);

  return {
    fields,
    handleChange: <Field extends keyof typeof fields>(
      field: Field,
      data: Partial<(typeof fields)[Field]>,
    ) => {
      setFields((fields) => ({
        ...fields,
        [field]: { ...fields[field], ...data },
      }));
    },
  };
}
