import { styled } from "@mui/material/styles";
import ArrowIconAsset from "assets/images/icons/arrow.svg";
import { PlainButton } from "components/button/plain";
import { ProgressButton } from "components/button/progress";
import { DoneLottie as DoneLottieComponent } from "components/lottie/done";

export const BackArrowIcon = styled(ArrowIconAsset, {
  label: "back-arrow-icon",
})`
  fill: currentColor;
  margin-right: ${({ theme }) => theme.spacing(1.75)};
  transform: rotate3d(0, 1, 0, 180deg);
  width: auto;
  height: ${({ theme }) => theme.spacing(2)};
`;

export const ArrowIcon = styled(ArrowIconAsset, {
  label: "arrow-icon",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

export const BackButton = styled(PlainButton, {
  label: "back-button",
})`
  align-items: center;
  display: none;
  font-size: 20px;
  font-weight: 500;
  margin-left: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
  }
`;

export const Container = styled("div", {
  label: "checkout-screen",
})`
  // It's the same as the CTA height
  --bottom-desktop-spacing: ${({ theme }) => theme.spacing(13)};

  background: ${({ theme }) => theme.palette.color.gray[100]};
  counter-reset: collapsable-section;
  margin: 0 auto;
  min-height: calc(100vh + var(--extra-mobile-height));
  padding: ${({ theme }) =>
    theme.spacing("var(--top-mobile-spacing)", 0, 10.75)};
  transition: ${({ theme }) => theme.transitions.create("min-height")};

  ${({ theme }) => theme.breakpoints.up("md")} {
    background: none;
    min-height: 100vh;
    padding: ${({ theme }) =>
      theme.spacing(6, "5vw", "var(--bottom-desktop-spacing)")};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: ${({ theme }) =>
      theme.spacing(6, 12.5, "var(--bottom-desktop-spacing)")};
  }
`;

export const CollapsableContent = styled("div", {
  label: "collapsable-content",
})`
  flex: 1;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-right: ${({ theme }) => theme.spacing(2.25)};
    width: auto;
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    margin-top: ${({ theme }) => theme.spacing(2.5)};
  }
`;

export const CtaButton = styled(ProgressButton, {
  label: "cta-button",
})`
  box-shadow: ${({ theme }) => theme.customShadows.medium};
  font-size: 18px;
  font-weight: 600;
  width: 100%;

  &.success {
    pointer-events: none;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-weight: 300;
    width: ${({ theme }) => theme.spacing(43)};
  }
`;

export const DoneLottie = styled(DoneLottieComponent, {
  label: "done-lottie",
})`
  &:not(.success) {
    display: none;
  }
`;

export const Title = styled("h1", {
  label: "title",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  display: none;
  font-size: 60px;
  font-weight: 400;
  line-height: 1.05;
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
    margin-left: ${({ theme }) => theme.spacing(2)};
  }
`;
