import { styled } from "@mui/material/styles";
import { Lottie } from "components/lottie";

export const Component = styled(Lottie, {
  label: "done-lottie",
})`
  margin: ${({ theme }) => theme.spacing(-3, -1, -3, -2)};
  width: ${({ theme }) => theme.spacing(6)};
  height: ${({ theme }) => theme.spacing(6)};

  & path {
    stroke: currentColor;
  }
`;
