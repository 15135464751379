import { useEffect, useRef, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import {
  type PaymentRequestPaymentMethodEvent,
  type PaymentRequest,
} from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import {
  type QuotePaymentResponse,
  sendQuotePayment,
  sendQuotePaymentIntent,
} from "utils/api/quote";
import { sentryCaptureError } from "utils/tracking/sentry";
import {
  Button,
  type ButtonPaymentMethod,
  type ProviderButtonProps,
} from "../../buttons/button";

export function StripeButton({
  currentPaymentMethod,
  onLoad,
  onPaymentChange,
  quoteDto,
  totalPrice,
  userPolicy,
  variant = "dark",
}: ProviderButtonProps) {
  const stripe = useStripe();

  const { t } = useTranslation("components", {
    keyPrefix: "paymentForm",
  });

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;

  const onPaymentChangeRef = useRef(onPaymentChange);
  onPaymentChangeRef.current = onPaymentChange;

  const quoteDtoRef = useRef(quoteDto);
  quoteDtoRef.current = quoteDto;

  const [availablePaymentMethod, setAvailablePaymentMethod] =
    useState<ButtonPaymentMethod>();

  const [paymentResponse, setPaymentResponse] =
    useState<QuotePaymentResponse>();

  function handleDoneLottieComplete() {
    if (paymentResponse) {
      onPaymentChangeRef.current(
        currentPaymentMethod as "applePay" | "googlePay",
        { response: paymentResponse, status: "success" },
      );
    }
  }

  useEffect(() => {
    if (!stripe || !!paymentRequest) {
      return;
    }

    setPaymentRequest(
      stripe.paymentRequest({
        country: "US",
        currency: "usd",
        requestPayerEmail: true,
        requestPayerName: true,
        total: {
          amount: Math.floor(totalPrice * 100), // Cents
          label: t("buttons.displayLabel"),
        },
      }),
    );
  }, [paymentRequest, stripe, t, totalPrice]);

  useEffect(() => {
    if (!paymentRequest) {
      return;
    }

    void paymentRequest.canMakePayment().then((value) => {
      let availablePaymentMethod: ButtonPaymentMethod | null = null;

      if (value?.applePay) {
        availablePaymentMethod = "applePay";
      } else if (value?.googlePay) {
        availablePaymentMethod = "googlePay";
      }

      if (availablePaymentMethod) {
        setAvailablePaymentMethod(availablePaymentMethod);
      }

      onLoadRef.current(!!availablePaymentMethod);
    });
  }, [paymentRequest]);

  useEffect(() => {
    if (!availablePaymentMethod || !paymentRequest) {
      return;
    }

    async function handlePaymentMethod(
      event: PaymentRequestPaymentMethodEvent,
    ) {
      const paymentMethod = event.walletName as "applePay" | "googlePay";
      onPaymentChangeRef.current(paymentMethod, { status: "request" });

      try {
        // event.complete("success") closes the browser payment dialog without
        // any errors. Errors will be handled inside handlePaymentMethod and
        // displayed in our custom error dialog.
        event.complete("success");

        setPaymentResponse(
          await sendQuotePayment(
            {
              paymentIntent: (await sendQuotePaymentIntent(quoteDtoRef.current))
                .id,
              paymentMethod: event.paymentMethod.id,
              paymentService: "stripe",
              purchasePaymentType:
                paymentMethod === "applePay"
                  ? "PAYMENT_TYPE_APPLE_PAY"
                  : "PAYMENT_TYPE_GOOGLE_PAY",
              ...(userPolicy?.id && { quoteId: userPolicy.id }),
            },
            quoteDtoRef.current,
          ),
        );
      } catch (error) {
        sentryCaptureError(error, {
          contexts: { payment: { paymentType: paymentMethod } },
          name: "Payment_failed",
        });

        onPaymentChangeRef.current(paymentMethod, {
          message:
            (error as Error).message ??
            (typeof error === "string" ? error : t("error.default")),
          status: "error",
        });
      }
    }

    paymentRequest.on("paymentmethod", handlePaymentMethod);

    return () => {
      paymentRequest.off("paymentmethod", handlePaymentMethod);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePaymentMethod]);

  if (availablePaymentMethod && paymentRequest) {
    if (availablePaymentMethod === "applePay") {
      return (
        <Button
          currentPaymentMethod={currentPaymentMethod}
          doneLabel={t("buttons.label.done")}
          onClick={paymentRequest.show}
          onDoneLottieComplete={handleDoneLottieComplete}
          paymentMethod="applePay"
          success={!!paymentResponse}
          variant={variant}
        />
      );
    } else if (availablePaymentMethod === "googlePay") {
      return (
        <Button
          currentPaymentMethod={currentPaymentMethod}
          doneLabel={t("buttons.label.done")}
          onClick={paymentRequest.show}
          onDoneLottieComplete={handleDoneLottieComplete}
          paymentMethod="googlePay"
          success={!!paymentResponse}
          variant={variant}
        />
      );
    }
  }

  return null;
}
