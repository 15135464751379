import classNames from "classnames";
import dayjs from "dayjs";
import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "hooks/use-breakpoint";
import { type PlaceDetails } from "types/quote-types";
import { dateRangeFormatter } from "utils/formatters/date-range";
import { type CurrencyParts, numberFormatter } from "utils/formatters/number";
import { googlePlacesGetDetails } from "utils/google-places";
import { LocalTimezoneDate } from "utils/date";
import {
  Container,
  Content,
  ContentColumns,
  Picture,
  PictureContainer,
  Price,
  ProfileLogo,
  Subtitle,
  Title,
} from "./styles";

export interface DestinationSectionProps {
  amendPayment?: boolean;
  amountParts: CurrencyParts | null;
  destinations?: PlaceDetails[];
  travelersCount: number;
  tripStartDate?: string;
  tripEndDate?: string;
  userPolicyUniqueUuid?: string;
}

export const DestinationSection = forwardRef<
  HTMLDivElement,
  DestinationSectionProps
>(function DestinationSection(
  {
    amendPayment = false,
    amountParts,
    destinations,
    travelersCount,
    tripEndDate,
    tripStartDate,
    userPolicyUniqueUuid,
  },
  ref,
) {
  const mdBreakpoint = useBreakpoint("md", { noSsr: true });

  const { t } = useTranslation("screens", {
    keyPrefix: "checkout.sections.destination",
  });

  const titleRef = useRef<HTMLDivElement | null>(null);
  const [pictureSrc, setPictureSrc] = useState<string>();

  const days = useMemo(
    () => dayjs(tripEndDate).diff(tripStartDate, "days"),
    [tripEndDate, tripStartDate],
  );

  const labels = useMemo(() => {
    return [...new Set(destinations?.map(({ label }) => label))];
  }, [destinations]);

  const [hiddenDestinations, setHiddenDestinations] = useState<number | null>(
    null,
  );

  useEffect(() => {
    function handleResize() {
      if (!destinations || !mdBreakpoint || pictureSrc) {
        return;
      }

      void googlePlacesGetDetails(destinations[0].googleId, {
        fields: ["photos"],
      }).then((value) => {
        const firstPhoto = value.photos?.[0]?.getUrl({ maxWidth: 960 });

        if (firstPhoto) {
          setPictureSrc(firstPhoto);
        }
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, [mdBreakpoint]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    function handleResize() {
      setHiddenDestinations(null);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    if (!titleRef.current) {
      return;
    }

    const { height } = titleRef.current.getBoundingClientRect();
    const { lineHeight } = window.getComputedStyle(titleRef.current);

    if (Math.round(height / parseFloat(lineHeight)) > 2) {
      setHiddenDestinations((count) => (count ?? 0) + 1);
    } else if (hiddenDestinations === null) {
      setHiddenDestinations(0);
    }
  }, [hiddenDestinations]);

  return (
    <Container className={classNames({ amendPayment })} ref={ref}>
      {!amendPayment && (
        <PictureContainer>
          {pictureSrc && <Picture alt="" src={pictureSrc} />}
        </PictureContainer>
      )}

      <ProfileLogo />

      <Content>
        <Subtitle>{t("days", { count: days || 1 })}</Subtitle>

        <ContentColumns>
          <div>
            <Title ref={titleRef}>
              {labels
                .slice(0, labels.length - (hiddenDestinations ?? 0))
                .join(" • ")}
              {!!hiddenDestinations && <span>+{hiddenDestinations}</span>}
            </Title>

            {amendPayment && (
              <Subtitle className="policyId">{userPolicyUniqueUuid}</Subtitle>
            )}

            <Subtitle>
              {!!tripStartDate &&
                !!tripEndDate &&
                dateRangeFormatter(
                  new LocalTimezoneDate(tripStartDate),
                  new LocalTimezoneDate(tripEndDate),
                )}
            </Subtitle>

            <Subtitle className="light">
              {t("travelers", { count: travelersCount })}
            </Subtitle>
          </div>

          {amountParts && (
            <Price>
              <span className="currency">{amountParts.currency}</span>
              {numberFormatter(parseInt(amountParts.integer))}
              <span className="fraction">
                {amountParts.decimalSeparator}
                {amountParts.fraction}
              </span>
              {amendPayment && <span className="info">{t("price.amend")}</span>}
            </Price>
          )}
        </ContentColumns>
      </Content>
    </Container>
  );
});
