import { autocompleteClasses } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";

export const Component = styled("div", {
  label: "paper",
})`
  background: ${({ theme }) => theme.palette.color.gray[100]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: ${({ theme }) => theme.customShadows.soft};
  margin: ${({ theme }) => theme.spacing(0.25, 0, 2)};
  overflow: hidden;

  .${autocompleteClasses.listbox} {
    padding: 0;
  }

  .${autocompleteClasses.noOptions},
  .${autocompleteClasses.listbox} .${autocompleteClasses.option},
  .${autocompleteClasses.listbox}
    .${autocompleteClasses.option}[aria-selected="true"] {
    background: ${({ theme }) => theme.palette.color.gray[100]};
    color: ${({ theme }) => theme.palette.color.text.primary};
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
    padding: ${({ theme }) => theme.spacing(1.5, 2.5)};

    &.${autocompleteClasses.focused} {
      background-color: ${({ theme }) => theme.palette.color.gray[95]};
    }
  }

  &.compact {
    .${autocompleteClasses.noOptions},
      .${autocompleteClasses.listbox}
      .${autocompleteClasses.option} {
      padding: ${({ theme }) => theme.spacing(1.5)};
    }
  }
`;
