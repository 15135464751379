import classNames from "classnames";
import {
  type ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container, SpeechBubble } from "./styles";

export interface TalkingHeadProps extends ComponentPropsWithoutRef<"div"> {
  show: boolean;
  type?: "info" | "warning";
}

export function TalkingHead({
  children,
  show,
  type = "info",
  ...props
}: TalkingHeadProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);

  const updateHeight = useCallback(() => {
    if (show && containerRef.current) {
      const containerClone = containerRef.current.cloneNode() as HTMLDivElement;

      containerClone.innerHTML = containerRef.current.innerHTML;
      containerClone.style.height = "auto";
      containerClone.style.visibility = "hidden";

      containerRef.current.insertAdjacentElement("afterend", containerClone);

      setHeight(containerClone.getBoundingClientRect().height);

      containerClone.remove();
    } else if (!show) {
      setHeight(0);
    }
  }, [show]);

  useEffect(() => {
    // Wait for the fonts to load, or the height value may break on initial load
    void document.fonts.ready.then(updateHeight);
  }, [updateHeight]);

  useEffect(() => {
    if (children) {
      updateHeight();
    }
  }, [children]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      {...props}
      className={classNames(props.className, { show })}
      ref={containerRef}
      style={{ height }}
    >
      <SpeechBubble role={type === "warning" ? "alert" : "status"}>
        {children}
      </SpeechBubble>
    </Container>
  );
}
