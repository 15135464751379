import { initializeApp } from "firebase/app";
import { type User, getAuth, signInWithCustomToken } from "firebase/auth";
import { validateOtt } from "./api/ott";

const CACHE: Record<string, Promise<User | null>> = {};

const ENDPOINTS = {
  glinda: `${import.meta.env.VITE_GLINDA_URL}/api/ott`,
};

export type OttTarget = keyof typeof ENDPOINTS;

export async function getOttUser(target: OttTarget): Promise<User | null> {
  if (!(target in CACHE)) {
    throw new Error(`${target} OTT was not initialized`);
  }

  return await CACHE[target];
}

export function initOtt(target: OttTarget) {
  if (!(target in CACHE)) {
    const url = ENDPOINTS[target];

    CACHE[target] = new Promise((resolve, reject) => {
      let timeout = window.setTimeout(() => {
        cleanup();
        resolve(null);
        timeout = 0;
      }, 10000);

      const iframe = document.createElement("iframe");
      iframe.src = url;
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";
      iframe.width = "0";
      iframe.height = "0";

      function cleanup() {
        window.removeEventListener("message", handleMessage);
        iframe.remove();
      }

      function handleMessage(event: MessageEvent) {
        if (
          event.source !== iframe?.contentWindow ||
          !url.startsWith(event.origin) ||
          event.data?.ott === undefined
        ) {
          return;
        } else if (event.data.ott === null) {
          resolve(null);
          return;
        } else if (!timeout) {
          reject(new Error("Expired OTT request"));
          return;
        }

        window.clearTimeout(timeout);
        cleanup();

        void validateOtt(event.data.ott)
          .then(async (token) => {
            initializeApp({
              apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
              appId: import.meta.env.VITE_FIREBASE_APP_ID,
              authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
              measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
              messagingSenderId: import.meta.env
                .VITE_FIREBASE_MESSAGING_SENDER_ID,
              projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
              storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
            });

            const { user } = await signInWithCustomToken(getAuth(), token);

            resolve(user);
          })
          .catch(reject);
      }

      window.addEventListener("message", handleMessage);
      document.body.append(iframe);
    });
  }
}
