import classNames from "classnames";
import { type ComponentPropsWithoutRef } from "react";
import { Component } from "./styles";

export interface PaperProps extends ComponentPropsWithoutRef<"div"> {
  compact?: boolean;
}

export function Paper({ className, compact, ...props }: PaperProps) {
  return (
    <Component className={classNames(className, { compact })} {...props} />
  );
}
