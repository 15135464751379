import { type ComponentPropsWithoutRef } from "react";
import { Trans } from "react-i18next";
import { Link } from "components/i18n/link";
import { selectDisclaimerPdfUrl } from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import { useDisclaimerParagraphs } from "../utils";
import { Container, Content } from "./styles";

export interface FootnotesProps extends ComponentPropsWithoutRef<"div"> {}

export function Footnotes(props: FootnotesProps) {
  const disclaimerPdfUrl = useSelector(selectDisclaimerPdfUrl);

  const paragraphs = useDisclaimerParagraphs();

  return (
    <Container {...props}>
      {paragraphs.map((paragraph) => (
        <Content key={paragraph}>
          <Trans
            components={{
              Link: <Link target="_blank" />,
              PdfLink: <Link to={disclaimerPdfUrl ?? "#"} target="_blank" />,
            }}
          >
            {paragraph}
          </Trans>
        </Content>
      ))}
    </Container>
  );
}
