import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";
import { ErrorMessage, ErrorStack, Title } from "./styles";

interface RouteError {
  data?: string;
  stack?: string;
  status?: number;
  statusText?: string;
}

export function ErrorScreen() {
  const error = useRouteError() as RouteError;
  const { t } = useTranslation("screens", {
    keyPrefix: "error",
  });

  const statusCodes = t("statusCodes", { returnObjects: true });
  const status = `${error.status ?? ""}` as keyof typeof statusCodes;

  return (
    <>
      <Title>{status || t("default.title")}</Title>

      {error.stack && import.meta.env.MODE === "development" && (
        <ErrorStack>{error?.stack}</ErrorStack>
      )}

      {!error.stack && (
        <ErrorMessage>
          {statusCodes[status]?.description ||
            (import.meta.env.MODE === "development"
              ? error.data
              : error.statusText)}
        </ErrorMessage>
      )}
    </>
  );
}
