import { type Dict } from "mixpanel-browser";
import { getAdvisorEmail } from "utils/api/advisor";
import { isClient } from "utils/is-client";
import { getAdvisorId, getUtm } from "./utm";

export interface BaseProperties {
  version: string | null;
  [key: string]: unknown;
}

export type UserProperties = Record<string, unknown>;

const CACHE = {
  baseProperties: null as BaseProperties | null,
  userProperties: null as UserProperties | null,
  initialized: false,
};

export function getMixpanel() {
  if (CACHE.initialized) {
    return window.mixpanel;
  }
}

export function initMixpanel(
  token: string,
  debug: boolean,
  baseProperties?: BaseProperties,
  userProperties?: UserProperties,
) {
  if (!isClient() || CACHE.initialized || !window?.mixpanel) {
    return;
  }

  window.mixpanel.init(token, {
    api_host: import.meta.env.VITE_MIXPANEL_API_HOST,
    debug,
    ignore_dnt: true,
    persistence: "localStorage",
    stop_utm_persistence: false,
  });

  CACHE.baseProperties = baseProperties ?? null;
  CACHE.userProperties = userProperties ?? null;
  CACHE.initialized = true;
}

export function mixpanelRegister(properties: Dict) {
  const mixpanel = getMixpanel();
  mixpanel?.register(properties);
}

export function mixpanelTrack(eventName: string, properties?: Dict) {
  const mixpanel = getMixpanel();

  mixpanel?.track(eventName, {
    "Local time": new Date().toLocaleString("en-US", { hour12: false }),
    ...properties,
  });
}

export function refreshMixpanelBaseProperties() {
  const mixpanel = getMixpanel();

  if (mixpanel && CACHE.baseProperties) {
    mixpanel.register(CACHE.baseProperties);
  }
}

export function refreshMixpanelUserProperties() {
  const mixpanel = getMixpanel();

  if (mixpanel && CACHE.userProperties) {
    mixpanel.people.set(CACHE.userProperties);
  }
}

export function refreshMixpanelUtm() {
  const mixpanel = getMixpanel();
  const utm = getUtm();

  if (mixpanel && utm) {
    mixpanel.people.set(utm);
    mixpanel.register(utm);
  }
}

export function updateMixpanelUser(properties?: Dict) {
  const mixpanel = getMixpanel();

  if (mixpanel && properties) {
    mixpanel.people.set(properties);
  }
}

// The methods below are exclusive to onboarding (not a part of a common module)

export async function identifyMixpanelUser(
  email: string,
  previousEmail?: string,
) {
  const mixpanel = getMixpanel();

  if (!mixpanel) {
    return;
  }

  const advisorId = getAdvisorId();
  const advisorEmail = advisorId && (await getAdvisorEmail(advisorId));

  const emailIsDifferentFromExistingAdvisorEmail =
    !!advisorEmail && previousEmail === advisorEmail && email !== advisorEmail;

  if (!!previousEmail || emailIsDifferentFromExistingAdvisorEmail) {
    // Generates a new distinct_id to avoid errAnonDistinctIdNonGUIDForm error
    mixpanel.reset();
    // re-register super properties, because a side effect of
    // "mixpanel.reset();" is clearing super properties
    refreshMixpanelBaseProperties();
    refreshMixpanelUtm();
    void refreshMixpanelAdvisor();
  }

  if (!previousEmail || emailIsDifferentFromExistingAdvisorEmail) {
    // Calling identify here creates a new profile, or associate the request to
    // an existing profile which used this email before
    mixpanel.identify(email.toLocaleLowerCase());
  } else {
    // previousEmail is already identified/aliased on Mixpanel, add the new
    // email to the identity cluster associating it to the previous value
    mixpanel.alias(
      email.toLocaleLowerCase(),
      previousEmail.toLocaleLowerCase(),
    );
  }
}

export async function refreshMixpanelAdvisor() {
  const advisorId = getAdvisorId();
  const mixpanel = getMixpanel();
  const utm = getUtm();

  if (advisorId && mixpanel && utm) {
    mixpanel.people.set({
      advisor_company: utm.utm_source,
      advisor_email: await getAdvisorEmail(advisorId),
      advisor_id: advisorId,
    });
  }
}
