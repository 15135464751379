import { useState, type ComponentPropsWithRef } from "react";
import { useTranslation } from "react-i18next";
import { selectTravelers } from "store/quote/selectors";
import { useOttUser } from "hooks/use-ott-user";
import { useSelector } from "hooks/use-selector";
import { Title } from "../title";
import { Input } from "./input";
import { Container, Description } from "./styles";

export function TripCostSection(props: ComponentPropsWithRef<"div">) {
  const travelers = useSelector(selectTravelers);
  const [showDescription, setShowDescription] = useState(true);

  const { t } = useTranslation("screens", { keyPrefix: "offer.tripCost" });

  const ottUser = useOttUser("glinda");

  return (
    <Container {...props}>
      <Title>
        {t("title", { context: ottUser && "advisor", count: travelers.length })}
      </Title>
      <Input
        onError={(error) => {
          setShowDescription(!error);
        }}
      />
      {showDescription && (
        <Description>
          {t("subtitle", { context: ottUser && "advisor" })}
        </Description>
      )}
    </Container>
  );
}
