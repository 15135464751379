import { styled } from "@mui/material/styles";
import { PopupHeader } from "components/popup/header";

export const Content = styled("p", {
  label: "content",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-weight: 300;
  font-size: 14px;
  line-height: 1.29;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  max-width: ${({ theme }) => theme.spacing(42)};
`;

export const Header = styled(PopupHeader, {
  label: "header",
})`
  max-width: ${({ theme }) => theme.spacing(42)};

  && {
    padding-left: 0;
    text-align: left;
  }
`;
