import { type Dict } from "mixpanel-browser";
import { store } from "store";
import { calculateAge } from "utils/birthdate";
import { countNonAdultTravelers } from "utils/traveler";
import { gtmTrack } from "./gtm";
import { mixpanelTrack } from "./mixpanel";

export function trackPageView(
  pageName: string,
  pathname: string,
  mixpanelProperties?: Dict,
) {
  const { quote } = store.getState();

  gtmTrack("page_view", {
    page_location: pathname,
    page_title: pageName,
  });

  mixpanelTrack("page_view", {
    $first_name: quote.name?.firstName,
    $last_name: quote.name?.lastName,
    main_traveler_age:
      quote.travelers?.[0] && calculateAge(quote.travelers[0].dateOfBirth),
    name_of_page: pageName,
    non_adult_travelers_count: countNonAdultTravelers(quote.travelers),
    total_travelers_count: quote.travelers?.length,
    ...mixpanelProperties,
  });
}
