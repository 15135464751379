import { type TextFieldProps } from "@mui/material/TextField";
import classNames from "classnames";
import { forwardRef } from "react";
import {
  Component,
  Container,
  EndAdornment,
  StartAdornment,
  Wrapper,
} from "./styles";

export interface BaseInputProps
  extends Omit<TextFieldProps, "size" | "variant"> {
  variant?: "default" | "simple";
  warning?: boolean;
}

export const BaseInput = forwardRef<HTMLDivElement, BaseInputProps>(
  function BaseInput(
    {
      className,
      error,
      helperText,
      InputProps: { endAdornment, startAdornment, ...InputProps } = {},
      label,
      variant = "default",
      warning,
      ...props
    },
    ref,
  ) {
    return (
      <Wrapper
        className={classNames(className, "faye__input", {
          "faye__input--error": !!error,
          "faye__input--warning": !!warning,
          "faye__input--filled": !!props.value,
        })}
      >
        <Container className="faye__input__container">
          {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}

          <Component
            error={error}
            InputProps={{
              ...InputProps,
              disableUnderline: true,
            }}
            {...(variant === "default"
              ? { label: helperText || label }
              : typeof label === "string"
                ? { placeholder: label }
                : {})}
            ref={ref}
            {...props}
            size="small"
            variant={variant === "default" ? "filled" : "standard"}
          />

          {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
        </Container>
      </Wrapper>
    );
  },
);
