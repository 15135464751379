import { type ButtonBaseProps } from "@mui/material/ButtonBase";
import { type ElementType, forwardRef } from "react";
import { Component } from "./styles";

export interface BaseButtonProps extends ButtonBaseProps {
  component?: ElementType;
  href?: string;
}

export const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  function BaseButton(props, ref) {
    if (
      typeof props.href !== "undefined" &&
      typeof props.component === "undefined"
    ) {
      props = { ...props, component: "a" };
    }

    return <Component ref={ref} {...props} />;
  },
);
