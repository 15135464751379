import { type LottieProps } from "..";
import url from "./lottie.json?url";
import { Component } from "./styles";

export function DoneLottie({
  options,
  play = true,
  ...props
}: Omit<LottieProps, "options" | "url"> & {
  options?: Omit<LottieProps["options"], "loop">;
}) {
  return (
    <Component
      options={{ ...options, loop: false }}
      play={play}
      {...props}
      url={url}
    />
  );
}
