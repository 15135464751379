import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "utils/tracking";

export function useTracking() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView("OB Loader", pathname);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
