import {
  COMPONENT_TYPE_AREA_LEVEL1,
  COMPONENT_TYPE_COUNTRY,
  COMPONENT_TYPE_LOCALITY,
} from "legacy/utils/constants";
import {
  type DestinationGoogleData,
  type GooglePlace,
  type PlaceDetails,
} from "types/quote-types";
import { loadGooglePlacesApi } from "utils/google-places";
import { type Place, getPlaceDetails } from ".";

export function destinationNameForDisplay(destination: DestinationGoogleData) {
  if (destination.terms?.length) {
    return destination.terms[0].value;
  } else if (destination?.address_components?.length) {
    for (const component of destination.address_components) {
      if (
        component.types.includes(COMPONENT_TYPE_LOCALITY) ||
        component.types.includes(COMPONENT_TYPE_AREA_LEVEL1) ||
        component.types.includes(COMPONENT_TYPE_COUNTRY)
      ) {
        return component.long_name;
      }
    }
  }

  return "";
}

export async function getDestinationPlacesGoogleData(places: GooglePlace[]) {
  const result: DestinationGoogleData[] = [];

  await loadGooglePlacesApi();

  for (const place of places) {
    result.push({
      ...(await getPlaceDetails(place)),
      place_id: place.googleId,
      terms: place.terms,
    });
  }

  return result;
}

export function getDestinationsPlaceDetails(
  placeResults: google.maps.places.PlaceResult[],
  destinations: GooglePlace[],
) {
  return placeResults.map((placeResult, index) => {
    const addressComponents = placeResult.address_components?.reduce(
      (result, component) => {
        const componentTypes = component.types;
        switch (true) {
          case componentTypes.includes(COMPONENT_TYPE_COUNTRY):
            result.country = component.short_name;
            break;
          case componentTypes.includes(COMPONENT_TYPE_LOCALITY):
            result.locality = component.long_name;
            break;
          case componentTypes.includes(COMPONENT_TYPE_AREA_LEVEL1):
            result.areaLevel1 = component.short_name;
            break;
        }

        return result;
      },
      {
        country: "",
        areaLevel1: "",
        locality: "",
      },
    );

    return {
      ...addressComponents,
      googleId: destinations[index].googleId,
      label: destinations[index].label || (placeResult.formatted_address ?? ""),
    } as PlaceDetails;
  });
}

export function isDomesticTrip(places: Place[]) {
  return places.every(({ terms }) => {
    return terms && terms[terms.length - 1]?.value.toLowerCase() === "usa";
  });
}

export function isIsraelTrip(places: Array<Place | PlaceDetails>) {
  for (const place of places) {
    if ("country" in place) {
      if (place.country === "IL") {
        return true;
      }
    } else if (
      place.placeId === "ChIJi8mnMiRJABURuiw1EyBCa2o" ||
      place.terms?.[place.terms.length - 1]?.value.toLowerCase() === "israel"
    ) {
      return true;
    }
  }

  return false;
}
