import { Popper } from "@mui/base/Popper";
import { styled } from "@mui/material/styles";

export const Component = styled(Popper, {
  label: "popper",
})`
  && {
    z-index: ${({ theme }) => theme.zIndex.modal};
  }
`;
