export function getCacheKey(this: unknown, ...args: unknown[]) {
  const cacheKeyParts: string[] = [];

  for (const arg of args) {
    if (Array.isArray(arg)) {
      cacheKeyParts.push(getCacheKey.apply(";", arg));
    } else if (typeof arg === "object" && arg !== null) {
      cacheKeyParts.push(
        Object.entries(arg)
          .sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
          .toString(),
      );
    } else {
      cacheKeyParts.push(String(arg));
    }
  }

  return cacheKeyParts.join(typeof this === "string" ? this : ":");
}
