import FormControl from "@mui/material/FormControl";
import {
  CardNumberElement,
  type CardNumberElementProps,
  type CardExpiryElementProps,
  type CardCvcElementProps,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  type StripeCardCvcElement,
  type StripeCardExpiryElement,
  type StripeCardNumberElement,
} from "@stripe/stripe-js";
import classNames from "classnames";
import { type MutableRefObject, type ReactNode } from "react";
import { HelperText } from "components/helper-text";
import { useBreakpoint } from "hooks/use-breakpoint";
import { Input, Label, styles } from "./styles";

type ElementRef<Type extends "cvc" | "expiry" | "number"> = Type extends "cvc"
  ? StripeCardCvcElement
  : Type extends "expiry"
    ? StripeCardExpiryElement
    : Type extends "number"
      ? StripeCardNumberElement
      : never;

export type StripeFieldProps<Type extends "cvc" | "expiry" | "number"> = {
  disabled?: boolean;
  elementRef: MutableRefObject<ElementRef<Type> | null>;
  error?: boolean;
  helperText?: string;
  label: ReactNode;
  onFocus?: () => void;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  type: Type;
} & (Type extends "cvc"
  ? Omit<CardCvcElementProps, "onFocus">
  : Type extends "expiry"
    ? Omit<CardExpiryElementProps, "onFocus">
    : Type extends "number"
      ? Omit<CardNumberElementProps, "onFocus">
      : never);

export function StripeField<Type extends "cvc" | "expiry" | "number">({
  className,
  disabled,
  error,
  helperText,
  id: idProp,
  elementRef,
  label,
  onFocus,
  onReady,
  options,
  placeholder,
  startAdornment,
  endAdornment,
  type,
  ...props
}: StripeFieldProps<Type>) {
  const mdBreakpoint = useBreakpoint("md");

  const id = idProp ?? `faye-stripe-field-${type}`;

  const baseProps = {
    ...props,
    id,
    onFocus: () => {
      if (disabled) {
        elementRef.current?.blur();
      } else if (onFocus) {
        onFocus();
      }
    },
    options: {
      ...options,
      placeholder,
      style: mdBreakpoint ? styles.stripeElementMd : styles.stripeElement,
    },
  };

  return (
    <FormControl
      className={className}
      error={error}
      onClick={() => {
        if (elementRef.current) {
          elementRef.current.focus();
        }
      }}
    >
      <Label disableAnimation error={error} htmlFor={id}>
        {label}
      </Label>

      <Input className={classNames(disabled && "disabled", error && "error")}>
        {startAdornment}

        {type === "cvc" && (
          <CardCvcElement
            {...(baseProps as CardCvcElementProps)}
            onReady={(element: StripeCardCvcElement) => {
              elementRef.current = element as ElementRef<Type>;

              if (onReady) {
                const callback =
                  onReady as Required<CardCvcElementProps>["onReady"];

                callback(element);
              }
            }}
          />
        )}

        {type === "expiry" && (
          <CardExpiryElement
            {...(baseProps as CardExpiryElementProps)}
            onReady={(element: StripeCardExpiryElement) => {
              elementRef.current = element as ElementRef<Type>;

              if (onReady) {
                const callback =
                  onReady as Required<CardExpiryElementProps>["onReady"];

                callback(element);
              }
            }}
          />
        )}

        {type === "number" && (
          <CardNumberElement
            {...(baseProps as CardNumberElementProps)}
            onReady={(element: StripeCardNumberElement) => {
              elementRef.current = element as ElementRef<Type>;

              if (onReady) {
                const callback =
                  onReady as Required<CardNumberElementProps>["onReady"];

                callback(element);
              }
            }}
          />
        )}

        {endAdornment}
      </Input>

      {helperText && <HelperText error={error}>{helperText}</HelperText>}
    </FormControl>
  );
}
