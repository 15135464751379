import { styled, keyframes } from "@mui/material/styles";
import { Lottie as LottieComponent } from "components/lottie";
import { theme } from "utils/theme";

const walk = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(min(
      min(${theme.breakpoints.values.sm}px, 100vh), 100vw)
    );
  }
`;

export const Container = styled("div", { label: "loader-container" })`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${({ theme }) => theme.spacing(2)});

  ${({ theme }) => theme.breakpoints.up("md")} {
    min-height: calc(100vh - ${({ theme }) => theme.spacing(7)});
    padding-top: ${({ theme }) => theme.spacing(10)};
  }
`;

export const LottieContainer = styled("div", {
  label: "lottie-container",
})`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(0, -2)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: 0;
  }
`;

export const LottieTrack = styled("div", {
  label: "lottie-track",
})`
  margin: ${({ theme }) => theme.spacing("auto", "auto", "10vh")};
  max-width: min(${({ theme }) => theme.breakpoints.values.sm}px, 100vh);
  overflow: hidden;
  position: relative;
  width: 100%;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    z-index: 1;
  }

  &:before {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    left: 0;
  }

  &:after {
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    right: 0;
  }
`;

export const Lottie = styled(LottieComponent, {
  label: "lottie",
})`
  pointer-events: none;
  transform: translateX(-100%);
  width: fit-content;
  height: 30vh;

  &.lottieIsReady {
    animation: 4s linear infinite ${walk};
  }
`;
