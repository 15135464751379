import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "components/popup";
import { selectResidencyState } from "store/quote/selectors";
import { numberFormatter } from "utils/formatters/number";
import { useSelector } from "hooks/use-selector";
import { useCoverage } from "../../../utils";
import { Item, type ItemProps } from "../item";
import { PopupBody } from "../popup-body";
import { PopupButton } from "../popup-button";
import { PopupHeader } from "../popup-header";
import { PopupSection } from "../popup-section";

export function ExtremeSports({ coverageType, price, ...props }: ItemProps) {
  const residencyState = useSelector(selectResidencyState);

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.addOns.extremeSports",
  });

  const { coverage, enable: enableCoverage } = useCoverage(coverageType);
  const [showPopup, setShowPopup] = useState(false);

  const topics: string[] = t("popup.topics", {
    returnObjects: true,
  });

  function closePopup() {
    setShowPopup(false);
  }

  function openPopup() {
    setShowPopup(true);
  }

  return (
    <>
      <Item
        {...props}
        coverageType={coverageType}
        price={price}
        {...(residencyState !== "NY"
          ? {
              TriggerProps: { onClick: openPopup },
              triggerLabel: t("popup.link"),
            }
          : {})}
      />

      {residencyState !== "NY" && (
        <Popup onClose={closePopup} open={showPopup}>
          <PopupHeader onClose={closePopup} title={t("popup.title")} />

          <PopupBody>
            <PopupSection as="p">{t("popup.description")}</PopupSection>

            <PopupSection as="ul">
              {topics.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </PopupSection>

            {!coverage?.isSelected && (
              <PopupButton
                onClick={() => {
                  enableCoverage();
                  closePopup();
                }}
              >
                {price
                  ? t("popup.ctaWithPrice", {
                      price: numberFormatter(price, { style: "currency" }),
                    })
                  : t("popup.ctaWithoutPrice")}
              </PopupButton>
            )}
          </PopupBody>
        </Popup>
      )}
    </>
  );
}
