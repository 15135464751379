import { styled } from "@mui/material/styles";
import ProfileLogoAsset from "assets/images/logo/profile.svg";
import { BaseButton } from "components/button/base";

export const Button = styled(BaseButton, {
  label: "button",
})`
  border-radius: 100%;
  height: ${({ theme }) => theme.spacing(13)};
  overflow: hidden;
`;

export const Container = styled("div", {
  label: "logo",
})`
  display: flex;
  justify-content: center;
`;

export const ProfileLogo = styled(ProfileLogoAsset, {
  label: "profile-logo",
})`
  display: block;
  width: auto;
  height: 100%;
`;
