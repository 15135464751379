import { useState } from "react";
import {
  selectInitialTripCost,
  selectTravelers,
  selectTripCost,
} from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import {
  MAX_TRIP_COST_VALUE,
  MIN_TRIP_COST_VALUE,
  MAX_TRIP_COST_VALUE_PER_TRAVELER,
  TRIP_COST_VALUE_STEP,
} from "settings";

const NOT_NUMBER_REGEXP = /[^\d]/g;

export function useInputValue() {
  const initialTripCost =
    useSelector(selectInitialTripCost) ?? MIN_TRIP_COST_VALUE;
  const tripCost = useSelector(selectTripCost);
  const travelers = useSelector(selectTravelers);

  const minTripCost = Math.min(MIN_TRIP_COST_VALUE, initialTripCost);
  const maxTripCost = Math.min(
    MAX_TRIP_COST_VALUE_PER_TRAVELER * travelers.length,
    MAX_TRIP_COST_VALUE,
  );
  const [value, setValue] = useState<number | null>(
    typeof tripCost === "undefined" ? null : Math.floor(tripCost),
  );

  function decrement() {
    if (value != null) {
      // != null: !== null && !== undefined
      updateValue(
        Math.ceil(value / TRIP_COST_VALUE_STEP) * TRIP_COST_VALUE_STEP -
          TRIP_COST_VALUE_STEP,
      );
    }
  }

  function increment() {
    updateValue(
      Math.floor((value ?? 0) / TRIP_COST_VALUE_STEP) * TRIP_COST_VALUE_STEP +
        TRIP_COST_VALUE_STEP,
    );
  }

  function set(input: string) {
    const value = input.replace(NOT_NUMBER_REGEXP, "");
    if (value === "") {
      setValue(null);
    } else if (value.length <= `${maxTripCost}`.length) {
      setValue(parseInt(value));
    }
  }

  function updateValue(result: number) {
    if (result < minTripCost) {
      setValue(minTripCost);
    } else if (result > maxTripCost) {
      setValue(maxTripCost);
    } else {
      setValue(result);
    }
  }

  return {
    decrementInputValue: decrement,
    incrementInputValue: increment,
    maxTripCost,
    inputValue: value ?? null,
    inputValueIsGreaterThanMax: value != null && value > maxTripCost,
    inputValueIsGreaterThanMin: value != null && value > minTripCost,
    inputValueIsLowerThanMax: value != null && value < maxTripCost,
    inputValueIsLowerThanMin: value != null && value < minTripCost,
    setInputValue: set,
  };
}
