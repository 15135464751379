import { useTranslation } from "react-i18next";
import { useAddOnsStrings } from "../../utils";
import { Section, type SectionProps } from "../section";
import { SectionBody } from "../section/body";
import { CancelForAnyReason } from "./cancel-for-any-reason";
import { CarCare } from "./car-care";
import { ExtremeSports } from "./extreme-sports";
import { Item } from "./item";
import { ItemsWrapper } from "./styles";

export function AddOns(props: SectionProps) {
  const { t } = useTranslation("screens", { keyPrefix: "offer.addOns" });

  const addOnsStrings = useAddOnsStrings();

  return (
    <Section {...props}>
      <SectionBody title={t("title")}>
        <ItemsWrapper>
          {addOnsStrings.addOns.map((addOn) => {
            const ItemComponent =
              addOn.coverageType === "cancelForAnyReason"
                ? CancelForAnyReason
                : addOn.coverageType === "car"
                  ? CarCare
                  : addOn.coverageType === "sportsExtreme"
                    ? ExtremeSports
                    : Item;

            return <ItemComponent key={addOn.coverageType} {...addOn} />;
          })}
        </ItemsWrapper>
      </SectionBody>
      {!!addOnsStrings.extraAddOns.length && (
        <SectionBody title={t("extrasTitle")}>
          <ItemsWrapper>
            {addOnsStrings.extraAddOns.map((addOn) => (
              <Item key={addOn.coverageType} {...addOn} />
            ))}
          </ItemsWrapper>
        </SectionBody>
      )}
    </Section>
  );
}
