import { loadState } from "store/session-persisted-state";
import {
  type DestinationGoogleData,
  type Name,
  type PlaceDetails,
  type QuoteDto,
  type Residency,
  type Traveler,
  type UserPolicy,
} from "types/quote-types";

interface Status {
  status: "pending" | "fulfilled" | "rejected" | null;
}

const CACHE: {
  state?: Quote;
} = {};

export interface Quote {
  advisorEmail?: string;
  calculate: Status;
  coveragesPrices?: Partial<Record<string, number>>;
  creditCardDetails?: string;
  dateOfBirth?: string;
  destinations?: PlaceDetails[];
  destinationsGoogleData?: DestinationGoogleData[];
  disclaimerPdfUrl?: string;
  effectiveEndDate?: string;
  effectiveStartDate?: string;
  email?: string;
  isDirectOffer?: boolean;
  isRemarketing?: boolean;
  isTestUser?: boolean;
  name?: Name;
  policyCodeHash?: string | null;
  policyUrl?: string;
  quote: Status;
  quoteDto?: QuoteDto;
  quoteFromHash: Status;
  receiptUrl?: string;
  removedTravelers: boolean;
  residency?: Residency;
  reviewAndEdit?: "reviewing" | "editing" | null;
  totalPrice?: number;
  travelers?: Traveler[];
  tripEndDate?: string;
  tripStartDate?: string;
  userPolicy?: UserPolicy;
}

export const initialState = (): Quote => {
  if (!CACHE.state) {
    CACHE.state = {
      ...loadState()?.quote,
      calculate: { status: null },
      quote: { status: null },
      quoteFromHash: { status: null },
      removedTravelers: false,
    };
  }

  return { ...CACHE.state };
};
