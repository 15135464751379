import { styled } from "@mui/material/styles";
import { PopupHeader } from "components/popup/header";

export const Component = styled(PopupHeader, {
  label: "popup-header",
})`
  && {
    ${({ theme }) => theme.breakpoints.up("sm")} {
      padding-left: 0;
      text-align: left;
    }
  }
`;
