import { useTranslation } from "react-i18next";
import { useSelector } from "hooks/use-selector";
import { selectTotalPriceParts } from "store/quote/selectors";
import { numberFormatter } from "utils/formatters/number";
import { Title } from "../title";
import { Container } from "./styles";

export function PolicyPrice() {
  const totalPriceParts = useSelector(selectTotalPriceParts);

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.printLayout.policyPrice",
  });

  return (
    <>
      <Title>{t("title")}</Title>

      {totalPriceParts && (
        <Container>
          <span className="currency">{totalPriceParts.currency}</span>

          {numberFormatter(parseInt(totalPriceParts.integer))}

          <span className="fraction">
            {totalPriceParts.decimalSeparator}
            {totalPriceParts.fraction}
          </span>
        </Container>
      )}
    </>
  );
}
