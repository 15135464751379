import { useTranslation } from "react-i18next";
import { dateRangeFormatter } from "utils/formatters/date-range";
import { LocalTimezoneDate } from "utils/date";
import { useSelector } from "hooks/use-selector";
import {
  selectDestinations,
  selectTravelers,
  selectTripEndDate,
  selectTripStartDate,
} from "store/quote/selectors";
import { Container, HighlightedItem, Item } from "./styles";

export function Details() {
  const destinations = useSelector(selectDestinations);
  const travelers = useSelector(selectTravelers);
  const tripStartDate = useSelector(selectTripStartDate);
  const tripEndDate = useSelector(selectTripEndDate);

  const { t } = useTranslation("screens", {
    keyPrefix: "confirmation.details",
  });

  return (
    <Container>
      {!!destinations?.[0] && (
        <HighlightedItem>
          {t("destinations", {
            count: destinations.length - 1,
            destination: destinations[0].label,
          })}
        </HighlightedItem>
      )}

      {!!tripStartDate && !!tripEndDate && (
        <Item>
          {dateRangeFormatter(
            new LocalTimezoneDate(tripStartDate),
            new LocalTimezoneDate(tripEndDate),
          )}
        </Item>
      )}

      {!!travelers?.length && (
        <Item>{t("travelers", { count: travelers.length })}</Item>
      )}
    </Container>
  );
}
