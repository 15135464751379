import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { type PaymentMethod } from "components/payment-form";
import { useSelector } from "hooks/use-selector";
import {
  selectCoverages,
  selectDateOfBirth,
  selectDefaultTripCost,
  selectDestinations,
  selectEmail,
  selectHasTripCancellationCoverage,
  selectInitialTripCost,
  selectIsDirectOffer,
  selectIsDomesticTraveling,
  selectIsRemarketing,
  selectIsTestUser,
  selectName,
  selectPolicyCodeHash,
  selectResidency,
  selectTotalPrice,
  selectTravelers,
  selectTripCost,
  selectTripEndDate,
  selectTripStartDate,
} from "store/quote/selectors";
import { type Residency } from "types/quote-types";
import { calculateAge } from "utils/birthdate";
import { dateFormatter } from "utils/formatters/date";
import { trackPageView } from "utils/tracking";
import { getGtmDataLayer, gtmTrack } from "utils/tracking/gtm";
import {
  track as iterableTrack,
  updateUser as iterableUpdateUser,
} from "utils/tracking/iterable";
import {
  identifyMixpanelUser,
  mixpanelTrack,
  updateMixpanelUser,
} from "utils/tracking/mixpanel";
import { getAdvisorId } from "utils/tracking/utm";
import { countNonAdultTravelers } from "utils/traveler";

export function trackCancelForAnyReasonDepositDate(event: string, date: Date) {
  mixpanelTrack(event, { initial_deposit_date: dateFormatter(date) });
}

export function useTrackAddressSubmit() {
  const name = useSelector(selectName);

  return (residency: Residency) => {
    // Google Tag Manager

    getGtmDataLayer().push({
      address: {
        city: residency.locality,
        country: residency.country,
        first_name: name?.firstName,
        last_name: name?.lastName,
        postal_code: residency.zipCode,
        region: residency.areaLevel1,
        street: `${residency.streetAndNumber} ${residency.apartment}`.trim(),
      },
    });

    // Mixpanel

    updateMixpanelUser({ address: residency.label });
  };
}

export function useTracking() {
  const birthdate = useSelector(selectDateOfBirth);
  const destinations = useSelector(selectDestinations);
  const email = useSelector(selectEmail);
  const hasTripCancellation = useSelector(selectHasTripCancellationCoverage);
  const isDirectOffer = useSelector(selectIsDirectOffer);
  const isRemarketing = useSelector(selectIsRemarketing);
  const isTestUser = useSelector(selectIsTestUser);
  const name = useSelector(selectName);
  const policyCodeHash = useSelector(selectPolicyCodeHash);
  const residency = useSelector(selectResidency);
  const startDate = useSelector(selectTripStartDate);
  const endDate = useSelector(selectTripEndDate);
  const totalPrice = useSelector(selectTotalPrice);
  const travelers = useSelector(selectTravelers);

  const { pathname } = useLocation();

  const [previousTotalPrice, setPreviousTotalPrice] = useState(totalPrice);

  useEffect(() => {
    // Google Tag Manager

    getGtmDataLayer().push({
      address: residency && {
        city: residency.locality,
        country: residency.country,
        first_name: name?.firstName,
        last_name: name?.lastName,
        postal_code: residency.zipCode,
        region: residency.areaLevel1,
        street: `${residency.streetAndNumber} ${residency.apartment}`.trim(),
      },
      age: birthdate && calculateAge(birthdate),
      email,
    });

    gtmTrack("offer", {
      price: totalPrice,
    });

    // Iterable

    if (email) {
      const commonIterableFields = {
        $first_name: name?.firstName,
        $last_name: name?.lastName,
        destinations: destinations
          ?.map((destination) => destination?.label)
          .filter(Boolean),
        quote_hash: policyCodeHash,
      };

      iterableTrack({
        dataFields: {
          ...commonIterableFields,
          is_advisor_utm: !!getAdvisorId(),
          is_direct_offer: !!isDirectOffer,
          is_remarketing: !!isRemarketing,
          main_traveler_age: travelers[0]
            ? calculateAge(travelers[0].dateOfBirth)
            : null,
          non_adult_travelers_count: countNonAdultTravelers(travelers),
          quote_hash: policyCodeHash,
          total_travelers_count: travelers.length,
        },
        email,
        eventName: "iterable_seen_offer_page",
      });

      iterableUpdateUser({
        dataFields: {
          ...commonIterableFields,
          $email: email,
          address: residency?.label ?? null,
          days:
            startDate && endDate
              ? Math.abs(
                  dayjs(startDate)
                    .startOf("day")
                    .diff(dayjs(endDate).startOf("day"), "days"),
                )
              : null,
        },
        email,
      });
    }

    // Mixpanel

    if (isDirectOffer && email) {
      void identifyMixpanelUser(email);
    }

    updateMixpanelUser({
      is_test_user: isTestUser,
      quote_hash: policyCodeHash,
    });

    // Track page view

    trackPageView("Offer Page", pathname, {
      is_remarketing: !!isRemarketing,
      quote_hash: policyCodeHash,
      quote_price: totalPrice,
      variation:
        residency?.areaLevel1 === "NY" || hasTripCancellation
          ? "saw_trip_cost"
          : "didnt_see_trip_cost",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Google Tag Manager

    if (totalPrice !== previousTotalPrice) {
      gtmTrack("price_updated", { price: totalPrice });
      setPreviousTotalPrice(totalPrice);
    }
  }, [previousTotalPrice, totalPrice]);
}

export function useAddressTracking(active: boolean, isFullAddress: boolean) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (active) {
      trackPageView(
        isFullAddress ? "Full address modal" : "Short address modal",
        pathname,
      );
    }
  }, [active, isFullAddress, pathname]);
}

export function usePaymentTracking(active: boolean) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (active) {
      trackPageView("Payment", pathname);
    }
  }, [active, pathname]);
}

export function useTrackCtaPressed() {
  const coverages = useSelector(selectCoverages);
  const totalPrice = useSelector(selectTotalPrice);
  const initialTripCost = useSelector(selectInitialTripCost);
  const tripCost = useSelector(selectTripCost);

  return () => {
    const selectedAddons = coverages
      .filter((coverage) => !coverage.isBase && coverage.isSelected)
      .map((coverage) => coverage.title);

    updateMixpanelUser({
      selected_add_ons: selectedAddons,
    });

    if (tripCost && initialTripCost && tripCost !== initialTripCost) {
      const tripCostDiff = Math.floor(tripCost - initialTripCost);
      const eventName =
        tripCostDiff > 0
          ? "clicked_increase_trip_cost"
          : "clicked_decrease_trip_cost";
      mixpanelTrack(eventName, { trip_cost_diff: tripCostDiff });
    }

    mixpanelTrack("offer_cta_pressed", {
      pressed_payment_CTA: "Bottom CTA",
      quote_price: totalPrice,
      selected_add_ons: selectedAddons,
      trip_cost: tripCost && Math.floor(tripCost),
    });
  };
}

export function useTrackPaymentMade() {
  const coverages = useSelector(selectCoverages);
  const defaultTripCost = useSelector(selectDefaultTripCost);
  const email = useSelector(selectEmail);
  const isDomesticTraveling = useSelector(selectIsDomesticTraveling);
  const isRemarketing = useSelector(selectIsRemarketing);
  const totalPrice = useSelector(selectTotalPrice);
  const travelers = useSelector(selectTravelers);
  const tripCost = useSelector(selectTripCost);

  return useCallback(
    (paymentMethod: PaymentMethod, uniqueUuid?: string, error?: string) => {
      const data = {
        default_trip_cost: defaultTripCost,
        payment_type: paymentMethod,
        status: typeof error !== "undefined" ? error : "Success",
        total_price: totalPrice,
        total_travelers_count: travelers.length,
        trip_cost: tripCost && Math.floor(tripCost),
      };

      const lastPurchaseDate = dayjs().format("YYYY-MM-DD");

      // Google Tag Manager

      const gtmItems = [
        {
          item_name: isDomesticTraveling ? "domesticBase" : "internationalBase",
          quantity: travelers.length,
        },
        ...coverages
          .filter(({ isDefault }) => !isDefault)
          .filter(({ isSelected }) => isSelected)
          .map(({ coverageType, isBase }) => ({
            item_name: coverageType,
            quantity: isBase ? travelers.length : 1,
          })),
      ];

      gtmTrack("purchase", {
        ecommerce: {
          currency: "USD",
          items: gtmItems,
          transaction_id: uniqueUuid,
          value: totalPrice,
        },
        email,
      });

      // Mixpanel

      updateMixpanelUser({
        last_purchase_date: lastPurchaseDate,
      });

      mixpanelTrack("made_payment", {
        is_remarketing: !!isRemarketing,
        last_purchase_date: lastPurchaseDate,
        ...data,
      });

      // Iterable

      if (email) {
        iterableTrack({
          dataFields: data,
          email,
          eventName: "iterable_purchase_policy",
        });
      }
    },

    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
}
