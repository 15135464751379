import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "screen-header",
})`
  max-width: ${({ theme }) => theme.spacing(100)};
  margin: ${({ theme }) => theme.spacing(0, "auto", 3)};
  padding-top: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-top: 0;
  }
`;

export const Caption = styled("p", {
  label: "caption",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: pre-line;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 0;
  }
`;

export const Title = styled("h1", {
  label: "title",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  white-space: pre-line;
  letter-spacing: -0.92px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 46px;
    line-height: 1.1;
  }
`;

export const Lead = styled("p", {
  label: "lead",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  white-space: pre-line;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    color: ${({ theme }) => theme.palette.color.primary.main};
    font-size: 18px;
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;
