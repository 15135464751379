import classNames from "classnames";
import { type ComponentPropsWithoutRef } from "react";
import { useTranslation } from "react-i18next";
import { PopupSubtitle } from "../subtitle";
import { PopupTitle } from "../title";
import { CloseButton, CloseIcon, Container } from "./styles";

export interface PopupHeaderProps extends ComponentPropsWithoutRef<"div"> {
  disableCloseButton?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
  subtitle?: string;
  title?: string;
}

export function PopupHeader({
  children,
  className,
  disableCloseButton,
  hideCloseButton,
  onClose,
  subtitle,
  title,
  ...props
}: PopupHeaderProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "popup",
  });

  return (
    <Container
      className={classNames(className, {
        "has-close-button": !hideCloseButton,
      })}
      {...props}
    >
      <div>
        {title && (
          <>
            <PopupTitle>{title}</PopupTitle>
            {subtitle && <PopupSubtitle>{subtitle}</PopupSubtitle>}
          </>
        )}

        {children}
      </div>

      {!hideCloseButton && (
        <CloseButton
          aria-label={t("header.closeButton.ariaLabel")}
          disabled={disableCloseButton}
          onClick={onClose}
        >
          <CloseIcon />
        </CloseButton>
      )}
    </Container>
  );
}
