import { getAvailableExperiments } from "utils/experiments";
import { getOttUser, type OttTarget } from "utils/ott";
import { sentryCaptureError } from "utils/tracking/sentry";

export interface ApiFetchOptions extends RequestInit {
  authenticate?: boolean;
  baseUrl?: string;
  headers?: Record<string, string>;
  ott?: OttTarget;
  searchParams?: Record<string, string>;
  throwBadResponse?: boolean;
}

export class BadResponseError extends Error {
  status: number;

  constructor({
    message,
    status = 0,
  }: { message?: string; status?: number } = {}) {
    super(message);
    this.name = "BadResponseError";
    this.status = status;
  }
}

export class ScriptLoadError extends Error {
  provider: string;

  constructor(provider: "PayPal" | "Checkout" | "Stripe") {
    super(`${provider} is not available`);
    this.name = "ScriptLoadError";
    this.provider = provider.toLowerCase();

    sentryCaptureError(this, {
      contexts: { provider: { providerType: provider } },
      name: "Provider_script_failed",
    });
  }
}

export async function apiFetch(
  path: string,
  {
    baseUrl = import.meta.env.VITE_API_URL,
    headers: optionsHeaders,
    ott,
    searchParams,
    throwBadResponse = true,
    ...options
  }: ApiFetchOptions = {},
) {
  const ottHeaders: Record<string, string> = {};
  const url = new URL(path, baseUrl);

  if (ott) {
    const ottUser = await getOttUser(ott);

    if (!ottUser) {
      throw new BadResponseError({ message: "OTT is unavailable" });
    }

    ottHeaders.authorization = `Bearer ${await ottUser.getIdToken()}`;
  }

  if (searchParams) {
    for (const key in searchParams) {
      url.searchParams.append(key, searchParams[key]);
    }
  }

  const features = new URLSearchParams(getAvailableExperiments());
  const headers: Record<string, string> = {
    ...(baseUrl === import.meta.env.VITE_API_URL && {
      accept: "application/json",
      "content-type": "application/json",
      platform: "onboarding",
      features: btoa(features.toString()),
    }),
    ...ottHeaders,
    ...optionsHeaders,
  };

  const response = await window.fetch(url, {
    headers,
    mode: "cors", // May fix Safari issue on DEV-5362
    ...options,
  });

  if (throwBadResponse && !response.ok) {
    throw new BadResponseError({
      message: response.statusText,
      status: response.status,
    });
  }

  return response;
}
