import { throttle } from "lodash-es";
import { type RootState } from "store";

const LOCAL_STORAGE_STATE_KEY = "faye:state";

type SessionPersistedState = Partial<RootState>;

export const loadState = (): SessionPersistedState | null => {
  const serializedState: string | null = sessionStorage.getItem(
    LOCAL_STORAGE_STATE_KEY,
  );

  return serializedState
    ? (JSON.parse(serializedState) as SessionPersistedState)
    : null;
};

export const saveState = throttle((values: SessionPersistedState) => {
  const serializedQuoteState = JSON.stringify(values);
  sessionStorage.setItem(LOCAL_STORAGE_STATE_KEY, serializedQuoteState);
}, 1000);
