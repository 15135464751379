import { Trans } from "react-i18next";
import { selectUserPolicy } from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import { Container } from "./styles";

export function Lead() {
  const userPolicy = useSelector(selectUserPolicy);

  return (
    <Container>
      <Trans
        i18nKey="confirmation.info"
        ns="screens"
        values={{ policyId: userPolicy?.uniqueUuid }}
      />
    </Container>
  );
}
