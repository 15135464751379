import { type ComponentPropsWithoutRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { useAutocompleteDebounce } from "hooks/use-autocomplete-debounce";
import {
  expireGooglePlacesSessionToken,
  googlePlacesAutocomplete,
} from "utils/google-places";
import { getStateByCode } from "utils/place/residency";
import { type AddressFields } from "../../utils";
import states from "strings/states.json";
import { Popper } from "components/autocomplete/base/popper";
import { PlainButton } from "components/button/plain";
import {
  Component,
  InputWrapper,
  Input,
  Paper,
  Footer,
  FullAddressTrigger,
  ResultsFromGoogle,
  ClearIcon,
} from "./styles";

export type AutocompleteOption = Pick<
  AddressFields,
  "googlePlacesId" | "label"
>;

export interface AutocompleteProps {
  name: string;
  onChange: (address: AddressFields | null) => void;
  onFullAddressTriggerClick: () => void;
  placeholder: string;
  stateRestriction?: string;
  value: AutocompleteOption | null;
}

export function Autocomplete({
  name,
  onChange,
  onFullAddressTriggerClick,
  placeholder,
  stateRestriction,
  value,
}: AutocompleteProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { query, options, setQuery } =
    useAutocompleteDebounce<AutocompleteOption>(
      async (query: string) => {
        const options: AutocompleteOption[] = [];

        // Here autocomplete was replaced in the commit
        // 2b31d092fcc37e3b74b55e8dc6828cbde717125a. In order to restore Here,
        // copy the API helper files and make the necessary adjustments.
        const items = await googlePlacesAutocomplete(
          stateRestriction
            ? `${query} ${getStateByCode(stateRestriction).label}`
            : query,
          { countries: ["us"], types: ["address"], useSessionToken: true },
        );

        for (const item of items) {
          let replaceStateName = false;
          let stateCode = item.terms[item.terms.length - 1].value;

          if (stateCode.length !== 2) {
            for (const state of states) {
              if (state.label === stateCode) {
                replaceStateName = true;
                stateCode = state.value;
                break;
              }
            }
          }

          if (
            stateRestriction === undefined ||
            stateCode === stateRestriction
          ) {
            options.push({
              googlePlacesId: item.place_id,
              label: replaceStateName
                ? item.description.replace(/(, *)[ A-Za-z]+$/, `$1${stateCode}`)
                : item.description,
            });
          }
        }

        return options;
      },
      {
        cacheKeyPrefix: stateRestriction ?? "NONE",
        cacheName: "faye:address_cache",
        minQueryLength: 3,
      },
    );
  const {
    getClearProps,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
  } = useAutocomplete({
    value,
    clearOnBlur: true,
    freeSolo: true,
    options: options ?? [],
    filterOptions: (options) => options,
    onInputChange: (_, value, reason) => {
      setQuery(reason === "input" ? value : "");
    },
    onChange: (_, value, reason) => {
      if (reason !== "selectOption") {
        onChange(null);
      } else if (typeof value !== "string" && value?.googlePlacesId) {
        onChange(value);
      }
    },
  });
  const { t } = useTranslation("components", {
    keyPrefix: "addressFields",
  });

  const showResults = options !== null && options.length > 0;

  useEffect(() => expireGooglePlacesSessionToken, []);

  return (
    <Component ref={containerRef}>
      <InputWrapper {...getRootProps()}>
        <Input
          {...getInputProps()}
          name={name}
          placeholder={placeholder}
          aria-label={placeholder}
          autoCapitalize="none"
          autoCorrect="off"
        />
        {value && (
          <PlainButton {...getClearProps()}>
            <ClearIcon />
          </PlainButton>
        )}
      </InputWrapper>
      <Popper
        open={!!query}
        {...(containerRef.current && {
          anchorEl: containerRef.current,
          style: { width: containerRef.current.offsetWidth },
        })}
      >
        <Paper className={autocompleteClasses.paper}>
          {showResults ? (
            <ul {...getListboxProps()} className={autocompleteClasses.listbox}>
              {options.map((option, index) => {
                const { key, ...optionProps } = getOptionProps({
                  option,
                  index,
                }) as ComponentPropsWithoutRef<"li">;
                return (
                  <li
                    key={key}
                    {...optionProps}
                    className={autocompleteClasses.option}
                  >
                    {option.label}
                  </li>
                );
              })}
            </ul>
          ) : null}

          <Footer>
            {onFullAddressTriggerClick && (
              <FullAddressTrigger
                onClick={onFullAddressTriggerClick}
                onMouseDown={(event) => {
                  // Avoid closing autocomplete suggestions onBlur
                  event.preventDefault();
                }}
              >
                {t("fullAddressTrigger.label")}
              </FullAddressTrigger>
            )}

            {showResults && <ResultsFromGoogle />}
          </Footer>
        </Paper>
      </Popper>
    </Component>
  );
}
