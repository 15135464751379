import { styled } from "@mui/material/styles";
import { PlainButton } from "components/button/plain";

export const Container = styled("div", { label: "item" })`
  font-weight: 300;
  line-height: 1.5;
  padding: 0;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.breakpoints.up("md")} {
      border-top: 1px solid ${({ theme }) => theme.palette.color.gray[90]};
      margin: ${({ theme }) => theme.spacing(1.5, 0, 0, -2)};
      padding: ${({ theme }) => theme.spacing(3, 0, 0, 2)};
    }
  }
`;

export const Content = styled("div", { label: "content" })`
  padding: ${({ theme }) => theme.spacing(2, 0)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(1, 0, 0)};
  }
`;

export const Title = styled("h3", { label: "title" })`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.25;
  min-height: auto;
  padding: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.1;
  }
`;

export const Topic = styled("div", {
  label: "topic",
})`
  align-items: center;
  color: ${({ theme }) => theme.palette.color.text.primary};
  display: flex;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.color.gray[90]};

  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: start;
    font-size: 20px;
    font-weight: 500;
    line-height: 2;
    border-bottom: none;
    padding-bottom: 0;
  }

  & + & {
    margin-top: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-top: ${({ theme }) => theme.spacing(0.5)};
    }
  }
`;

export const TopicDescription = styled("div", {
  label: "topic-description",
})`
  flex: 0 0 40%;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: ${({ theme }) => theme.palette.color.primary.main};
  margin-left: ${({ theme }) => theme.spacing(1)};
  text-align: right;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 20px;
    line-height: 2;
    flex: 0 1 auto;
    white-space: nowrap;
  }
`;

export const Trigger = styled(PlainButton, {
  label: "trigger",
})`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.color.primary.main};
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;
