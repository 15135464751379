import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "hooks/use-breakpoint";
import { Actions } from "./actions";
import { Art } from "./art";
import { Details } from "./details";
import { Download } from "./download";
import { Lead } from "./lead";
import lottieUrl from "./lottie.json?url";
import { useTracking } from "./tracking";
import { Lottie, Summary, Title, Top } from "./styles";

export function ConfirmationScreen() {
  const mdBreakpoint = useBreakpoint("md", { noSsr: true });

  const { t } = useTranslation("screens", {
    keyPrefix: "confirmation",
  });

  const [showLottie, setShowLottie] = useState(mdBreakpoint);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useTracking();

  return (
    <>
      <Summary>
        <Top>
          <Title>{t("title")}</Title>
          <Lead />
          <Details />
          <Actions />
        </Top>
        <Download />
      </Summary>

      <Art />

      {showLottie && (
        <Lottie
          onComplete={() => {
            setShowLottie(false);
          }}
          options={{ loop: false }}
          play
          url={lottieUrl}
        />
      )}
    </>
  );
}
