import dayjs, { type Dayjs } from "dayjs";
import { MAX_TRIP_DURATION_DAYS, MAX_TRIP_START_DAYS } from "settings";

export function getMaxStart() {
  return dayjs().startOf("day").add(MAX_TRIP_START_DAYS, "days");
}

export function getMaxEnd(startDate: Dayjs | null) {
  return startDate
    ? startDate.add(MAX_TRIP_DURATION_DAYS, "days")
    : dayjs()
        .startOf("day")
        .add(MAX_TRIP_START_DAYS + MAX_TRIP_DURATION_DAYS, "days");
}

export function getMinStart() {
  return dayjs().isAfter(dayjs(new Date(2024, 6, 24)))
    ? dayjs()
    : dayjs(new Date(2024, 6, 24));
}

export function getMinEnd(startDate: Dayjs | null) {
  return startDate ?? dayjs();
}
