import type { CSSProperties, ComponentPropsWithoutRef } from "react";
import type { SvgImport } from "types/svg-import";
import { getSvgrAspectRatio } from "utils/svgr";
import { Container } from "./styles";

export interface MediaBarProps extends ComponentPropsWithoutRef<"div"> {
  images: SvgImport[];
}

export function MediaBar({ images, ...props }: MediaBarProps) {
  return (
    <Container {...props}>
      {images.map((Image, index) => (
        <Image
          key={index}
          style={
            {
              "--image-aspect-ratio": getSvgrAspectRatio(Image),
            } as CSSProperties
          }
        />
      ))}
    </Container>
  );
}
