import { createFilterOptions } from "@mui/material/Autocomplete";
import states from "strings/states.json";

export interface State {
  label: string;
  value: string;
}

export function getStateByCode(stateCode: string): State {
  for (const state of states) {
    if (stateCode === state.value) {
      return state;
    }
  }

  throw new Error("stateCode is invalid");
}

// Use ZWSP char to separate query parts, so users can't match
// queries like "new york ny", "new york - ny", etc.
export const stateFilterOptions = createFilterOptions<State>({
  // eslint-disable-next-line no-irregular-whitespace
  stringify: (option) => `${option.label}​${option.value}`,
});
