import {
  type ComponentPropsWithoutRef,
  type ReactNode,
  forwardRef,
} from "react";
import { Label, type LabelProps } from "./label";
import { Component, Container } from "./styles";

export interface InputGroupProps
  extends Omit<
    ComponentPropsWithoutRef<"div">,
    "color" // Avoid type issues when using it as MUI DateRangePicker root element
  > {
  error?: boolean;
  helperText?: ReactNode;
  label?: LabelProps & { text: string };
}

export const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>(
  function InputGroup({ children, error, label, ...props }, ref) {
    const labelProps = {
      ...label,
      text: props.helperText || label?.text,
    };

    return (
      <Container ref={ref} {...props}>
        {labelProps.text && (
          <Label {...labelProps} error={error}>
            {labelProps.text}
          </Label>
        )}
        <Component>{children}</Component>
      </Container>
    );
  },
);
