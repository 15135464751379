import { styled } from "@mui/material/styles";
import { Button as ButtonComponent } from "../button";
import { buttonBaseClasses } from "@mui/material";

export const Button = styled(ButtonComponent, {
  label: "button",
})`
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &.${buttonBaseClasses.disabled} {
    pointer-events: auto;
  }
`;

export const Container = styled("div", {
  label: "paypal-button",
})`
  display: flex;
  flex: 0 1 ${({ theme }) => theme.spacing(20)};
  min-width: 150px; // Minimum width set by PayPal script
  position: relative;
  width: 0;
`;

export const PaypalButtonContainer = styled("div", {
  label: "paypal-button-container",
})`
  display: flex;
  opacity: 0.00000001;
  position: relative;
  width: 100%;
  z-index: 0;

  &.completed {
    pointer-events: none;
  }
`;
