import { buttonBaseClasses } from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import CloseIconAsset from "assets/images/icons/close-light.svg";
import { PlainButton } from "components/button/plain";

export const CLOSE_ICON_WIDTH = 2;

export const CloseButton = styled(PlainButton, {
  label: "close-buttton",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.color.primary[95]};
  border-radius: ${({ theme }) => theme.shape.borderRadius * 5}px;
  padding: ${({ theme }) => theme.spacing(1.5)};

  &.${buttonBaseClasses.disabled} {
    color: ${({ theme }) => theme.palette.color.gray[80]};
    border-color: ${({ theme }) => theme.palette.color.gray[80]};
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: ${({ theme }) => theme.spacing(-2, -4, 0, 0)};
  }
`;

export const CloseIcon = styled(CloseIconAsset, {
  label: "close-icon",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(CLOSE_ICON_WIDTH)};
  height: auto;
`;

export const Container = styled("div", {
  label: "popup-header",
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: ${({ theme }) => theme.spacing(6, 2, 0)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: ${({ theme }) => theme.spacing(6, 5, 0, 8)};
    position: relative;
  }

  &.has-close-button {
    ${({ theme }) => theme.breakpoints.up("sm")} {
      padding: ${({ theme }) => theme.spacing(0, CLOSE_ICON_WIDTH + 1)};
    }
  }
`;
