import { useEffect, useState } from "react";
import { getAdvisorEmail } from "utils/api/advisor";
import { getAdvisorId } from "utils/tracking/utm";

export function useAdvisorEmail() {
  const [advisorEmail, setAdvisorEmail] = useState<string | null>();

  useEffect(() => {
    const advisorId = getAdvisorId();

    if (advisorId) {
      void getAdvisorEmail(advisorId).then((advisorEmail) => {
        setAdvisorEmail(advisorEmail);
      });
    } else {
      setAdvisorEmail(null);
    }
  }, []);

  return advisorEmail;
}
