import { useTranslation } from "react-i18next";
import { type InputProps, Input } from "components/input";
import { AutocompleteInput } from "../autocomplete-input";
import { type InputGroupProps } from "components/input-group";
import { upperFirst } from "lodash-es";
import { nameValidation } from "utils/validation/name";
import { useOttUser } from "hooks/use-ott-user";
import { type TravelerField } from "..";
import { parseBirthdate } from "utils/birthdate";

import { Container } from "./styles";

export interface NameInputProps extends Omit<InputProps, "onChange"> {
  onChange: (event: { target: { value: string } }) => void;
}

export interface NameFieldProps extends Omit<InputGroupProps, "onChange"> {
  index: number;
  firstName?: string;
  lastName?: string;
  onChange: (field: TravelerField) => void;
}

export function NameField({
  index,
  firstName,
  lastName,
  onChange,
  ...props
}: NameFieldProps) {
  const ottUser = useOttUser("glinda");
  const { t } = useTranslation("components", {
    keyPrefix: "personalDataForm",
  });

  const firstNameInputProps: NameInputProps = {
    id: `first_name_${index}`,
    label: t("fields.firstName"),
    name: `first_name_${index}`,
    required: index === 0,
    validate: nameValidation,
    value: firstName ?? "",
    inputProps: {
      autoCapitalize: "none", // Safari only
      autoCorrect: "off", // Safari only
      spellCheck: "false",
    },
    onChange: (e) => {
      onChange({ firstName: { value: upperFirst(e.target.value) } });
    },
    onValidation: (validation) => {
      onChange({ firstName: { validation } });
    },
  };

  const lastNameInputProps: NameInputProps = {
    id: `last_name_${index}`,
    label: t("fields.lastName"),
    name: `last_name_${index}`,
    required: index === 0,
    validate: nameValidation,
    value: lastName ?? "",
    inputProps: {
      autoCapitalize: "none", // Safari only
      autoCorrect: "off", // Safari only
      spellCheck: "false",
    },
    onChange: (e) => {
      onChange({ lastName: { value: upperFirst(e.target.value) } });
    },
    onValidation: (validation) => {
      onChange({ lastName: { validation } });
    },
  };

  return (
    <Container {...props}>
      {ottUser && (
        <>
          <AutocompleteInput
            field="firstName"
            InputProps={firstNameInputProps}
            onChange={(_, value) => {
              if (typeof value !== "string") {
                const { lastName, dateOfBirth, email } = value;
                onChange({ lastName: { value: lastName } });
                onChange({ birthdate: { value: parseBirthdate(dateOfBirth) } });
                onChange({ email: { value: email } });
              }
            }}
          />
          <AutocompleteInput
            field="lastName"
            InputProps={lastNameInputProps}
            onChange={(_, value) => {
              if (typeof value !== "string") {
                const { firstName, dateOfBirth, email } = value;
                onChange({ firstName: { value: firstName } });
                onChange({ birthdate: { value: parseBirthdate(dateOfBirth) } });
                onChange({ email: { value: email } });
              }
            }}
          />
        </>
      )}

      {!ottUser && (
        <>
          <Input {...firstNameInputProps} autoFocus={index === 0 && !ottUser} />
          <Input {...lastNameInputProps} />
        </>
      )}
    </Container>
  );
}
