import ChipComponent, { chipClasses } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import CloseIconAsset from "assets/images/icons/close.svg";
import { PopupSection } from "../../popup-section";
import { Label as LabelComponent } from "../label";

export const Chip = styled(ChipComponent, {
  label: "chip",
})`
  flex-direction: row-reverse;
  font-size: 10px;
  font-weight: 600;
  height: auto;
  letter-spacing: 0.5px;
  line-height: 1;
  margin: ${({ theme }) => theme.spacing(1.5, 0, 0, 1.5)};
  text-transform: uppercase;

  &,
  &:hover {
    background: ${({ theme }) => theme.palette.color.primary[95]};
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .${chipClasses.label} {
    padding: ${({ theme }) => theme.spacing(0.5, 0, 0.5, 1)};
  }

  .${chipClasses.deleteIcon} {
    margin: 0;
  }
`;

export const ChipsWrapper = styled("div", {
  label: "chips-wrapper",
})`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing(-1.5, 0, 0, -1.5)};
`;

export const CloseIcon = styled(CloseIconAsset, {
  label: "close-icon",
})`
  fill: ${({ theme }) => theme.palette.primary.main};
  width: ${({ theme }) => theme.spacing(0.5)};
  height: auto;

  &&.${chipClasses.icon} {
    // Increase specifity
    margin: ${({ theme }) => theme.spacing(0, 1, 0, 0.75)};
  }
`;

export const Container = styled(PopupSection, {
  label: "selected-days",
})`
  align-items: baseline;
  display: flex;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: ${({ theme }) => theme.spacing(42)};
  }
`;

export const Label = styled(LabelComponent, {
  label: "label",
})`
  margin: ${({ theme }) => theme.spacing(0.25, 1.5, 0.25, 0)};
  white-space: nowrap;

  &.disabled {
    color: ${({ theme }) => theme.palette.color.gray[100]};
  }
`;
