import { styled } from "@mui/material/styles";
import StorksAsset from "assets/images/storks.svg";
import ArrowIconAsset from "assets/images/icons/arrow.svg";
import { PlainButton } from "components/button/plain";
import { Logo as LogoComponent } from "components/logo";

export const Header = styled("header", { label: "header" })`
  color: ${({ theme }) => theme.palette.color.primary.main};
  display: flex;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
    background-color: initial;
  }
`;

export const TitleWrapper = styled("div", { label: "title-wrapper" })`
  text-align: center;
  flex: auto;
`;

export const Title = styled("h1", { label: "title" })`
  font-size: 18px;
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 54px;
    font-weight: 500;
  }
`;

export const Subtitle = styled("span", { label: "subtitle" })`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 18px;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 24px;
    font-weight: 400;
  }
`;

export const Component = styled("div", {
  label: "offer-layout-content",
})`
  background: ${({ theme }) => theme.palette.color.gray[100]};
  padding: 0;
  position: relative;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(4, 4, 1)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-right: ${({ theme }) => theme.spacing(10)};
    padding-left: ${({ theme }) => theme.spacing(10)};
  }
`;

export const StorksImage = styled(StorksAsset, { label: "storks" })`
  position: absolute;
  display: none;
  top: ${({ theme }) => theme.spacing(-14)};
  right: ${({ theme }) => theme.spacing(-7)};

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: initial;
  }
`;

export const ArrowIcon = styled(ArrowIconAsset, {
  label: "arrow-icon",
})`
  transform: rotate(-180deg);
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
`;

export const BackButton = styled(PlainButton, {
  label: "back-button",
})`
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const Logo = styled(LogoComponent, {
  label: "logo",
})`
  position: absolute;
`;
