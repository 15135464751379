import { styled } from "@mui/material/styles";

export const Container = styled("dl", {
  label: "topic-list",
})`
  align-items: baseline;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(2.5)};
  }

  &.personalDetails,
  &.standard {
    line-height: ${({ theme }) => theme.spacing(3)};
  }

  &.details {
    line-height: 1.6;
  }

  &.personalDetails {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: ${({ theme }) => theme.spacing(5.5)};
  }

  &.plain {
    line-height: 1.8;
  }
`;

export const Row = styled("div", {
  label: "row",
})`
  display: flex;
`;

export const Description = styled("dd", {
  label: "description",
})`
  margin: 0;
  white-space: pre-line;

  .personalDetails &,
  .plain &,
  .standard & {
    font-size: 11px;
  }

  .plain &,
  .standard & {
    margin-left: auto;
    text-align: right;
  }

  .personalDetails &,
  .standard & {
    font-weight: 300;
  }

  .details & {
    font-size: 9px;
  }

  .plain & {
    font-weight: 700;
  }
`;

export const Title = styled("dt", {
  label: "title",
})`
  margin-right: ${({ theme }) => theme.spacing(2)};
  white-space: nowrap;

  .personalDetails &,
  .standard & {
    font-size: 13px;
  }

  .details & {
    font-size: 9px;
    font-weight: 600;
    margin-top: ${({ theme }) => theme.spacing(2.5)};
  }

  .personalDetails & {
    font-weight: 600;
  }

  .plain & {
    font-weight: 300;
    font-size: 11px;
  }
`;
