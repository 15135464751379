import { getDetails } from "use-places-autocomplete";
import { type GooglePlace } from "types/quote-types";

export interface Place {
  country?: string;
  label: string;
  placeId: string;
  terms?: google.maps.places.PredictionTerm[];
  types?: string[];
}

export async function getPlaceDetails(place: GooglePlace) {
  return (await getDetails({
    placeId: place.googleId,
    fields: ["address_components", "formatted_address", "place_id"],
    language: "en",
  })) as google.maps.places.PlaceResult;
}
