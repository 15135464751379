import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  DismissButton,
  FailureIcon,
  Lead,
  Message,
  PopupBody,
  PopupHeader,
  PopupTitle,
} from "./styles";

export interface ErrorPopupProps {
  lead: string | null;
  error: string | null;
  onClose: () => void;
}

export function ErrorPopup({ lead, error, onClose }: ErrorPopupProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "stripe.paymentForm.errorPopup",
  });

  const lastErrorRef = useRef(error);
  lastErrorRef.current = error ?? lastErrorRef.current;

  return (
    <Container
      onClose={(_, reason) => {
        if (reason === "escapeKeyDown") {
          onClose();
        }
      }}
      open={error !== null}
    >
      <PopupHeader hideCloseButton>
        <FailureIcon />
        <PopupTitle>{t("title")}</PopupTitle>
      </PopupHeader>

      <PopupBody>
        {lead && <Lead>{lead}</Lead>}
        <Message>{lastErrorRef.current}</Message>
        <DismissButton autoFocus onClick={onClose}>
          {t("button.label")}
        </DismissButton>
      </PopupBody>
    </Container>
  );
}
