import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form } from "components/form";
import { LimitedTripLengthPopup } from "components/limited-trip-length-popup";
import { ScreenHeader } from "components/screen-header";
import { TalkingHead } from "components/talking-head";
import { useDispatch } from "hooks/use-dispatch";
import { useLimitedTripLengthIsValid } from "hooks/use-limited-trip-length-is-valid";
import { useResidencyStateInDestinations } from "hooks/use-residency-state-in-destinations";
import { useSelector } from "hooks/use-selector";
import { selectIsDomesticTraveling } from "store/quote/selectors";
import { setMainTravelerStateThunk } from "store/quote/thunks/steps";
import { type State } from "utils/place/residency";
import { Autocomplete } from "./autocomplete";
import { trackSubmit, useTracking } from "./tracking";

export function StateScreen() {
  const dispatch = useDispatch();
  const isDomesticTraveling = useSelector(selectIsDomesticTraveling);
  const limitedTripLengthIsValid = useLimitedTripLengthIsValid();
  const navigate = useNavigate();

  const { t } = useTranslation("screens", {
    keyPrefix: "state",
  });

  const [limitedTripLengthStateCode, setLimitedTripLengthStateCode] = useState(
    null as string | null,
  );

  const [state, setState] = useState({
    valid: null as boolean | null,
    value: null as State | null,
  });

  const residencyStateInDestinations = useResidencyStateInDestinations(
    state.value?.value,
  );

  useTracking();

  return (
    <>
      <ScreenHeader title={t("title")} />

      <Form
        SubmitButtonProps={{
          showArrow: true,
        }}
        onSubmit={() => {
          if (!state.valid || !state.value) {
            // Do nothing
          } else if (
            !limitedTripLengthIsValid(state.value.value) &&
            !limitedTripLengthStateCode
          ) {
            setLimitedTripLengthStateCode(state.value.value);
          } else {
            dispatch(setMainTravelerStateThunk(state.value.value));
            trackSubmit(state.value.value);

            if (isDomesticTraveling && state.value.value === "MO") {
              navigate("/coming-soon");
            } else if (limitedTripLengthStateCode) {
              // Go to dates
              navigate(-1);
            } else {
              navigate("/travelers");
            }
          }
        }}
        showDisclaimer
        submittable={!!state.valid}
      >
        <Autocomplete
          onChange={(value) => {
            setState({ valid: !!value, value });
          }}
          placeholder={t("form.inputPlaceholder")}
        />

        <TalkingHead show>
          {residencyStateInDestinations
            ? t("talkingHead.residencyStateInDestinations")
            : t("talkingHead.default")}
        </TalkingHead>

        <LimitedTripLengthPopup stateCode={limitedTripLengthStateCode} />
      </Form>
    </>
  );
}
