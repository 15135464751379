import { styled } from "@mui/material/styles";
import AppStoresQrCodeAsset from "assets/images/qr-codes/app-stores.svg";

export const AppStoresQrCode = styled(AppStoresQrCodeAsset, {
  label: "app-stores-qr-code",
})`
  display: block;
  width: auto;
  height: ${({ theme }) => theme.spacing(15)};
`;

export const Container = styled("div", {
  label: "qr-code",
})`
  display: none;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
    margin-right: ${({ theme }) => theme.spacing(3)};
    text-align: center;
  }
`;

export const Title = styled("h3", {
  label: "title",
})`
  color: ${({ theme }) => theme.palette.color.gray[10]};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.405;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
