import { getCacheKey } from ".";

const CACHE: Record<string, Intl.NumberFormat> = {};

export interface CurrencyParts extends DecimalParts {
  currency: string;
}

export interface DecimalParts {
  decimalSeparator: string;
  fraction: string;
  groupSeparator: string;
  integer: string;
}

export interface NumberFormatterOptions<
  GetParts extends boolean,
  Style extends "decimal" | "currency",
> extends Omit<Intl.NumberFormatOptions, "style" | "unit" | "unitDisplay"> {
  fractionDigits?: number | null;
  getParts?: GetParts;
  locale?: string;
  style?: Style;
}

export function numberFormatter<
  GetParts extends boolean = false,
  Style extends "decimal" | "currency" = "decimal",
>(
  value: number,
  {
    fractionDigits = null,
    getParts = false as GetParts,
    locale = "en-US",
    style = "decimal" as Style,
    ...publicOptions
  }: NumberFormatterOptions<GetParts, Style> = {},
) {
  const privateOptions =
    style === "currency"
      ? { currency: "USD", currencyDisplay: "narrowSymbol" }
      : {};

  if (fractionDigits !== null) {
    publicOptions.maximumFractionDigits = fractionDigits;
    publicOptions.minimumFractionDigits = fractionDigits;
  }

  const options = { ...privateOptions, ...publicOptions, style };
  const cacheKey = getCacheKey(locale, options);

  if (!CACHE[cacheKey]) {
    CACHE[cacheKey] = new Intl.NumberFormat(locale, options);
  }

  let result: string | Record<string, string>;

  if (!getParts) {
    result = CACHE[cacheKey].format(value);
  } else {
    const parts = CACHE[cacheKey].formatToParts(value);

    result = {};

    for (const part of parts) {
      if (part.type === "fraction" || part.type === "integer") {
        result[part.type] = `${result[part.type] ?? ""}${part.value}`;
      } else if (part.type === "decimal" || part.type === "group") {
        result[`${part.type}Separator`] = part.value;
      } else if (style === "currency" && part.type === "currency") {
        result[part.type] = part.value;
      }
    }
  }

  return result as GetParts extends false
    ? string
    : Style extends "currency"
      ? CurrencyParts
      : DecimalParts;
}
