import { autocompleteClasses } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { Autocomplete } from "components/autocomplete";
import { type AdvisorTraveler } from "utils/api/advisor";

export const Component = styled(
  Autocomplete<AdvisorTraveler, false, true, true>,
  { label: "autocomplete-input" },
)`
  flex: 1;
  position: static;

  &:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  .${autocompleteClasses.paper} {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;
