import { styled } from "@mui/material/styles";

export const Row = styled("div", {
  label: "row",
})`
  display: flex;
  margin: ${({ theme }) => theme.spacing(0, -0.5)};

  & + & {
    margin-top: ${({ theme }) => theme.spacing(2.5)};
  }
`;
