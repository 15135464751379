import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FullAddressFields } from "components/address-fields/full";
import { SimpleAddressFields } from "components/address-fields/simple";
import {
  getAddressFieldsFromResidency,
  getResidencyFromAddressFields,
  getAddressFieldsFromGooglePlacesAddress,
  type AddressFields,
} from "components/address-fields/utils";
import { useDispatch } from "hooks/use-dispatch";
import { selectResidency } from "store/quote/selectors";
import {
  setMainTravelerResidencyThunk,
  setMainTravelerStateThunk,
} from "store/quote/thunks/steps";
import { type Residency } from "types/quote-types";
import { getExperimentVariation } from "utils/experiments";
import {
  getGooglePlacesAddress,
  isGooglePlacesAddressComplete,
} from "utils/google-places";
import {
  CollapsableSection,
  type CollapsableSectionRef,
  type CollapsableSectionProps,
} from "../collapsable-section";
import { useAddressTracking, useTrackAddressSubmit } from "../tracking";
import { AddressForm, Content, Lead } from "./styles";

export interface AddressSectionProps
  extends Pick<
    CollapsableSectionProps,
    "active" | "completed" | "disabled" | "onActivate"
  > {
  onChange: (complete: boolean, isFullAddress?: boolean) => void;
}

export function AddressSection({
  active,
  onChange,
  ...props
}: AddressSectionProps) {
  const dispatch = useDispatch();
  const residency = useSelector(selectResidency);
  const isFullAddressActive =
    getExperimentVariation("fullAddress") === "active";

  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation("screens", {
    keyPrefix: "checkout.sections.address",
  });

  const trackAddressSubmit = useTrackAddressSubmit();

  const collapsableSectionRef = useRef<CollapsableSectionRef | null>(null);
  const [addressFields, setAddressFields] = useState(() =>
    getAddressFieldsFromResidency(residency),
  );

  const fullAddress =
    (!!location.state?.fullAddress || !!isFullAddressActive) ?? false;

  useEffect(() => {
    collapsableSectionRef.current?.updateContentHeight();
  }, [fullAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  useAddressTracking(active, fullAddress);

  function clearAddress() {
    if (residency?.areaLevel1) {
      dispatch(setMainTravelerStateThunk(residency.areaLevel1));
    }
    setAddressFields(null);
  }

  const submitAddress = async (address: AddressFields | null) => {
    if (!address) {
      clearAddress();
      onChange(false);
      return;
    }

    let nextResidency: Residency | null = null;

    if (fullAddress) {
      nextResidency = getResidencyFromAddressFields(address);
    } else {
      const googlePlacesAddress = address.googlePlacesId
        ? await getGooglePlacesAddress(address.googlePlacesId, {
            useSessionToken: true,
          })
        : {};

      const googlePlacesAddressIsComplete =
        isGooglePlacesAddressComplete(googlePlacesAddress);

      const nextAddressFields = getAddressFieldsFromGooglePlacesAddress(
        googlePlacesAddress,
        {
          apartment: address.apartment,
          googlePlacesId: address.googlePlacesId,
          label: address.label,
        },
      );

      if (!googlePlacesAddressIsComplete) {
        setAddressFields(nextAddressFields);
        onChange(!!getResidencyFromAddressFields(nextAddressFields), true);
        navigate(location, { state: { ...location.state, fullAddress: true } });
        return;
      }

      nextResidency = getResidencyFromAddressFields(nextAddressFields);
    }

    if (!nextResidency) {
      clearAddress();
      onChange(false);
      return;
    }

    dispatch(setMainTravelerResidencyThunk(nextResidency));
    trackAddressSubmit(nextResidency);
    setAddressFields(getAddressFieldsFromResidency(nextResidency));
    onChange(true, fullAddress);
  };

  return (
    <CollapsableSection
      active={active}
      ref={collapsableSectionRef}
      {...props}
      title={t("title")}
    >
      <Content>
        <Lead>{t("lead")}</Lead>

        <AddressForm>
          {!fullAddress && (
            <SimpleAddressFields
              initialValue={addressFields}
              onChange={(address) => {
                void submitAddress(address);
              }}
              onFullAddressTriggerClick={() => {
                clearAddress();
                onChange(false);
                navigate(location, {
                  state: { ...location.state, fullAddress: true },
                });
              }}
              showApartmentField={false}
              stateRestriction={residency?.areaLevel1}
            />
          )}

          {fullAddress && (
            <FullAddressFields
              initialValue={addressFields}
              onChange={(address) => {
                void submitAddress(address);
              }}
              stateRestriction={residency?.areaLevel1}
            />
          )}
        </AddressForm>
      </Content>
    </CollapsableSection>
  );
}
