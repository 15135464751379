import { useTranslation } from "react-i18next";
import { useSelector } from "hooks/use-selector";
import {
  selectDestinations,
  selectTripCost,
  selectTripEndDate,
  selectTripStartDate,
} from "store/quote/selectors";
import { dateRangeFormatter } from "utils/formatters/date-range";
import { numberFormatter } from "utils/formatters/number";
import { Title } from "../title";
import { TopicList } from "../topic-list";
import { LocalTimezoneDate } from "utils/date";

export function TripDetails() {
  const destinations = useSelector(selectDestinations);
  const tripCost = useSelector(selectTripCost);
  const tripStartDate = useSelector(selectTripStartDate);
  const tripEndDate = useSelector(selectTripEndDate);

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.printLayout.tripDetails",
  });

  return (
    <>
      <Title>{t("title")}</Title>

      <TopicList
        items={[
          {
            title: t("dates.title"),
            description:
              tripStartDate && tripEndDate
                ? dateRangeFormatter(
                    new LocalTimezoneDate(tripStartDate),
                    new LocalTimezoneDate(tripEndDate),
                  )
                : "",
          },
          {
            title: t("destinations.title", {
              count: destinations?.length ?? 0,
            }),
            description: t("destinations.description", {
              count:
                destinations && destinations.length > 2
                  ? destinations.length - 2
                  : 0,
              destinations: destinations
                ?.slice(0, 2)
                .map(({ label }) => label)
                .join(", "),
            }),
          },
          {
            title: t("tripCost.title"),
            description: tripCost
              ? numberFormatter(tripCost, {
                  fractionDigits: 0,
                  style: "currency",
                })
              : "",
          },
        ]}
      />
    </>
  );
}
