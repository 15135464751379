import { type ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { type Coverage, type QuoteDto } from "types/quote-types";
import { type Quote } from "./initial-state";
import { updateAdvisorEmail } from "./thunks/general";
import {
  calculateQuotePrice,
  getQuote,
  getQuoteFromHash,
} from "./thunks/quote";

type ExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<Quote>>) => void;

export const extraReducers: ExtraReducers = (builder) => {
  builder.addCase(getQuote.pending, (state) => {
    state.quote.status = "pending";
  });
  builder.addCase(getQuote.fulfilled, (state, { payload }) => {
    const coverages = payload.coverages.reduce<Coverage[]>(
      (accumulator, current) => {
        let isSelected: boolean;
        if (current.isBase) {
          isSelected = current.isSelected;
        } else {
          isSelected = !!state.quoteDto?.coverages.find(
            (coverage) => coverage.coverageType === current.coverageType,
          )?.isSelected;
        }

        const coverageWithUpdatedSelectionValue = {
          ...current,
          isSelected,
        };

        return [...accumulator, coverageWithUpdatedSelectionValue];
      },
      [],
    );

    const quoteEditableData: Partial<QuoteDto> = {
      coverages,
      tripCost: payload.tripCost,
      carDatesDto: state.quoteDto?.carDatesDto ?? [],
      initialDepositDate: state.quoteDto?.initialDepositDate,
    };

    state.quote.status = "fulfilled";

    if (state.reviewAndEdit === "editing" || !state.policyCodeHash) {
      state.policyCodeHash = payload.policyCodeHash;
    }

    if (state.reviewAndEdit) {
      state.reviewAndEdit = null;
    }

    state.quoteDto = {
      ...payload,
      initialTripCost: payload.tripCost,
      policyCodeHash: state.policyCodeHash,
      travellers: state.travelers ?? [],
      ...quoteEditableData,
    };
  });
  builder.addCase(getQuoteFromHash.pending, (state) => {
    state.quoteFromHash.status = "pending";
  });
  builder.addCase(
    getQuoteFromHash.fulfilled,
    (state, { payload: { quote, destinationsGoogleData } }) => {
      state.advisorEmail = quote.advisorEmail;
      state.destinationsGoogleData = destinationsGoogleData;

      state.tripStartDate = quote.tripStartDate;
      state.tripEndDate = quote.tripEndDate;
      state.destinations = quote.destinations;
      state.travelers = quote.travellers;
      state.residency = quote.residency;

      const firstTraveler = quote.travellers[0];
      state.dateOfBirth = firstTraveler.dateOfBirth;
      state.name = {
        firstName: firstTraveler.firstName,
        lastName: firstTraveler.lastName,
      };
      state.quoteDto = quote;

      state.quoteFromHash.status = "fulfilled";
    },
  );

  builder.addCase(calculateQuotePrice.pending, (state) => {
    state.calculate.status = "pending";
  });

  builder.addCase(calculateQuotePrice.fulfilled, (state, { payload }) => {
    state.calculate.status = "fulfilled";
    state.coveragesPrices = payload.coveragesPrice;
    state.isTestUser = !!payload.isTest;
    state.totalPrice = payload.totalPrice;
    state.policyCodeHash = payload.policyCodeHash ?? state.policyCodeHash;
  });

  builder.addCase(calculateQuotePrice.rejected, (state) => {
    state.calculate.status = "rejected";
  });

  builder.addCase(updateAdvisorEmail.fulfilled, (state, { payload }) => {
    if (payload) {
      state.advisorEmail = payload;
    }
  });
};
