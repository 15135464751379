import { useEffect, useMemo, useRef } from "react";
import usePlacesAutocompleteHook from "use-places-autocomplete";
import {
  UNSUPPORTED_COUNTRIES,
  UNSUPPORTED_PLACE_IDS,
  HIDDEN_PLACE_IDS,
} from "settings";
import {
  type DestinationGoogleData,
  type PlaceDetails,
} from "types/quote-types";
import { loadScript } from "utils/load-script";
import { type Place } from "utils/place";
import { mixpanelTrack } from "utils/tracking/mixpanel";

const MAX_FORBIDDEN_DESTINATION_INDEX = 1;

export interface AutocompletePlace extends Place {
  description: string;
  disabled?: boolean;
}

export function parseSelectedPlaces(
  destinations: Array<PlaceDetails | null> | null,
  destinationsGoogleData?: DestinationGoogleData[],
) {
  if (!destinations) {
    return [];
  }

  return destinations.reduce<Place[]>((result, destination, index) => {
    if (destination) {
      result.push({
        country: destination.country,
        label: destination.label,
        placeId: destination.googleId,
        terms:
          destination.terms ?? destinationsGoogleData?.[index]?.terms ?? [],
        types:
          destination.types ?? destinationsGoogleData?.[index]?.types ?? [],
      });
    }

    return result;
  }, []);
}

export function selectedPlaceIdsAreEqual(
  a: Array<string | null>,
  b: Array<string | null>,
) {
  if (a.length !== b.length) {
    return false;
  }

  for (let index = 0; index < a.length; index++) {
    if (a[index] !== b[index]) {
      return false;
    }
  }

  return true;
}

export function useGooglePlacesAutocomplete(
  selectedPlaces: Place[],
  sessionToken?: google.maps.places.AutocompleteSessionToken,
) {
  const { clearSuggestions, init, setValue, suggestions, value, ready } =
    usePlacesAutocompleteHook({
      debounce: 300,
      initOnMount: false,
      requestOptions: {
        language: "en",
        sessionToken,
        types: ["(regions)"],
      },
    });

  const selectedPlacesRef = useRef(selectedPlaces);
  selectedPlacesRef.current = selectedPlaces;

  const options = useMemo<AutocompletePlace[]>(() => {
    const result: AutocompletePlace[] = [];
    const suggestionsLength = suggestions.data.length;

    for (let index = 0; index < suggestionsLength; index++) {
      const {
        description,
        place_id: placeId,
        terms,
        types,
      } = suggestions.data[index];

      if (HIDDEN_PLACE_IDS.includes(placeId)) {
        continue;
      }

      const country = terms[terms.length - 1].value.toUpperCase();

      if (
        UNSUPPORTED_COUNTRIES.includes(country) ||
        UNSUPPORTED_PLACE_IDS.includes(placeId)
      ) {
        if (
          index <= MAX_FORBIDDEN_DESTINATION_INDEX &&
          (suggestionsLength === 1 || value.length >= 4)
        ) {
          result.push({ description, disabled: true, label: "", placeId });
        }

        continue;
      }

      const placeIsAlreadySelected = selectedPlacesRef.current.some(
        (selectedPlace) => selectedPlace.placeId === placeId,
      );

      if (placeIsAlreadySelected) {
        continue;
      }

      result.push({
        description,
        label: terms[0].value,
        placeId,
        terms,
        types,
      });
    }

    return result;
  }, [suggestions.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const properties: Record<string, string> = {};
    let hasForbiddenDestinations = false;

    for (let index = 0; index <= MAX_FORBIDDEN_DESTINATION_INDEX; index++) {
      if (options[index]?.disabled) {
        properties[`destination_${index + 1}`] = options[index].description;
        hasForbiddenDestinations = true;
      }
    }

    if (hasForbiddenDestinations) {
      properties.input = value;
      mixpanelTrack("shown_forbidden_destinations", properties);
    }
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    void loadScript("googlePlacesApi").then(() => {
      init();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    clear: clearSuggestions,
    options,
    query: value,
    ready,
    setQuery: setValue,
  };
}
