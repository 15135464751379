import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "field",
})`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const Label = styled(InputLabel, {
  label: "label",
})`
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  max-width: 100%;
  padding-left: ${({ theme }) => theme.spacing(1.5)};
  pointer-events: auto;
  position: static;
  transform: none;
`;
