import { useTranslation } from "react-i18next";
import { type AutocompleteProps } from "components/autocomplete";
import { useAutocompleteDebounce } from "hooks/use-autocomplete-debounce";
import { type NameInputProps } from "../name-field";
import { type AdvisorTraveler, getAdvisorTravelers } from "utils/api/advisor";
import { calculateAge } from "utils/birthdate";

import { Component } from "./styles";

export interface AutocompleteInputProps
  extends Omit<
    AutocompleteProps<AdvisorTraveler, false, true, true>,
    "InputProps" | "inputValue" | "onInputChange" | "options"
  > {
  field: "firstName" | "lastName";
  InputProps: NameInputProps;
}

export function AutocompleteInput({
  field,
  InputProps: { onChange: InputPropsOnChange, ...InputProps },
  ...props
}: AutocompleteInputProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "personalDataForm.fields.autocomplete",
  });

  const { options, setQuery } = useAutocompleteDebounce(
    async (query) => await getAdvisorTravelers(query),
    {
      cacheName: `faye:advisor_travelers`,
      minQueryLength: 3,
      debounceInterval: 250,
    },
  );

  return (
    <Component
      {...props}
      autoSelect={false}
      disableClearable
      disablePortal
      freeSolo
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : option[field];
      }}
      InputProps={InputProps}
      onInputChange={(_, value) => {
        InputPropsOnChange({ target: { value } });
        setQuery(value);
      }}
      options={options ?? []}
      renderOption={(props, option, state) => {
        return (
          <li {...props} key={state.index}>
            {t("optionLabel", {
              age: calculateAge(option.dateOfBirth),
              context: option.policy === "offer" ? "quote" : "policy",
              destination:
                option.destinations[0].label +
                (option.destinations.length > 1 ? "+" : ""),
              email: option.email,
              name: `${option.firstName} ${option.lastName}`.trim(),
            }).trim()}
          </li>
        );
      }}
      inputValue={InputProps.value}
    />
  );
}
