import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { getDateFirstMinute, getDateLastMinute } from "legacy/utils/dates";
import {
  type CarDatesDto,
  type DestinationGoogleData,
  type Name,
  type PlaceDetails,
  type Residency,
  type Traveler,
} from "types/quote-types";
import { type QuotePaymentResponse } from "utils/api/quote";
import { extraReducers } from "./extra-reducers";
import { type Quote, initialState } from "./initial-state";

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  extraReducers,
  reducers: {
    setDestinationsGoogleData: (
      state,
      {
        payload: destinationGooglePlaceResult,
      }: { payload: DestinationGoogleData[] },
    ) => {
      state.destinationsGoogleData = destinationGooglePlaceResult;
    },

    setDates: (
      state,
      {
        payload: { dates },
      }: {
        payload: {
          dates: {
            tripStartDate: string;
            tripEndDate: string;
          };
        };
      },
    ) => {
      state.tripStartDate = dates.tripStartDate;
      state.tripEndDate = dates.tripEndDate;
    },

    setDestinations: (
      state,
      {
        payload: { destinations },
      }: {
        payload: {
          destinations: PlaceDetails[];
        };
      },
    ) => {
      state.destinations = destinations;
    },

    setInitialDepositDate: (state, { payload }: { payload: string }) => {
      if (state.quoteDto) {
        state.quoteDto.initialDepositDate = payload;
      }
    },

    setMainTraveler: (
      state,
      {
        payload: { dateOfBirth, firstName, lastName, email },
      }: {
        payload: {
          dateOfBirth: string;
          firstName: string;
          lastName: string;
          email?: string;
        };
      },
    ) => {
      state.dateOfBirth = dateOfBirth;
      state.email = email;
      state.name = { firstName, lastName };
    },

    setMainTravelerEmail: (
      state,
      {
        payload: email,
      }: {
        payload: string | null;
      },
    ) => {
      const travelers = state.travelers?.map(
        ({ email: _email, ...traveler }) => ({
          ...(email !== null && { email }),
          ...traveler,
        }),
      );

      if (email !== null) {
        state.email = email;
      } else {
        delete state.email;
      }

      state.travelers = travelers;

      if (state.quoteDto && travelers) {
        state.quoteDto.travellers = travelers;
      }
    },

    setMainTravelerPersonalInformation: (
      state,
      {
        payload: { dateOfBirth, email, firstName, lastName },
      }: {
        payload: {
          dateOfBirth: string;
          email: string;
          firstName: string;
          lastName: string;
        };
      },
    ) => {
      state.dateOfBirth = dateOfBirth;
      state.email = email;
      state.name = { firstName, lastName };

      const traveler = {
        dateOfBirth,
        email,
        firstName,
        lastName,
        mainTraveller: true,
      };

      if (state.travelers) {
        state.travelers[0] = traveler;
      } else {
        state.travelers = [traveler];
      }
    },

    setTravelers: (
      state,
      {
        payload,
      }: {
        payload: Traveler[];
      },
    ) => {
      state.travelers = payload;
    },

    updateQuoteCoverageIsSelected: (
      state,
      {
        payload: { isSelected, coverageIndex },
      }: { payload: { isSelected: boolean; coverageIndex: number } },
    ) => {
      if (state.quoteDto) {
        state.quoteDto.coverages[coverageIndex].isSelected = isSelected;
        state.quoteDto.lastClickedCoverageType =
          state.quoteDto.coverages[coverageIndex].coverageType;
      }
    },

    setResidency: (state, { payload }: { payload: Residency }) => {
      state.residency = payload;
    },

    addTraveler: (
      state,
      {
        payload: { birthdate, email, name },
      }: {
        payload: {
          birthdate: string;
          email: string;
          name: Name;
        };
      },
    ) => {
      const traveler = {
        dateOfBirth: birthdate,
        email,
        firstName: name.firstName,
        lastName: name.lastName,
        mainTraveller: false,
      };

      if (state.travelers) {
        state.travelers.push(traveler);
      } else {
        state.travelers = [traveler];
      }
    },

    updateTraveler: (
      state,
      {
        payload: { birthdate, email, index, name },
      }: {
        payload: {
          birthdate: string;
          email: string;
          index: number;
          name: Name;
        };
      },
    ) => {
      if (state.travelers?.[index]) {
        state.travelers[index] = {
          dateOfBirth: birthdate,
          email,
          firstName: name.firstName,
          lastName: name.lastName,
          mainTraveller: false,
        };
      }
    },

    updateCarDetails: (
      state,
      { payload }: { payload: { carsCount: number; dates: CarDatesDto[] } },
    ) => {
      if (state.quoteDto) {
        state.quoteDto.noOfCars = payload.carsCount;
        state.quoteDto.carDatesDto = payload.dates.map(
          ({ startDate, endDate }) => ({ startDate, endDate }),
        );
      }
    },

    updateTripCost: (state, { payload }: { payload: number }) => {
      if (state.quoteDto) {
        state.quoteDto.tripCost = payload;
      }
    },

    setDirectOfferDetailsFromUrlParams: (
      state,
      {
        payload: { email, isRemarketing, policyCodeHash },
      }: {
        payload: {
          email?: string;
          isRemarketing: boolean;
          policyCodeHash: string;
        };
      },
    ) => {
      state.email = email;
      state.isDirectOffer = true;
      state.isRemarketing = isRemarketing;
      state.policyCodeHash = policyCodeHash;
    },

    setEffectivePolicyDates: (state) => {
      const endDate = dayjs(state.tripEndDate).startOf("day");

      const effectiveStartDate = getDateFirstMinute(dayjs().add(1, "day"));
      const effectiveEndDate = getDateLastMinute(endDate);

      state.effectiveStartDate = effectiveStartDate.format();
      state.effectiveEndDate = effectiveEndDate.format();
    },

    clearPolicyCodeHash: (state) => {
      state.policyCodeHash = null;
    },

    setRegularQuote: (state) => {
      // Not a direct offer
      state.isDirectOffer = false;
    },

    resetQuoteStatus: (state) => {
      state.quote.status = null;
      state.quoteFromHash.status = null;
    },

    setDisclaimerPdfUrl: (
      state,
      {
        payload,
      }: {
        payload: string;
      },
    ) => {
      state.disclaimerPdfUrl = payload;
    },

    setReviewAndEdit: (
      state,
      { payload }: { payload: Quote["reviewAndEdit"] },
    ) => {
      // Set to "editing" only if it is currently set to "reviewing"
      if (payload !== "editing" || state.reviewAndEdit === "reviewing") {
        state.reviewAndEdit = payload;
      }
    },

    setPolicyData: (state, { payload }: { payload: QuotePaymentResponse }) => {
      state.policyUrl = payload.policyUrl;
      state.receiptUrl = payload.receiptUrl;
      state.userPolicy = payload.userPolicy;
    },
  },
});

export const {
  addTraveler,
  clearPolicyCodeHash,
  resetQuoteStatus,
  setDates,
  setDestinations,
  setDestinationsGoogleData,
  setDirectOfferDetailsFromUrlParams,
  setDisclaimerPdfUrl,
  setEffectivePolicyDates,
  setInitialDepositDate,
  setMainTraveler,
  setMainTravelerEmail,
  setMainTravelerPersonalInformation,
  setPolicyData,
  setRegularQuote,
  setResidency,
  setReviewAndEdit,
  setTravelers,
  updateCarDetails,
  updateQuoteCoverageIsSelected,
  updateTraveler,
  updateTripCost,
} = quoteSlice.actions;

export default quoteSlice.reducer;
