import { styled } from "@mui/material/styles";
import { Input as InputComponent } from "components/input";

export const Input = styled(InputComponent, {
  label: "input",
})`
  flex: 3;

  &:last-child {
    flex: 4;
  }
`;
