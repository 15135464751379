import { styled } from "@mui/material/styles";
import PadlockAsset from "assets/images/icons/padlock.svg";
import PoweredByStripeAsset from "assets/images/powered-by-stripe.svg";
import { StripeField } from "./stripe-field";

export const Container = styled("form", {
  label: "credit-card",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing(0, -1)};
`;

export const ExtraFields = styled("div", {
  label: "extra-fields",
})`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(5)};
  width: 100%;
`;

export const MainField = styled(StripeField, {
  label: "main-field",
})`
  flex: 0 0 100%;
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const SecondaryField = styled(StripeField, {
  label: "secondary-field",
})`
  flex: 0 0 50%;
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const Padlock = styled(PadlockAsset, {
  label: "padlock",
})`
  fill: ${({ theme }) => theme.palette.color.primary.main};
  margin-left: ${({ theme }) => theme.spacing(1.5)};
  width: auto;
  height: ${({ theme }) => theme.spacing(2)};
`;

export const PoweredByStripe = styled(PoweredByStripeAsset, {
  label: "powered-by-stripe",
})`
  fill: ${({ theme }) => theme.palette.color.gray[70]};
  margin-left: auto;
  width: auto;
  height: ${({ theme }) => theme.spacing(1.5)};
`;
