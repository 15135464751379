import { Fragment } from "react";
import { Container, Description, Row, Title } from "./styles";

export interface TopicListProps {
  items: Array<{ title: string; description: string }>;
  template?: "details" | "personalDetails" | "plain" | "standard";
}

export function TopicList({ items, template = "standard" }: TopicListProps) {
  const ItemRow =
    template === "details" || template === "personalDetails" ? Fragment : Row;

  return (
    <Container className={template}>
      {items.map(({ title, description }, index) => (
        <ItemRow key={`${title}-${index}`}>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </ItemRow>
      ))}
    </Container>
  );
}
