import Cookies from "js-cookie";
import { EXPERIMENTS } from "settings";

export type Experiments = typeof EXPERIMENTS;

export type ExperimentName = keyof Experiments;

export type Variations<Experiment extends ExperimentName> =
  Experiments[Experiment]["variations"];

export type VariationKey<Experiment extends ExperimentName> =
  keyof Variations<Experiment>;

export type VariationName<Experiment extends ExperimentName> =
  Variations<Experiment>[VariationKey<Experiment>];

const CACHE: { [Key in ExperimentName]?: string } = {};

export function getAvailableExperiments() {
  Object.keys(EXPERIMENTS).forEach((key) => {
    const experiment = key as ExperimentName;
    if (typeof CACHE[experiment] === "undefined") {
      const cookieVariation = Cookies.get(EXPERIMENTS[experiment].key);

      if (typeof cookieVariation !== "undefined") {
        CACHE[experiment] = cookieVariation;
      }
    }
  });

  return Object.entries(CACHE).reduce<Record<string, string>>((acc, val) => {
    const key = EXPERIMENTS[val[0] as ExperimentName].key;
    acc[key] = val[1];
    return acc;
  }, {});
}

export function getExperimentVariation<Experiment extends ExperimentName>(
  experiment: Experiment,
) {
  if (typeof CACHE[experiment] === "undefined") {
    const cookieVariation = Cookies.get(EXPERIMENTS[experiment].key);

    if (typeof cookieVariation === "undefined") {
      Cookies.set(
        EXPERIMENTS[experiment].key,
        EXPERIMENTS[experiment].defaultVariation,
      );

      CACHE[experiment] = EXPERIMENTS[experiment].defaultVariation;
    } else {
      CACHE[experiment] = cookieVariation;
    }
  }

  return (EXPERIMENTS[experiment].variations as Variations<Experiment>)[
    CACHE[experiment] as VariationKey<Experiment>
  ];
}

export function setExperimentVariation<Experiment extends ExperimentName>(
  experiment: Experiment,
  variationName: VariationName<Experiment>,
) {
  for (const variation of Object.entries(EXPERIMENTS[experiment].variations)) {
    if (variation[1] === variationName) {
      Cookies.set(EXPERIMENTS[experiment].key, variation[0]);
      CACHE[experiment] = variation[0];
      return;
    }
  }
}
