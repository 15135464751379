import dayjs from "dayjs";
import { selectTripEndDate, selectTripStartDate } from "store/quote/selectors";
import { useSelector } from "hooks/use-selector";
import {
  MAX_LIMITED_TRIP_LENGTH_DURATION_DAYS,
  LIMITED_TRIP_LENGTH_STATES,
} from "settings";

export function useLimitedTripLengthIsValid() {
  const tripStartDate = useSelector(selectTripStartDate);
  const tripEndDate = useSelector(selectTripEndDate);

  if (!tripStartDate) {
    throw new Error("tripStartDate is empty.");
  } else if (!tripEndDate) {
    throw new Error("tripEndDate is empty.");
  }

  return (stateCode: string) => {
    if (!LIMITED_TRIP_LENGTH_STATES.includes(stateCode)) {
      return true;
    }

    const tripLength = dayjs(tripEndDate)
      .startOf("day")
      .diff(dayjs(tripStartDate).startOf("day"), "days");

    return tripLength <= MAX_LIMITED_TRIP_LENGTH_DURATION_DAYS;
  };
}
