import { captureException, captureMessage } from "@sentry/react";
import { type Context, type Scope } from "@sentry/types";
import { BadResponseError } from "utils/api";

export interface SentryCaptureErrorOptions {
  contexts?: Record<string, Context>;
  name?: string;
}

export function sentryCaptureError(
  error: unknown,
  { contexts, name }: SentryCaptureErrorOptions = {},
) {
  function captureContext(scope: Scope) {
    name && scope.setTransactionName(name);

    if (contexts) {
      for (const context of Object.keys(contexts)) {
        scope.setContext(context, contexts[context]);
      }
    }

    return scope;
  }

  if (error instanceof BadResponseError) {
    const errorMessage = error.message;
    error.message = `[${error.status}] ${error.message ?? ""}`.trim();
    captureException(error, captureContext);
    error.message = errorMessage;
  } else if (error instanceof Error) {
    captureException(error, captureContext);
  } else {
    const message = typeof error !== "string" ? JSON.stringify(error) : error;
    captureMessage(message, captureContext);
  }
}
