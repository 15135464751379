import debounce from "lodash-es/debounce";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "hooks/use-dispatch";
import { useOnPointer } from "hooks/use-on-pointer";
import { updateTripCost } from "store/quote";
import { calculateQuotePrice } from "store/quote/thunks/quote";
import { asyncQueue } from "utils/async-queue";
import { numberFormatter } from "utils/formatters/number";
import { OfferContext } from "../../../..";
import { useInputValue } from "./use-input-value";
import { getAdvisorId } from "utils/tracking/utm";
import {
  Container,
  IconButton,
  ErrorMessage,
  Field,
  MinusIcon,
  PlusIcon,
} from "./styles";

export interface InputProps {
  onError: (error: string | null) => void;
}

export function Input({ onError }: InputProps) {
  const dispatch = useDispatch();

  const onPointer = useOnPointer();
  const { t } = useTranslation("screens", { keyPrefix: "offer.tripCost" });
  const advisorId = getAdvisorId();

  const {
    decrementInputValue,
    incrementInputValue,
    maxTripCost,
    inputValue,
    inputValueIsGreaterThanMax,
    inputValueIsGreaterThanMin,
    inputValueIsLowerThanMax,
    inputValueIsLowerThanMin,
    setInputValue,
  } = useInputValue();

  const { invalidTripCost } = useContext(OfferContext);
  const [error, setError] = useState<string | null>(null);
  const hasChanged = useRef(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const debouncedSetError = useMemo(() => {
    return debounce((error: string | null) => {
      setError(error);
      onError(error);
    }, 300);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedUpdateTripCost = useMemo(() => {
    const queue = asyncQueue();

    return debounce((inputValue: number) => {
      void queue.push(async () => {
        dispatch(updateTripCost(inputValue));
        await dispatch(calculateQuotePrice());
      });
    }, 300);
  }, [dispatch]);

  useEffect(() => {
    if (hasChanged.current) {
      if (inputValueIsLowerThanMin) {
        debouncedUpdateTripCost.cancel(); // Cancel pending requests
        debouncedSetError(t("errorMessages.tooLow"));
      } else if (inputValueIsGreaterThanMax) {
        debouncedUpdateTripCost.cancel(); // Cancel pending requests
        debouncedSetError(
          t(advisorId ? "errorMessages.groupTooHigh" : "errorMessages.max", {
            amount: numberFormatter(maxTripCost, {
              fractionDigits: 0,
              style: "currency",
            }),
          }),
        );
      } else if (inputValue === null) {
        debouncedUpdateTripCost.cancel(); // Cancel pending requests
        debouncedSetError(t("errorMessages.empty"));
      } else {
        debouncedSetError.cancel(); // Cancel pending requests
        debouncedUpdateTripCost(inputValue);
        setError(null); // Clear error immediately
        onError(null);
      }
    }
  }, [
    debouncedSetError,
    debouncedUpdateTripCost,
    maxTripCost,
    advisorId,
    inputValue,
    inputValueIsGreaterThanMax,
    inputValueIsLowerThanMin,
    onError,
    t,
  ]);

  useEffect(() => {
    if (error ?? inputValue === null) {
      if (invalidTripCost?.value === false) {
        invalidTripCost.set(true);
      }
    } else if (invalidTripCost?.value === true) {
      invalidTripCost.set(false);
    }
  }, [error, inputValue, invalidTripCost]);

  return (
    <Container>
      <Field
        error={!!error}
        inputProps={{
          "aria-label": t("inputField.ariaLabel"),
          inputMode: "numeric",
          onChange: (event) => {
            hasChanged.current = true;
            setInputValue(event.currentTarget.value);
          },
          pattern: "[0-9]*", // Makes iOS show numeric keyboard
        }}
        inputRef={inputRef}
        name="trip_cost"
        startAdornment={
          <IconButton
            variant="outlined"
            aria-label={t("inputField.decreaseButton.ariaLabel")}
            disabled={inputValue === null || !inputValueIsGreaterThanMin}
            {...onPointer((event) => {
              hasChanged.current = true;
              decrementInputValue();

              if (event.pointerType === "mouse") {
                inputRef.current?.focus();
              }
            })}
            icon={<MinusIcon />}
          />
        }
        endAdornment={
          <IconButton
            variant="outlined"
            aria-label={t("inputField.increaseButton.ariaLabel")}
            disabled={inputValue !== null && !inputValueIsLowerThanMax}
            {...onPointer((event) => {
              hasChanged.current = true;
              incrementInputValue();

              if (event.pointerType === "mouse") {
                inputRef.current?.focus();
              }
            })}
            icon={<PlusIcon />}
          />
        }
        type="text"
        value={
          inputValue !== null
            ? numberFormatter(inputValue, {
                fractionDigits: 0,
                style: "currency",
              })
            : ""
        }
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
}
