import { styled } from "@mui/material/styles";
import { Button } from "components/button";
import { ProgressButton } from "components/button/progress";
import ArrowIconAsset from "assets/images/icons/arrow.svg";

export const DefaultComponent = styled(Button, {
  label: "popup-button",
})`
  display: flex;
  margin: ${({ theme }) => theme.spacing(3, "auto", 0)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const ProgressComponent = DefaultComponent.withComponent(ProgressButton);

export const ArrowIcon = styled(ArrowIconAsset, {
  label: "arrow-icon",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
`;
