import classNames from "classnames";
import { type ComponentPropsWithoutRef, type ReactNode } from "react";
import { Component, Content } from "./styles";

export interface LabelProps extends ComponentPropsWithoutRef<"label"> {
  required?: boolean;
  startAdornment?: ReactNode;
  error?: boolean;
}

export function Label({
  children,
  required,
  error,
  startAdornment = null,
  ...props
}: LabelProps) {
  return (
    <Component
      {...props}
      className={classNames(props.className, {
        "faye__label--error": error,
      })}
    >
      {startAdornment}

      <Content>
        {children}
        {required ? " *" : null}
      </Content>
    </Component>
  );
}
