import { styled } from "@mui/material/styles";
import { QRCodeSVG } from "qrcode.react";

export const Content = styled("div", {
  label: "content",
})`
  display: flex;
  line-height: 1.2;
  margin-top: ${({ theme }) => theme.spacing(3.5)};
`;

export const Lead = styled("p", {
  label: "lead",
})`
  font-size: 13px;
`;

export const QrCode = styled(QRCodeSVG, {
  label: "qr-code",
})`
  margin: ${({ theme }) => theme.spacing(-6, 0, 0, 1.25)};
  width: auto;
  height: ${({ theme }) => theme.spacing(6)};
`;

export const QrCodeContainer = styled("div", {
  label: "qr-code-container",
})`
  align-items: flex-end;
  display: flex;
  font-size: 11px;
  font-style: italic;
  font-weight: 700;
  justify-content: flex-end;
  line-height: 1;
  margin-left: auto;
`;

export const Title = styled("h1", {
  label: "title",
})`
  font-size: 20px;
  font-weight: 700;
`;
