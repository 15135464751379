import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "footnotes",
})`
  border: 1px solid ${({ theme }) => theme.palette.color.primary[100]};
  border-radius: ${({ theme }) => +theme.shape.borderRadius * 3}px;
  margin: ${({ theme }) => theme.spacing(2, 4)};
  padding: ${({ theme }) => theme.spacing(3)};
  position: relative;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: ${({ theme }) => theme.spacing(2)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: ${({ theme }) => theme.spacing(2, "-4vw")};
  }
`;

export const Content = styled("p", {
  label: "content",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 12px;
  font-weight: 300;
  line-height: 1.19;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(1.5)};

    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-top: ${({ theme }) => theme.spacing(4)};
    }
  }
`;
