import { type ReactNode } from "react";
import { BaseButton } from "components/button/base";
import { WEBSITE_URL } from "legacy/utils/constants";
import { Container, Logo } from "./styles";

export interface OfferLayoutPrintHeaderProps {
  children: ReactNode;
}

export function OfferLayoutPrintHeader({
  children,
}: OfferLayoutPrintHeaderProps) {
  return (
    <Container>
      <BaseButton href={WEBSITE_URL}>
        <Logo />
      </BaseButton>

      {children}
    </Container>
  );
}
