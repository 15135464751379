import { styled } from "@mui/material/styles";
import { InputGroup } from "components/input-group";

export const Container = styled(InputGroup, {
  label: "name-field",
})`
  position: relative;
  .name-field-label {
    color: ${({ theme }) => theme.palette.color.primary.main};
    font-size: 18px;
    font-weight: 500;
  }
`;
