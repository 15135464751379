import dayjs, { type Dayjs } from "dayjs";
import dayjsTzPlugin from "dayjs/plugin/timezone";
import dayjsUtcPlugin from "dayjs/plugin/utc";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Popup } from "components/popup";
import { useDispatch } from "hooks/use-dispatch";
import { useSelector } from "hooks/use-selector";
import { setInitialDepositDate } from "store/quote";
import { selectResidency } from "store/quote/selectors";
import {
  CANCEL_FOR_ANY_REASON_DAYS_LIMIT,
  CANCEL_FOR_ANY_REASON_DAYS_LIMIT_MISSOURI,
} from "settings";
import { mixpanelTrack } from "utils/tracking/mixpanel";
import { type ValidationResult } from "utils/validation";
import { trackCancelForAnyReasonDepositDate } from "../../../tracking";
import { useCoverage } from "../../../utils";
import { Item, type ItemProps } from "../item";
import { PopupButton } from "../popup-button";
import { PopupHeader } from "../popup-header";
import { PopupSection } from "../popup-section";
import { Caption, DateInput, PopupBody } from "./styles";

dayjs.extend(dayjsTzPlugin);
dayjs.extend(dayjsUtcPlugin);

export function CancelForAnyReason({
  coverageType,
  price,
  ...props
}: ItemProps) {
  const dispatch = useDispatch();
  const residency = useSelector(selectResidency);

  const { enable: enableCoverage } = useCoverage(coverageType);

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.addOns.cancelForAnyReason",
  });

  const [isEligible, setIsEligible] = useState<boolean | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  const [depositDate, setDepositDate] = useState({
    validation: null as ValidationResult | null,
    value: null as Dayjs | null,
  });

  const isValid = depositDate.validation?.status === "valid" && isEligible;

  function closePopup() {
    setShowPopup(false);

    if (isEligible && depositDate.value) {
      trackCancelForAnyReasonDepositDate(
        "clicked_add_cfar",
        depositDate.value.toDate(),
      );
    } else {
      mixpanelTrack("closed_cfar_popup");
    }
  }

  function openPopup() {
    setDepositDate({ validation: null, value: null });
    setIsEligible(null);
    setShowPopup(true);

    mixpanelTrack("cfar_popup_opened");
  }

  return (
    <>
      <Item
        {...props}
        CtaButtonProps={{ onClick: openPopup }}
        coverageType={coverageType}
        price={price}
      />

      <Popup onClose={closePopup} open={showPopup}>
        <PopupHeader onClose={closePopup} title={t("popup.title")} />

        <PopupBody>
          <PopupSection as="p">
            <Trans
              i18nKey={
                residency?.areaLevel1 === "MO"
                  ? "missouri.popup.description"
                  : "popup.description"
              }
              t={t}
            />
          </PopupSection>

          <PopupSection>
            <DateInput
              autoFocus
              onChange={(value) => {
                setDepositDate({
                  ...depositDate,
                  value: value?.tz("UTC", true) ?? null,
                });
              }}
              onValidation={(validation) => {
                setDepositDate({
                  ...depositDate,
                  validation,
                });
              }}
              validate={(value) => {
                setIsEligible(null);

                if (value === null) {
                  return true;
                } else if (!value.isValid()) {
                  return t("popup.errorMessages.invalid");
                }

                const today = dayjs.utc().startOf("day");
                const daysFromDeposit = today.diff(value, "days");

                const daysLimit =
                  residency?.areaLevel1 === "MO"
                    ? CANCEL_FOR_ANY_REASON_DAYS_LIMIT_MISSOURI
                    : CANCEL_FOR_ANY_REASON_DAYS_LIMIT;

                if (daysFromDeposit > daysLimit || daysFromDeposit < 0) {
                  setIsEligible(false);
                  trackCancelForAnyReasonDepositDate(
                    "saw_cfar_error",
                    value.toDate(),
                  );
                } else {
                  setIsEligible(true);
                }

                return true;
              }}
              value={depositDate.value}
              variant="simple"
            />

            {isEligible === false && (
              <Caption className="alert">
                {t("popup.errorMessages.ineligible")}
              </Caption>
            )}
            {isEligible === true && (
              <Caption className="warning">
                {t("popup.errorMessages.eligible")}
              </Caption>
            )}
          </PopupSection>

          <PopupButton
            showArrow={!!isValid}
            disabled={
              !depositDate.value || depositDate.validation?.status !== "valid"
            }
            onClick={() => {
              if (isEligible && depositDate.value) {
                const isoDate = depositDate.value.toISOString();
                dispatch(setInitialDepositDate(isoDate));
                enableCoverage();
              }

              closePopup();
            }}
          >
            {isValid ? t("popup.ctaAdd") : t("popup.ctaClose")}
          </PopupButton>
        </PopupBody>
      </Popup>
    </>
  );
}
