import { styled } from "@mui/material/styles";
import AccordionSummary, {
  accordionSummaryClasses,
  type AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import DropdownIconAsset from "assets/images/icons/dropdown.svg";

export const ExpandIcon = styled(DropdownIconAsset, {
  label: "expand-icon",
})`
  fill: currentColor;
  width: auto;
  height: ${({ theme }) => theme.spacing(1)};
  transform: rotateZ(0);
  transition: ${({ theme }) => theme.transitions.create("transform")};
  &.expanded {
    transform: rotateZ(-180deg);
  }
`;

export const Component = styled(
  (props: AccordionSummaryProps) => (
    <AccordionSummary disableRipple {...props} />
  ),
  {
    label: "title",
  },
)`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  color: ${({ theme }) => theme.palette.color.primary.main};
  padding: 0;

  &,
  .${accordionSummaryClasses.content} {
    cursor: default;
  }

  .${accordionSummaryClasses.content} {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 32px;
    font-weight: 500;
  }

  .${accordionSummaryClasses.expandIconWrapper} {
    color: ${({ theme }) => theme.palette.color.primary.main};
    padding: ${({ theme }) => theme.spacing(0, 1.25)};
    ${({ theme }) => theme.breakpoints.up("md")} {
      display: none;
    }
  }
`;
