import { useTranslation } from "react-i18next";
import { selectCarDates } from "store/quote/selectors";
import { dateRangeFormatter } from "utils/formatters/date-range";
import { useSelector } from "hooks/use-selector";
import { type AddOnStrings } from "../../utils";
import { Title } from "../title";
import { TopicList } from "../topic-list";
import { LocalTimezoneDate } from "utils/date";

export interface AddOnsProps {
  addOnStrings: AddOnStrings[];
}

export function AddOns({ addOnStrings }: AddOnsProps) {
  const carDates = useSelector(selectCarDates);

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.printLayout.addOns",
  });

  const itemsStrings = t("items", {
    carDates: carDates
      ? carDates
          .map(({ endDate, startDate }) =>
            dateRangeFormatter(
              new LocalTimezoneDate(startDate),
              new LocalTimezoneDate(endDate),
              { style: "short" },
            ),
          )
          .join(", ")
      : "",
    returnObjects: true,
  });

  return (
    <>
      <Title>{t("title")}</Title>

      <TopicList
        items={addOnStrings.map(({ coverageType, title }) => ({
          description: t("itemDescription"),
          title,
          ...(itemsStrings as Record<string, Partial<typeof itemsStrings>>)[
            coverageType
          ],
        }))}
        template="plain"
      />
    </>
  );
}
