import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  type PaymentMethod,
  type PaymentFormProps,
} from "components/payment-form";
import { useSelector } from "hooks/use-selector";
import { selectIsTestUser, selectUpdatedQuoteDto } from "store/quote/selectors";
import { type UserPolicy } from "types/quote-types";
import { getExperimentVariation } from "utils/experiments";
import {
  CollapsableSection,
  type CollapsableSectionRef,
  type CollapsableSectionProps,
} from "../collapsable-section";
import { usePaymentTracking } from "../tracking";
import { PaymentForm } from "./styles";

export interface PaymentSectionProps
  extends Pick<
      CollapsableSectionProps,
      "active" | "disabled" | "onActivate" | "showTitleBadge"
    >,
    Pick<
      PaymentFormProps,
      "creditCardFormId" | "onCreditCardChange" | "onPaymentChange"
    > {
  amendPaymentHash?: string;
  currentPaymentMethod: PaymentMethod;
  totalPrice?: number;
  userPolicy?: UserPolicy;
}

export function PaymentSection({
  active,
  amendPaymentHash,
  creditCardFormId,
  currentPaymentMethod,
  onCreditCardChange,
  onPaymentChange,
  totalPrice,
  userPolicy,
  ...props
}: PaymentSectionProps) {
  const isTestUser = useSelector(selectIsTestUser);
  const updatedQuoteDto = useSelector(selectUpdatedQuoteDto);

  const { t } = useTranslation("screens", {
    keyPrefix: "checkout.sections.payment",
  });

  const collapsableSectionRef = useRef<CollapsableSectionRef | null>(null);
  const [loaded, setLoaded] = useState(false);

  usePaymentTracking(
    active && loaded && !!updatedQuoteDto && totalPrice !== undefined,
  );

  if (!updatedQuoteDto || totalPrice === undefined) {
    return null;
  }

  return (
    <CollapsableSection
      active={active}
      ref={collapsableSectionRef}
      {...props}
      completed={false}
      loading={!loaded}
      title={t("title")}
    >
      <PaymentForm
        active={active}
        ButtonsProps={{ variant: "light" }}
        onLoad={() => {
          setLoaded(true);
          collapsableSectionRef.current?.updateContentHeight();
        }}
        creditCardExtraFields={
          getExperimentVariation("creditCardExtraFields") === "active"
        }
        creditCardFormId={creditCardFormId}
        currentPaymentMethod={currentPaymentMethod}
        onCreditCardChange={(...args) => {
          collapsableSectionRef.current?.updateContentHeight();
          onCreditCardChange?.(...args);
        }}
        onPaymentChange={onPaymentChange}
        quoteDto={updatedQuoteDto}
        {...(!!amendPaymentHash && {
          paymentLinkHash: amendPaymentHash,
          show: ["paypal"],
        })}
        testUser={!!isTestUser}
        totalPrice={totalPrice}
        userPolicy={userPolicy}
      />
    </CollapsableSection>
  );
}
