import { type FocusEvent, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { useTranslation } from "react-i18next";
import { type Place } from "utils/place";
import { Popper } from "components/autocomplete/base/popper";
import { Input } from "components/input";
import { type AutocompletePlace, useGooglePlacesAutocomplete } from "../utils";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import {
  Component,
  AlertIcon,
  Paper,
  Footer,
  ResultsFromGoogle,
} from "./styles";

export interface AutocompleteProps {
  name: string;
  selectedPlaces: Place[];
  onChange: (value: Place) => void;
  onBlur: (event: FocusEvent<HTMLInputElement>) => void;
}

export function Autocomplete({
  name,
  selectedPlaces,
  onBlur,
  onChange,
}: AutocompleteProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();
  const [value, setValue] = useState<Place | null>(null);
  const { options, query, ready, setQuery } = useGooglePlacesAutocomplete(
    selectedPlaces,
    sessionToken,
  );

  const { t } = useTranslation("screens", {
    keyPrefix: "destination.autocomplete",
  });

  const { getRootProps, getInputProps, getListboxProps, getOptionProps } =
    useAutocomplete({
      value,
      clearOnBlur: true,
      freeSolo: true,
      autoHighlight: true,
      options: options ?? [],
      filterOptions: (options) => options,
      getOptionDisabled: (option) => !!(option as AutocompletePlace).disabled,
      onInputChange: (_, value, reason) => {
        setQuery(reason === "input" ? value : "");
      },
      onChange: (_, value) => {
        if (typeof value !== "string" && value?.placeId) {
          onChange(value);
          setValue(null);
        }
      },
    });

  useEffect(() => {
    if (ready) {
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
    }
  }, [ready]);

  const showResults = options !== null && options.length > 0;

  return (
    <Component ref={containerRef} {...getRootProps()}>
      <Input
        inputProps={{
          ...getInputProps(),
          enterKeyHint: "go",
          value: query,
        }}
        name={name}
        placeholder={t("placeholder")}
        aria-label={t("placeholder")}
        autoCapitalize="none"
        autoCorrect="off"
        autoFocus={!isMobile}
        onBlur={onBlur}
        variant="simple"
      />

      <Popper
        open={!!options?.length}
        {...(containerRef.current && {
          anchorEl: containerRef.current,
          style: { width: containerRef.current.offsetWidth },
        })}
      >
        <Paper className={autocompleteClasses.paper}>
          {showResults && (
            <ul {...getListboxProps()} className={autocompleteClasses.listbox}>
              {options.map((option, index) => {
                const { className, ...optionProps } = getOptionProps({
                  option,
                  index,
                });
                return (
                  <li
                    {...optionProps}
                    key={option.placeId}
                    className={classNames(
                      className,
                      autocompleteClasses.option,
                      {
                        disabled: !!option.disabled,
                      },
                    )}
                  >
                    {option.description}
                    {option.disabled && (
                      <>
                        {` | ${t("unavailableOptionSuffix")}`}
                        <AlertIcon />
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}

          <Footer>{!!options?.length && <ResultsFromGoogle />}</Footer>
        </Paper>
      </Popper>
    </Component>
  );
}
