import { styled } from "@mui/material/styles";

export const Component = styled("div", {
  label: "input-group",
})`
  display: flex;

  .faye__input--error {
    z-index: 1;
  }

  .faye__input .faye__input__container {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }

  .faye__input:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Container = styled("div", {
  label: "input-group-container",
})`
  width: 100%;
  color: ${({ theme }) => theme.palette.color.gray[70]};
  &:focus-within > label:not(.faye__label--error) {
    color: ${({ theme }) => theme.palette.color.primary.main};
  }
`;
