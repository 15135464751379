import DividerComponent, { dividerClasses } from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "buttons",
})`
  &:not(.loaded) {
    height: 0;
    overflow: hidden;
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-wrap: nowrap;
  }
`;

export const Divider = styled(DividerComponent, {
  label: "divider",
})`
  color: ${({ theme }) => theme.palette.color.gray[70]};
  font-weight: 700;
  margin: ${({ theme }) => theme.spacing(4, 0)};

  .${dividerClasses.wrapper} {
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }

  &::before,
  &::after {
    border-color: ${({ theme }) => theme.palette.color.gray[70]};
  }
`;
