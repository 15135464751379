import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CtaBar } from "components/cta-bar";
import { useAdvisorProfile } from "hooks/use-advisor-profile";
import { useBreakpoint } from "hooks/use-breakpoint";
import { useOttUser } from "hooks/use-ott-user";
import {
  selectDestinations,
  selectEmail,
  selectFormattedTotalPrice,
  selectFullName,
  selectTripEndDate,
  selectTripStartDate,
} from "store/quote/selectors";
import { dateRangeFormatter } from "utils/formatters/date-range";
import { mixpanelTrack } from "utils/tracking/mixpanel";
import { useTrackCtaPressed } from "../tracking";
import { OfferContext } from "..";
import { LocalTimezoneDate } from "utils/date";
import { Button, ExternalIcon } from "./styles";

export function Cta() {
  const destinations = useSelector(selectDestinations);
  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const tripStartDate = useSelector(selectTripStartDate);
  const tripEndDate = useSelector(selectTripEndDate);
  const formattedTotalPrice = useSelector(selectFormattedTotalPrice);

  const navigate = useNavigate();

  const mdBreakpoint = useBreakpoint("md");

  const { t } = useTranslation("screens", {
    keyPrefix: "offer",
  });

  const { t: tQuoteEmail } = useTranslation("emails", {
    keyPrefix: "quote",
  });

  const advisorProfile = useAdvisorProfile();
  const ottUser = useOttUser("glinda");
  const trackCtaPressed = useTrackCtaPressed();

  const { invalidTripCost } = useContext(OfferContext);

  const emailHref = useMemo(() => {
    if (!ottUser) {
      return null;
    }

    const offerLinkUrl = new URL(window.location.href);
    offerLinkUrl.searchParams.append("utm_term", "mailto");

    const url = new URL(`mailto:${email}`);
    url.searchParams.append("subject", tQuoteEmail("subject"));

    if (advisorProfile && destinations && tripEndDate && tripStartDate) {
      url.searchParams.append(
        "body",
        tQuoteEmail("body", {
          advisorName:
            `${advisorProfile.firstName} ${advisorProfile.lastName}`.trim(),
          customerName: fullName || tQuoteEmail("defaultCustomerName"),
          dates: dateRangeFormatter(
            new LocalTimezoneDate(tripStartDate),
            new LocalTimezoneDate(tripEndDate),
          ),
          destination: tQuoteEmail("destination", {
            count: destinations.length,
            name: destinations[0].label,
          }),
          offerLink: offerLinkUrl.toString(),
        }),
      );
    }

    return url.toString().replace(/\+/g, "%20");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisorProfile, ottUser]);

  return (
    <CtaBar>
      {mdBreakpoint && emailHref && (
        <Button
          href={emailHref}
          onClick={() => {
            mixpanelTrack("clicked_email_quote", {
              location: "offer_page_glinda",
            });
          }}
          variant="outlined"
        >
          {t("ctaBar.email.label")}
          <ExternalIcon />
        </Button>
      )}
      <Button
        disabled={invalidTripCost?.value}
        onClick={() => {
          navigate("/checkout");
          trackCtaPressed();
        }}
      >
        {t("ctaBar.payment.label", { price: formattedTotalPrice })}
      </Button>
    </CtaBar>
  );
}
