import { type ComponentPropsWithoutRef, useState } from "react";
import { Popup, type PopupDataProps } from "./popup";
import {
  Container,
  Content,
  Title,
  Topic,
  TopicDescription,
  Trigger,
} from "./styles";

export interface ItemProps extends ComponentPropsWithoutRef<"div"> {
  popup:
    | null
    | (PopupDataProps & {
        trigger: string;
      });
  topics: Array<{
    description: string;
    title: string;
  }>;
}

export function Item({ popup, title, topics, ...props }: ItemProps) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <Container {...props}>
        <Title>{title}</Title>

        <Content>
          {topics.map((topic) => (
            <Topic key={topic.title}>
              <div>{topic.title}</div>

              <TopicDescription>{topic.description}</TopicDescription>
            </Topic>
          ))}

          {popup && (
            <Trigger
              onClick={() => {
                setShowPopup(true);
              }}
            >
              {popup.trigger}
            </Trigger>
          )}
        </Content>
      </Container>

      {popup && (
        <Popup
          content={popup.content}
          onClose={() => {
            setShowPopup(false);
          }}
          open={showPopup}
          title={popup.title}
        />
      )}
    </>
  );
}
