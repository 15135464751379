import axios, { type AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { SignJWT } from "jose";
import { sentryCaptureError } from "utils/tracking/sentry";

interface UserData {
  email: string;
  dataFields?: Record<string, unknown>;
}

interface TrackData extends UserData {
  eventName: string;
}

const CACHE: {
  accessToken: string | null;
  axiosInstance: AxiosInstance | null;
  email: string | null;
} = {
  accessToken: null,
  axiosInstance: null,
  email: null,
};

async function getAccessToken() {
  if (!CACHE.accessToken) {
    const secret = new TextEncoder().encode(
      import.meta.env.VITE_ITERABLE_JWT_SECRET,
    );

    CACHE.accessToken = await new SignJWT({ email: CACHE.email })
      .setProtectedHeader({ alg: "HS256" })
      .setIssuedAt()
      .setExpirationTime("1h")
      .sign(secret);
  }

  return CACHE.accessToken;
}

function getAxiosInstance(email: string) {
  if (CACHE.email !== email) {
    CACHE.accessToken = null; // Reset accessToken if email changes
    CACHE.email = email;
  }

  if (!CACHE.axiosInstance) {
    CACHE.axiosInstance = axios.create({
      baseURL: "https://api.iterable.com/api",
      headers: { "api-key": import.meta.env.VITE_ITERABLE_API_KEY },
    });

    CACHE.axiosInstance.interceptors.request.use(async (request) => {
      if (request.headers) {
        request.headers.authorization = `Bearer ${await getAccessToken()}`;
      }

      return request;
    });

    createAuthRefreshInterceptor(CACHE.axiosInstance, async () => {
      CACHE.accessToken = null;
    });
  }

  return CACHE.axiosInstance;
}

export function track(data: TrackData) {
  const axiosInstance = getAxiosInstance(data.email);

  axiosInstance("/events/track", { method: "POST", data }).catch((error) => {
    sentryCaptureError(error, {
      name: "Iterable_track_event_Error",
      contexts: { request: { ...data } },
    });
  });
}

export function updateUser(data: UserData) {
  const axiosInstance = getAxiosInstance(data.email);

  axiosInstance("/users/update", { method: "POST", data }).catch((error) => {
    sentryCaptureError(error, {
      name: "Iterable_user_update_Error",
      contexts: { request: { ...data } },
    });
  });
}
