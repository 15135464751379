import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "trip-cost",
})`
  margin: ${({ theme }) => theme.spacing(2, "auto", 0)};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 0;
  }
`;

export const Description = styled("p", {
  label: "description",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  margin: ${({ theme }) => theme.spacing(1, "auto", 0)};
  max-width: ${({ theme }) => theme.spacing(32)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 16px;
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;
