import { styled } from "@mui/material/styles";
import { PopupHeader as PopupHeaderComponent } from "components/popup/header";

export const Item = styled("div", {
  label: "item",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

export const ItemTitle = styled("h3", {
  label: "item-title",
})`
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const PopupHeader = styled(PopupHeaderComponent, {
  label: "popup-header",
})`
  && {
    padding-left: 0;
    text-align: left;
  }
`;
