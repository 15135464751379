import classNames from "classnames";
import {
  type CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEvent, useMeasure } from "react-use";
import { CtaBar } from "components/cta-bar";
import { type PaymentMethod } from "components/payment-form";
import { useDispatch } from "hooks/use-dispatch";
import { useSelector } from "hooks/use-selector";
import { setPolicyData } from "store/quote";
import {
  selectDestinations,
  selectEmail,
  selectFormattedTotalPrice,
  selectResidency,
  selectTotalPrice,
  selectTotalPriceParts,
  selectTravelers,
  selectTripEndDate,
  selectTripStartDate,
  selectUserPolicy,
} from "store/quote/selectors";
import {
  type PaymentAmendmentInfo,
  getPolicyPaymentAmendmentInfo,
} from "utils/api/policy";
import { type QuotePaymentResponse } from "utils/api/quote";
import { getExperimentVariation } from "utils/experiments";
import { type CurrencyParts, numberFormatter } from "utils/formatters/number";
import { AddressSection } from "./address-section";
import { DestinationSection } from "./destination-section";
import { EmailSection } from "./email-section";
import { PaymentSection } from "./payment-section";
import {
  BackArrowIcon,
  ArrowIcon,
  BackButton,
  CollapsableContent,
  Container,
  Content,
  CtaButton,
  DoneLottie,
  Title,
} from "./styles";
import { useTrackCtaPressed, useTrackPaymentMade } from "./tracking";

const PAYMENT_FORM_ID = "faye-screen-checkout-payment-form";

export function CheckoutScreen() {
  const dispatch = useDispatch();
  const destinations = useSelector(selectDestinations);
  const email = useSelector(selectEmail);
  const formattedTotalPrice = useSelector(selectFormattedTotalPrice);
  const residency = useSelector(selectResidency);
  const totalPrice = useSelector(selectTotalPrice);
  const totalPriceParts = useSelector(selectTotalPriceParts);
  const travelers = useSelector(selectTravelers);
  const tripStartDate = useSelector(selectTripStartDate);
  const tripEndDate = useSelector(selectTripEndDate);
  const userPolicy = useSelector(selectUserPolicy);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation("screens", {
    keyPrefix: "checkout",
  });

  const amendPaymentHash = searchParams.get("amend");
  const residencyIsSet = !!residency?.streetAndNumber;

  const collapsableContentRef = useRef<HTMLDivElement>(null);
  const [addressIsReady, setAddressIsReady] = useState(residencyIsSet);
  const [collapsedSectionHeight, setCollapsedSectionHeight] = useState(0);
  const [creditCardIsReady, setCreditCardIsReady] = useState(false);
  const [emailIsReady, setEmailIsReady] = useState(!!email);
  const [showEmailStep, setShowEmailStep] = useState(false);
  const [goBack, setGoBack] = useState(false);

  const [currentPaymentMethod, setCurrentPaymentMethod] =
    useState<PaymentMethod | null>(null);

  const [paymentAmendmentInfo, setPaymentAmendmentInfo] = useState<
    | (PaymentAmendmentInfo & {
        additionalChargeParts: CurrencyParts;
      })
    | null
  >(null);

  const [paymentResponse, setPaymentResponse] =
    useState<QuotePaymentResponse | null>(null);

  const trackCtaPressed = useTrackCtaPressed();
  const trackPaymentMade = useTrackPaymentMade();

  const [destinationSectionRef, { height: destinationSectionHeight }] =
    useMeasure<HTMLDivElement>();

  useEffect(() => {
    setShowEmailStep(!email || getExperimentVariation("shortOb") === "active");

    if (amendPaymentHash) {
      void getPolicyPaymentAmendmentInfo(amendPaymentHash).then(
        async (data) => {
          setPaymentAmendmentInfo({
            ...data,
            additionalChargeParts: numberFormatter(data.additionalCharge, {
              getParts: true,
              style: "currency",
            }),
          });
        },
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function updateCollapsedSectionHeight() {
    const collapsedSection = collapsableContentRef.current?.querySelector(
      ":scope > :not(.active)",
    );

    if (collapsedSection) {
      setCollapsedSectionHeight(collapsedSection.clientHeight + 1);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(updateCollapsedSectionHeight, []);
  useEvent("resize", updateCollapsedSectionHeight);

  useLayoutEffect(() => {
    if (goBack) {
      // This will repeat on every location change
      // until checkout page is unmounted
      navigate(-1);
    }
  }, [goBack, location, navigate]);

  const step: "address" | "email" | "payment" =
    location.state?.step ?? (amendPaymentHash ? "payment" : "address");

  function navigateToConfirmation(paymentResponse: QuotePaymentResponse) {
    if (currentPaymentMethod && paymentResponse) {
      trackPaymentMade(
        currentPaymentMethod,
        paymentResponse.userPolicy.uniqueUuid,
      );
      dispatch(setPolicyData(paymentResponse));
      navigate("/confirmation");
    }
  }

  if (amendPaymentHash && !paymentAmendmentInfo) {
    return null;
  }

  const success = currentPaymentMethod === "creditCard" && !!paymentResponse;
  const stepIndex = step === "email" ? 1 : step === "payment" ? 2 : 0;

  return (
    <>
      <Container
        style={
          {
            "--extra-mobile-height": !amendPaymentHash
              ? `${collapsedSectionHeight * stepIndex}px`
              : "0px",
            "--top-mobile-spacing": `${destinationSectionHeight}px`,
          } as CSSProperties
        }
      >
        {!amendPaymentHash && (
          <BackButton
            onClick={() => {
              setGoBack(true);
            }}
          >
            <BackArrowIcon />
            {t("payment.back")}
          </BackButton>
        )}

        <Title>
          {amendPaymentHash ? t("amend.title") : t("payment.title")}
        </Title>

        <Content>
          <CollapsableContent ref={collapsableContentRef}>
            {!amendPaymentHash && (
              <AddressSection
                active={step === "address"}
                completed={addressIsReady}
                disabled={!!currentPaymentMethod}
                onActivate={() => {
                  navigate(location, {
                    state: { ...location.state, step: "address" },
                  });
                }}
                onChange={(complete, isFullAddress) => {
                  setAddressIsReady(complete);
                  if (complete && !isFullAddress) {
                    navigate(location, {
                      state: {
                        ...location.state,
                        step: showEmailStep ? "email" : "payment",
                      },
                    });
                  }
                }}
              />
            )}

            {showEmailStep && !amendPaymentHash && (
              <EmailSection
                active={step === "email"}
                completed={emailIsReady}
                disabled={!!currentPaymentMethod || !addressIsReady}
                onActivate={() => {
                  setAddressIsReady(true);
                  navigate(location, {
                    state: { ...location.state, step: "email" },
                  });
                }}
                onChange={(complete) => {
                  setEmailIsReady(complete);
                }}
              />
            )}

            <PaymentSection
              active={step === "payment"}
              creditCardFormId={PAYMENT_FORM_ID}
              currentPaymentMethod={currentPaymentMethod}
              disabled={!addressIsReady || !emailIsReady}
              onActivate={() => {
                setAddressIsReady(true);
                navigate(location, {
                  state: { ...location.state, step: "payment" },
                });
              }}
              onCreditCardChange={(complete, error) => {
                if (!creditCardIsReady) {
                  if (complete && !error) {
                    setCreditCardIsReady(true);
                  }
                } else if (!complete || error) {
                  setCreditCardIsReady(false);
                }
              }}
              onPaymentChange={(method, data) => {
                if (method === null) {
                  setCurrentPaymentMethod(null);
                } else if (data?.status === "error") {
                  if (currentPaymentMethod) {
                    trackPaymentMade(currentPaymentMethod, "", data.message);
                  }
                  setCurrentPaymentMethod(null);
                } else if (data?.status === "request") {
                  setCurrentPaymentMethod(method);
                } else if (data?.status === "success") {
                  if (method === "creditCard") {
                    setPaymentResponse(data.response);
                  } else {
                    navigateToConfirmation(data.response);
                  }
                }
              }}
              showTitleBadge={!amendPaymentHash}
              {...(amendPaymentHash
                ? {
                    amendPaymentHash,
                    totalPrice: paymentAmendmentInfo?.additionalCharge,
                  }
                : {
                    totalPrice,
                    userPolicy,
                  })}
            />
          </CollapsableContent>

          {paymentAmendmentInfo ? (
            <DestinationSection
              amendPayment
              amountParts={paymentAmendmentInfo.additionalChargeParts}
              destinations={paymentAmendmentInfo.destinations}
              ref={destinationSectionRef}
              travelersCount={paymentAmendmentInfo.noOfTravelers}
              tripStartDate={paymentAmendmentInfo.tripStartDate}
              tripEndDate={paymentAmendmentInfo.tripEndsDate}
              userPolicyUniqueUuid={paymentAmendmentInfo.policyGroup}
            />
          ) : (
            <DestinationSection
              amountParts={totalPriceParts}
              destinations={destinations}
              ref={destinationSectionRef}
              travelersCount={travelers.length}
              tripStartDate={tripStartDate}
              tripEndDate={tripEndDate}
              userPolicyUniqueUuid={userPolicy?.uniqueUuid}
            />
          )}
        </Content>
      </Container>

      <CtaBar>
        <CtaButton
          className={classNames({ success })}
          disabled={
            step === "address"
              ? !addressIsReady
              : step === "email"
                ? !emailIsReady
                : !creditCardIsReady ||
                  (!!currentPaymentMethod &&
                    (currentPaymentMethod !== "creditCard" || !paymentResponse))
          }
          {...(step === "payment" && { form: PAYMENT_FORM_ID })}
          loading={currentPaymentMethod === "creditCard" && !paymentResponse}
          onClick={() => {
            if (step === "address") {
              navigate(location, {
                state: {
                  ...location.state,
                  step: showEmailStep ? "email" : "payment",
                },
              });
            } else if (step === "email") {
              navigate(location, {
                state: {
                  ...location.state,
                  step: addressIsReady ? "payment" : "address",
                },
              });
            }

            trackCtaPressed();
          }}
          type={step === "payment" ? "submit" : "button"}
        >
          {step !== "payment" ? (
            <>
              {t("cta.default")}
              <ArrowIcon />
            </>
          ) : (
            <>
              <DoneLottie
                className={classNames({ success })}
                onComplete={() => {
                  if (paymentResponse) {
                    navigateToConfirmation(paymentResponse);
                  }
                }}
                play={success}
                preload
              />
              {success
                ? t("cta.done")
                : t("cta.pay", { price: formattedTotalPrice })}
            </>
          )}
        </CtaButton>
      </CtaBar>
    </>
  );
}
