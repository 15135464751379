import { createAsyncThunk } from "@reduxjs/toolkit";
import { type RootState } from "store";
import {
  type DestinationGoogleData,
  type GooglePlace,
  type QuoteDto,
} from "types/quote-types";
import { getDestinationPlacesGoogleData } from "utils/place/destinations";
import { sentryCaptureError } from "utils/tracking/sentry";
import { getUtm, setUtm } from "utils/tracking/utm";
import { setDisclaimerPdfUrl, setEffectivePolicyDates } from "store/quote";
import { selectUpdatedQuoteDto } from "store/quote/selectors";
import {
  type QuotePricingResponse,
  calculateQuote,
  getQuoteDisclaimerPdfUrl,
  getQuote as apiGetQuote,
  getQuoteOffer,
} from "utils/api/quote";
import { loadScript } from "utils/load-script";
import {
  refreshMixpanelAdvisor,
  refreshMixpanelBaseProperties,
  refreshMixpanelUserProperties,
  refreshMixpanelUtm,
} from "utils/tracking/mixpanel";
import { updateAdvisorEmail } from "./general";

export const getQuote = createAsyncThunk<
  QuoteDto,
  void, // eslint-disable-line @typescript-eslint/no-invalid-void-type
  { rejectValue: { message: string }; state: RootState }
>("quote/getQuote", async (_, { getState, rejectWithValue }) => {
  const {
    destinations,
    quoteDto,
    residency,
    reviewAndEdit,
    travelers,
    tripEndDate,
    tripStartDate,
  } = getState().quote;

  if (
    !destinations?.length ||
    !residency?.areaLevel1 ||
    !travelers?.length ||
    !tripStartDate ||
    !tripEndDate
  ) {
    return rejectWithValue({ message: "Missing quote data" });
  }

  return {
    ...(await apiGetQuote(
      destinations.map(({ country }) => country),
      tripStartDate,
      tripEndDate,
      travelers.length,
      residency.areaLevel1,
    )),
    ...(reviewAndEdit && quoteDto && { tripCost: quoteDto.tripCost }),
  };
});

export const getQuoteFromHash = createAsyncThunk<
  { destinationsGoogleData: DestinationGoogleData[]; quote: QuoteDto },
  void, // eslint-disable-line @typescript-eslint/no-invalid-void-type
  { rejectValue: { message: string }; state: RootState }
>(
  "quote/getQuoteFromHash",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState();

    const email = state.quote.email;
    const policyCodeHash = state.quote.policyCodeHash;

    if (!policyCodeHash) {
      return rejectWithValue({ message: "Missing quote data" });
    }

    let quote: QuoteDto;

    try {
      quote = await getQuoteOffer(policyCodeHash, email);
    } catch (error) {
      sentryCaptureError(error, { name: "Quote_by_hash_error" });
      throw error;
    }

    await loadScript("googlePlacesApi");

    const destinationsGoogleData = await getDestinationPlacesGoogleData(
      quote.destinations as GooglePlace[],
    );

    let hasUtm = false;

    if (quote.utms?.length) {
      setUtm(
        quote.utms.reduce(
          (result, { key, value }) => ({ ...result, [key]: value }),
          {},
        ),
      );

      refreshMixpanelUtm();
      void refreshMixpanelAdvisor();
      refreshMixpanelBaseProperties();
      refreshMixpanelUserProperties();

      hasUtm = true;
    } else if (getUtm()) {
      hasUtm = true;
    }

    if (hasUtm && !quote.advisorEmail) {
      await dispatch(updateAdvisorEmail());
    }

    return {
      destinationsGoogleData,
      quote,
    };
  },
);

export const calculateQuotePrice = createAsyncThunk<
  QuotePricingResponse,
  string | undefined,
  { rejectValue: { message: string }; state: RootState }
>(
  "quote/calculateQuotePrice",
  async (previousPolicyCodeHash, { dispatch, getState }) => {
    dispatch(setEffectivePolicyDates());

    const state = getState();
    const updatedQuoteDto = selectUpdatedQuoteDto(state);

    const response = await calculateQuote(
      updatedQuoteDto,
      previousPolicyCodeHash,
    );

    dispatch(
      setDisclaimerPdfUrl(
        await getQuoteDisclaimerPdfUrl({
          coverages: updatedQuoteDto.coverages,
          destinations: updatedQuoteDto.destinations,
          residency: updatedQuoteDto.residency,
        }),
      ),
    );

    return response;
  },
);
