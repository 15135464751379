import Cookies from "js-cookie";

export interface OfflineConversions {
  fbclid?: string;
  fbp?: string;
  gclid?: string;
  msclid?: string;
  ttclid?: string;
  irclickid?: string;
}

const CACHE: { offlineConversions?: OfflineConversions } = {};

export function getOfflineConversions() {
  return CACHE.offlineConversions;
}

export function loadOfflineConversions() {
  const searchParams = new URLSearchParams(window.location.search);
  const result: OfflineConversions = {};

  const irclickid = Cookies.get("utm_irclickid");
  const fbp = Cookies.get("_fbp") || Cookies.get("utm_fbp");
  const msclid = searchParams.get("msclid") || Cookies.get("utm_msclid");
  const ttclid = searchParams.get("ttclid") || Cookies.get("utm_ttclid");

  const fbclid =
    searchParams.get("fbclid") ||
    Cookies.get("_fbc") ||
    Cookies.get("utm_fbclid");

  const gclid =
    searchParams.get("gclid") ||
    Cookies.get("_gcl_aw") ||
    Cookies.get("utm_gclid");

  if (fbclid) {
    result.fbclid = fbclid.split(".").slice(3).join(".");
  }

  if (fbp) {
    result.fbp = fbp.split(".").slice(2).join(".");
  }

  if (gclid) {
    result.gclid = gclid.split(".").slice(2).join(".");
  }

  if (msclid) {
    result.msclid = msclid;
  }

  if (ttclid) {
    result.ttclid = ttclid;
  }

  if (irclickid) {
    result.irclickid = irclickid;
  }

  CACHE.offlineConversions = result;
}
