import classNames from "classnames";
import { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CnnTravelSvg from "assets/images/media/cnn-travel.svg";
import ForbesSvg from "assets/images/media/forbes.svg";
import MarketWatchSvg from "assets/images/media/market-watch.svg";
import TechCrunchSvg from "assets/images/media/tech-crunch.svg";
import TravelWeeklySvg from "assets/images/media/travel-weekly.svg";
import UsaTodaySvg from "assets/images/media/usa-today.svg";
import WallStreetJournalSvg from "assets/images/media/wall-street-journal.svg";
import { PlainButton } from "components/button/plain";
import { Form } from "components/form";
import { ScreenHeader } from "components/screen-header";
import { useDispatch } from "hooks/use-dispatch";
import { useSelector } from "hooks/use-selector";
import { OnboardingLayoutContext } from "layouts/onboarding";
import {
  selectDestinationsGoogleData,
  selectDestinations,
} from "store/quote/selectors";
import { setDestinationThunk } from "store/quote/thunks/steps";
import {
  getDestinationPlacesGoogleData,
  getDestinationsPlaceDetails,
  isDomesticTrip,
  isIsraelTrip,
} from "utils/place/destinations";
import { getExperimentVariation } from "utils/experiments";
import { type Place } from "utils/place";
import { Autocomplete } from "./autocomplete";
import { MediaBar } from "./media-bar";
import { Taglist, Tag, ClearIcon, TalkingHead } from "./styles";
import { trackSubmit, useTracking } from "./tracking";
import { parseSelectedPlaces, selectedPlaceIdsAreEqual } from "./utils";

export function DestinationScreen() {
  const dispatch = useDispatch();
  const destinations = useSelector(selectDestinations);
  const destinationsGoogleData = useSelector(selectDestinationsGoogleData);
  const { showHeader, resetHeaderPosition } = useContext(
    OnboardingLayoutContext,
  );

  const navigate = useNavigate();

  const { t } = useTranslation("screens", {
    keyPrefix: "destination",
  });

  const [talkingHead, setTalkingHead] = useState({ show: false, text: "" });
  const [removedList, setRemovedList] = useState<string[] | null>(null);
  const [selectedPlaces, setSelectedPlaces] = useState<Place[]>(() => {
    return destinations
      ? parseSelectedPlaces(destinations, destinationsGoogleData)
      : [];
  });

  useEffect(() => {
    if (isIsraelTrip(selectedPlaces)) {
      setTalkingHead({
        show: true,
        text: t("talkingHead.israelTrip"),
      });
      return;
    } else if (selectedPlaces.length < 5) {
      setTalkingHead((talkingHead) => ({
        ...talkingHead,
        show: false,
      }));
      return;
    }

    setTalkingHead({
      show: true,
      text: isDomesticTrip(selectedPlaces)
        ? t("talkingHead.domestic")
        : t("talkingHead.international"),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaces.length]);

  useEffect(() => {
    showHeader(true);
    return () => {
      showHeader(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useTracking();

  return (
    <>
      <ScreenHeader
        caption={t(
          getExperimentVariation("femaleAvatar") === "active"
            ? "captionFemale"
            : "caption",
        )}
        title={t("title")}
        lead={t("lead")}
      />

      <Form
        name="destination"
        onSubmit={async () => {
          if (!selectedPlaces.length) {
            return;
          }

          const googlePlaces = selectedPlaces.map(
            ({ placeId, ...destination }) => ({
              ...destination,
              googleId: placeId,
            }),
          );

          if (
            destinations &&
            destinationsGoogleData &&
            selectedPlaceIdsAreEqual(
              destinations.map((destination) => destination?.googleId ?? null),
              selectedPlaces.map(({ placeId }) => placeId),
            )
          ) {
            await dispatch(
              setDestinationThunk({ destinationsGoogleData, destinations }),
            );

            trackSubmit(destinations);
          } else {
            const destinationsGoogleData =
              await getDestinationPlacesGoogleData(googlePlaces);

            const detailedDestinations = getDestinationsPlaceDetails(
              destinationsGoogleData,
              googlePlaces,
            );

            await dispatch(
              setDestinationThunk({
                destinations: detailedDestinations,
                destinationsGoogleData,
              }),
            );

            trackSubmit(detailedDestinations);
          }

          navigate("/dates");
        }}
        SubmitButtonProps={{ showArrow: true }}
        submittable={!!selectedPlaces.length}
      >
        <Autocomplete
          name="destinations"
          selectedPlaces={selectedPlaces}
          onBlur={() => {
            // Resetting the header margin-top when virtual keyboard is closed to avoid the
            // choppy animation caused by the visualViewport resize event that is being fired
            // after the virtual keyboard animation ends.
            resetHeaderPosition();
          }}
          onChange={(value) => {
            setSelectedPlaces([...selectedPlaces, value]);
          }}
        />

        <Taglist>
          {selectedPlaces.map((place) => (
            <Tag
              key={place.placeId}
              className={classNames({
                removed: removedList?.includes(place.placeId),
              })}
              onTransitionEnd={(event) => {
                if (event.propertyName === "transform") {
                  const places = selectedPlaces.filter(
                    (item) => item.placeId !== place.placeId,
                  );
                  setSelectedPlaces(places);
                  setRemovedList(
                    (prev) =>
                      prev?.filter((id) => id !== place.placeId) ?? null,
                  );
                }
              }}
            >
              {place.label}
              <PlainButton
                onClick={() => {
                  setRemovedList((prev) => [...(prev ?? []), place.placeId]);
                }}
              >
                <ClearIcon />
              </PlainButton>
            </Tag>
          ))}
        </Taglist>

        <TalkingHead show={talkingHead.show}>{talkingHead.text}</TalkingHead>
      </Form>

      {getExperimentVariation("mediaProof") === "active" && (
        <MediaBar
          images={[
            MarketWatchSvg,
            ForbesSvg,
            CnnTravelSvg,
            UsaTodaySvg,
            WallStreetJournalSvg,
            TechCrunchSvg,
            TravelWeeklySvg,
          ]}
        />
      )}
    </>
  );
}
