import { t } from "i18next";

const VALID_EMAIL = new RegExp(
  "^(" +
    '([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)' +
    "|" +
    '(\\".+\\")' +
    ")" +
    "@(" +
    "(\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])" +
    "|" +
    "(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})" +
    ")$",
);

export function emailValidation(value: string) {
  return (
    !value ||
    VALID_EMAIL.test(value.trim()) ||
    t("email.invalid", { ns: "validation" })
  );
}
