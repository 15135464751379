import { type MutableRefObject, createContext, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useBreakpoint } from "hooks/use-breakpoint";
import { useEventListener } from "hooks/use-event-listener";
import { Header } from "./header";
import { Main } from "./styles";

export type AvatarType = "person" | "faye" | null;

export const OnboardingLayoutContext = createContext({
  headerRef: { current: null } as MutableRefObject<HTMLElement | null>,
  updateAvatar: (() => {}) as (type: AvatarType) => void,
  showHeader: (() => {}) as (show: boolean) => void,
  resetHeaderPosition: (() => {}) as () => void,
});

export function OnboardingLayout() {
  const mdBreakpoint = useBreakpoint("md", { noSsr: true });
  const headerRef = useRef<HTMLElement | null>(null);
  const [showHeader, setShowHeader] = useState(false);
  const [avatar, setAvatar] = useState<AvatarType>("person");
  const [headerOffset, setHeaderOffset] = useState("0");

  useEventListener(
    "resize",
    window.visualViewport,
    () => {
      const offsetTop = window.visualViewport?.offsetTop;
      if (offsetTop) {
        const diff = (headerRef.current?.clientHeight ?? offsetTop) - offsetTop;
        setHeaderOffset(`${diff * -1}px`);
      } else {
        setHeaderOffset("0");
      }
    },
    [],
  );

  return (
    <OnboardingLayoutContext.Provider
      value={{
        headerRef,
        updateAvatar: (type) => {
          setAvatar(type);
        },
        resetHeaderPosition: () => {
          setHeaderOffset("0");
        },
        showHeader: (show) => {
          setShowHeader(show);
        },
      }}
    >
      {(mdBreakpoint || showHeader) && (
        <Header avatar={avatar} topOffset={headerOffset} />
      )}

      <Main>
        <Outlet />
      </Main>
    </OnboardingLayoutContext.Provider>
  );
}
