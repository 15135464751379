import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { type Quote } from "store/quote/initial-state";
import { type QuoteDto } from "types/quote-types";
import { type QuotePaymentResponse, sendQuotePayment } from "utils/api/quote";
import { getPaypalCheckout } from "utils/payment";
import { sentryCaptureError } from "utils/tracking/sentry";
import { type PaymentMethod, type PaymentChangeListener } from "../..";
import { Button, Container, PaypalButtonContainer } from "./styles";
import { sendPolicyAmendmentPayment } from "utils/api/policy";

export interface PayPalProps {
  currentPaymentMethod: PaymentMethod;
  onLoad: (success: boolean) => void;
  onPaymentChange: PaymentChangeListener<
    Extract<PaymentMethod, "payPal" | null>
  >;
  paymentLinkHash?: string;
  quoteDto: QuoteDto;
  totalPrice: number;
  userPolicy?: Quote["userPolicy"];
  variant: "dark" | "light";
}

export function PayPal({
  currentPaymentMethod,
  onLoad,
  onPaymentChange,
  paymentLinkHash,
  quoteDto,
  totalPrice,
  userPolicy,
  variant,
}: PayPalProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "paymentForm",
  });

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;

  const onPaymentChangeRef = useRef(onPaymentChange);
  onPaymentChangeRef.current = onPaymentChange;

  const quoteDtoRef = useRef(quoteDto);
  quoteDtoRef.current = quoteDto;

  const [paymentResponse, setPaymentResponse] =
    useState<QuotePaymentResponse>();

  const [policyAmendmentPaymentCompleted, setPolicyAmendmentPaymentCompleted] =
    useState(false);

  const [showButton, setShowButton] = useState<boolean | null>(null);

  useEffect(() => {
    void getPaypalCheckout()
      .then(async (paypalCheckout) => {
        await window.paypal
          .Buttons({
            createOrder: async () => {
              onPaymentChangeRef.current("payPal", { status: "request" });

              return await paypalCheckout.createPayment({
                amount: totalPrice,
                currency: "USD",
                displayName: t("buttons.displayLabel"),
                flow: "checkout",
                intent: "capture",
              });
            },

            fundingSource: window.paypal.FUNDING.PAYPAL,

            onApprove: async (data) => {
              const payload = await paypalCheckout.tokenizePayment(data);

              if (paymentLinkHash) {
                setPolicyAmendmentPaymentCompleted(
                  await sendPolicyAmendmentPayment({
                    paymentLinkHash,
                    paymentMethodNonce: payload.nonce,
                  }),
                );
              } else {
                setPaymentResponse(
                  await sendQuotePayment(
                    {
                      paymentMethodNonce: payload.nonce,
                      paymentService: "payPal",
                      ...(userPolicy?.id && { quoteId: userPolicy.id }),
                      purchasePaymentType: "PAYMENT_TYPE_PAYPAL",
                    },
                    quoteDtoRef.current,
                  ),
                );
              }
            },

            onCancel: () => {
              onPaymentChangeRef.current(null);
            },

            onError: (error) => {
              sentryCaptureError(error, {
                contexts: { payment: { paymentType: "payPal" } },
                name: "Payment_failed",
              });

              if ((error as Error)?.message === "Detected popup close") {
                onPaymentChangeRef.current(null);
              } else {
                onPaymentChangeRef.current("payPal", {
                  message: t("error.default"),
                  status: "error",
                });
              }
            },

            style: {
              ...(!!buttonRef.current && {
                height: buttonRef.current.offsetHeight,
              }),
              color: variant === "light" ? "white" : "black",
              label: "pay",
              layout: "horizontal",
            },
          })
          .render("#faye-paypal-button");
      })
      .then(() => {
        setShowButton(true);
        onLoadRef.current(true);
      })
      .catch((error) => {
        setShowButton(false);
        onLoadRef.current(false);
        throw error;
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (showButton === false) {
    return null;
  }

  return (
    <Container>
      <PaypalButtonContainer
        className={classNames({
          completed: policyAmendmentPaymentCompleted || !!paymentResponse,
        })}
        id="faye-paypal-button"
      />

      <Button
        aria-label="paypal"
        ref={buttonRef}
        currentPaymentMethod={currentPaymentMethod}
        doneLabel={t("buttons.label.done")}
        monochrome={variant === "dark"}
        onDoneLottieComplete={() => {
          if (paymentResponse) {
            onPaymentChangeRef.current("payPal", {
              response: paymentResponse,
              status: "success",
            });
          }
        }}
        paymentMethod="payPal"
        success={policyAmendmentPaymentCompleted || !!paymentResponse}
        variant={variant}
      />
    </Container>
  );
}
