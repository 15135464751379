import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "body",
})`
  margin: ${({ theme }) => theme.spacing(4, 4, 2)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: ${({ theme }) => theme.spacing(4, 0, 4)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }

  ::before {
    ${({ theme }) => theme.breakpoints.up("md")} {
      border-top: 2px dotted ${({ theme }) => theme.palette.color.primary.main};
      content: "";
      display: block;
      margin: ${({ theme }) => theme.spacing(0, -4)};
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-right: ${({ theme }) => theme.spacing(-10)};
      margin-left: ${({ theme }) => theme.spacing(-10)};
    }
  }
`;

export const SectionsWrapper = styled("div", {
  label: "sections-wrapper",
})`
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    margin: ${({ theme }) => theme.spacing(4, -2, 0)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-right: ${({ theme }) => theme.spacing(-4)};
    margin-left: ${({ theme }) => theme.spacing(-4)};
  }
`;
