import { DRIVING_AGE, ADULT_AGE } from "settings";
import { type Traveler } from "types/quote-types";
import { calculateAge } from "utils/birthdate";

function checkTravelerAge(age: number) {
  return (traveler: Traveler) => calculateAge(traveler.dateOfBirth) >= age;
}

export const canTravelerDrive = checkTravelerAge(DRIVING_AGE);

export const isTravelerAdult = checkTravelerAge(ADULT_AGE);

export function countNonAdultTravelers(travelers?: Traveler[]) {
  const nonAdultTravelers = travelers?.filter((traveler) => {
    return !isTravelerAdult(traveler);
  });

  return nonAdultTravelers?.length ?? 0;
}

export function calculateTravelersDetails(travelers: Traveler[]) {
  return {
    ages: travelers.map(({ dateOfBirth }) => dateOfBirth).map(calculateAge),
    emails: travelers.map(({ email }) => email).filter(Boolean),
    non_adult_travelers_count: countNonAdultTravelers(travelers),
  };
}
