import { BadResponseError, apiFetch } from ".";

export async function validateOtt(token: string): Promise<string> {
  const response = await apiFetch(`one-time-token/validate/${token}`, {
    throwBadResponse: false,
  });

  const json = await response.json();

  if (!response.ok) {
    throw new BadResponseError({
      message: json.message,
      status: json.statusCode,
    });
  } else if (json.error) {
    throw new BadResponseError({ message: json.error });
  }

  return json.token;
}
