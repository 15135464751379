import { type PopperProps } from "@mui/base/Popper";
import { Component } from "./styles";

export function Popper(props: PopperProps) {
  return (
    <Component
      {...props}
      modifiers={[
        {
          name: "flip",
          options: {
            fallbackPlacements: [],
          },
        },
      ]}
      popperOptions={{
        placement: "bottom",
      }}
    />
  );
}
