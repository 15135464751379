import { styled } from "@mui/material/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion, {
  type AccordionProps,
  accordionClasses,
} from "@mui/material/Accordion";

export const Container = styled(
  (props: AccordionProps) => <Accordion disableGutters {...props} />,
  {
    label: "section",
  },
)`
  flex: 1;
  box-shadow: none;
  padding: 0;

  &::before {
    content: none;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: ${({ theme }) => theme.spacing(0, 2)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: ${({ theme }) => theme.spacing(0, 4)};
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.palette.color.gray[80]};
    ${({ theme }) => theme.breakpoints.up("md")} {
      border-top: none;
    }
  }
`;

export const Content = styled(AccordionDetails, {
  label: "content",
})`
  padding: ${({ theme }) => theme.spacing(1, 0)};

  // Put "." inside template literal to avoid a linting error
  ${`.${accordionClasses.root}`}:last-of-type & {
    padding-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(1, 0, 0)};
  }
`;
