import { forwardRef } from "react";
import { type ValidationProps } from "utils/validation";
import { BaseInput, type BaseInputProps } from "./base";
import { useInnerProps } from "./utils";

export interface InputProps extends BaseInputProps, ValidationProps<string> {
  value?: string;
}

export const Input = forwardRef<HTMLDivElement, InputProps>(function Input(
  { helperText, onBlur, onChange, onValidation, validate, ...props },
  ref,
) {
  const innerProps = useInnerProps({
    helperText,
    onValidation,
    startingValue: "",
    validate,
    valueProps: props,
  });

  return (
    <BaseInput
      error={innerProps.error}
      ref={ref}
      warning={innerProps.warning}
      {...props}
      helperText={innerProps.helperText}
      onBlur={(...args) => {
        innerProps.onBlur();

        if (onBlur) {
          onBlur(...args);
        }
      }}
      onChange={(event) => {
        innerProps.onChange(event.target.value);

        if (onChange) {
          onChange(event);
        }
      }}
    />
  );
});
