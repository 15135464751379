import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "media-bar",
})`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: min(3vw, ${({ theme }) => theme.spacing(2.5)})
    min(2.25vw, ${({ theme }) => theme.spacing(4)});
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(8)};
  opacity: 0.2;

  & > svg {
    --image-height: min(5vw, ${({ theme }) => theme.spacing(3.5)});
    --image-height-ratio: min(0.045vw, ${({ theme }) => theme.spacing(0.225)});

    width: auto;
    // The code below makes wider images to have slightly lower height
    height: calc(
      var(--image-height) - calc(
          var(--image-aspect-ratio) * var(--image-height-ratio)
        ) + var(--image-height-ratio)
    );
  }
`;
