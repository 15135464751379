import { type ButtonBaseProps } from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import { BaseButton } from "../base";

export const Component = styled(
  (props: ButtonBaseProps) => <BaseButton disableRipple {...props} />,
  {
    label: "plain-button",
  },
)`
  line-height: inherit;
`;
