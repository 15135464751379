import { inputBaseClasses } from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { BaseInput } from "../base";

export const Component = styled(BaseInput, {
  label: "date-input",
})`
  ${`.${inputBaseClasses.input}`}::selection {
    background: none;
    color: inherit;
  }
`;
