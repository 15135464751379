import { styled } from "@mui/material/styles";
import ProfileLogoAsset from "assets/images/logo/profile.svg";

export const Container = styled("div", {
  label: "destination-section",
})`
  background: ${({ theme }) => theme.palette.color.gray[100]};
  box-shadow: ${({ theme }) => theme.customShadows.medium};
  color: ${({ theme }) => theme.palette.color.text.primary};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.appBar};

  ${({ theme }) => theme.breakpoints.up("md")} {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
    box-shadow: none;
    overflow: hidden;
    position: static;
    width: clamp(
      ${({ theme }) => theme.spacing(30)},
      40vw,
      ${({ theme }) => theme.spacing(55)}
    );
  }

  &.amendPayment {
    overflow: visible;
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  padding: ${({ theme }) => theme.spacing(2, 3)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) =>
      `clamp(${theme.spacing(3)}, 2.25vw, ${theme.spacing(4)})`};
  }
`;

export const ContentColumns = styled("div", {
  label: "content",
})`
  display: flex;
`;

export const Picture = styled("img", {
  label: "picture",
})`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const PictureContainer = styled("div", {
  label: "picture-container",
})`
  background: ${({ theme }) => theme.palette.color.gray[80]};
  display: none;
  padding-bottom: 55%;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: block;
  }
`;

export const Price = styled("div", {
  label: "price",
})`
  font-size: clamp(44px, 3.9vw, 56px);
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 700;
  line-height: 1;
  margin-left: auto;
  padding-left: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-left: min(1.66vw, ${({ theme }) => theme.spacing(3)});
  }

  .currency {
    font-size: clamp(13px, 1.18vw, 17px);
    font-weight: 600;
  }

  .fraction {
    font-size: clamp(19px, 1.735vw, 25px);
    font-weight: 700;
  }

  .info {
    display: block;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: clamp(10px, 1vw, 14px);
    font-weight: 500;
    margin-top: ${({ theme }) => theme.spacing(0.25)};
    text-align: center;
  }
`;

export const ProfileLogo = styled(ProfileLogoAsset, {
  label: "profile-logo",
})`
  display: none;

  ${({ theme }) => theme.breakpoints.up("md")} {
    border-radius: 100%;
    display: block;
    margin: ${({ theme }) =>
      theme.spacing(
        `clamp(${theme.spacing(-7)}, -3.9vw, ${theme.spacing(-5)})`,
        `clamp(${theme.spacing(3)}, 2.25vw, ${theme.spacing(4)})`,
        `clamp(${theme.spacing(-4)}, -2.25vw, ${theme.spacing(-3)})`,
        "auto",
      )};
    position: relative;
    width: auto;
    height: ${({ theme }) =>
      `clamp(${theme.spacing(8)}, 6.15vw, ${theme.spacing(11)})`};
  }

  .amendPayment & {
    ${({ theme }) => theme.breakpoints.up("md")} {
      margin: ${({ theme }) =>
        theme.spacing(
          `clamp(${theme.spacing(-4)}, -2.25vw, ${theme.spacing(-3)})`,
          "auto",
          `clamp(${theme.spacing(-7)}, -3.9vw, ${theme.spacing(-5)})`,
          "auto",
        )};
    }
  }
`;

export const Subtitle = styled("div", {
  label: "subtitle",
})`
  font-size: clamp(13px, 1.25vw, 18px);
  font-weight: 500;
  line-height: 1.4;

  &.light {
    font-weight: 300;
  }

  .amendPayment & {
    &:not(.policyId) {
      font-size: clamp(12px, 1.15vw, 16px);
    }
  }
`;

export const Title = styled("div", {
  label: "title",
})`
  font-size: clamp(24px, 2.3vw, 33px);
  font-weight: 700;
  line-height: 1.15;
  margin: ${({ theme }) => theme.spacing(0.5, 0)};

  span {
    font-weight: 500;
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;
