import { type ComponentPropsWithRef, useEffect, useRef, useState } from "react";
import { useCountUp } from "react-countup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useOttUser } from "hooks/use-ott-user";
import { useEventListener } from "hooks/use-event-listener";
import { selectTotalPriceParts, selectTravelers } from "store/quote/selectors";
import { numberFormatter } from "utils/formatters/number";
import {
  Container,
  Price,
  Title,
  TravelersButton,
  TravelersButtonIcon,
  TravelerItem,
  TravelersPopover,
  TravelersList,
} from "./styles";

const REACT_COUNTUP_OPTIONS = {
  duration: 1,
  separator: numberFormatter(1000, {
    getParts: true,
    style: "currency",
  }).groupSeparator,
  start: 0,
  end: 0,
  startOnMount: false,
};

export function PolicyPriceSection(props: ComponentPropsWithRef<"div">) {
  const totalPriceParts = useSelector(selectTotalPriceParts);
  const travelers = useSelector(selectTravelers);

  const ottUser = useOttUser("glinda");
  const fractionRef = useRef<HTMLSpanElement | null>(null);
  const integerRef = useRef<HTMLSpanElement | null>(null);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null,
  );

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.policyPrice",
  });

  const { update: updateInteger } = useCountUp({
    ...REACT_COUNTUP_OPTIONS,
    ref: integerRef,
    start: totalPriceParts?.integer ? parseInt(totalPriceParts.integer) : 0,
  });

  const { update: updateFraction } = useCountUp({
    ...REACT_COUNTUP_OPTIONS,
    formattingFn: (value) => (value < 10 ? `0${value}` : `${value}`),
    ref: fractionRef,
    start: totalPriceParts?.fraction ? parseInt(totalPriceParts.fraction) : 0,
  });

  useEventListener(
    "scroll",
    window,
    () => {
      if (popoverAnchor !== null) {
        setPopoverAnchor(null);
      }
    },
    [popoverAnchor],
  );

  useEffect(() => {
    if (typeof totalPriceParts?.integer !== "undefined") {
      updateInteger(totalPriceParts.integer);
    }
  }, [totalPriceParts?.integer, updateInteger]);

  useEffect(() => {
    if (typeof totalPriceParts?.fraction !== "undefined") {
      updateFraction(totalPriceParts.fraction);
    }
  }, [totalPriceParts?.fraction, updateFraction]);

  return (
    <Container {...props}>
      <Title>{t("title", { context: ottUser && "advisor" })}</Title>

      {totalPriceParts && (
        <Price>
          <span className="currency">{totalPriceParts.currency}</span>
          <span ref={integerRef} />
          <span className="fraction">{totalPriceParts.decimalSeparator}</span>
          <span className="fraction" ref={fractionRef} />
        </Price>
      )}

      {!!travelers?.length && (
        <>
          <TravelersButton
            className="container-item"
            onClick={(event) => {
              setPopoverAnchor(event.currentTarget);
            }}
          >
            {t("travelerCount", { count: travelers.length })}
            <TravelersButtonIcon />
          </TravelersButton>

          <TravelersPopover
            open={!!popoverAnchor}
            anchorEl={popoverAnchor}
            disableScrollLock={true}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            transitionDuration={0}
            onClose={() => {
              setPopoverAnchor(null);
            }}
          >
            <TravelersList>
              {travelers.map(({ firstName, lastName }, index) => (
                <TravelerItem key={`${firstName} ${lastName}-${index}`}>
                  {firstName} {lastName}
                </TravelerItem>
              ))}
            </TravelersList>
          </TravelersPopover>
        </>
      )}
    </Container>
  );
}
