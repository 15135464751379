import { type DateRangePickerDayProps } from "@mui/x-date-pickers-pro/DateRangePickerDay";
import { type PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { forwardRef } from "react";
import { Component } from "./styles";

export const Hidden = forwardRef<
  HTMLButtonElement,
  Pick<DateRangePickerDayProps<Date> & PickersDayProps<Date>, "role">
>(function Hidden({ role }, ref) {
  return <Component ref={ref} role={role} />;
});
