import { type ComponentPropsWithoutRef } from "react";
import { Caption, Container, Lead, Title } from "./styles";

export interface ScreenHeaderProps extends ComponentPropsWithoutRef<"div"> {
  lead?: string;
  TitleProps?: ComponentPropsWithoutRef<"h1">;
  title?: string;
  caption?: string;
}

export function ScreenHeader({
  lead,
  TitleProps = {},
  caption,
  title,
  ...props
}: ScreenHeaderProps) {
  return (
    <Container {...props}>
      {caption && <Caption>{caption}</Caption>}
      {title && <Title {...TitleProps}>{title}</Title>}
      {lead && <Lead>{lead}</Lead>}
    </Container>
  );
}
