import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "section",
})`
  flex: 1;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: ${({ theme }) => theme.spacing(0, 2)};
  }

  & + & {
    margin-top: 0;
    border-top: 1px solid ${({ theme }) => theme.palette.color.gray[80]};
    ${({ theme }) => theme.breakpoints.up("md")} {
      border-top: none;
    }
  }
`;
