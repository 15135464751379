import { type DependencyList, useEffect, useRef } from "react";

export function useEventListener(
  event: "resize" | "scroll",
  element: EventTarget | null,
  callback: () => void,
  deps?: DependencyList,
) {
  const animationFrame = useRef(0);

  useEffect(() => {
    function eventListener() {
      if (!animationFrame.current) {
        animationFrame.current = window.requestAnimationFrame(() => {
          animationFrame.current = 0;
          callback();
        });
      }
    }

    element?.addEventListener(event, eventListener);

    return () => {
      element?.removeEventListener(event, eventListener);
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
