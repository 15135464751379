import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Paper as BasePaper } from "components/autocomplete/base/paper";
import AlertIconAsset from "assets/images/icons/alert.svg";
import ResultsFromGoogleImage from "assets/images/results-from-google.svg";

export const Component = styled("div", { label: "autocomplete" })`
  width: 100%;
  position: relative;
`;

export const Paper = styled(BasePaper, { label: "autocomplete-paper" })`
  && .${autocompleteClasses.option} {
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(1.5)};

    ${({ theme }) => theme.breakpoints.up("md")} {
      padding: ${({ theme }) => theme.spacing(1.5, 2.5)};
    }

    &:hover,
    &.Mui-focused {
      background-color: ${({ theme }) => theme.palette.color.primary[95]};
      &.disabled {
        background: none;
      }
    }

    &.disabled {
      color: ${({ theme }) => theme.palette.color.gray[80]};
      opacity: 1;
      pointer-events: none;
    }
  }
`;

export const ResultsFromGoogle = styled(ResultsFromGoogleImage, {
  label: "results-from-google",
})`
  display: block;
  fill: ${({ theme }) => theme.palette.color.gray[70]};
  margin-left: auto;
  width: auto;
  height: ${({ theme }) => theme.spacing(2)};
`;

export const Footer = styled("div", {
  label: "footer",
})`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  padding: ${({ theme }) => theme.spacing(1.5, 2.5)};
`;

export const AlertIcon = styled(AlertIconAsset, {
  label: "alert-icon",
})`
  fill: currentColor;
  flex: 0 0 ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(2)};
  width: ${({ theme }) => theme.spacing(2)};
  height: auto;
`;
