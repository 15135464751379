import { type PaymentSessionResponse } from "@checkout.com/checkout-web-components";
import { apiFetch } from ".";

export interface CheckoutSession {
  id: string;
  payment_session_token: string;
  _links: { self: { href: string } };
}

const CACHE: {
  session?: Promise<PaymentSessionResponse>;
} = {};

export async function getCheckoutSession(
  hash: string,
): Promise<PaymentSessionResponse> {
  if (CACHE.session === undefined) {
    CACHE.session = apiFetch(`payment/checkout/session/${hash}`).then(
      async (response) => (await response.json()) as PaymentSessionResponse,
    );
  }

  return await CACHE.session;
}
