import { type SetOptional, type Writable } from "type-fest";

export enum CoverageType {
  medicalCoverage = "medicalCoverage",
  tripInterruption = "tripInterruption",
  tripCancellation = "tripCancellation",
  car = "car",
  cancelForAnyReason = "cancelForAnyReason",
  baggageExtraCare = "baggageExtraCare",
  pet = "pet",
  vacationRental = "vacationRental",
  sportsExtreme = "sportsExtreme",
}

export interface CarDatesDto {
  startDate: string;
  endDate: string;
}

export interface Coverage {
  coverageType: CoverageType;
  isBase: boolean;
  isDefault: boolean;
  isSelected: boolean;
  title: string;
}

export interface DestinationGoogleData
  extends google.maps.places.PlaceResult,
    GoogleAutoCompleteTerms {}

export interface GoogleAutoCompleteTerms {
  terms?: Array<{ offset: number; value: string }>;
}

export interface GooglePlace extends GoogleAutoCompleteTerms {
  label: string;
  googleId: string;
  types?: string[];
}

export interface Name {
  firstName: string;
  lastName: string;
}

export type PlaceDetails = GooglePlace & {
  country: string;
  areaLevel1?: string;
  locality?: string;
};

export interface PricingObjectResponseDTO {
  coveragesPrice: Record<string, number>;
  isTest?: boolean;
  totalPrice: number;
}

// All QuoteDtoBase properties are optional and readonly. Set all of them
// required and writable, then set specific keys as optional.
export interface QuoteDto
  extends SetOptional<
    Writable<Required<QuoteDtoBase>>,
    | "advisorEmail"
    | "initialDepositDate"
    | "lastClickedCoverageType"
    | "noOfCars"
    | "userId"
  > {}

// A class allows us to extend it in an interface
// and extract its keys during runtime
export class QuoteDtoBase {
  constructor(
    readonly advisorEmail?: string,
    readonly carDatesDto?: CarDatesDto[],
    readonly coverages?: Coverage[],
    readonly defaultTripCost?: number,
    readonly destinations?: PlaceDetails[],
    readonly effectiveEndDate?: string,
    readonly effectiveStartDate?: string,
    readonly email?: string,
    readonly initialDepositDate?: string,
    readonly lastClickedCoverageType?: CoverageType,
    readonly noOfCars?: number,
    readonly policyCodeHash?: string,
    readonly residency?: Residency,
    readonly travellers?: Traveler[],
    readonly initialTripCost?: number,
    readonly tripCost?: number,
    readonly tripEndDate?: string,
    readonly tripStartDate?: string,
    readonly userId?: number,
    readonly utms?: Array<{ key: string; value: string }>,
  ) {}
}

export interface Residency {
  apartment?: string;
  areaLevel1?: string;
  country: string;
  googleId?: string;
  label: string;
  locality: string;
  streetAndNumber?: string;
  zipCode?: string;
}

export interface Traveler extends Name {
  dateOfBirth: string;
  mainTraveller: boolean;
  email?: string;
}

export interface UserPolicy {
  id?: number;
  uniqueUuid?: string;
}
