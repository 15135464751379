import { buttonBaseClasses } from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import CloseLightIconAsset from "assets/images/icons/close-light.svg";
import DropdownIconAsset from "assets/images/icons/dropdown.svg";

export const ClearIcon = styled(CloseLightIconAsset, {
  label: "clear-icon",
})`
  fill: ${({ theme }) => theme.palette.color.gray[10]};
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
`;

export const DropdownIcon = styled(DropdownIconAsset, {
  label: "dropdown-icon",
})`
  fill: ${({ theme }) => theme.palette.color.primary.main};
  width: ${({ theme }) => theme.spacing(1)};
  height: auto;

  .${buttonBaseClasses.disabled} & {
    fill: ${({ theme }) => theme.palette.color.gray[70]};
  }
`;
