import { styled } from "@mui/material/styles";
import { BirthdateField as BirthdateFieldComponent } from "components/birthdate-field";
import { PlainButton } from "components/button/plain";
import ClearIconAsset from "assets/images/icons/close-light.svg";

export const BirthdateField = styled(BirthdateFieldComponent, {
  label: "birthdate-field",
})`
  flex: 0 0 ${({ theme }) => theme.spacing(25.5)};

  input {
    text-align: center;
  }
`;

export const Container = styled("div", {
  label: "fields",
})`
  & + & {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  gap: ${({ theme }) => theme.spacing(1.5)};

  & + & {
    margin-top: ${({ theme }) => theme.spacing(2.25)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-wrap: nowrap;
    gap: ${({ theme }) => theme.spacing(2.5)};
  }
`;

export const RemoveButton = styled(PlainButton, {
  label: "remove-button",
})`
  border: 1px solid ${({ theme }) => theme.palette.color.alert.light};
  color: ${({ theme }) => theme.palette.color.alert.main};
  padding: ${({ theme }) => theme.spacing(0.85)};
  border-radius: ${({ theme }) => theme.spacing(1.5)};
`;

export const RemoveIcon = styled(ClearIconAsset, {
  label: "remove-icon",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(1)};
  height: auto;
`;
