import classNames from "classnames";
import { type ReactNode, forwardRef } from "react";
import { type ButtonProps as ButtonComponentProps } from "components/button";
import { Component } from "./styles";

export interface IconButtonProps extends ButtonComponentProps {
  variant?: "standard" | "outlined" | "link";
  icon: ReactNode;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    { className, variant = "standard", icon, ...props },
    ref,
  ) {
    return (
      <Component
        className={classNames(className, variant)}
        ref={ref}
        {...props}
      >
        {icon}
      </Component>
    );
  },
);
