import { sentryCaptureError } from "utils/tracking/sentry";
import { BadResponseError, apiFetch } from ".";

const CACHE: {
  email: Map<string, Promise<string | null>>;
  profile?: Promise<AdvisorProfile | null>;
} = {
  email: new Map(),
};

export interface AdvisorProfile {
  firstName: string;
  lastName: string;
}

export interface AdvisorTraveler {
  dateOfBirth: string;
  destinations: Array<{
    areaLevel1: string;
    country: string;
    googleId: string;
    label: string;
    locality: string;
  }>;
  email: string;
  firstName: string;
  lastName: string;
  policy: string;
  tripEndDate: string;
  tripStartDate: string;
}

export async function getAdvisorProfile(): Promise<AdvisorProfile | null> {
  let promise = CACHE.profile;

  if (promise === undefined) {
    promise = apiFetch("advisor/myprofile", { ott: "glinda" })
      .then(async (response) => {
        const json = await response.json();
        return { firstName: json.firstName, lastName: json.lastName };
      })
      .catch((error) => {
        sentryCaptureError(error);
        return null;
      });
  }

  return await promise;
}

export async function getAdvisorEmail(
  advisorId: string,
): Promise<string | null> {
  let promise = CACHE.email.get(advisorId);

  if (promise === undefined) {
    promise = apiFetch(`advisor/${advisorId}`, {
      throwBadResponse: false,
    })
      .then(async (response) => {
        const status = response.status;

        if (status !== 200) {
          throw new BadResponseError({
            message:
              status === 404
                ? (await response.json()).message
                : await response.text(),
            status,
          });
        }

        return await response.text();
      })
      .catch((error) => {
        sentryCaptureError(error, {
          ...(error.status === 404 && { name: "Advisor_id_not_found" }),
          contexts: { request: { advisorId } },
        });

        return null;
      });

    CACHE.email.set(advisorId, promise);
  }

  return await promise;
}

export async function getAdvisorTravelers(
  query: string,
): Promise<AdvisorTraveler[]> {
  const response = await apiFetch(`advisor/travellers/${query}`, {
    ott: "glinda",
  });

  return await response.json();
}
