import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "checkout-layout",
})`
  ${({ theme }) => theme.breakpoints.up("md")} {
    background-color: ${({ theme }) => theme.palette.color.primary.background};
  }
`;

export const Main = styled("main", {
  label: "main",
})`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.spacing(180)};
  padding: 0;
`;
