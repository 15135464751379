import { buttonBaseClasses } from "@mui/material/ButtonBase";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Button } from "..";

export const Component = styled(Button, {
  label: "progress-button",
})`
  overflow: hidden;
`;

export const Progress = styled(LinearProgress, {
  label: "progress",
})`
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  .${linearProgressClasses.bar} {
    background: ${({ theme }) => theme.palette.color.primary.main};
  }

  .${buttonBaseClasses.disabled} &,
  .outlined & {
    .${linearProgressClasses.bar} {
      background: ${({ theme }) => theme.palette.color.primary.main};
    }
  }
`;
