import { createAsyncThunk } from "@reduxjs/toolkit";
import { type RootState } from "store";
import {
  setDates,
  setDestinations,
  setDestinationsGoogleData,
} from "store/quote";
import { selectDestinations } from "store/quote/selectors";
import { type GooglePlace, type PlaceDetails } from "types/quote-types";
import { getAdvisorEmail } from "utils/api/advisor";
import {
  getDestinationPlacesGoogleData,
  getDestinationsPlaceDetails,
} from "utils/place/destinations";
import { updateMixpanelUser } from "utils/tracking/mixpanel";
import { getAdvisorId } from "utils/tracking/utm";

export const datesAndDestinationGivenThunk = createAsyncThunk(
  "quote/datesAndDestinationGivenThunk",
  async (
    {
      destinationGoogleId,
      dates,
    }: {
      destinationGoogleId?: string;
      dates?: { tripStartDate: string; tripEndDate: string };
    },
    { dispatch },
  ) => {
    if (dates) {
      dispatch(setDates({ dates }));
    }

    if (destinationGoogleId) {
      const chosenDestinations: GooglePlace = {
        googleId: destinationGoogleId.toString(),
        label: "",
      };

      const destinationGooglePlaceResults: google.maps.places.PlaceResult[] =
        await getDestinationPlacesGoogleData([chosenDestinations]);

      const destinationDetails: PlaceDetails[] = getDestinationsPlaceDetails(
        destinationGooglePlaceResults,
        [chosenDestinations] as GooglePlace[],
      );

      dispatch(setDestinationsGoogleData(destinationGooglePlaceResults));
      dispatch(setDestinations({ destinations: destinationDetails }));
    }
  },
);

export const setPolicyDestination = createAsyncThunk(
  "quote/setPolicyDestination",
  (_, { getState }) => {
    const destinations = selectDestinations(getState() as RootState);

    const destinationsNames = destinations?.map(
      (destination) => destination?.label,
    );

    updateMixpanelUser({
      policy_destinations: destinationsNames,
    });
  },
);

export const updateAdvisorEmail = createAsyncThunk(
  "quote/updateAdvisorEmail",
  async () => {
    const advisorId = getAdvisorId();
    if (advisorId) {
      return await getAdvisorEmail(advisorId);
    }
  },
);
