import { type ComponentPropsWithoutRef, useState } from "react";
import { BaseButton } from "components/button/base";
import { WEBSITE_URL } from "legacy/utils/constants";
import { Container, Image, Version } from "./styles";

export function Logo(props: ComponentPropsWithoutRef<"div">) {
  const [showVersion, setShowVersion] = useState(false);

  return (
    <Container {...props}>
      <BaseButton href={WEBSITE_URL}>
        <Image />
      </BaseButton>

      <Version
        className={showVersion ? "show" : ""}
        onClick={(event) => {
          if (event.detail === 3) {
            setShowVersion((showVersion) => !showVersion);
          }
        }}
      >
        {import.meta.env.VITE_APP_VERSION}
      </Version>
    </Container>
  );
}
