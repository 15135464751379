import { useEffect, useMemo, useRef, useState } from "react";
import { isClient } from "utils/is-client";

export function useAutocompleteDebounce<Option>(
  getOptions: (query: string) => Promise<Option[] | null>,
  {
    cacheKeyPrefix,
    cacheName,
    debounceInterval = 500,
    minQueryLength = 1,
  }: {
    cacheKeyPrefix?: string;
    cacheName: string;
    debounceInterval?: number;
    minQueryLength?: number;
  },
) {
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState<Option[] | null>(null);

  const getOptionsRef = useRef(getOptions);
  getOptionsRef.current = getOptions;

  const updateOptions = useMemo(() => {
    const sessionStorageCache = isClient()
      ? window.sessionStorage.getItem(cacheName)
      : null;

    const cache = JSON.parse(sessionStorageCache ?? "{}");

    let reduceTimeout = true;
    let timeout = 0;

    return (query: string) => {
      query = query.trim().toLocaleLowerCase();

      if (timeout) {
        window.clearTimeout(timeout);
      }

      if (query.length < minQueryLength) {
        setOptions(null);
        reduceTimeout = true;
        timeout = 0;
        return;
      }

      const cacheKey = cacheKeyPrefix ? `${cacheKeyPrefix}:${query}` : query;

      if (cache[cacheKey]) {
        setOptions(cache[cacheKey]);
        reduceTimeout = false;
        timeout = 0;
        return;
      }

      function setTimeout(query: string) {
        const internalTimeout = window.setTimeout(
          () => {
            void getOptionsRef.current(query).then((options) => {
              if (options === null) {
                setOptions(null);
                return;
              } else if (reduceTimeout || internalTimeout === timeout) {
                setOptions(options);
                reduceTimeout = false;
              }

              if (internalTimeout === timeout) {
                timeout = 0;
              }

              cache[cacheKey] = options;
              window.sessionStorage.setItem(cacheName, JSON.stringify(cache));
            });
          },
          debounceInterval * (reduceTimeout ? 0.5 : 1),
        );

        return internalTimeout;
      }

      timeout = setTimeout(query);
    };
  }, [cacheKeyPrefix, cacheName, debounceInterval, minQueryLength]);

  useEffect(() => {
    updateOptions(query);
  }, [updateOptions, query]);

  return { query, options, setQuery };
}
