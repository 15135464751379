import CircularProgressComponent from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "test-user",
})`
  align-items: center;
  background: ${({ theme }) => theme.palette.color.gray[100]};
  color: ${({ theme }) => theme.palette.color.text.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

export const CircularProgress = styled(CircularProgressComponent, {
  label: "circular-progress",
})`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  width: ${({ theme }) => theme.spacing(8)} !important;
  height: ${({ theme }) => theme.spacing(8)} !important;
`;
