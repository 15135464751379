import { styled } from "@mui/material/styles";
import { PlainButton } from "components/button/plain";
import Popover, { popoverClasses } from "@mui/material/Popover";
import DropdownIconAsset from "assets/images/icons/dropdown.svg";
import { Title as TitleComponent } from "../title";

export const Container = styled("div", {
  label: "policy-price",
})`
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-left: auto;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin: ${({ theme }) => theme.spacing(0, 4, 0, "auto")};
  }
`;

export const Price = styled("div", {
  label: "price",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  margin-top: ${({ theme }) => theme.spacing(3)};
  position: relative;

  & > .currency {
    font-size: 30px;
    font-weight: 600;
  }

  & > .fraction {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 46px;
    margin-top: ${({ theme }) => theme.spacing(2)};

    & > .currency {
      font-size: 24px;
    }

    & > .fraction {
      font-size: 24px;
    }
  }
`;

export const Title = styled(TitleComponent, {
  label: "title",
})`
  display: none;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: initial;
  }
`;

export const TravelersButton = styled(PlainButton, {
  label: "travelers-button",
})`
  font-size: 18px;
  line-height: 1;
  margin-top: ${({ theme }) => theme.spacing(0.75)};
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 24px;
    margin-top: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const TravelersButtonIcon = styled(DropdownIconAsset, {
  label: "travelers-button-icon",
})`
  fill: ${({ theme }) => theme.palette.color.text.primary};
  margin: ${({ theme }) => theme.spacing(0, 0, 0, 1.5)};
  width: ${({ theme }) => theme.spacing(1.25)};
  height: auto;
`;

export const TravelersPopover = styled(Popover, {
  label: "travelers-popover",
})`
  .${popoverClasses.paper} {
    margin-top: ${({ theme }) => theme.spacing(1.75)};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    overflow: visible;
    box-shadow: none;
    filter: drop-shadow(${({ theme }) => theme.customShadows.soft});

    &::before {
      background: inherit;
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
      content: "";
      display: block;
      position: absolute;
      width: ${({ theme }) => theme.spacing(3)};
      height: ${({ theme }) => theme.spacing(3)};
      z-index: 0;
      top: ${({ theme }) => theme.shape.borderRadius / 2}px;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }
  }
`;

export const TravelersList = styled("ul", {
  label: "travelers-list",
})`
  padding: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2.5)};
  color: ${({ theme }) => theme.palette.color.text.primary};
  list-style: none;
  font-size: 16px;
`;

export const TravelerItem = styled("li", {
  label: "traveler-item",
})`
  &:nth-of-type(1) {
    font-weight: 600;
  }

  & + & {
    margin-top: ${({ theme }) => theme.spacing(1.25)};
  }
`;
