import { osName, osVersion } from "react-device-detect";
import { type QuoteDto } from "types/quote-types";
import {
  type OfflineConversions,
  getOfflineConversions,
} from "utils/tracking/offline-conversions";
import { getUtm, getUtmArray } from "utils/tracking/utm";
import { BadResponseError, apiFetch } from ".";

export interface QuotePaymentInfo {
  paymentService: "payPal" | "stripe" | "checkOut";
  purchasePaymentType:
    | "PAYMENT_TYPE_CREDIT_CARD"
    | "PAYMENT_TYPE_PAYPAL"
    | "PAYMENT_TYPE_APPLE_PAY"
    | "PAYMENT_TYPE_GOOGLE_PAY";
  quoteId?: number;
}

export interface QuotePaymentCreditCardInfo extends QuotePaymentInfo {
  paymentService: "stripe" | "checkOut";
  paymentToken: string;
  purchasePaymentType: "PAYMENT_TYPE_CREDIT_CARD";
}

export interface QuotePaymentPayPalInfo extends QuotePaymentInfo {
  paymentMethodNonce: string;
  paymentService: "payPal";
  purchasePaymentType: "PAYMENT_TYPE_PAYPAL";
}

interface QuotePaymentProviderInfo extends QuotePaymentInfo {
  paymentMethod: string;
  purchasePaymentType: "PAYMENT_TYPE_APPLE_PAY" | "PAYMENT_TYPE_GOOGLE_PAY";
}

export interface QuoteStripeProviderInfo extends QuotePaymentProviderInfo {
  paymentIntent: string;
  paymentService: "stripe";
}

export interface QuoteCheckoutProviderInfo extends QuotePaymentProviderInfo {
  paymentToken: string;
  paymentService: "checkOut";
}

export interface QuotePaymentResponse {
  policyUrl: string;
  receiptUrl: string;
  userPolicy: {
    id?: number;
    uniqueUuid?: string;
  };
}

export interface QuotePaymentIntentResponse {
  clientSecret: string;
  id: string;
  status: string;
}

export interface QuotePricingResponse {
  coveragesPrice: Record<string, number>;
  isTest?: boolean;
  totalPrice: number;
  policyCodeHash: string | null;
}

export async function calculateQuote(
  quoteDto: QuoteDto,
  previousPolicyCodeHash?: string,
): Promise<QuotePricingResponse> {
  const body = {
    ...quoteDto,
    ...(previousPolicyCodeHash ? { previousPolicyCodeHash } : {}),
    utms: getUtmArray(),
  };

  const response = await apiFetch("quote/calculate", {
    body: JSON.stringify(body),
    method: "POST",
    throwBadResponse: false,
  });

  const json = await response.json();

  if (!response.ok) {
    throw new BadResponseError({
      message: json.message,
      status: json.statusCode,
    });
  }

  return json;
}

export async function getQuote(
  countries: string[],
  startDate: string,
  endDate: string,
  travelerCount: number,
  residencyState: string,
): Promise<QuoteDto> {
  const utm = getUtm();

  const response = await apiFetch(
    "/quote" +
      `/destination/${countries.join(",")}` +
      `/date/${startDate}` +
      `/no-travellers/${travelerCount}` +
      `/residency/${residencyState}` +
      `/endDate/${endDate}` +
      `/source/${utm?.utm_source || null}`,
  );

  return await response.json();
}

export async function getQuoteDisclaimerPdfUrl(
  requestParams: Pick<QuoteDto, "coverages" | "destinations" | "residency">,
): Promise<string> {
  const response = await apiFetch("quote/additionalOfferDetails", {
    body: JSON.stringify(requestParams),
    method: "POST",
  });

  return (await response.json()).policyWordingUrl;
}

export async function getQuoteOffer(
  policyCodeHash: string,
  email?: string,
): Promise<QuoteDto> {
  const response = await apiFetch(
    `/quote/get/offer/${policyCodeHash}/email/${email ?? null}`,
  );

  return await response.json();
}

export async function sendQuotePayment(
  paymentInfo:
    | QuotePaymentCreditCardInfo
    | QuotePaymentPayPalInfo
    | QuoteStripeProviderInfo
    | QuoteCheckoutProviderInfo,
  quoteDto: QuoteDto,
): Promise<QuotePaymentResponse> {
  const offlineConversions = getOfflineConversions();

  const offlineConversionsUtm = offlineConversions
    ? (Object.keys(offlineConversions) as Array<keyof OfflineConversions>).map(
        (key) => ({ key: `utm_${key}`, value: offlineConversions[key] }),
      )
    : [];

  const response = await apiFetch("quote/saveAndPay", {
    body: JSON.stringify({
      paymentInfo,
      quoteDto: {
        ...quoteDto,
        utms: [...getUtmArray(), ...offlineConversionsUtm],
      },
      osInfoDto: {
        customerTZ: Intl.DateTimeFormat().resolvedOptions().timeZone,
        operationSystem: osName,
        osVersion,
      },
    }),
    method: "POST",
    throwBadResponse: false,
  });

  const json = await response.json();

  if (!response.ok) {
    throw new BadResponseError({
      message: json.message,
      status: json.statusCode,
    });
  }

  return {
    policyUrl: json.policyLink,
    receiptUrl: json.receiptUrl,
    userPolicy: {
      id: json.savedPoliciesInfos?.[0]?.id,
      uniqueUuid: json.savedPoliciesInfos?.[0]?.userPolicyUniqueUUID,
    },
  };
}

export async function sendQuotePaymentIntent(
  quoteDto: QuoteDto,
): Promise<QuotePaymentIntentResponse> {
  const response = await apiFetch("quote/payment/intent", {
    body: JSON.stringify({ ...quoteDto, utms: getUtmArray() }),
    method: "POST",
  });
  const json = await response.json();

  return {
    clientSecret: json.client_secret,
    id: json.id,
    status: json.status,
  };
}
