import { type ElementType, useState } from "react";
import { trackPolicyInfoAction } from "../../tracking";
import { Component, ProgressIndicator, Svg } from "./styles";

export interface LinkProps {
  Icon: ElementType;
  href?: string;
  label: string;
  shouldFetch?: boolean;
  track: Parameters<typeof trackPolicyInfoAction>[0];
}

export function Link({ Icon, href, label, shouldFetch, track }: LinkProps) {
  const [loading, setLoading] = useState<boolean | null>(null);

  return (
    <Component
      href={href}
      onClick={async (event) => {
        trackPolicyInfoAction(track);

        if (!href) {
          return;
        }

        event.preventDefault();

        setLoading(null);

        window.setTimeout(() => {
          setLoading((loading) => loading === null);
        }, 1000); // Show loading indicator after 1s

        if (!shouldFetch) {
          setLoading(false);
          window.location.href = href;
          return;
        }

        await fetch(href).finally(() => {
          setLoading(false);
          window.location.href = href;
        });
      }}
    >
      {loading ? <ProgressIndicator /> : <Svg as={Icon} />}
      {label}
    </Component>
  );
}
