import { type ReactNode, useEffect, useRef, useState } from "react";
import {
  validation,
  type ValidationFunction,
  type ValidationResult,
} from "utils/validation";

export function useInnerProps<Value>({
  helperText,
  onValidation,
  startingValue,
  validate,
  valueProps,
}: {
  helperText: ReactNode | undefined;
  onValidation: ((validation: ValidationResult) => void) | undefined;
  startingValue: Value;
  validate:
    | ValidationFunction<Value>
    | Array<ValidationFunction<Value>>
    | undefined;
  valueProps: {
    value?: Value;
    [key: string]: unknown;
  };
}) {
  const valueInProps = "value" in valueProps;

  const onValidationRef = useRef(onValidation);
  const validateRef = useRef(validate);
  const [changed, setChanged] = useState(false);
  const [innerValue, setInnerValue] = useState(startingValue);

  const [isDirty, setIsDirty] = useState(() =>
    valueInProps ? valueProps.value !== startingValue : false,
  );

  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);

  const error = isDirty && validationResult?.status === "error";
  const warning = isDirty && validationResult?.status === "warning";

  const value = valueInProps
    ? typeof valueProps.value !== "undefined"
      ? valueProps.value
      : startingValue
    : innerValue;

  onValidationRef.current = onValidation;
  validateRef.current = validate;

  useEffect(() => {
    if (validateRef.current) {
      setValidationResult(validation<Value>(validateRef.current, value));
    }
  }, [value]);

  useEffect(() => {
    if (onValidationRef.current && validationResult) {
      onValidationRef.current(validationResult);
    }
  }, [validationResult]);

  return {
    error,
    helperText: helperText ?? (isDirty ? validationResult?.helperText : null),
    onBlur: () => {
      if (!isDirty && changed) {
        setIsDirty(true);
      }
    },
    onChange: (value: Value) => {
      if (!changed) {
        setChanged(true);
      }

      if (!valueInProps) {
        setInnerValue(value);
      }
    },
    warning,
  };
}
