export function asyncQueue() {
  const queue: Array<() => Promise<void>> = [];

  async function executeQueue() {
    if (queue.length) {
      await queue[0]();
      queue.shift();
      await executeQueue();
    }
  }

  return {
    push: async (asyncFunction: () => Promise<void>) => {
      queue.push(asyncFunction);

      if (queue.length === 1) {
        await executeQueue();
      }
    },
  };
}
