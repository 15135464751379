import { type Dispatch, type SetStateAction, useState } from "react";

export interface ContextState<State> {
  set: Dispatch<SetStateAction<State>>;
  value: State;
}

export function useContextState<State>(
  initialState: State | (() => State),
): ContextState<State> {
  const [state, setState] = useState<State>(initialState);

  return {
    set: setState,
    value: state,
  };
}
