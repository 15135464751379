import { dialogClasses } from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import FailureAsset from "assets/images/failure.svg";
import { Popup } from "components/popup";
import { PopupButton } from "components/popup/button";
import { PopupBody as PopupBodyComponent } from "components/popup/body";
import { PopupHeader as PopupHeaderComponent } from "components/popup/header";
import { PopupTitle as PopupTitleComponent } from "components/popup/title";

export const Container = styled(Popup, {
  label: "error-popup",
})`
  text-align: center;
  word-wrap: break-word;

  .${dialogClasses.paper} {
    ${({ theme }) => theme.breakpoints.up("sm")} {
      max-width: ${({ theme }) => theme.spacing(40)};
    }
  }
`;

export const DismissButton = styled(PopupButton, {
  label: "dismiss-button",
})`
  font-size: 18px;
`;

export const FailureIcon = styled(FailureAsset, {
  label: "failure-icon",
})`
  fill: ${({ theme }) => theme.palette.color.alert.main};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  width: auto;
  height: ${({ theme }) => theme.spacing(8)};
`;

export const Lead = styled("div", {
  label: "lead",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.239;
`;

export const Message = styled("div", {
  label: "message",
})`
  color: ${({ theme }) => theme.palette.color.gray[50]};
  font-size: 14px;
  line-height: 1.5;
  margin: ${({ theme }) => theme.spacing(6, 0)};
`;

export const PopupBody = styled(PopupBodyComponent, {
  label: "popup-body",
})`
  && {
    padding-right: ${({ theme }) => theme.spacing(4)};
    padding-left: ${({ theme }) => theme.spacing(4)};
  }
`;

export const PopupHeader = styled(PopupHeaderComponent, {
  label: "popup-header",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};

  && {
    margin-right: ${({ theme }) => theme.spacing(4)};
    margin-left: ${({ theme }) => theme.spacing(4)};
  }
`;

export const PopupTitle = styled(PopupTitleComponent, {
  label: "popup-title",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
`;
