import { styled } from "@mui/material/styles";
import backgroundUrl from "assets/images/clouds.svg?url";

export const Container = styled("div", {
  label: "offer-layout",
})`
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.color.gray[100]};
  padding-bottom: ${({ theme }) => theme.spacing(14)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    background-color: ${({ theme }) => theme.palette.color.primary[95]};
    background-image: url("${backgroundUrl}");
    background-repeat: repeat-y;
  }

  &.hasPrintLayout {
    @media print {
      padding-bottom: 0;
    }
  }
`;

export const Main = styled("main", {
  label: "main",
})`
  margin: 0 auto;
  position: relative;
  padding: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(4, 8, 0)};
  }

  .hasPrintLayout & {
    @media print {
      margin: 0;
      max-width: none;
      padding: 0;
    }
  }
`;
