import { useCallback, useEffect, useRef, useState } from "react";

const MINIMUM_LOADER_TIME_MS = 2500;
const MINIMUM_LOADER_WORD_READING_TIME_MS = 250;

/**
 * @param text - according to the amount of words, the random process step will
 *               be calculated
 *
 * Example:
 * Interval = 0.25s, RandomMaxStep = 20 ----> TotalTime =~ 2.5s
 * Interval = 0.25s, RandomMaxStep = 10 ----> TotalTime =~ 5s
 * Interval = 0.25s, RandomMaxStep = 5 ----> TotalTime =~ 10s
 *
 * NOTE: Usually the average step will be half from the RandomMaxStep, so the
 * calculation should be:
 *
 * 100 (stands for 100% progress)
 * ------------------------------ * 2 (because the NOTE above) = RandomMaxStep
 * Amount of words in the text
 */
function getRandomTimeRangeMaximumProgressPerWord(text: string) {
  return (
    (100 * 2) /
    Math.max(
      MINIMUM_LOADER_TIME_MS / MINIMUM_LOADER_WORD_READING_TIME_MS,
      text.replaceAll("\n", " ").split(" ").length,
    )
  );
}

export function useProgressFromText(
  text: string,
  steps: number,
  onProgressComplete: () => void,
) {
  const randomMaxStep = useRef<number>();
  const timeout = useRef(0);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(-1);

  const onProgressCompleteRef = useRef(onProgressComplete);
  onProgressCompleteRef.current = onProgressComplete;

  const completeStep = useCallback((step?: number) => {
    setStep((currentStep) => step ?? currentStep + 1);
  }, []);

  if (typeof randomMaxStep.current === "undefined") {
    randomMaxStep.current = getRandomTimeRangeMaximumProgressPerWord(text);
  }

  useEffect(() => {
    // Set to 0 to indicate that the loading process started
    // and a calculation can be requested
    setStep(0);
  }, []);

  useEffect(() => {
    if (progress < 100 / ((steps + 1) / (step + 1))) {
      timeout.current = window.setTimeout(() => {
        setProgress((progress) =>
          progress < 100
            ? Math.min(
                progress + Math.random() * (randomMaxStep.current as number),
                100,
              )
            : 100,
        );

        timeout.current = 0;
      }, MINIMUM_LOADER_WORD_READING_TIME_MS);
    } else if (progress === 100) {
      onProgressCompleteRef.current();
    }

    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, [progress, step, steps, text]);

  return { completeStep, step };
}
