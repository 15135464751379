import { styled } from "@mui/material/styles";

export const AdditionalTitle = styled("span", {
  label: "additional-title",
})`
  font-size: 13px;
  font-weight: 600;
  margin-left: auto;
`;

export const Container = styled("h2", {
  label: "title",
})`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-top: ${({ theme }) => theme.spacing(2.5)};

  & + & {
    margin-top: 0;
  }
`;
