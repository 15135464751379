import { type TextFieldProps } from "@mui/material/TextField";
import classNames from "classnames";
import {
  Container,
  EndAdornment,
  StartAdornment,
  Wrapper,
  LabelContainer,
} from "./styles";

export type FieldProps = Pick<
  TextFieldProps,
  "className" | "error" | "children" | "InputProps" | "fullWidth" | "label"
>;

export function Field({
  className,
  error,
  children,
  InputProps,
  fullWidth,
  label,
}: FieldProps) {
  const { startAdornment, endAdornment } = InputProps ?? {};
  return (
    <Wrapper
      className={classNames(className, "faye__input", {
        "faye__input--half": !fullWidth,
        "faye__input--error": !!error,
      })}
    >
      <LabelContainer>{label}</LabelContainer>
      <Container className="faye__input__container">
        {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
        {children}
        {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
      </Container>
    </Wrapper>
  );
}
