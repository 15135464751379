import { useRef } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "components/autocomplete";
import states from "strings/states.json";
import { type State, stateFilterOptions } from "utils/place/residency";
import { Row } from "../styles";
import { useFullAddressFields, type AddressFields } from "../utils";
import { ApartmentInput, Input, StateWrapper } from "./styles";

export interface FullAddressFieldsProps {
  initialValue: AddressFields | null;
  onChange: (address: AddressFields | null) => void;
  stateRestriction?: string;
}

export function FullAddressFields({
  stateRestriction,
  ...props
}: FullAddressFieldsProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "addressFields",
  });

  const { fields, handleChange } = useFullAddressFields({
    stateRestriction,
    ...props,
  });

  const zipCodeRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Row>
        <Input
          autoComplete="address-line1"
          autoFocus={!isMobile}
          label={t("streetAndNumber")}
          name="street_and_number"
          onChange={(event) => {
            handleChange("streetAndNumber", { value: event.target.value });
          }}
          onValidation={(validation) => {
            handleChange("streetAndNumber", { validation });
          }}
          required
          validate={(value) => !!value}
          value={fields.streetAndNumber.value}
        />

        <ApartmentInput
          inputProps={{ "aria-label": t("apartment.ariaLabel") }}
          label={t("apartment.label")}
          name="apartment"
          onChange={(event) => {
            handleChange("apartment", { value: event.target.value });
          }}
          onValidation={(validation) => {
            handleChange("apartment", { validation });
          }}
          validate={(value) => value.length <= 7}
          value={fields.apartment.value}
        />
      </Row>

      <Row>
        <Input
          autoComplete="address-level2"
          label={t("city")}
          name="city"
          onChange={(event) => {
            handleChange("city", { value: event.target.value });
          }}
          onValidation={(validation) => {
            handleChange("city", { validation });
          }}
          required
          validate={(value) => value.length > 2}
          value={fields.city.value}
        />
      </Row>

      <Row>
        <StateWrapper>
          <Autocomplete
            disableClearable
            disabled={!!stateRestriction}
            filterOptions={stateFilterOptions}
            InputProps={{
              autoComplete: "off",
              error: fields.state.valid === false,
              label: t("state"),
              name: "state",
              required: true,
            }}
            noOptionsText={t("noStateMatches")}
            onBlur={() => {
              if (fields.state.valid && fields.state.value === null) {
                handleChange("state", { valid: false });
              }
            }}
            onChange={(_, value) => {
              handleChange("state", { valid: true, value });
            }}
            onKeyDown={({ code }) => {
              if (
                (code === "Backspace" || code === "Delete") &&
                fields.state.value
              ) {
                handleChange("state", { valid: false, value: null });
              }
            }}
            options={states as State[]}
            value={fields.state.value as State}
          />
        </StateWrapper>

        <Input
          autoComplete="postal-code"
          inputRef={zipCodeRef}
          label={t("zipCode")}
          name="postal_code"
          onChange={(event) => {
            handleChange("postalCode", {
              value: event.target.value.replace(/[^\d]/g, "").slice(0, 5),
            });
          }}
          onValidation={(validation) => {
            handleChange("postalCode", { validation });
          }}
          required
          validate={(value) => /^\d{5}$/.test(value)}
          value={fields.postalCode.value}
        />
      </Row>
    </>
  );
}
