import classNames from "classnames";
import { forwardRef, type ElementType } from "react";
import { type BaseButtonProps } from "./base";
import { Component, OutlinedContentWrapper, Svg, Label } from "./styles";

export interface ButtonProps extends BaseButtonProps {
  variant?: "standard" | "outlined" | "link";
  StartIcon?: ElementType;
  EndIcon?: ElementType;
  compact?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      children,
      className,
      variant = "standard",
      StartIcon,
      EndIcon,
      compact = false,
      ...props
    },
    ref,
  ) {
    return (
      <Component
        className={classNames(className, variant, { compact })}
        ref={ref}
        {...props}
      >
        {variant === "outlined" ? (
          <OutlinedContentWrapper>
            <Label>
              {StartIcon && <Svg as={StartIcon} />}
              {children} {EndIcon && <Svg as={EndIcon} />}
            </Label>
          </OutlinedContentWrapper>
        ) : (
          <Label>
            {StartIcon && <Svg as={StartIcon} />}
            {children}
            {EndIcon && <Svg as={EndIcon} />}
          </Label>
        )}
      </Component>
    );
  },
);
