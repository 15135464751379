import { styled } from "@mui/material/styles";

export const Component = styled("h2", {
  label: "popup-title",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
`;
