import { type PlaceDetails } from "types/quote-types";
import { apiFetch } from ".";

export interface PaymentAmendmentInfo {
  additionalCharge: number;
  days: number;
  destinations: PlaceDetails[];
  noOfTravelers: number;
  policyGroup: string;
  tripStartDate: string;
  tripEndsDate: string;
}

export async function getPolicyPaymentAmendmentInfo(
  hash: string,
): Promise<PaymentAmendmentInfo> {
  const response = await apiFetch(
    `/policy/amendment/link/payment/info/${hash}`,
  );

  return await response.json();
}

export async function sendPolicyAmendmentPayment({
  paymentLinkHash,
  paymentMethodNonce,
}: {
  paymentLinkHash: string;
  paymentMethodNonce: string;
}) {
  const response = await apiFetch("/policy/amendment/pay/payment/link", {
    body: JSON.stringify({ paymentLinkHash, paymentMethodNonce }),
    method: "POST",
  });

  return response.ok;
}
