import { apiFetch } from ".";

const CACHE: {
  ipIsInUs?: Promise<boolean>;
} = {};

export async function checkIpIsInUs(): Promise<boolean> {
  if (CACHE.ipIsInUs === undefined) {
    CACHE.ipIsInUs = apiFetch("ip")
      .then(async (response) => (await response.json()).isInUs)
      .finally((isInUs?: boolean) => !!isInUs);
  }

  return await CACHE.ipIsInUs;
}
