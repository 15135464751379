import { AdditionalTitle, Container } from "./styles";

export interface TitleProps {
  additionalTitle?: string;
  children: string;
}

export function Title({ additionalTitle, children }: TitleProps) {
  return (
    <Container>
      {children}
      {additionalTitle && <AdditionalTitle>{additionalTitle}</AdditionalTitle>}
    </Container>
  );
}
