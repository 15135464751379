import classNames from "classnames";
import { forwardRef, type ElementType } from "react";
import { type ButtonProps as ButtonComponentProps } from "components/button";
import { DoneLottie } from "components/lottie/done";
import { type PaymentChangeListener, type PaymentMethod } from "../..";
import ApplePayImageAsset from "assets/images/apple-pay.svg";
import GooglePayImageAsset from "assets/images/google-pay.svg";
import PayPalImageAsset from "assets/images/paypal.svg";
import { type QuoteDto } from "types/quote-types";
import { type Quote } from "store/quote/initial-state";
import { Component, Svg } from "./styles";

export type ButtonPaymentMethod = Extract<
  PaymentMethod,
  "applePay" | "googlePay" | "payPal"
>;
export interface ProviderButtonProps {
  currentPaymentMethod: PaymentMethod | null;
  onLoad: (success: boolean) => void;
  onPaymentChange: PaymentChangeListener<ButtonPaymentMethod>;
  quoteDto: QuoteDto;
  totalPrice: number;
  userPolicy?: Quote["userPolicy"];
  variant?: "dark" | "light";
}

export interface ButtonProps extends Omit<ButtonComponentProps, "variant"> {
  currentPaymentMethod: PaymentMethod | null;
  doneLabel: string;
  monochrome?: boolean;
  onDoneLottieComplete: () => void;
  paymentMethod: ButtonPaymentMethod;
  success: boolean;
  variant: "dark" | "light";
}

const ICONS = {
  applePay: ApplePayImageAsset,
  googlePay: GooglePayImageAsset,
  payPal: PayPalImageAsset,
} as Record<ButtonPaymentMethod, ElementType>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      className,
      currentPaymentMethod,
      doneLabel,
      monochrome,
      onDoneLottieComplete,
      paymentMethod,
      success,
      variant,
      ...props
    },
    ref,
  ) {
    return (
      <Component
        ref={ref}
        {...props}
        className={classNames(className, { monochrome, success })}
        disabled={
          !!currentPaymentMethod &&
          (currentPaymentMethod !== paymentMethod || !success)
        }
        loading={currentPaymentMethod === paymentMethod && !success}
        variant={variant === "light" ? "outlined" : "standard"}
      >
        {currentPaymentMethod === paymentMethod && success ? (
          <>
            <DoneLottie onComplete={onDoneLottieComplete} />
            {doneLabel}
          </>
        ) : (
          <Svg as={ICONS[paymentMethod]} />
        )}
      </Component>
    );
  },
);
