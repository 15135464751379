import { useTranslation } from "react-i18next";
import { isIOS, isMacOs } from "react-device-detect";
import { useBreakpoint } from "hooks/use-breakpoint";
import { DOWNLOAD_APP_ANDROID_URL, DOWNLOAD_APP_IOS_URL } from "settings";
import { trackDownloadClick } from "../tracking";
import { QrCode } from "./qrcode";
import {
  AppStoreImage,
  Container,
  Content,
  GooglePlayImage,
  Lead,
  Link,
  Links,
  Title,
} from "./styles";

export function Download() {
  const { t } = useTranslation("screens", {
    keyPrefix: "confirmation.download",
  });

  const mdBreakpoint = useBreakpoint("md", { noSsr: true });

  return (
    <Container>
      <Title>
        <span className="caption">{t("caption")}</span>
        {t("newTitle")}
      </Title>

      <Content>
        <QrCode />

        <div>
          <Lead>{t("lead")}</Lead>

          <Links>
            {(mdBreakpoint || isIOS || isMacOs) && (
              <Link
                href={DOWNLOAD_APP_IOS_URL}
                onClick={() => {
                  trackDownloadClick("apple");
                }}
                {...{ target: "_blank" }}
              >
                <AppStoreImage />
              </Link>
            )}
            {(mdBreakpoint || !(isIOS || isMacOs)) && (
              <Link
                href={DOWNLOAD_APP_ANDROID_URL}
                onClick={() => {
                  trackDownloadClick("other");
                }}
                {...{ target: "_blank" }}
              >
                <GooglePlayImage />
              </Link>
            )}
          </Links>
        </div>
      </Content>
    </Container>
  );
}
