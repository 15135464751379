import { Link as LinkComponent, type LinkProps } from "components/i18n/link";
import { mixpanelTrack } from "utils/tracking/mixpanel";

export function Link({ to, ...props }: LinkProps) {
  return (
    <LinkComponent
      {...props}
      onClick={() => {
        if (to?.includes("privacy-policy")) {
          mixpanelTrack("clicked_privacy_link");
        } else if (to?.includes("terms-of-use")) {
          mixpanelTrack("clicked_tos_link");
        }
      }}
      to={to}
    />
  );
}
