import classNames from "classnames";
import { useContext } from "react";
import { dateRangeFormatter } from "utils/formatters/date-range";
import { type PopupSectionProps } from "../../popup-section";
import { CarCareContext } from "..";
import { Chip, ChipsWrapper, CloseIcon, Container, Label } from "./styles";

export interface SelectedDaysProps extends PopupSectionProps {
  label: string;
}

export function SelectedDays({ label, ...props }: SelectedDaysProps) {
  const { dateRanges, removeDateRange } = useContext(CarCareContext);

  return (
    <Container {...props}>
      <Label className={classNames(dateRanges.length === 0 && "disabled")}>
        {label}
      </Label>

      <ChipsWrapper>
        {dateRanges.map(({ end, start }, index) => (
          <Chip
            icon={<CloseIcon />}
            key={`${start.valueOf()}-${end.valueOf()}-${index}`}
            label={dateRangeFormatter(start.toDate(), end.toDate(), {
              style: "short",
            })}
            onClick={() => {
              removeDateRange(index);
            }}
          />
        ))}
      </ChipsWrapper>
    </Container>
  );
}
