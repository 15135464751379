import classNames from "classnames";
import { type Dayjs } from "dayjs";
import { type PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { forwardRef, useState } from "react";
import { type DateRangePickerDayProps } from "@mui/x-date-pickers-pro/DateRangePickerDay";
import { Hidden } from "./hidden";
import { Component } from "./styles";

export const Day = forwardRef<
  HTMLButtonElement,
  DateRangePickerDayProps<Dayjs> & PickersDayProps<Dayjs>
>(function Day(
  {
    classes: _classes, // Ignore
    className,
    day,
    disableHighlightToday: _disableHighlightToday, // Ignore
    disableMargin: _disableMargin, // Ignore
    isAnimating: _isAnimating, // Ignore
    isEndOfHighlighting,
    isEndOfPreviewing,
    isFirstVisibleCell: _isFirstVisibleCell,
    isHighlighting,
    isLastVisibleCell: _isLastVisibleCell,
    isPreviewing,
    isStartOfHighlighting,
    isStartOfPreviewing,
    isVisuallySelected: _isVisuallySelected,
    onBlur,
    onClick,
    onDaySelect,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    outsideCurrentMonth,
    role,
    selected,
    showDaysOutsideCurrentMonth: _showDaysOutsideCurrentMonth, // Ignore
    today,
    ...props
  },
  ref,
) {
  const [hover, setHover] = useState(false);

  if (outsideCurrentMonth) {
    return <Hidden ref={ref} role={role} />;
  }

  return (
    <Component
      centerRipple
      className={classNames(className, {
        highlight: isHighlighting,
        highlightStart: isStartOfHighlighting,
        highlightEnd: isEndOfHighlighting,
        hover,
        preview: isPreviewing,
        previewStart: isStartOfPreviewing,
        previewEnd: isEndOfPreviewing,
        selected,
        today,
      })}
      onBlur={(event) => {
        if (onBlur) {
          onBlur(event, day);
        }
      }}
      onClick={(event) => {
        onDaySelect(day);
        setHover(false);

        if (onClick) {
          onClick(event);
        }
      }}
      onFocus={(event) => {
        if (onFocus) {
          onFocus(event, day);
        }
      }}
      onKeyDown={(event) => {
        if (onKeyDown) {
          onKeyDown(event, day);
        }
      }}
      ref={ref}
      role={role}
      {...props}
      onMouseEnter={(event) => {
        setHover(true);

        if (onMouseEnter) {
          onMouseEnter(event, day);
        }
      }}
      onMouseLeave={(event) => {
        setHover(false);

        if (onMouseLeave) {
          onMouseLeave(event);
        }
      }}
    >
      {day.date()}
    </Component>
  );
});
