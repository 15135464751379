import classNames from "classnames";
import { type ComponentPropsWithoutRef, type FormEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { type ButtonProps } from "components/button";
import { getGtmDataLayer } from "utils/tracking/gtm";
import { mixpanelTrack } from "utils/tracking/mixpanel";
import { Disclaimer } from "./disclaimer";
import { ArrowIcon, Button, ButtonGroup, Container } from "./styles";

export interface FormButtonProps extends ButtonProps {
  label?: string | null;
  showArrow?: boolean;
}

export interface FormProps
  extends Omit<ComponentPropsWithoutRef<"form">, "onSubmit"> {
  AdditionalButtonProps?: FormButtonProps;
  SubmitButtonProps?: FormButtonProps;
  onSubmit?: (
    event: FormEvent<HTMLFormElement>,
    response: {
      isAdditionalButtonRequest: boolean;
    },
  ) => void;
  showAdditionalButton?: boolean;
  showDisclaimer?: boolean;
  submittable: boolean;
}

export function Form({
  AdditionalButtonProps: {
    label: additionalButtonLabel = null,
    onClick: additionalButtonOnClick,
    ...AdditionalButtonProps
  } = {},
  SubmitButtonProps: {
    label: submitButtonLabel = null,
    showArrow: submitButtonArrow = false,
    ...SubmitButtonProps
  } = {},
  children,
  className,
  onSubmit,
  showAdditionalButton,
  showDisclaimer,
  submittable,
  ...props
}: FormProps) {
  const { t } = useTranslation("components", {
    keyPrefix: "form",
  });

  const isAdditionalButtonRequest = useRef(false);
  const hasAdditionalButton =
    showAdditionalButton && additionalButtonLabel !== null;

  return (
    <Container
      className={classNames(className, {
        "single-button": !hasAdditionalButton,
      })}
      {...props}
      onSubmit={(event) => {
        event.preventDefault();

        if (submittable && onSubmit) {
          onSubmit(event, {
            isAdditionalButtonRequest: isAdditionalButtonRequest.current,
          });

          if (showDisclaimer && !isAdditionalButtonRequest.current) {
            mixpanelTrack("clicked_agree_and_next");
            getGtmDataLayer().push({ is_consent: true });
          }
        }

        isAdditionalButtonRequest.current = false;
      }}
    >
      <button
        disabled={!submittable}
        {...SubmitButtonProps}
        className=""
        hidden
        style={{}}
        type="submit"
      />

      {children}

      {showDisclaimer && <Disclaimer />}

      <ButtonGroup>
        {hasAdditionalButton && (
          <Button
            disabled={!submittable}
            type="submit"
            variant="outlined"
            {...AdditionalButtonProps}
            onClick={(event) => {
              isAdditionalButtonRequest.current = true;

              if (additionalButtonOnClick) {
                additionalButtonOnClick(event);
              }
            }}
          >
            {additionalButtonLabel}
          </Button>
        )}

        <Button disabled={!submittable} {...SubmitButtonProps} type="submit">
          {submitButtonLabel ?? t("defaultSubmitButtonLabel")}
          {submitButtonArrow && <ArrowIcon />}
        </Button>
      </ButtonGroup>
    </Container>
  );
}
