import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "hooks/use-dispatch";
import { useSelector } from "hooks/use-selector";
import { getExperimentVariation } from "utils/experiments";
import {
  selectEmail,
  selectDateOfBirth,
  selectName,
  selectTravelers,
} from "store/quote/selectors";
import { setTravelersThunk } from "store/quote/thunks/steps";
import { Fields } from "./fields";
import {
  useTracking,
  trackAddTraveler,
  useTrackSubmit,
  trackRemoveTraveler,
} from "./tracking";
import { AddButton, Form, ScreenHeader } from "./styles";
import PlusIconAsset from "assets/images/icons/plus.svg";

export interface Traveler {
  email?: string;
  birthdate: string;
  firstName: string;
  lastName: string;
}

export function TravelersScreen() {
  useTracking();
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);
  const dateOfBirth = useSelector(selectDateOfBirth);
  const name = useSelector(selectName);
  const travelers = useSelector(selectTravelers);
  const [renderKey, setRenderKey] = useState(0);
  const shortObExperiment = getExperimentVariation("shortOb") === "active";
  const trackSubmit = useTrackSubmit();
  const navigate = useNavigate();
  const { t } = useTranslation("screens", { keyPrefix: "travelers" });

  const [value, setValue] = useState<Array<Traveler | null>>(() => [
    dateOfBirth && name
      ? {
          email,
          birthdate: dateOfBirth,
          firstName: name.firstName,
          lastName: name.lastName,
        }
      : null,
    ...travelers
      .slice(1)
      .map(({ dateOfBirth, firstName, lastName, email }) => ({
        birthdate: dateOfBirth,
        firstName,
        lastName,
        email,
      })),
  ]);

  return (
    <>
      <ScreenHeader title={t("title")} />

      <Form
        SubmitButtonProps={{ label: t("submitButton.label"), showArrow: true }}
        onSubmit={async () => {
          if (value.some((value) => value === null)) {
            return;
          }

          await dispatch(
            setTravelersThunk(
              (value as Traveler[]).map(
                ({ birthdate, firstName, lastName, email }) => ({
                  ...(!shortObExperiment && { email }),
                  dateOfBirth: birthdate,
                  firstName,
                  lastName,
                }),
              ),
            ),
          );

          const { birthdate, firstName, lastName, email } =
            value[0] as Traveler;
          trackSubmit(firstName, lastName, birthdate, value.length, email);

          navigate("/offer");
        }}
        submittable={!value.some((value) => value === null)}
      >
        {value.map((value, index) => (
          <Fields
            key={`${renderKey}_${index}`}
            index={index}
            initialValue={value}
            onChange={(traveler, ready) => {
              setValue((value) => [
                ...value.slice(0, index),
                ...(traveler ? (ready ? [traveler] : [null]) : []),
                ...value.slice(index + 1),
              ]);

              // traveler removed
              if (traveler === null) {
                trackRemoveTraveler();
                setRenderKey((key) => key + 1);
              }
            }}
          />
        ))}

        <AddButton
          variant="outlined"
          compact
          onClick={() => {
            if (value[value.length - 1]) {
              setValue((value) => [...value, null]);
              trackAddTraveler();
            }
          }}
          StartIcon={PlusIconAsset}
        >
          {t("addTravelerButton.label")}
        </AddButton>
      </Form>
    </>
  );
}
