import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { PlainButton } from "components/button/plain";

export const Component = styled(PlainButton, {
  label: "link",
})`
  color: ${({ theme }) => theme.palette.color.primary.main};
  font-size: 16px;
  font-weight: 500;

  &.loading {
    pointer-events: none;
  }
`;

export const ProgressIndicator = styled(CircularProgress, {
  label: "progress-indicator",
})`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  width: ${({ theme }) => theme.spacing(2)} !important;
  height: ${({ theme }) => theme.spacing(2)} !important;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Svg = styled("svg", {
  label: "svg",
})`
  fill: currentColor;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  width: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
