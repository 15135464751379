import { type ComponentPropsWithRef } from "react";
import { WEBSITE_URL } from "legacy/utils/constants";
import { mixpanelTrack } from "utils/tracking/mixpanel";
import { Button, Container, ProfileLogo } from "./styles";

export function CircularLogo(props: ComponentPropsWithRef<"div">) {
  return (
    <Container {...props}>
      <Button
        href={WEBSITE_URL}
        onClick={() => {
          // The page will unload when the navigation goes to the website, and
          // this event will not be immediately fired. Mixpanel saves it to
          // localStorage. Once the website loads Mixpanel, it'll get the
          // cached event and send it.
          mixpanelTrack("logo_pressed");
        }}
      >
        <ProfileLogo />
      </Button>
    </Container>
  );
}
