import { useTranslation } from "react-i18next";
import { useSelector } from "hooks/use-selector";
import { selectEmail, selectResidency } from "store/quote/selectors";
import { TopicList } from "../topic-list";

export function PersonalDetails() {
  const email = useSelector(selectEmail);
  const residency = useSelector(selectResidency);

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.printLayout.personalDetails",
  });

  return (
    <TopicList
      items={[
        {
          title: t("email.title"),
          description: email ?? "",
        },
        {
          title: t("address.title"),
          description: residency?.areaLevel1 ?? "",
        },
      ]}
      template="personalDetails"
    />
  );
}
