import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "store";
import { type QuoteDto, QuoteDtoBase } from "types/quote-types";
import { hasTripCancellationCoverage } from "utils/coverage-collections";
import { numberFormatter } from "utils/formatters/number";
import { type Quote } from "../initial-state";

export const selectQuoteDto = (state: RootState) => state.quote.quoteDto;
export const selectName = (state: RootState) => state.quote.name;
export const selectDateOfBirth = (state: RootState) => state.quote.dateOfBirth;
export const selectTripStartDate = (state: RootState) =>
  state.quote.tripStartDate;
export const selectTripEndDate = (state: RootState) => state.quote.tripEndDate;
export const selectDestinations = (state: RootState) =>
  state.quote.destinations;
export const selectResidency = (state: RootState) => state.quote.residency;
export const selectTravelers = (state: RootState) =>
  state.quote.travelers ?? [];
export const selectEmail = (state: RootState) => state.quote.email;
export const selectCoveragesPrices = (state: RootState) =>
  state.quote.coveragesPrices;
export const selectDestinationsGoogleData = (state: RootState) =>
  state.quote.destinationsGoogleData;
export const selectIsDirectOffer = (state: RootState) =>
  state.quote.isDirectOffer;
export const selectIsRemarketing = (state: RootState) =>
  state.quote.isRemarketing;
export const selectIsTestUser = (state: RootState) => state.quote.isTestUser;
export const selectInitialTripCost = (state: RootState) =>
  state.quote.quoteDto?.initialTripCost;
export const selectTripCost = (state: RootState) =>
  state.quote.quoteDto?.tripCost;
export const selectPolicyCodeHash = (state: RootState) =>
  state.quote.policyCodeHash;
export const selectDefaultTripCost = (state: RootState) =>
  state.quote.quoteDto?.defaultTripCost;
export const selectResidencyState = (state: RootState) =>
  state.quote.residency?.areaLevel1?.toUpperCase() ?? null;
export const selectCoverages = (state: RootState) =>
  state.quote.quoteDto?.coverages ?? [];
export const selectRemovedTravelers = (state: RootState) =>
  state.quote.removedTravelers;
export const selectDisclaimerPdfUrl = (state: RootState) =>
  state.quote.disclaimerPdfUrl ?? null;
export const selectCarDates = (state: RootState) =>
  state.quote.quoteDto?.carDatesDto ?? null;
export const selectPolicyUrl = (state: RootState) => state.quote.policyUrl;
export const selectReceiptUrl = (state: RootState) => state.quote.receiptUrl;
export const selectUserPolicy = (state: RootState) => state.quote.userPolicy;
export const selectTotalPrice = (state: RootState) => state.quote.totalPrice;
export const selectCalculate = (state: RootState) => state.quote.calculate;
export const selectQuote = (state: RootState) => state.quote.quote;
export const selectQuoteFromHash = (state: RootState) =>
  state.quote.quoteFromHash;
export const selectReviewAndEdit = (state: RootState) =>
  state.quote.reviewAndEdit;

export const selectUpdatedQuoteDto = (state: RootState) => {
  const quoteDto = state.quote.quoteDto;
  const quoteValues: Record<string, unknown> = {};
  const keys = Object.keys(new QuoteDtoBase()) as Array<keyof QuoteDto>;

  for (const key of keys) {
    const value = state.quote[key as keyof Quote] ?? quoteDto?.[key];

    if (value != null) {
      quoteValues[key] =
        key === "email" ? (value as string).toLocaleLowerCase() : value;
    }
  }

  return { ...quoteDto, ...quoteValues } as QuoteDto;
};

export const selectIsDomesticTraveling = createSelector(
  selectDestinations,
  (destinations) =>
    !destinations?.some((currentDestination) => {
      return currentDestination.country.toLowerCase() !== "us";
    }),
);

export const selectHasTripCancellationCoverage = createSelector(
  selectCoverages,
  (coverages) => hasTripCancellationCoverage(coverages),
);

export const selectCanGetQuote = createSelector(
  selectIsDirectOffer,
  selectPolicyCodeHash,
  selectEmail,
  (isDirectOffer, policyCodeHash) => {
    if (isDirectOffer === undefined) {
      return false;
    }

    // arrived through steps flow || !!(arrived from remarketing link)
    return !isDirectOffer || !!policyCodeHash;
  },
);

export const selectFormattedTotalPrice = createSelector(
  selectTotalPrice,
  (totalPrice) => {
    return totalPrice === undefined
      ? null
      : numberFormatter(totalPrice, { style: "currency" });
  },
);

export const selectTotalPriceParts = createSelector(
  selectTotalPrice,
  (totalPrice) => {
    return totalPrice === undefined
      ? null
      : numberFormatter(totalPrice, { getParts: true, style: "currency" });
  },
);

export const selectFullName = createSelector(selectName, (name) => {
  return `${name?.firstName ?? ""} ${name?.lastName ?? ""}`.trim();
});
