import { type Dayjs } from "dayjs";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "utils/tracking";
import { mixpanelTrack, updateMixpanelUser } from "utils/tracking/mixpanel";

export function trackSubmit(startDate: Dayjs, endDate: Dayjs) {
  const data = {
    days: endDate.startOf("day").diff(startDate.startOf("day")),
    end_date: endDate.format("YYYY-MM-DD"),
    start_date: startDate.format("YYYY-MM-DD"),
  };

  updateMixpanelUser(data);
  mixpanelTrack("setting_dates", data);
}

export function useTracking() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView("Dates", pathname);
  }, [pathname]);
}
