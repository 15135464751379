import { styled } from "@mui/material/styles";
import PlusIconAsset from "assets/images/icons/plus.svg";
import { Button } from "components/button";
import { Form as FormComponent } from "components/form";
import { ScreenHeader as ScreenHeaderComponent } from "components/screen-header";

export const AddButton = styled(Button, {
  label: "add-button",
})`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const Form = styled(FormComponent, {
  label: "form",
})`
  && {
    max-width: ${({ theme }) => theme.spacing(80)};
  }
`;

export const PlusIcon = styled(PlusIconAsset, {
  label: "plus-badge-icon",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
`;

export const ScreenHeader = styled(ScreenHeaderComponent, {
  label: "screen-header",
})`
  margin-right: auto;
  margin-left: auto;
  max-width: ${({ theme }) => theme.spacing(80)};
`;
