import { styled } from "@mui/material/styles";
import { Title as TitleComponent } from "../title";

export const Container = styled("div", {
  label: "trip-info",
})`
  overflow: hidden;
`;

export const DestinationContainer = styled("div", {
  label: "destination-container",
})`
  align-items: baseline;
  color: ${({ theme }) => theme.palette.color.primary.main};
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  gap: ${({ theme }) => theme.spacing(0.75)};
  line-height: 1;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Destination = styled("div", {
  label: "destination",
})`
  font-size: 30px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 46px;
  }
`;

export const DestinationMore = styled("span", {
  label: "destination-more",
})`
  font-size: 18px;
  white-space: nowrap;
`;

export const Title = styled(TitleComponent, {
  label: "title",
})`
  display: none;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: initial;
  }
`;

export const Days = styled("div", { label: "days" })`
  font-size: 14px;
  font-weight: 400;
  line-height: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

export const TripDates = styled("div", {
  label: "trip-dates",
})`
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  margin-top: ${({ theme }) => theme.spacing(0.75)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 24px;
    margin-top: ${({ theme }) => theme.spacing(1.5)};
  }
`;
