import { createFilterOptions } from "@mui/material/Autocomplete";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Autocomplete as AutocompleteComponent } from "components/autocomplete";
import { selectResidency } from "store/quote/selectors";
import states from "strings/states.json";
import { useSelector } from "hooks/use-selector";
import { DropdownIcon, DropdownTrigger } from "./styles";

export interface AutocompleteProps {
  onChange: (value: State | null) => void;
  placeholder?: string;
}

export interface State {
  label: string;
  value: string;
}

export function Autocomplete({ onChange, placeholder }: AutocompleteProps) {
  const residency = useSelector(selectResidency);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<State | string>("");

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const previousOnChangeValue = useRef<State | null>(
    typeof value !== "string" ? value : null,
  );

  useEffect(() => {
    if (typeof value === "string") {
      if (value === "" && previousOnChangeValue.current) {
        previousOnChangeValue.current = null;
        onChangeRef.current(null);
      }
    } else {
      previousOnChangeValue.current = value;
      onChangeRef.current(value);
    }
  }, [value]);

  useEffect(() => {
    if (residency?.areaLevel1) {
      setValue(
        states.find(({ value }) => {
          return value === residency.areaLevel1;
        }) as State,
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AutocompleteComponent
      disableClearable
      disablePortal
      filterOptions={createFilterOptions({
        // Use NUL char to separate query parts, so users can't match queries
        // like "new york ny", "new york - ny", etc.
        stringify: (option) => `${option.label} ${option.value}`,
      })}
      freeSolo
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label
      }
      InputProps={{
        autoComplete: "off",
        InputProps: {
          endAdornment: (
            <DropdownTrigger
              className={classNames({ active: open })}
              disableRipple
              onClick={() => {
                setOpen((open) => !open);
              }}
            >
              <DropdownIcon />
            </DropdownTrigger>
          ),
        },
        label: placeholder,
        name: "state",
        variant: "simple",
      }}
      onBlur={() => {
        if (typeof value === "string") {
          setValue(states.find((state) => state.label === value) ?? "");
        }
      }}
      onChange={(_, value) => {
        setValue(value);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(_, value) => {
        setValue(value);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      options={states as State[]}
      value={value as State}
    />
  );
}
