import { type Dayjs } from "dayjs";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "components/popup";
import { updateCarDetails } from "store/quote";
import { numberFormatter } from "utils/formatters/number";
import { useDispatch } from "hooks/use-dispatch";
import { useCoverage } from "../../../utils";
import { Item, type ItemProps } from "../item";
import { PopupBody } from "../popup-body";
import { PopupButton } from "../popup-button";
import { PopupHeader } from "../popup-header";
import { DatePicker } from "./date-picker";
import { SelectedDays } from "./selected-days";
import { SelectedCars } from "./selected-cars";
import { Total } from "./total";

export interface DateRange {
  start: Dayjs;
  end: Dayjs;
}

export const CarCareContext = createContext<{
  addDateRange: (start: Dayjs, end: Dayjs) => void;
  removeDateRange: (index: number) => void;
  countDateRanges: () => number;
  updateCars: (amount: number) => void;
  dateRanges: DateRange[];
  cars: number;
}>({
  addDateRange: () => {},
  removeDateRange: () => {},
  countDateRanges: () => 0,
  updateCars: () => {},
  dateRanges: [],
  cars: 1,
});

export function CarCare({ coverageType, price, ...props }: ItemProps) {
  const dispatch = useDispatch();

  const { t } = useTranslation("screens", {
    keyPrefix: "offer.addOns.carCare",
  });

  const { coverage, enable: enableCoverage } = useCoverage(coverageType);
  const [dateRanges, setDateRanges] = useState<DateRange[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [cars, setCars] = useState(1);
  const totalPrice = (price || 0) * countDateRanges() * cars;

  function addDateRange(start: Dayjs, end: Dayjs) {
    setDateRanges([...dateRanges, { start, end }]);
  }

  function removeDateRange(index: number) {
    setDateRanges([
      ...dateRanges.slice(0, index),
      ...dateRanges.slice(index + 1),
    ]);
  }

  function countDateRanges() {
    return dateRanges.reduce((result, range) => {
      return result + range.end.diff(range.start, "day") + 1;
    }, 0);
  }

  function closePopup() {
    setShowPopup(false);
  }

  function openPopup() {
    setShowPopup(true);
  }

  useEffect(() => {
    if (showPopup) {
      setDateRanges([]);
      setCars(1);
    }
  }, [showPopup]);

  return (
    <CarCareContext.Provider
      value={{
        addDateRange,
        removeDateRange,
        countDateRanges,
        updateCars: setCars,
        dateRanges,
        cars,
      }}
    >
      <Item
        {...props}
        CtaButtonProps={{ onClick: openPopup }}
        coverageType={coverageType}
        price={price}
        priceSuffix={t("priceSuffix")}
        triggerLabel={!coverage?.isSelected ? t("popup.link") : null}
        TriggerProps={{ onClick: openPopup }}
      />

      <Popup onClose={closePopup} open={showPopup}>
        <PopupHeader onClose={closePopup} title={t("popup.title")} />

        <PopupBody>
          <DatePicker label={t("popup.datePickerLabel")} />
          <SelectedDays label={t("popup.selectedDaysLabel")} />
          <SelectedCars label={t("popup.cars.label")} />
          <Total
            label={t("popup.totalLabel")}
            {...(price !== false && { coveragePrice: price })}
            totalPrice={totalPrice}
          />

          <PopupButton
            showArrow
            disabled={!dateRanges.length}
            onClick={() => {
              dispatch(
                updateCarDetails({
                  carsCount: cars,
                  dates: dateRanges.map(({ start, end }) => ({
                    startDate: start.format("YYYY-MM-DD"),
                    endDate: end.format("YYYY-MM-DD"),
                  })),
                }),
              );

              enableCoverage();
              closePopup();
            }}
          >
            {dateRanges.length && price
              ? t("popup.ctaEnabled", {
                  price: numberFormatter(totalPrice, { style: "currency" }),
                })
              : t("popup.ctaDisabled")}
          </PopupButton>
        </PopupBody>
      </Popup>
    </CarCareContext.Provider>
  );
}
