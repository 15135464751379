import { AddOns } from "./add-ons";
import { Container, SectionsWrapper } from "./styles";
import { WhatsCovered } from "./whats-covered";

export function Body() {
  return (
    <Container>
      <SectionsWrapper>
        <WhatsCovered />
        <AddOns />
      </SectionsWrapper>
    </Container>
  );
}
