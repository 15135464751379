import { useEffect, useState } from "react";
import { type AdvisorProfile, getAdvisorProfile } from "utils/api/advisor";

export function useAdvisorProfile() {
  const [advisorProfile, setAdvisorProfile] = useState<AdvisorProfile | null>();

  useEffect(() => {
    void getAdvisorProfile().then((advisorProfile) => {
      setAdvisorProfile(advisorProfile);
    });
  }, []);

  return advisorProfile;
}
