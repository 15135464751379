import { styled } from "@mui/material/styles";

export const Content = styled("p", {
  label: "content",
})`
  font-size: 10px;
  font-weight: 300;
  line-height: 1.19;
  margin-top: ${({ theme }) => theme.spacing(2.5)};
`;
