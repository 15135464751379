import { type MutableRefObject, useRef, useState } from "react";
import {
  type AnimationConfig,
  type AnimationEventCallback,
  type AnimationEventName,
  type AnimationEvents,
  type AnimationItem,
  type LottiePlayer,
} from "lottie-web";

export interface LottieAnimation {
  addEventListener: <Name extends AnimationEventName>(
    name: Name,
    callback: AnimationEventCallback<AnimationEvents[Name]>,
  ) => void;
  removeEventListener: <Name extends AnimationEventName>(
    name: Name,
    callback: AnimationEventCallback<AnimationEvents[Name]>,
  ) => void;
  item?: Promise<AnimationItem>;
  load: () => void;
  pause: () => void;
  play: (restart?: boolean) => void;
  ready: boolean;
}

export interface LottieOptions
  extends Omit<AnimationConfig, "autoplay" | "container" | "path"> {
  delay?: number;
}

const CACHE: {
  lottie?: Promise<LottiePlayer>;
} = {};

export function useLottieAnimation(
  containerRef: MutableRefObject<Element>,
  path: string,
  { delay, ...options }: LottieOptions = {},
) {
  const readyRef = useRef(false);

  const [animation, setAnimation] = useState<LottieAnimation>({
    addEventListener: (name, callback) => {
      void animation.item?.then((animation) => {
        animation.addEventListener(name, callback);
      });
    },

    removeEventListener: (name, callback) => {
      void animation.item?.then((animation) => {
        animation.removeEventListener(name, callback);
      });
    },

    load: () => {
      if (!CACHE.lottie) {
        CACHE.lottie = new Promise((resolve) => {
          window.setTimeout(resolve, delay ?? 0);
        }).then(
          async () =>
            await import("lottie-web").then((module) => {
              return module.default;
            }),
        );
      }

      animation.item = CACHE.lottie.then((lottie) => {
        const animation = lottie.loadAnimation({
          ...options,
          autoplay: false,
          container: containerRef.current,
          path,
        });

        animation.addEventListener("DOMLoaded", () => {
          setAnimation((animation) => ({ ...animation, ready: true }));
          readyRef.current = true;
        });

        return animation;
      });
    },

    pause: () => {
      void animation.item?.then((animation) => {
        if (!animation.isPaused) {
          animation.pause();
        }
      });
    },

    play: (restart) => {
      void new Promise<AnimationItem>((resolve) => {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        window.setTimeout(resolve, (readyRef.current && delay) || 0);
      }).then(
        async () =>
          await animation.item?.then((animation) => {
            if (animation.isPaused) {
              if (restart) {
                animation.stop();
                animation.play();
              } else {
                animation.play();
              }
            }
          }),
      );
    },

    ready: readyRef.current,
  });

  return animation;
}
