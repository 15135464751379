import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "offer-layout-print",
})`
  @page {
    margin: 0.75in;
    size: letter;
  }

  color: ${({ theme }) => theme.palette.color.text.primary};
  display: none;
  overflow: hidden;

  @media print {
    display: block;
  }
`;
