import dayjs, { type Dayjs } from "dayjs";
import { forwardRef, useEffect, useRef, useState } from "react";
import { type ValidationProps } from "utils/validation";
import { type BaseInputProps } from "../base";
import { useInnerProps } from "../utils";
import { Component } from "./styles";

type InputValue = Record<"day" | "month" | "year", string>;

export interface DateInputProps
  extends Omit<BaseInputProps, "onChange">,
    ValidationProps<Dayjs | null> {
  onChange?: (value: Dayjs | null) => void;
  value?: Dayjs | null;
}

const CHAR_REGEXP = /[^\d/]/;
const DIGIT_REGEXP = /\d/;
const DIGITS_FROM_START_REGEXP = /^\d+(\/|$)/;
const LAST_DIGIT_OR_SLASH_REGEXP = /[\d/](?=[^\d])|$/;
const NON_DIGITS_GLOBAL_REGEXP = /[^\d]+/g;
const SINGLE_DIGIT_OR_SLASH_REGEXP = /[\d/]/;
const SLASHES_GLOBAL_REGEXP = /\//g;
const ZERO_PAD_TEST_REGEXP = /[1-9][^\d]/;

const EMPTY_VALUES = { day: "DD", month: "MM", year: "YYYY" };

function getDateString(parts: InputValue) {
  return parts ? `${parts.month}/${parts.day}/${parts.year}` : "";
}

export const DateInput = forwardRef<HTMLDivElement, DateInputProps>(
  function DateInput(
    { helperText, onBlur, onChange, onValidation, validate, ...props },
    ref,
  ) {
    const backspaceRef = useRef(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [cursor, setCursor] = useState(0);

    const [inputValue, setInputValue] = useState<InputValue | null>(null);

    const innerProps = useInnerProps({
      helperText,
      onValidation,
      startingValue: null as Dayjs | null,
      validate,
      valueProps: props,
    });

    const { value: _, ...propsWithoutValue } = props;

    function cursorFlickeringFix() {
      // When the input is clicked, the cursor will appear in the clicked position
      // — or in the end of the input, when the field is edited —, then it will
      // immediately go to the position defined in the cursor state. This fix
      // makes caret-color transparent for a short period, so the cursor will
      // appear only in the position defined by cursor state.
      const input = inputRef.current;

      if (!input) {
        return;
      }

      input.style.caretColor = "transparent";

      window.setTimeout(() => {
        input.style.caretColor = "";
      }, 50);
    }

    function updateCursor(index: number) {
      if (index !== cursor) {
        setCursor(index);
      } else {
        inputRef.current?.setSelectionRange(index, index);
      }
    }

    function zeroPad(datePart: "day" | "month") {
      if (!inputValue || !ZERO_PAD_TEST_REGEXP.test(inputValue[datePart])) {
        return;
      }

      setInputValue({
        ...inputValue,
        [datePart]: inputValue[datePart]
          .replace(NON_DIGITS_GLOBAL_REGEXP, "")
          .padStart(2, "0")
          .slice(-2),
      });

      window.requestAnimationFrame(() => {
        updateCursor(cursor);
      });
    }

    useEffect(() => {
      inputRef.current?.setSelectionRange(cursor, cursor);
    }, [cursor]);

    return (
      <Component
        error={innerProps.error}
        helperText={innerProps.helperText}
        ref={ref}
        warning={innerProps.warning}
        placeholder={getDateString(EMPTY_VALUES)}
        {...propsWithoutValue}
        InputProps={{
          ...props.InputProps,
          value: inputValue ? getDateString(inputValue) : "",
        }}
        inputProps={{
          ...props.inputProps,
          onBlur: (event) => {
            if (!DIGIT_REGEXP.test(event.currentTarget.value)) {
              setInputValue(null);
            }
          },
          onClick: (event) => {
            const { currentTarget } = event;
            const selectionStart = currentTarget.selectionStart ?? 0;

            if (selectionStart > 2) {
              zeroPad("month");
              if (selectionStart > 5) {
                zeroPad("day");
              }
            } else if (selectionStart < 3) {
              zeroPad("day");
            }
          },
          onFocus: (event) => {
            const value =
              event.currentTarget.value || getDateString(EMPTY_VALUES);

            const index = value.search(CHAR_REGEXP);

            if (inputValue === null) {
              setInputValue(EMPTY_VALUES);
            }

            if (index !== -1) {
              window.requestAnimationFrame(() => {
                updateCursor(index);
              });
            }
          },
          onKeyDown: (event) => {
            const { currentTarget, key } = event;
            const selectionStart = currentTarget.selectionStart ?? 0;
            const { value } = currentTarget;

            if (key === "Backspace") {
              if (event.altKey || event.ctrlKey || event.metaKey) {
                event.preventDefault();
              } else if (value[selectionStart - 1] === "/") {
                event.preventDefault();
                const index = selectionStart - 1;
                updateCursor(index);
              } else {
                backspaceRef.current = true;
              }
            } else if (key === "ArrowLeft" || key === "ArrowRight") {
              if (event.shiftKey) {
                event.preventDefault();
              } else if (
                key === "ArrowLeft" &&
                value[selectionStart - 1] === "/"
              ) {
                event.preventDefault();

                const boundaryIndex = value
                  .split("")
                  .reverse()
                  .join("")
                  .slice(1 - selectionStart)
                  .search(SINGLE_DIGIT_OR_SLASH_REGEXP);

                const index =
                  boundaryIndex !== -1
                    ? (boundaryIndex + (1 - selectionStart)) * -1
                    : 0;

                updateCursor(index);
              } else if (
                key === "ArrowRight" &&
                !SINGLE_DIGIT_OR_SLASH_REGEXP.test(value[selectionStart])
              ) {
                event.preventDefault();

                if (selectionStart < 6) {
                  const boundaryIndex = value
                    .slice(selectionStart)
                    .search(LAST_DIGIT_OR_SLASH_REGEXP);

                  if (boundaryIndex !== -1) {
                    const index = boundaryIndex + selectionStart + 1;
                    updateCursor(index);
                  }
                }
              }

              if (key === "ArrowLeft" && selectionStart === 3) {
                zeroPad("day");
              } else if (key === "ArrowRight") {
                if (selectionStart === 1) {
                  zeroPad("month");
                } else if (selectionStart === 4) {
                  zeroPad("day");
                }
              }
            } else if (
              key !== "Tab" &&
              key !== "Enter" &&
              !DIGIT_REGEXP.test(key)
            ) {
              event.preventDefault();
            }

            if (key === "/" && value[selectionStart] === "/") {
              updateCursor(selectionStart);
            }
          },
          onMouseDown: cursorFlickeringFix,
          onKeyUp: (event) => {
            if (event.code === "Backspace" && backspaceRef.current) {
              backspaceRef.current = false;
            }
          },
          onPaste: (event) => {
            event.preventDefault();
          },
          onSelect: (event) => {
            const { currentTarget } = event;
            const selectionEnd = currentTarget.selectionEnd ?? 0;
            const selectionStart = currentTarget.selectionStart ?? 0;
            const { value } = currentTarget;

            if (selectionEnd !== selectionStart) {
              updateCursor(selectionStart);
            } else if (
              selectionStart > 0 &&
              !SINGLE_DIGIT_OR_SLASH_REGEXP.test(value[selectionStart - 1])
            ) {
              const start = selectionStart < 3 ? 0 : selectionStart < 6 ? 3 : 6;
              const boundaryIndex = value.slice(start).search(CHAR_REGEXP);
              const index =
                boundaryIndex === -1 ? start : start + boundaryIndex;

              updateCursor(index);
            }
          },
          onTouchStart: cursorFlickeringFix,
        }}
        inputRef={inputRef}
        onBlur={(...args) => {
          innerProps.onBlur();

          if (onBlur) {
            onBlur(...args);
          }
        }}
        onChange={(event) => {
          const { currentTarget } = event;
          const selectionStart = currentTarget.selectionStart ?? 0;
          const offset = backspaceRef.current ? -1 : 0;
          const { value } = currentTarget;

          const chars = value.split("");

          cursorFlickeringFix();

          if (chars[selectionStart + offset] === "/") {
            if (!backspaceRef.current) {
              const substring = chars.slice(selectionStart + 1).join("");
              chars.splice(
                selectionStart,
                DIGITS_FROM_START_REGEXP.test(substring) ? 2 : 1,
                "/",
                chars[selectionStart - 1],
              );
            }
          } else {
            chars.splice(selectionStart, offset || 1);
          }

          let [month, day, year] = chars.join("").split(SLASHES_GLOBAL_REGEXP);

          day = day.padEnd(2, "D").slice(0, 2);
          month = month.padEnd(2, "M").slice(0, 2);

          year = year
            .replace(NON_DIGITS_GLOBAL_REGEXP, "")
            .padEnd(4, "Y")
            .slice(0, 4);

          const dateString = getDateString({ day, month, year });
          const parsedDate = dayjs(dateString, "MM/DD/YYYY");
          const date = DIGIT_REGEXP.test(dateString) ? parsedDate : null;

          setInputValue({ day, month, year });

          innerProps.onChange(date);

          if (onChange) {
            onChange(date);
          }

          if (
            dateString[selectionStart] === "/" ||
            dateString[selectionStart - 1] === "/"
          ) {
            updateCursor(selectionStart + (backspaceRef.current ? -1 : 1));
          } else {
            updateCursor(selectionStart);
          }
        }}
      />
    );
  },
);
