import { type PointerEvent, type PointerEventHandler, useEffect } from "react";

const CACHE: {
  instances: number;
  target: (EventTarget & Element) | null;
} = {
  instances: 0,
  target: null,
};

function pointerUpListener() {
  if (CACHE.target !== null) {
    CACHE.target = null;
  }
}

export function useOnPointer() {
  useEffect(() => {
    if (CACHE.instances++ === 0) {
      window.addEventListener("pointerup", pointerUpListener);
    }

    return () => {
      if (--CACHE.instances === 0) {
        window.removeEventListener("pointerup", pointerUpListener);
      }
    };
  }, []);

  return <TargetElement extends Element & { disabled?: boolean }>(
    listener: PointerEventHandler<TargetElement>,
  ) => ({
    onPointerDown: ({ currentTarget }: PointerEvent<TargetElement>) => {
      if (!currentTarget.disabled) {
        CACHE.target = currentTarget;
      }
    },

    onPointerUp: (
      event: PointerEvent<TargetElement> & { target: EventTarget & Element },
    ) => {
      if (
        event.target === CACHE.target ||
        CACHE.target?.contains(event.target)
      ) {
        listener(event);
      }
    },
  });
}
