import { styled } from "@mui/material/styles";
import { PaymentForm as PaymentFormComponent } from "components/payment-form";

export const PaymentForm = styled(PaymentFormComponent, {
  label: "payment-form",
})`
  margin: ${({ theme }) => theme.spacing(4, "auto")};

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: ${({ theme }) => theme.spacing(56)};
  }
`;
