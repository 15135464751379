import { type PaymentMethod } from "frames-react";
import visaIconUrl from "assets/images/card-providers/visa.svg?url";
import mastercardIconUrl from "assets/images/card-providers/mastercard.svg?url";
import amexIconUrl from "assets/images/card-providers/american-express.svg?url";
import dinersIconUrl from "assets/images/card-providers/diners-club.svg?url";
import maestroIconUrl from "assets/images/card-providers/maestro.svg?url";
import discoverIconUrl from "assets/images/card-providers/discover.svg?url";
import { Component } from "./styles";

export interface CardIconProps {
  type?: PaymentMethod | null;
}

export const cardProviderIcons = {
  Visa: visaIconUrl,
  Mastercard: mastercardIconUrl,
  "American Express": amexIconUrl,
  "Diners Club": dinersIconUrl,
  Maestro: maestroIconUrl,
  Discover: discoverIconUrl,
};

export function CardIcon({ type }: CardIconProps) {
  const iconUrl = cardProviderIcons[type as keyof typeof cardProviderIcons];

  if (type && iconUrl) {
    return <Component src={iconUrl} alt={type} />;
  }

  return null;
}
