export type ValidationFunction<Value> = (
  value: Value,
) => ValidationResult | string | boolean | null;

export interface ValidationProps<Value> {
  onValidation?: (validation: ValidationResult) => void;
  validate?: ValidationFunction<Value> | Array<ValidationFunction<Value>>;
}

export interface ValidationResult {
  helperText: string | null;
  status: "error" | "warning" | "info" | "valid";
  state?: unknown;
}

export function validation<Value>(
  validate: ValidationFunction<Value> | Array<ValidationFunction<Value>>,
  value: Value,
): ValidationResult {
  if (!Array.isArray(validate)) {
    const valid = validate(value);

    if (valid && typeof valid === "object") return valid;

    return valid === null || typeof valid === "boolean"
      ? { helperText: null, status: valid ? "valid" : "error" }
      : { helperText: valid, status: "error" };
  }

  let defaultResponse: ValidationResult = { helperText: null, status: "valid" };

  for (const validateFunction of validate) {
    defaultResponse = validation(validateFunction, value);

    if (defaultResponse?.status !== "valid") {
      return defaultResponse;
    }
  }

  // The last validation result in the array will be sent back
  // That's how the optional state could be transfered
  return defaultResponse;
}
