import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "hooks/use-dispatch";
import { useOttUser } from "hooks/use-ott-user";
import { useSelector } from "hooks/use-selector";
import {
  CANCEL_FOR_ANY_REASON_DAYS_LIMIT,
  CANCEL_FOR_ANY_REASON_DAYS_LIMIT_MISSOURI,
  CANCEL_FOR_ANY_REASON_DAYS_LIMIT_WASHINGTON,
} from "settings";
import { updateQuoteCoverageIsSelected } from "store/quote";
import {
  selectCoverages,
  selectCoveragesPrices,
  selectDestinations,
  selectHasTripCancellationCoverage,
  selectIsDomesticTraveling,
  selectResidencyState,
  selectTripCost,
  selectTripStartDate,
} from "store/quote/selectors";
import { calculateQuotePrice } from "store/quote/thunks/quote";
import { CoverageType } from "types/quote-types";
import { numberFormatter } from "utils/formatters/number";
import { isIsraelTrip } from "utils/place/destinations";
import { mixpanelTrack } from "utils/tracking/mixpanel";

export interface AddOnStrings {
  coverageType: CoverageType;
  description: string;
  price?: number | false;
  title: string;
}

export function useAddOnsStrings({ filterDisabled = false } = {}) {
  const coverages = useSelector(selectCoverages);
  const coveragesPrices = useSelector(selectCoveragesPrices);
  const destinations = useSelector(selectDestinations);
  const residencyState = useSelector(selectResidencyState);
  const tripStartDate = useSelector(selectTripStartDate);

  const { t } = useTranslation("screens", { keyPrefix: "offer.addOns" });

  return useMemo(() => {
    const addOns: AddOnStrings[] = [];
    const extraAddOns: AddOnStrings[] = [];

    function coverageIsEnabled(coverageType: CoverageType) {
      return (
        !filterDisabled ||
        !!coverages.find((coverage) => coverage.coverageType === coverageType)
          ?.isSelected
      );
    }

    // car

    if (
      coverages.find(
        (coverage) =>
          coverage.coverageType === "car" &&
          !coverage.isBase &&
          !coverage.isDefault,
      ) &&
      coverageIsEnabled(CoverageType.car)
    ) {
      addOns.push({
        coverageType: CoverageType.car,
        description:
          residencyState === "MO"
            ? t("carCare.missouri.description")
            : residencyState === "NY"
              ? t("carCare.newYork.description")
              : residencyState === "WA"
                ? t("carCare.washington.description")
                : t("carCare.description"),
        price: coveragesPrices?.car,
        title: t("carCare.title"),
      });
    }

    // sportsExtreme

    if (
      residencyState !== "MO" &&
      residencyState !== "WA" &&
      coverageIsEnabled(CoverageType.sportsExtreme)
    ) {
      addOns.push({
        coverageType: CoverageType.sportsExtreme,
        description:
          residencyState === "NY"
            ? t("extremeSports.newYork.description")
            : t("extremeSports.description"),
        price: coveragesPrices?.sportsExtreme,
        title: t("extremeSports.title"),
      });
    }

    // vacationRental

    if (
      residencyState !== "MO" &&
      residencyState !== "NY" &&
      residencyState !== "WA" &&
      coverageIsEnabled(CoverageType.vacationRental)
    ) {
      addOns.push({
        coverageType: CoverageType.vacationRental,
        description: t("vacationRental.description"),
        price: coveragesPrices?.vacationRental,
        title: t("vacationRental.title"),
      });
    }

    // pet

    if (
      residencyState !== "MO" &&
      residencyState !== "NY" &&
      residencyState !== "WA" &&
      coverageIsEnabled(CoverageType.pet)
    ) {
      addOns.push({
        coverageType: CoverageType.pet,
        description: t("petCare.description"),
        price: coveragesPrices?.pet,
        title: t("petCare.title"),
      });
    }

    // extraAddOns

    if (residencyState === "NY") {
      extraAddOns.push({
        coverageType: CoverageType.pet,
        description: t("petCare.newYork.description"),
        title: t("petCare.title"),
      });
    }

    // cancelForAnyReason

    if (residencyState !== "NY" && residencyState !== "WA") {
      if (destinations && isIsraelTrip(destinations)) {
        const hoursDifference = dayjs(tripStartDate).diff(dayjs(), "hours");

        if (hoursDifference > 48 && hoursDifference < 72) {
          addOns.push({
            coverageType: CoverageType.cancelForAnyReason,
            description: t("cancelForAnyReason.israelTrip.description"),
            price: false,
            title: t("cancelForAnyReason.title"),
          });
        }
      } else if (
        !!coverages.find(
          (coverage) =>
            coverage.coverageType === "cancelForAnyReason" &&
            !coverage.isBase &&
            !coverage.isDefault,
        ) &&
        coverageIsEnabled(CoverageType.cancelForAnyReason)
      ) {
        addOns.unshift({
          coverageType: CoverageType.cancelForAnyReason,
          description:
            residencyState === "MO"
              ? t("cancelForAnyReason.missouri.description")
              : t("cancelForAnyReason.description"),
          price: coveragesPrices?.cancelForAnyReason,
          title: t("cancelForAnyReason.title"),
        });
      }
    }

    return { addOns, extraAddOns };
  }, [
    coverages,
    coveragesPrices?.cancelForAnyReason,
    coveragesPrices?.car,
    coveragesPrices?.pet,
    coveragesPrices?.sportsExtreme,
    coveragesPrices?.vacationRental,
    destinations,
    filterDisabled,
    residencyState,
    t,
    tripStartDate,
  ]);
}

export function useCoverage(coverageType: string) {
  const dispatch = useDispatch();
  const coverages = useSelector(selectCoverages);

  const coverage = useMemo(() => {
    const index = coverages.findIndex((coverage) => {
      return coverage.coverageType === coverageType;
    });

    if (index === -1) {
      return null;
    } else {
      return { ...coverages[index], index };
    }
  }, [coverages, coverageType]);

  return {
    coverage,
    enable: () => {
      if (coverage && !coverage.isSelected) {
        mixpanelTrack("clicked_add_on", {
          add_on_name: coverage.title,
          add_on_status: "on",
        });

        dispatch(
          updateQuoteCoverageIsSelected({
            coverageIndex: coverage.index,
            isSelected: true,
          }),
        );

        void dispatch(calculateQuotePrice());
      }
    },
    disable: () => {
      if (coverage?.isSelected) {
        mixpanelTrack("clicked_add_on", {
          add_on_name: coverage.title,
          add_on_status: "off",
        });

        dispatch(
          updateQuoteCoverageIsSelected({
            coverageIndex: coverage.index,
            isSelected: false,
          }),
        );

        void dispatch(calculateQuotePrice());
      }
    },
  };
}

export function useDisclaimerParagraphs(showPrintParagraph = false) {
  const residencyState = useSelector(selectResidencyState);

  const { t } = useTranslation("screens", { keyPrefix: "offer.disclaimers" });

  const paragraphs = t("main", {
    tripDepositDays:
      residencyState === "MO"
        ? CANCEL_FOR_ANY_REASON_DAYS_LIMIT_MISSOURI
        : residencyState === "WA"
          ? CANCEL_FOR_ANY_REASON_DAYS_LIMIT_WASHINGTON
          : CANCEL_FOR_ANY_REASON_DAYS_LIMIT,
  }).split(/\n+/);

  if (showPrintParagraph) {
    paragraphs.unshift(t("print"));
  }

  return paragraphs;
}

export function useWhatsCoveredStrings() {
  const hasTripCancelation = useSelector(selectHasTripCancellationCoverage);
  const isDomestic = useSelector(selectIsDomesticTraveling);
  const residencyState = useSelector(selectResidencyState);
  const tripCost = useSelector(selectTripCost);

  const { t } = useTranslation("screens", { keyPrefix: "offer.whatsCovered" });

  const ottUser = useOttUser("glinda");

  return useMemo(() => {
    const tripCancelation =
      typeof tripCost !== "undefined"
        ? numberFormatter(Math.floor(tripCost), {
            fractionDigits: 0,
            style: "currency",
          })
        : "";

    const tripInterruption =
      typeof tripCost !== "undefined"
        ? numberFormatter(Math.round((tripCost * 1.5) / 5) * 5, {
            fractionDigits: 0,
            style: "currency",
          })
        : "";

    return {
      yourHealth: {
        title: t("yourHealth.title", { context: ottUser && "advisor" }),
        topics: t(
          `yourHealth.topics.${
            residencyState === "MO"
              ? "missouri"
              : residencyState === "NY"
                ? "newYork"
                : residencyState === "WA"
                  ? isDomestic
                    ? "washington.domestic"
                    : "washington.international"
                  : isDomestic
                    ? "domestic"
                    : "international"
          }`,
          { returnObjects: true },
        ),
        popup: {
          content: t(
            `yourHealth.popup.${
              residencyState === "MO"
                ? "missouri.topics"
                : residencyState === "NY"
                  ? "newYork.topics"
                  : residencyState === "WA"
                    ? isDomestic
                      ? "washington.domestic.topics"
                      : "washington.international.topics"
                    : isDomestic
                      ? "domestic.topics"
                      : "international.topics"
            }`,
            { returnObjects: true },
          ),
          subtitle: t("yourHealth.popup.subtitle"),
          title: t("yourHealth.popup.title"),
          trigger: t("yourHealth.popup.link"),
        },
      },

      yourStuff: {
        title: t("yourStuff.title", { context: ottUser && "advisor" }),
        topics: t(
          `yourStuff.${
            residencyState === "MO"
              ? "missouri.topics"
              : residencyState === "NY"
                ? "newYork.topics"
                : residencyState === "WA"
                  ? "washington.topics"
                  : "topics"
          }`,
          { returnObjects: true },
        ),
        popup: {
          content: t(
            `yourStuff.popup.${
              residencyState === "MO"
                ? "missouri.topics"
                : residencyState === "NY"
                  ? "newYork.topics"
                  : residencyState === "WA"
                    ? "washington.topics"
                    : "topics"
            }`,
            { returnObjects: true },
          ),
          subtitle: t("yourStuff.popup.subtitle"),
          title: t("yourStuff.popup.title"),
          trigger: t("yourStuff.popup.link"),
        },
      },

      yourTrip: {
        title: t("yourTrip.title", { context: ottUser && "advisor" }),
        topics: t(
          `yourTrip.topics.${
            residencyState === "MO"
              ? isDomestic
                ? "missouriDomestic"
                : "missouri"
              : residencyState === "NY"
                ? hasTripCancelation
                  ? "newYork.withTripCancelation"
                  : "newYork.withoutTripCancelation"
                : residencyState === "WA"
                  ? "washington"
                  : hasTripCancelation
                    ? isDomestic
                      ? "withTripCancelationDomestic"
                      : "withTripCancelation"
                    : isDomestic
                      ? "withoutTripCancelationDomestic"
                      : "withoutTripCancelation"
          }`,
          {
            returnObjects: true,
            tripCancelation,
            tripInterruption,
          },
        ),
        popup: {
          content: t(
            `yourTrip.popup.${
              residencyState === "MO"
                ? isDomestic
                  ? "missouri.domestic.topics"
                  : "missouri.topics"
                : residencyState === "NY"
                  ? hasTripCancelation
                    ? "withTripCancelation.newYork.topics"
                    : "withoutTripCancelation.newYork.topics"
                  : residencyState === "WA"
                    ? "washington.topics"
                    : hasTripCancelation
                      ? isDomestic
                        ? "withTripCancelation.domestic.topics"
                        : "withTripCancelation.topics"
                      : isDomestic
                        ? "withoutTripCancelation.domestic.topics"
                        : "withoutTripCancelation.topics"
            }`,
            {
              returnObjects: true,
              tripCancelation,
              tripInterruption,
            },
          ),
          subtitle: t("yourTrip.popup.subtitle"),
          title: t("yourTrip.popup.title"),
          trigger: t("yourTrip.popup.link"),
        },
      },
    };
  }, [hasTripCancelation, isDomestic, ottUser, residencyState, t, tripCost]);
}
