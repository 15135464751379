import { useEffect, useRef, useContext, useState } from "react";
import {
  type FramePaymentMethodChangedEvent,
  type FrameCardValidationChangedEvent,
  type FrameValidationChangedEvent,
  type PaymentMethod,
  type FrameElementIdentifer,
} from "frames-react";
import { useTranslation } from "react-i18next";
import { sendQuotePayment } from "utils/api/quote";
import { sentryCaptureError } from "utils/tracking/sentry";
import { type CreditCardProps } from "../../credit-card";
import { ProviderContext } from "../..";
import { Field } from "./field";
import { CardIcon } from "./card-icon";
import {
  CardNumberField,
  ExpiryDateField,
  CvvField,
  Container,
  PoweredByCheckout,
} from "./styles";

type ElementsValidity = {
  [key in FrameElementIdentifer]: boolean | null;
};

export function CheckoutCard({
  active,
  onLoad,
  onChange,
  onPaymentChange,
  userPolicy,
  quoteDto,
  formId,
}: CreditCardProps) {
  const [token, setToken] = useState<string>();
  const [isValid, setIsValid] = useState(false);
  const [validation, setValidation] = useState<ElementsValidity>({
    "card-number": null,
    "expiry-date": null,
    cvv: null,
  });
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null,
  );

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const onPaymentChangeRef = useRef(onPaymentChange);
  onPaymentChangeRef.current = onPaymentChange;

  const { isActivated } = useContext(ProviderContext) ?? {};

  const { t } = useTranslation("components", {
    keyPrefix: "paymentForm",
  });

  useEffect(() => {
    if (isActivated !== undefined) {
      onLoad();
    }
  }, [isActivated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (active && isActivated === false) {
      onPaymentChangeRef.current("creditCard", {
        status: "error",
        message: t("error.default"),
      });
    }
  }, [active, isActivated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onChangeRef.current?.(isValid ?? false, false);
  }, [isValid]);

  // register Frames event listeners
  useEffect(() => {
    // using window.Frames instead of Frames because the events
    // have weird type signature

    window.Frames?.addEventHandler(
      window.Frames.Events.PAYMENT_METHOD_CHANGED,
      onPaymentMethodChanged,
    );

    window.Frames?.addEventHandler(
      window.Frames.Events.CARD_VALIDATION_CHANGED,
      onCardValidationChanged,
    );

    window.Frames?.addEventHandler(
      window.Frames.Events.FRAME_VALIDATION_CHANGED,
      onFrameValidationChanged,
    );

    return () => {
      window.Frames?.removeEventHandler(
        window.Frames.Events.PAYMENT_METHOD_CHANGED,
        onPaymentMethodChanged,
      );

      window.Frames?.removeEventHandler(
        window.Frames.Events.CARD_VALIDATION_CHANGED,
        onCardValidationChanged,
      );

      window.Frames?.removeEventHandler(
        window.Frames.Events.FRAME_VALIDATION_CHANGED,
        onFrameValidationChanged,
      );
    };
  }, [isActivated]); // eslint-disable-line react-hooks/exhaustive-deps

  function onPaymentMethodChanged(event: FramePaymentMethodChangedEvent) {
    setPaymentMethod(event.paymentMethod);
  }

  function onCardValidationChanged(event: FrameCardValidationChangedEvent) {
    setIsValid(event.isValid);
  }

  function onFrameValidationChanged(event: FrameValidationChangedEvent) {
    setValidation({ ...validation, [event.element]: event.isValid });
  }

  async function onTokenize(token: string) {
    try {
      onPaymentChangeRef.current("creditCard", {
        status: "success",
        response: await sendQuotePayment(
          {
            paymentService: "checkOut",
            paymentToken: token,
            purchasePaymentType: "PAYMENT_TYPE_CREDIT_CARD",
            ...(userPolicy?.id && { quoteId: userPolicy.id }),
          },
          quoteDto,
        ),
      });
    } catch (error) {
      const message =
        (error as Error).message ??
        (typeof error === "string" ? error : t("error.default"));
      onTokenizeFailed(message);
    }
  }

  function onTokenizeFailed(message?: string | null) {
    sentryCaptureError(message, {
      contexts: { payment: { paymentType: "creditCard" } },
      name: "Payment_failed",
    });

    onPaymentChangeRef.current("creditCard", {
      status: "error",
      message: message ?? t("error.default"),
    });
  }

  return (
    <Container
      id={formId}
      onSubmit={async (event) => {
        event.preventDefault();
        onPaymentChangeRef.current("creditCard", { status: "request" });
        if (!token) {
          try {
            const { token: chkToken } = await window.Frames.submitCard();
            setToken(chkToken);
            await onTokenize(chkToken);
          } catch (error) {
            const message =
              (error as Error).message ??
              (typeof error === "string" ? error : t("error.default"));
            onTokenizeFailed(message);
          }
        } else {
          await onTokenize(token);
        }
      }}
    >
      <Field
        fullWidth
        error={validation?.["card-number"] === false}
        label={<PoweredByCheckout />}
        InputProps={{
          endAdornment: <CardIcon type={paymentMethod} />,
        }}
      >
        <CardNumberField />
      </Field>
      <Field error={validation?.["expiry-date"] === false}>
        <ExpiryDateField />
      </Field>
      <Field error={validation?.cvv === false}>
        <CvvField />
      </Field>
    </Container>
  );
}
