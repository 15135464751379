import { useEffect, useState } from "react";
import { Outlet, useBlocker, useLocation, useNavigate } from "react-router-dom";
import { ENTRY_ROUTE } from "settings";

const CACHE = { visitedKeys: [] as string[] };
const VISITED_KEYS_STORAGE_KEY = "faye:onboarding:visited_keys";

export function Redirect() {
  const { key, pathname, search } = useLocation();
  const navigate = useNavigate();

  const [willRedirect, setWillRedirect] = useState<boolean>();

  useBlocker(({ currentLocation }) => {
    return currentLocation.pathname.includes("/confirmation");
  });

  useEffect(() => {
    CACHE.visitedKeys = JSON.parse(
      window.sessionStorage.getItem(VISITED_KEYS_STORAGE_KEY) ?? "[]",
    );

    setWillRedirect(false);

    if (CACHE.visitedKeys.includes(key)) {
      return;
    } else {
      const searchParams = new URLSearchParams(search);

      if (
        (pathname.includes("/checkout") && searchParams.get("amend")) ||
        (pathname.includes("/offer") &&
          searchParams.get("utm_term") !== "glinda")
      ) {
        return;
      }
    }

    if (!pathname.startsWith(ENTRY_ROUTE)) {
      navigate(ENTRY_ROUTE, { replace: true });
      setWillRedirect(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (key !== "default" && !CACHE.visitedKeys.includes(key)) {
      CACHE.visitedKeys.push(key);

      window.sessionStorage.setItem(
        VISITED_KEYS_STORAGE_KEY,
        JSON.stringify(CACHE.visitedKeys),
      );
    }
  }, [key]);

  useEffect(() => {
    if (willRedirect) {
      setWillRedirect(false);
    }
  }, [willRedirect]);

  return willRedirect === false ? <Outlet /> : null;
}
