import { formHelperTextClasses } from "@mui/material/FormHelperText";
import { inputBaseClasses } from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { DateInput as DateInputComponent } from "components/input/date";
import { PopupBody as PopupBodyComponent } from "../popup-body";

export const DateInput = styled(DateInputComponent, {
  label: "date-input",
})`
  .${inputBaseClasses.input}, .${formHelperTextClasses.root} {
    text-align: center;
  }
`;

export const Caption = styled("p", {
  label: "caption",
})`
  font-size: 16px;
  line-height: 1.25;
  margin-top: ${({ theme }) => theme.spacing(1.5)};

  &.alert {
    color: ${({ theme }) => theme.palette.color.alert.main};
  }

  &.warning {
    color: ${({ theme }) => theme.palette.color.warning.text};
  }
`;

export const PopupBody = styled(PopupBodyComponent, {
  label: "popup-body",
})`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: ${({ theme }) => theme.spacing(60)};
  }
`;
