import { styled } from "@mui/material/styles";
import { Paper as BasePaper } from "components/autocomplete/base/paper";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import ResultsFromGoogleImage from "assets/images/results-from-google.svg";
import { PlainButton } from "components/button/plain";
import ClearIconAsset from "assets/images/icons/close-light.svg";

export const Component = styled("div", { label: "autocomplete" })`
  width: calc(100% - 1rem);
  margin: ${({ theme }) => theme.spacing(0, 0.5)};
  position: relative;
`;

export const InputWrapper = styled("div", { label: "input-wrapper" })`
  display: flex;
  color: ${({ theme }) => theme.palette.color.gray[80]};
  background: ${({ theme }) => theme.palette.color.gray[100]};
  border: 1px solid currentColor;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1.5)};
  &:focus-within {
    color: ${({ theme }) => theme.palette.color.primary.main};
  }
`;

export const Input = styled("input", {
  label: "input",
})`
  border: unset;
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 16px;
  flex: auto;

  line-height: inherit;
  outline: unset;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 14px;
    line-height: 1.5;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.color.gray[70]};
    opacity: 1;
  }
`;

export const Paper = styled(BasePaper, { label: "autocomplete-paper" })`
  && .${autocompleteClasses.option}.${autocompleteClasses.focused} {
    background-color: ${({ theme }) => theme.palette.color.primary[95]};
  }
`;

export const Footer = styled("div", {
  label: "footer",
})`
  align-items: flex-end;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

export const FullAddressTrigger = styled(PlainButton, {
  label: "full-address-trigger",
})`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ResultsFromGoogle = styled(ResultsFromGoogleImage, {
  label: "results-from-google",
})`
  fill: ${({ theme }) => theme.palette.color.gray[70]};
  flex: 0 0 auto;
  margin: 0.1em 0 0 auto;
  width: auto;
  height: 1.1em;
`;

export const ClearIcon = styled(ClearIconAsset, {
  label: "clear-icon",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(1.5)};
  height: auto;
`;
