import { styled } from "@mui/material/styles";
import { theme } from "utils/theme";
import { CardNumber, ExpiryDate, Cvv, type FramesStyle } from "frames-react";
import PoweredByCheckoutAsset from "assets/images/powerd-by-checkout.svg";

export const Container = styled("form", {
  label: "credit-card",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Field = styled("div", { label: "field" })`
  height: 18px;
  width: 100%;
`;

export const CardNumberField = Field.withComponent(CardNumber);
export const ExpiryDateField = Field.withComponent(ExpiryDate);
export const CvvField = Field.withComponent(Cvv);

export const styles: FramesStyle = {
  base: {
    fontSize: "16px",
    lineHeight: "1.25",
  },
  placeholder: {
    base: {
      fontFamily: theme.typography.fontFamily,
    },
  },
};

export const PoweredByCheckout = styled(PoweredByCheckoutAsset, {
  label: "powered-by-checkout",
})`
  fill: ${({ theme }) => theme.palette.color.gray[70]};
  margin-left: auto;
  width: auto;
  height: ${({ theme }) => theme.spacing(1.5)};
`;
