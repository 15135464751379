import { buttonBaseClasses } from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import { BaseButton } from "./base";

export const Component = styled(BaseButton, {
  label: "button",
})`
  border-radius: ${({ theme }) => theme.shape.borderRadius * 5}px;

  padding: ${({ theme }) => theme.spacing(1.75, 2.5)};
  transition: ${({ theme }) =>
    theme.transitions.create(["background", "border-color", "color"])};

  &.${buttonBaseClasses.disabled} {
    cursor: default;
    // MUI disables pointer-events, it may show the wrong
    // cursor sometimes, so reenable it
    pointer-events: auto;
  }

  &.compact {
    padding: ${({ theme }) => theme.spacing(1.25, 2.5)};
  }

  &.standard {
    background: ${({ theme }) => theme.palette.color.primary.main};
    color: ${({ theme }) => theme.palette.color.text.inverse};

    &:hover:not(.${buttonBaseClasses.disabled}) {
      background: ${({ theme }) => theme.palette.color.primary[70]};
    }

    &.${buttonBaseClasses.disabled} {
      background: ${({ theme }) => theme.palette.color.gray[80]};
      color: ${({ theme }) => theme.palette.color.gray[100]};
    }
  }

  &.outlined {
    background: ${({ theme }) => theme.palette.color.gray[100]};
    border: 1px solid ${({ theme }) => theme.palette.color.primary[90]};
    color: ${({ theme }) => theme.palette.color.primary.main};

    &.${buttonBaseClasses.disabled} {
      border-color: ${({ theme }) => theme.palette.color.gray[90]};
      color: ${({ theme }) => theme.palette.color.gray[60]};
      background: ${({ theme }) => theme.palette.color.gray[95]};
    }

    &:hover:not(.${buttonBaseClasses.disabled}) {
      background: ${({ theme }) => theme.palette.color.primary.background};
    }
  }

  &.link {
    padding: 0;
    background: transparent;
    color: ${({ theme }) => theme.palette.color.primary.main};

    &.${buttonBaseClasses.disabled} {
      color: ${({ theme }) => theme.palette.color.gray[80]};
    }

    &:hover:not(.${buttonBaseClasses.disabled}) {
      color: ${({ theme }) => theme.palette.color.primary[70]};
    }
  }
`;

export const OutlinedContentWrapper = styled("span", {
  label: "outlined-content-wrapper",
})`
  align-items: inherit;
  display: flex;
  justify-content: inherit;
  margin: -1px 0;
`;

export const Svg = styled("svg", {
  label: "svg",
})`
  height: auto;
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(1.5)};
  height: ${({ theme }) => theme.spacing(1.5)};
`;

export const Label = styled("span", {
  label: "button-label",
})`
  gap: ${({ theme }) => theme.spacing(1)};
  font-weight: 500;
  display: inline-flex;
  align-items: center;
`;
