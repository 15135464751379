import dayjs, { type Dayjs } from "dayjs";
import { getMaxEnd, getMaxStart } from "./utils";

export type StartDateError =
  | "startDateInvalid"
  | "startDateBeforeToday"
  | "startDateAfterMaxDate"
  | "startDateAfterEndDate";

export type EndDateError =
  | "endDateInvalid"
  | "endDateAfterMaxDate"
  | "endDateBeforeStartDate"
  | "exceedsMaxDuration";

function validateStartDate(startDate: Dayjs | null, endDate: Dayjs | null) {
  if (startDate === null) {
    return null;
  } else if (!startDate.isValid()) {
    return "startDateInvalid";
  } else if (startDate.isBefore(dayjs().startOf("day"))) {
    return "startDateBeforeToday";
  } else if (startDate.isAfter(getMaxStart())) {
    return "startDateAfterMaxDate";
  } else if (endDate === null) {
    return null;
  } else if (startDate.isAfter(endDate)) {
    return "startDateAfterEndDate";
  }

  return null;
}

function validateEndDate(startDate: Dayjs | null, endDate: Dayjs | null) {
  if (endDate === null) {
    return null;
  } else if (!endDate.isValid()) {
    return "endDateInvalid";
  } else if (endDate.isAfter(getMaxEnd(startDate))) {
    return "endDateAfterMaxDate";
  } else if (startDate === null) {
    return null;
  } else if (endDate.isBefore(startDate)) {
    return "endDateBeforeStartDate";
  } else if (endDate.isAfter(getMaxEnd(startDate))) {
    return "exceedsMaxDuration";
  }

  return null;
}

export function validate(
  value: [Dayjs | null, Dayjs | null],
): [StartDateError | null, EndDateError | null] {
  return [validateStartDate(...value), validateEndDate(...value)];
}
