import { styled } from "@mui/material/styles";
import { PopupBody } from "components/popup/body";

export const Component = styled(PopupBody, {
  label: "popup-body",
})`
  color: ${({ theme }) => theme.palette.color.text.primary};
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;

  strong {
    font-weight: 700;
  }
`;
