import { styled } from "@mui/material/styles";
import Logo from "assets/images/logo/main.svg";

export const Container = styled("div", {
  label: "logo",
})`
  position: relative;
`;

export const Image = styled(Logo, {
  label: "image",
})`
  display: block;
  fill: currentColor;
  position: relative;
  width: auto;
  height: ${({ theme }) => theme.spacing(5.5)};
  z-index: 1;
`;

export const Version = styled("div", {
  label: "version",
})`
  cursor: default;
  display: block;
  font-size: 13px;
  opacity: 0;
  position: absolute;
  top: 100%;
  transform: translate3d(0, ${({ theme }) => theme.spacing(-2)}, 0);
  transition: ${({ theme }) => theme.transitions.create("opacity")},
    ${({ theme }) => theme.transitions.create("transform")};
  user-select: none;
  width: ${({ theme }) => theme.spacing(6)};
  height: ${({ theme }) => theme.spacing(6)};

  &.show {
    opacity: 1;
    transform: translate3d(0, ${({ theme }) => theme.spacing(-1.75)}, 0);
  }
`;
