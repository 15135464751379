import { styled } from "@mui/material/styles";
import MailIconAsset from "assets/images/icons/mail.svg";
import { Button } from "components/button";
import { Input } from "components/input";

export const Form = styled("form", {
  label: "form",
})`
  align-items: flex-start;
  display: flex;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.spacing(72)};
`;

export const FormInput = styled(Input, {
  label: "form-input",
})`
  .faye__input__container {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 52px;

    ${({ theme }) => theme.breakpoints.up("sm")} {
      height: 54px;
    }
  }
`;

export const FormSubmitButton = styled(Button, {
  label: "form-submit-button",
})`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 300;
  height: 52px;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-weight: 600;
    height: 54px;
    padding: ${({ theme }) => theme.spacing(0, 4.5)};
  }
`;

export const HomepageLinkButton = styled(Button, {
  label: "homepage-link-button",
})`
  display: block;
  margin: ${({ theme }) => theme.spacing(3, "auto", 0)};
  max-width: ${({ theme }) => theme.spacing(42)};
  text-align: center;
`;

export const MailIcon = styled(MailIconAsset, {
  label: "mail-icon",
})`
  fill: ${({ theme }) => theme.palette.color.primary.main};
  margin: ${({ theme }) => theme.spacing(0, 0.5, 0, 1)};
  width: ${({ theme }) => theme.spacing(2)};
  height: auto;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: ${({ theme }) => theme.spacing(0, 1, 0, 2)};
  }
`;
