import { useState } from "react";
import { type AccordionDetailsProps } from "@mui/material/AccordionDetails";
import { type AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { useBreakpoint } from "hooks/use-breakpoint";
import { SectionTitle } from "../title";
import { Container, Content } from "./styles";

export interface SectionBodyProps extends AccordionDetailsProps {
  titleProps?: AccordionSummaryProps;
}

export function SectionBody({ title, titleProps, ...props }: SectionBodyProps) {
  const mdBreakpoint = useBreakpoint("md");
  const [expanded, setExpanded] = useState(true);

  return (
    <Container expanded={expanded}>
      <SectionTitle
        {...titleProps}
        {...(!mdBreakpoint && {
          onClick() {
            setExpanded((prev) => !prev);
          },
        })}
        role={mdBreakpoint ? "heading" : "button"}
      >
        {title}
      </SectionTitle>
      <Content {...props} />
    </Container>
  );
}
