import { styled } from "@mui/material/styles";
import { Button } from "../../buttons/button";

export const ButtonWrapper = styled("div", {
  label: "payment-button-wrapper",
})`
  transition: ${({ theme }) => theme.transitions.create("filter")};

  &.disabled {
    pointer-events: none;
    filter: grayscale(1) invert(0.8) opacity(0.5);

    && .focus {
      outline: 0;
    }
  }

  && button {
    vertical-align: middle;
    border-radius: ${({ theme }) => theme.spacing(1)};
    height: ${({ theme }) => theme.spacing(5.25)};

    &:not(.gpay-card-info-container) {
      height: ${({ theme }) => theme.spacing(5.5)};
    }
    .gpay-card-info-animated-progress-bar-container {
      width: calc(100% - 8px);
      left: 4px;
    }
  }
`;

export const SuccessButton = styled(Button, { label: "success-button" })`
  && {
    background: ${({ theme }) => theme.palette.color.gray[10]};
    color: ${({ theme }) => theme.palette.color.gray[100]};
    padding: ${({ theme }) => theme.spacing(1.5, 4)};
    margin: 0;
  }
`;
